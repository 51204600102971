<template>
  <v-app>

    <v-fade-transition hide-on-leave>
      <app-nav v-if="isAuthorised" />
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <v-main v-if="isRegisteredModules || !isAuthorised">
        <v-fade-transition hide-on-leave>
          <router-view />
        </v-fade-transition>
      </v-main>
    </v-fade-transition>

    <app-footer v-if="$vuetify.breakpoint.mdAndUp || $route.name === 'Login'" />

    <app-alert />

    <app-popup />

    <app-button-scroll />

  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Nav from '@/components/nav/Nav'
import Footer from '@/components/Footer'
import Popup from '@/components/UI-Kit/popups/Popup.vue'
import Alert from './components/UI-Kit/alerts/Alert'
import ButtonScroll from '@/components/UI-Kit/buttons/ButtonScroll'

export default {
  name: 'App',
  components: {
    'app-nav': Nav,
    'app-footer': Footer,
    'app-popup': Popup,
    'app-alert': Alert,
    'app-button-scroll': ButtonScroll
  },
  computed: {
    ...mapGetters([
      'isAuthorised',
      'isRegisteredModules',
      'getClubByObjectId'
    ])
  },
  created () {
    // if (this.isMobile()) {
    //   this.showAlert({
    //     text: 'Мобильная версия портала в разработке. Для вашего удобства рекомендуем использовать компьютер или ноутбук.',
    //     type: 'success',
    //     timeout: 0
    //   })
    // }
    this.sockets.subscribe('reportsGenerate', (msg) => {
      if (msg.data && msg.data.club) {
        let clubId = this.getClubByObjectId(msg.data.club).id
        if (this.$route.params.id !== clubId && msg.data.type !== this.$route.params.report_type) {
          let text = `Отчет ${msg.data.name} успешно сформирован <br> <a class="v-btn v-btn--contained theme--light v-size--default button button--outline button--s mt-1 v-btn--wait" href="/clubs/${clubId}/reports/generated/${msg.data._id}">Перейти к отчету</a>`
          if (msg.data.empty) {
            text = `Для формирования отчета <strong>${msg.data.name}</strong> данные не найдены</a>`
          }
          this.showAlert(
            {
              text,
              type: 'new',
              timeout: -1
            }
          )
        }
      }
    })
    this.sockets.subscribe('reportsGenerateError', (data) => {
      this.showAlert(
        {
          text: `Генерация отчета ${data.data.name} произошла с ошибкой. Пожалуйста попробуйте позже.`,
          type: 'error',
          timeout: -1
        }
      )
    })
  },
  methods: {
    ...mapActions([
      'showAlert'
    ]),
    isMobile () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 960) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
  .mainProgress {
    top: 64px;
    position: fixed;
  }
  html {
    overflow-y: auto;
  }
  .v-treeview-node__children {
  /* width */
  &::-webkit-scrollbar {
     width: 3px;
     background: transparent;
     margin-right: 1px;
   }

  /* Track */
  &::-webkit-scrollbar-track {
     background: transparent;
   }

  /* Handle */
  &::-webkit-scrollbar-thumb {
     background: rgba(72, 72, 72, .3);
     width: 3px;
     border-radius: 5px;
   }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
     background: rgba(72, 72, 72, .9);
   }
  }
  a {
    text-decoration: none;
  }
</style>
