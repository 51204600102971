import { productsApi } from '@/api/products'
import Vue from 'vue'

/**
 *
 * @param {array} promoCodes
 * @param {array} productCodes
 * @return {string} value Regexp for promotion
 */
function promotionValueToServer (promoCodes, productCodes) {
  let str = ''
  if (productCodes.length && promoCodes.length) {
    const assign = []
    productCodes.forEach(product => {
      promoCodes.forEach(promo => {
        assign.push(`${product}_${promo}`)
      })
    })
    str = `^` + assign.join('$|^') + '$'
  } else if (productCodes.length && !promoCodes.length) {
    str = `^` + productCodes.join('*$|^') + '*$'
  } else if (!productCodes.length && promoCodes.length) {
    str = `^.*_` + promoCodes.join('$|^.*_') + '$'
  }
  return str
}

/**
 * @param value {String} Regexp from promotion server
 * @return parseValue.productCodes {Array} Array with list of productCodes
 * @return parseValue.promoCodes {Array} Array with list of productCodes
 */
function promotionValueFromServer (value) {
  let promoCodes = new Set()
  let productCodes = new Set()
  if (/\w+_\w+/.test(value)) {
    value.split(/\W+/).forEach(item => {
      if (item.length) {
        const parseItem = item.split('_')
        productCodes.add(parseItem[0])
        if (parseItem[1]) {
          promoCodes.add(parseItem[1])
        }
      }
    })
  } else if (/_\w+/.test(value)) {
    value.split(/\W+|_/).forEach(item => {
      if (item.length) {
        promoCodes.add(item)
      }
    })
  } else if (value) {
    value.split(/\W+|_/).forEach(item => {
      if (item.length) {
        productCodes.add(item)
      }
    })
  }
  return {
    promoCodes: Array.from(promoCodes),
    productCodes: Array.from(productCodes)
  }
}
export default {
  namespaced: true,
  state: () => ({
    products: [],
    productsStatus: '', // loading, ok, error
    promotions: [],
    promotionsStatus: '' // loading, ok, error
  }),
  getters: {
    getProducts: state => state.products,
    getProductsStatus: state => state.productsStatus,
    getPromotions: state => state.promotions,
    getPromotionsStatus: state => state.productsStatus,
    getProductById: state => (productCode) => {
      return state.products.find(product => product.code === productCode)
    },
    getCategories: state => {
      const categories = new Set()
      state.products.forEach(product => {
        if (product.categories) {
          product.categories.forEach(cat => {
            categories.add(cat)
          })
        }
      })
      return Array.from(categories)
    },
    getProductsByRetailPoints: state => (retailPoints) => {
      const products = []
      retailPoints.forEach(point => {
        state.products.forEach(product => {
          if (product.retailPoints.includes(point)) {
            products.push(product)
          }
        })
      })
      return Array.from(products)
    }
  },
  mutations: {
    SET_PRODUCTS (state, data) {
      console.log(data)
      state.products.push(...data)
    },
    SET_PRODUCTS_STATUS (state, status) {
      state.productsStatus = status
    },
    SET_PROMOTIONS_STATUS (state, status) {
      state.promotionsStatus = status
    },
    SET_EDIT_PRODUCT (state, data) {
      const index = state.products.findIndex(product => product._id === data._id)
      Vue.set(state.products, index, data)
    },
    REMOVE_PRODUCT (state, id) {
      const index = state.products.findIndex(product => product._id === id)
      Vue.delete(state.products, index)
    },
    REMOVE_MANY (state, products) {
      products.forEach(del => {
        const index = state.products.findIndex(product => product._id === del)
        Vue.delete(state.products, index)
      })
    },
    ADD_MANY (state, products) {
      state.products.unshift(...products)
    },
    SET_PROMOTIONS (state, promotions) {
      const refactorPromo = promotions.map(promo => {
        return {
          ...promo,
          ...promotionValueFromServer(promo.values[0].value)
        }
      })
      state.promotions = refactorPromo
    },
    EDIT_PROMOTION (state, payload) {
      let index = state.promotions.findIndex(promo => promo.id === payload.id)
      Vue.set(state.promotions, index, {
        ...payload,
        ...promotionValueFromServer(payload.values[0].value)
      })
    },
    ADD_PROMOTION (state, payload) {
      state.promotions.unshift({
        ...payload,
        ...promotionValueFromServer(payload.values[0].value)
      })
    },
    REMOVE_PROMOTIONS (state, payload) {
      payload.forEach(deletePromo => {
        let index = state.promotions.findIndex(promo => promo.id === deletePromo.id)
        Vue.delete(state.promotions, index)
      })
    }
  },
  actions: {
    async loadProducts ({ commit, getters }, clubId) {
      commit('SET_PRODUCTS_STATUS', 'loading')
      try {
        let products = await productsApi.all(clubId)
        commit('SET_PRODUCTS', products.data)
        commit('SET_PRODUCTS_STATUS', 'ok')
      } catch (e) {
        commit('SET_PRODUCTS_STATUS', 'error')
        console.error(e)
      }
    },
    async loadPromotions ({ commit }, clubId) {
      commit('SET_PROMOTIONS_STATUS', 'loading')
      try {
        const res = await productsApi.getPromo(clubId)
        commit('SET_PROMOTIONS', res.data)
        commit('SET_PROMOTIONS_STATUS', 'ok')
      } catch (e) {
        commit('SET_PROMOTIONS_STATUS', 'error')
        console.error(e)
        return false
      }
    },
    async editProduct ({ commit, dispatch }, data) {
      try {
        let edit = await productsApi.edit(data)
        commit('SET_EDIT_PRODUCT', edit.data)
        return true
      } catch (e) {
        console.error(e)
        return {
          error: e.data.message
        }
      }
    },
    async removeProduct ({ commit }, data) {
      try {
        const del = await productsApi.remove(data.clubId, data.id)
        console.log(del)
        commit('REMOVE_PRODUCT', del.data._id)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async removeSelected ({ commit }, data) {
      try {
        await productsApi.removeSelected(data.clubId, data.data)
        commit('REMOVE_MANY', data.data)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async addMany ({ commit }, data) {
      try {
        const res = await productsApi.addMany(data.clubId, data.data)
        commit('ADD_MANY', res.data)
        return true
      } catch (e) {
        console.error(e)
        return {
          error: e.data.message
        }
      }
    },
    async editPromotion ({ commit }, payload) {
      try {
        const body = {
          id: payload.data.id,
          promoType: payload.data.promoType,
          operationType: payload.data.operationType,
          beginDate: payload.data.beginDate,
          endDate: payload.data.endDate,
          title: payload.data.title,
          retailPoints: payload.data.retailPoints,
          display: payload.data.display,
          rewards: payload.data.rewards,
          values: [
            {
              ...payload.data.values[0],
              value: promotionValueToServer(payload.data.promoCodes, payload.data.productCodes)
            }
          ]
        }
        console.log(body)
        const res = await productsApi.editPromo(payload.clubId, body)
        commit('EDIT_PROMOTION', res.data)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async addPromotion ({ commit }, payload) {
      try {
        const now = new Date().setHours(new Date().getHours() + 3)
        const body = {
          promoType: payload.data.promoType,
          operationType: 'PAYMENT',
          beginDate: new Date(now).toISOString(),
          endDate: '9999-12-31T23:59:59',
          title: `Акция на товары от ${new Date().toLocaleString()}`,
          retailPoints: payload.data.retailPoints,
          display: false,
          rewards: payload.data.rewards,
          value: promotionValueToServer(payload.data.promoCodes, payload.data.productCodes)
        }
        const res = await productsApi.addPromo(payload.clubId, body)
        commit('ADD_PROMOTION', res.data)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async removePromotions ({ commit }, payload) {
      try {
        const res = await productsApi.removePromo(payload.clubId, payload.data)
        commit('REMOVE_PROMOTIONS', res.data)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    }
  }
}
