<template>
  <div>
    <v-row
      class="mt-0 mb-0"
      v-for="param in data.values"
      :key="param.title"
    >
      <v-col class="col-12 col-sm-6">
        {{ getParamTitle(param.parameterId) }}
      </v-col>

      <v-col class="col-12 col-sm-6 promotions-info__value">
        {{ param.value }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PromotionsInfoParameters',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    clubId () {
      return this.$route.params.id
    },
    template () {
      return this.$store.getters[`${this.clubId}/promotions/getTemplate`](this.data.templateId)
    }
  },
  methods: {
    getParamTitle (paramId) {
      let tmp = this.template.parameters.find(param => {
        return param.parameterId === paramId
      })
      return tmp.title
    }
  }
}
</script>

<style lang="scss">

</style>
