<template>
  <v-select
    v-model="v"
    ref="select"
    :class="`input select select--multiple select--${mode}`"
    :items="itemsComputed"
    :item-text="itemText"
    :item-value="itemValue"
    rounded
    :label="selectLabel"
    multiple
    clearable
    :disabled="disabled"
    :menu-props="getMenuProps"
    v-resize="setWidth"
    @change="$emit('change', $event)"
    @click:clear="clear"
    @blur="clearOnBlur ? clear() : null"
    :return-object="returnObject"
    :hide-details="hideDetails"
    v-scroll="onScroll"
    :rules="rules"
    @update:error="$emit('error', $event)"
  >
    <template
      v-if="['point', 'terminal', 'template'].indexOf(mode) !== -1"
      v-slot:prepend-inner
    >
      <div
        class="select__prepend-icon"
        v-html="require(`@/assets/img/icons/${mode}.svg`)"
      />
    </template>
    <template v-slot:append>
      <div
        class="select__append-icon"
        v-html="require(`@/assets/img/icons/arrow.svg`)"
      />
    </template>
    <template
      v-slot:item="{ parent, item, on, attrs }"
      v-if="mode === 'terminal'"
    >
      <v-list-item
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-action>
          <v-simple-checkbox
            :value="attrs.inputValue"
            @change="parent.$emit('select')"
          />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.code }} - {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:prepend-item>
      <div class="select__prepend-item">
        <app-input
          mode="search"
          :value.sync="search"
          placeholder="Начните вводить название..."
        />
      </div>
      <v-list-item
        ripple
        v-if="selectAll"
        @change="toggleSelectAll()"
        class="v-list-item-select-all"
        :class="{ 'v-list-item--active': selectAllItems }"
      >
        <v-list-item-action>
          <v-simple-checkbox
            @click="toggleSelectAll()"
          />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ selectAllItems ? 'Отменить выделение' : 'Выбрать все'}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-slot:selection="{ item, index }">
      <span
        v-if="index === 0"
        class="mr-1"
      >
        {{ item.title || item.text || item }}
      </span>
      <span
        v-if="index === 1"
        class="select__selection-quantity"
      >
        (еще {{ v.length - 1 }})
      </span>
    </template>
    <template v-slot:no-data>
      <div class="select__no-data">Нет данных</div>
    </template>
  </v-select>
</template>

<script>
/**
 * Компонент селекта с возможностью выбора нескольких значений и фильтрации значений по названию
 * @view
 * Селекта с возможностью выбора нескольких значений и инпут для фильтрации значений
 * @actions
 * setWidth: Устанавливает ширину выпадающего меню равную инпуту
 * searchItems: Фильтрует отображаемые значения
 * @props
 * mode String Тип селекта (для добавления иконок, стилей соответствующих выбранному типу)
 * value Any Возвращаемое значение
 * items Array Список отображаемых значений в селекте
 * headers Array Список сетей/точек для группировки точек/терминалов по их сети/точке
 * label String Плейсхолдер
 * disabled Boolean
 * itemText Поле в котором содержится название пункта меню
 * itemValue Поле в котором содержится идентификатор
 * selectAll Boolean Чекбокс с выбором (отменой выбора) всех точек
 * clear-on-blur Очищать селект при событии blur
 */
import { Ripple } from 'vuetify/lib/directives'
import Input from '@/components/UI-Kit/inputs/Input'

export default {
  name: 'SelectMultiple',
  components: {
    'app-input': Input
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: '',
      validator (value) {
        return ['', 'point', 'terminal', 'report', 'template'].indexOf(value) !== -1
      }
    },
    value: {
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    label: {
      type: String,
      required: true
    },
    disabled: Boolean,
    itemText: {
      type: String,
      required: false,
      default: 'title'
    },
    itemValue: {
      type: String,
      required: false,
      default: 'id'
    },
    returnObject: {
      type: Boolean,
      required: false
    },
    hideDetails: Boolean,
    menuProps: {
      type: Object,
      required: false,
      default: () => {}
    },
    selectAll: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    clearOnBlur: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      width: '',
      search: '',
      parents: {
        point: 'networkId',
        terminal: 'retailPointId',
        report: 'categoryId',
        template: 'operationType'
      },
      rulesList: {
        required: value => !!value.length || 'Обязательно для заполнения.'
      },
      rules: []
    }
  },
  watch: {
    required: {
      handler () {
        // if (this.required && this.mode !== 'time') this.rules.push(this.rulesList.required)
        // else if (this.required && this.mode === 'time') this.rules.push(this.rulesList.time)
        if (this.required) this.rules.push(this.rulesList.required)
        else this.rules = []
      },
      immediate: true
    }
  },
  computed: {
    v: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('update:value', newVal)
      }
    },
    selectLabel () {
      return this.required && this.label.length ? this.label + '*' : this.label
    },
    itemsComputed () {
      let items = []
      if (!this.headers.length) {
        return this.search ? this.searchItems(this.items) : this.items
      }
      this.headers.forEach(el => {
        let points = this.items.filter(item => item[this.parents[this.mode]] === el.id)
        if (points.length) {
          items.push({ header: el.title || el.text })
          items.push(...points)
        }
      })
      let final = items.filter(el => el.header).length > 1 ? items : this.items
      return this.search ? this.searchItems(final) : final
    },
    getMenuProps () {
      return {
        contentClass: `select__menu select__menu--multiple select__menu--${this.mode}`,
        offsetY: true,
        // maxWidth: width,
        // minWidth: width,
        closeOnClick: true,
        nudgeBottom: 10,
        nudgeWidth: -16, // 'auto' prop adds 16px to menu min-width
        auto: true, // needs for showing all items immediately
        ...this.menuProps
      }
    },
    selectAllItems () {
      return this.items.length === this.v.length
    }
  },
  methods: {
    setWidth () {
      this.width = this.$refs.select.$el.offsetWidth
    },
    searchItems (v) {
      if (this.mode === 'terminal') {
        return v.filter(el => el.title && (el.title.toUpperCase().includes(this.search.toUpperCase()) || el.code.includes(this.search)))
      } else {
        return v.filter(el => {
          if (el.title) {
            return el.title && el.title.toUpperCase().includes(this.search.toUpperCase())
          }
          if (el.text) {
            return el.text && el.text.toUpperCase().includes(this.search.toUpperCase())
          }
          if (typeof el === 'string') {
            return el.toUpperCase().includes(this.search.toUpperCase())
          }
        })
      }
    },
    clear () {
      this.$refs.select.blur()
      this.$emit('clear')
    },
    onScroll () {
      this.$refs.select.blur()
    },
    toggleSelectAll () {
      if (this.selectAllItems) {
        this.v = []
      } else {
        if (!this.returnObject) {
          this.v = this.items.map(item => item[this.itemValue])
        } else {
          this.v = this.items
        }
      }
    }
  },
  directives: {
    Ripple
  }
}
</script>

<style lang="scss">
</style>
