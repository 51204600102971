const getDefaultState = () => {
  return {
    popup: {
      name: '',
      value: false,
      key: '',
      data: {},
      transition: 300
    }
  }
}

const state = getDefaultState()

const mutations = {
  TOGGLE_POPUP (state, value) {
    state.popup.value = value
  },
  SHOW_POPUP (state) {
    state.popup.value = true
  },
  HIDE_POPUP (state) {
    state.popup.value = false
  },
  SET_POPUP (state, data) {
    state.popup.name = data.name
    state.popup.key = data.key
    state.popup.data = data.data
    if (data.bill) state.popup.bill = data.bill
  },
  RESET_POPUP (state) {
    state.popup.key = ''
  },
  EDIT_DATA (state, data) {
    state.popup.data = data
  }
}

const actions = {
  togglePopup ({ commit }, data) {
    commit('SET_POPUP', data)
    commit('TOGGLE_POPUP', data.value)
  },
  showPopup ({ commit, dispatch }, data) {
    commit('SET_POPUP', data)
    commit('SHOW_POPUP')
  },
  hidePopup ({ state, commit }) {
    commit('HIDE_POPUP')
    setTimeout(() => {
      commit('RESET_POPUP')
    }, state.transition)
  },
  editData ({ commit }, data) {
    commit('EDIT_DATA', data)
  }
}

const getters = {
  popup: state => state.popup
}

export default {
  state,
  mutations,
  actions,
  getters
}
