import axios from 'axios'
import store from '../store'

let baseURL
if (process.env.NODE_ENV === 'development') baseURL = 'http://localhost:8086'

function serializeJSONData (data) {
  let serializeJSON = {}

  for (let item in data) {
    if (data[item] !== undefined) {
      if (data[item] !== null && Object.entries(data[item]).length && data[item].constructor === Object) {
        let objData = serializeJSONData(data[item])
        if (Object.keys(objData).length > 0 && objData.constructor === Object) {
          serializeJSON[item] = objData
        }
      } else if (Array.isArray(data[item])) {
        let arrayData = serializeJSONData(data[item])
        if (Object.keys(arrayData).length && arrayData[0] !== undefined && arrayData[0] !== null) {
          serializeJSON[item] = Object.keys(arrayData).map(key => arrayData[key])
        }
      } else if (
        data[item] !== null &&
        data[item] !== undefined &&
        data[item] !== '' &&
        data[item].length !== 0 &&
        data[item].constructor !== Object
      ) {
        if (data[item] === 'empty') serializeJSON[item] = ''
        else if (data[item] === 'emptyArray') serializeJSON[item] = []
        else serializeJSON[item] = data[item]
      }
    }
  }
  return serializeJSON
}

async function updateRefresh () {
  let login = store.getters['getUserLogin']
  let refreshToken = localStorage.getItem('bmsAdminRefresh')
  let refresh = await server.post('/auth/refresh', { login, refreshToken })
  localStorage.setItem('bmsAdminToken', refresh.data.token)
  localStorage.setItem('bmsAdminRefresh', refresh.data.refreshToken)
}

const server = axios.create({
  baseURL,
  timeout: 600000
})

server.interceptors.request.use((request) => {
  if (!(request.data instanceof FormData) && request.method !== 'patch' && request.method !== 'put') {
    request.data = serializeJSONData(request.data)
  }
  let token = localStorage.getItem('bmsAdminToken')

  if (token) request.headers.Authorization = `Bearer ${token}`
  return request
})

server.interceptors.response.use(null, async (error) => {
  let originalRequest = error.response.config
  if (store.state.user.user.BMSAdmin) {
    store.dispatch('showAdminAlert', { type: 'error', serverError: error.response || error })
  }
  originalRequest.data = JSON.parse(originalRequest.data)
  if (error.response && error.response.status === 401) {
    if (error.response.data.message === 'jwt expired' || error.response.data.message === 'jwt invalid') {
      try {
        await updateRefresh()
        return await server(originalRequest)
      } catch (e) {
        if (error.response.status === 401) {
          await store.dispatch('logout')
          // console.log(e)
        } else {
          throw e
        }
      }
    } else {
      await store.dispatch('logout')
      throw error.response || error
    }
  } else {
    throw error.response || error
  }
})

export { server, serializeJSONData, updateRefresh }
