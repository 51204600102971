<script>
import { Doughnut } from 'vue-chartjs'

export default {
  name: 'dashboardPieDiagram',
  extends: Doughnut,
  data: () => ({
    options: {
      responsive: true,
      legend: {
        display: false
      }
    }
  }),
  props: {
    data: {
      type: Object,
      require: true,
      default: () => {}
    }
  },
  computed: {
    labels () {
      return Object.keys(this.data)
    },
    colors () {
      return Object.values(this.data).map(a => a.color)
    },
    nums () {
      return Object.values(this.data).map(a => a.count)
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.drawChart()
      }
    }
  },
  mounted () {
    this.drawChart()
  },
  methods: {
    drawChart () {
      const data = {
        labels: this.labels,
        datasets: [
          {
            label: '',
            backgroundColor: this.colors,
            hoverOffset: 10,
            data: this.nums
          }
        ]
      }
      this.renderChart(data, this.options)
    }
  }
}
</script>
