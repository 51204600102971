import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/'
import Home from '@/views/Home.vue'

import isAuth from './middleware/auth'
import isBMSAdmin from './middleware/BMSAdmin'
import checkPermission from './middleware/permission'
import isWebshop from './middleware/webshop'
// import loadStructure from './middleware/structure'

import middlewarePipeline from './middlewarePipeline'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      middleware: [
        isAuth
      ],
      icon: 'work-space',
      title: 'Рабочий стол'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    meta: {
      middleware: [
        isAuth
      ],
      icon: 'faq',
      title: 'FAQ',
      type: 'node'
    }
  },
  // {
  //   path: '/settings',
  //   name: 'Settings',
  //   component: () => import('@/views/Settings/Main.vue'),
  //   meta: {
  //     middleware: [
  //       isAuth
  //     ],
  //     title: 'Настройки',
  //     icon: 'settings'
  //   }
  // },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/Users/index.vue'),
    meta: {
      permission: 'READ',
      middleware: [
        isAuth,
        checkPermission
      ],
      title: 'Пользователи',
      icon: 'user'
    },
    children: [
      {
        path: '/',
        name: 'UsersMain',
        component: () => import('@/views/Users/main.vue'),
        meta: {
          permission: 'READ',
          middleware: [
            isAuth,
            checkPermission
          ]
        }
      },
      {
        path: '/users/edit/:login',
        name: 'UsersEdit',
        component: () => import('@/views/Users/edit.vue'),
        meta: {
          permission: 'WRITE',
          middleware: [
            isAuth,
            checkPermission
          ]
        }
      },
      {
        path: '/users/new',
        name: 'UsersNew',
        component: () => import('@/views/Users/new.vue'),
        meta: {
          permission: 'WRITE',
          middleware: [
            isAuth,
            checkPermission
          ]
        }
      },
      {
        path: '/users/actions',
        name: 'UsersActions',
        component: () => import('@/views/Users/actions.vue'),
        BMSAdmin: true,
        meta: {
          middleware: [
            isAuth,
            isBMSAdmin
          ],
          title: 'Действия пользователей',
          icon: 'actions'
        }
      },
      {
        path: '/users/statistics',
        name: 'UsersStatistics',
        component: () => import('@/views/Users/statistics.vue'),
        BMSAdmin: true,
        meta: {
          middleware: [
            isAuth,
            isBMSAdmin
          ],
          title: 'Статистика пользвоателей',
          icon: 'actions'
        }
      }
    ]
  },
  {
    path: '/clubs',
    name: 'Clubs',
    // component: () => import('@/views/AllClubs.vue'),
    meta: {
      middleware: [
        isAuth
      ],
      title: 'Программы лояльности',
      icon: 'loyality'
    }
  },
  {
    path: '/clubs/new',
    name: 'ClubsNew',
    component: () => import('@/views/Club/New.vue'),
    BMSAdmin: true,
    meta: {
      middleware: [
        isAuth,
        isBMSAdmin
      ]
    }
  },
  {
    path: '/clubs/:id/',
    name: 'Club',
    component: () => import('@/views/Club/index.vue'),
    children: [
      {
        path: 'transactions',
        name: 'ClubTransactions',
        component: () => import('@/views/Club/ClubTransactions/Index.vue'),
        meta: {
          permission: 'READ',
          middleware: [
            isAuth,
            checkPermission
          ],
          title: 'Операции',
          icon: 'operations'
        }
      },
      {
        path: 'participants',
        name: 'ClubParticipants',
        component: () => import('@/views/Club/ClubParticipants/Index.vue'),
        meta: {
          permission: 'READ',
          middleware: [
            isAuth,
            checkPermission
          ],
          title: 'Участники клуба',
          icon: 'participants'
        },
        children: [
          {
            path: '/',
            name: 'ClubParticipantsMain',
            component: () => import('@/views/Club/ClubParticipants/Main.vue'),
            meta: {
              permission: 'READ',
              middleware: [
                isAuth,
                checkPermission
              ],
              title: 'Участники клуба'
            }
          },
          {
            path: ':participant_id',
            name: 'ClubParticipantsEdit',
            component: () => import('@/views/Club/ClubParticipants/Edit.vue'),
            meta: {
              permission: 'WRITE',
              middleware: [
                isAuth,
                checkPermission
              ],
              header: false
            }
          }
        ]
      },
      {
        path: 'news',
        name: 'ClubNews',
        component: () => import('@/views/Club/ClubContent/ClubNews/Index.vue'),
        meta: {
          permission: 'READ',
          middleware: [
            isAuth,
            checkPermission
          ],
          title: 'Новости',
          icon: 'news'
        },
        children: [
          {
            path: '/',
            name: 'ClubNewsMain',
            component: () => import('@/views/Club/ClubContent/ClubNews/Main.vue'),
            meta: {
              permission: 'READ',
              middleware: [
                isAuth,
                checkPermission
              ],
              title: 'Новости'
            }
          },
          {
            path: 'new',
            name: 'ClubNewsCreate',
            component: () => import('@/views/Club/ClubContent/ClubNews/Edit.vue'),
            meta: {
              permission: 'READ',
              middleware: [
                isAuth,
                checkPermission
              ],
              header: false
            }
          },
          {
            path: 'edit/:news_id',
            name: 'ClubNewsEdit',
            component: () => import('@/views/Club/ClubContent/ClubNews/Edit.vue'),
            meta: {
              permission: 'READ',
              middleware: [
                isAuth,
                checkPermission
              ],
              header: false
            }
          }
          // {
          //   path: 'feedback',
          //   name: 'ClubFeedback',
          //   component: () => import('@/views/Club/ClubContent/ClubFeedback/Main.vue'),
          //   meta: {
          //     permission: 'READ',
          //     middleware: [
          //       isAuth,
          //       checkPermission
          //     ],
          //     title: 'Отзывы',
          //     icon: 'feedback'
          //   }
          // }
        ]
      },
      // {
      //   path: 'polls',
      //   name: 'ClubPolls',
      //   meta: { title: 'Опросы', icon: 'polls' },
      //   component: () => import('@/components/Club/ClubPolls.vue')
      // },
      {
        path: 'push',
        name: 'ClubPush',
        component: () => import('@/views/Club/ClubContent/ClubPush/Index.vue'),
        meta: {
          permission: 'READ',
          middleware: [
            isAuth,
            checkPermission
          ],
          title: 'Push-уведомления',
          icon: 'push'
        },
        children: [
          {
            path: '/',
            name: 'ClubPushMain',
            component: () => import('@/views/Club/ClubContent/ClubPush/Main.vue'),
            meta: {
              permission: 'READ',
              middleware: [
                isAuth,
                checkPermission
              ]
            }
          },
          {
            path: 'new',
            name: 'ClubPushNew',
            component: () => import('@/views/Club/ClubContent/ClubPush/New.vue'),
            meta: {
              permission: 'WRITE',
              middleware: [
                isAuth,
                checkPermission
              ],
              header: false
            }
          }
        ]
      },
      {
        path: 'push-messengers',
        name: 'ClubPushMessengers',
        component: () => import('@/views/Club/ClubContent/ClubPush/Index.vue'),
        meta: {
          permission: 'READ',
          middleware: [
            isAuth,
            checkPermission
          ],
          title: 'Push-уведомления в мессенджеры',
          icon: 'push'
        },
        children: [
          {
            path: '/',
            name: 'ClubPushMainMessengers',
            component: () => import('@/views/Club/ClubContent/ClubPush/MainMessengers.vue'),
            meta: {
              permission: 'READ',
              middleware: [
                isAuth,
                checkPermission
              ]
            }
          },
          {
            path: 'new',
            name: 'ClubPushNewMessengers',
            component: () => import('@/views/Club/ClubContent/ClubPush/NewMessengers.vue'),
            meta: {
              permission: 'WRITE',
              middleware: [
                isAuth,
                checkPermission
              ],
              header: false
            }
          }
        ]
      },
      {
        path: 'reports',
        name: 'ClubReports',
        component: () => import('@/views/Club/ClubReports/Index.vue'),
        meta: {
          permission: 'READ',
          middleware: [
            isAuth,
            checkPermission
          ],
          title: 'Отчеты',
          icon: 'report'
        },
        children: [
          {
            path: '/',
            name: 'ClubReportsMain',
            component: () => import('@/views/Club/ClubReports/Main'),
            meta: {
              permission: 'READ',
              middleware: [
                isAuth,
                checkPermission
              ],
              title: 'Отчеты',
              icon: 'report'
            }
          },
          {
            path: '/list',
            name: 'ClubReportsList',
            component: () => import('@/views/Club/ClubReports/List.vue'),
            meta: {
              permission: 'READ',
              middleware: [
                isAuth,
                checkPermission
              ]
            }
          },
          {
            path: ':report_type',
            name: 'ClubReportsItem',
            component: () => import('@/views/Club/ClubReports/Item.vue'),
            meta: {
              permission: 'READ',
              middleware: [
                isAuth,
                checkPermission
              ]
            }
          },
          {
            path: 'auto/new',
            name: 'ClubReportsNew',
            component: () => import('@/views/Club/ClubReports/New'),
            meta: {
              permission: 'WRITE',
              middleware: [
                isAuth,
                checkPermission
                // loadStructure
              ],
              header: false
            }
          },
          {
            path: 'auto/edit/:autoReportId',
            name: 'ClubReportsEdit',
            component: () => import('@/views/Club/ClubReports/Edit'),
            meta: {
              permission: 'WRITE',
              middleware: [
                isAuth,
                checkPermission
              ],
              header: false
            }
          },
          {
            path: 'generated/:reportId',
            name: 'GeneratedReport',
            component: () => import('@/views/Club/ClubReports/GeneratedItem'),
            meta: {
              permission: 'READ',
              middleware: [
                isAuth,
                checkPermission
              ]
            }
          }
        ]
      },
      {
        path: 'structure',
        name: 'ClubStructure',
        component: () => import('@/views/Club/ClubStructure/index.vue'),
        meta: {
          permission: 'READ',
          middleware: [
            isAuth,
            checkPermission
          ],
          title: 'Структура клуба',
          icon: 'workflow'
        }
      },
      {
        path: 'promotions',
        name: 'ClubPromotions',
        component: () => import('@/views/Club/ClubPromotions/Index.vue'),
        meta: {
          permission: 'READ',
          middleware: [
            isAuth,
            checkPermission
          ],
          title: 'Акции',
          icon: 'discount'
        },
        children: [
          {
            path: '/',
            name: 'ClubPromotionsMain',
            component: () => import('@/views/Club/ClubPromotions/Main.vue'),
            meta: {
              permission: 'READ',
              middleware: [
                isAuth,
                checkPermission
              ],
              title: 'Акции'
            }
          },
          {
            path: 'new',
            name: 'ClubPromotionsNew',
            component: () => import('@/views/Club/ClubPromotions/New.vue'),
            meta: {
              permission: 'WRITE',
              middleware: [
                isAuth,
                checkPermission
                // loadStructure
              ],
              header: false
            }
          },
          {
            path: 'edit/:promoId',
            name: 'ClubPromotionsEdit',
            component: () => import('@/views/Club/ClubPromotions/Edit.vue'),
            meta: {
              permission: 'WRITE',
              middleware: [
                isAuth,
                checkPermission
                // loadStructure
              ],
              header: false
            }
          }
        ]
      },
      {
        path: 'webshop',
        name: 'ClubWebshop',
        component: () => import('@/views/Club/ClubWebshop/index.vue'),
        meta: {
          permission: 'READ',
          middleware: [
            isAuth,
            checkPermission,
            isWebshop
          ],
          title: 'Веб витрина',
          icon: 'webshop'
        }
      },
      {
        path: 'products',
        name: 'ClubProducts',
        component: () => import('@/views/Club/ClubProducts/Index.vue'),
        meta: {
          permission: 'READ',
          middleware: [
            isAuth,
            checkPermission
          ],
          title: 'Товары',
          icon: 'products',
          beta: true
        }
      },
      {
        path: 'dashboard',
        name: 'ClubDashboard',
        component: () => import('@/views/Club/ClubDashboards/Index'),
        meta: {
          permission: 'READ',
          middleware: [
            isAuth,
            checkPermission
          ],
          title: 'Аналитика',
          icon: 'dashboard',
          beta: true
        }
      },
      {
        path: 'delivery',
        name: 'ClubDelivery',
        component: () => import('@/views/Club/ClubDelivery/Index.vue'),
        meta: {
          permission: 'READ',
          middleware: [
            isAuth,
            checkPermission
          ],
          title: 'Доставка',
          icon: 'delivery',
          beta: true
        },
        children: [
          {
            path: '/',
            name: 'ClubDeliveryMain',
            component: () => import('@/views/Club/ClubDelivery/Main.vue'),
            meta: {
              permission: 'READ',
              middleware: [
                isAuth,
                checkPermission
              ],
              title: 'Доставка'
            }
          },
          {
            path: ':itemId',
            name: 'ClubDeliveryEdit',
            component: () => import('@/views/Club/ClubDelivery/Edit.vue'),
            meta: {
              permission: 'WRITE',
              middleware: [
                isAuth,
                checkPermission
              ],
              header: false
            }
          },
          {
            path: 'add',
            name: 'ClubDeliveryAdd',
            component: () => import('@/views/Club/ClubDelivery/Add.vue'),
            meta: {
              permission: 'WRITE',
              middleware: [
                isAuth,
                checkPermission
              ],
              header: false
            }
          }
        ]
      },
      {
        path: 'settings',
        name: 'ClubSettings',
        component: () => import('@/views/Club/edit.vue'),
        meta: {
          permission: 'READ',
          middleware: [
            isAuth,
            checkPermission
          ],
          title: 'Настройки клуба',
          icon: 'edit'
        }
      }
      // {
      //   path: 'photos',
      //   name: 'ClubPhotos',
      //   component: () => import('@/components/Club/ClubPhotos.vue'),
      //   beforeEnter: ifAccessedScope
      // }
    ]
  },
  {
    path: '/ui-kit',
    name: 'UI-Kit',
    component: () => import('@/views/UI-Kit.vue'),
    BMSAdmin: true,
    meta: {
      middleware: [
        isAuth,
        isBMSAdmin
      ]
    }
  },
  // {
  //   path: '/faq',
  //   name: 'FAQ',
  //   component: () => import('@/views/FAQ.vue'),
  //   meta: {
  //     middleware: [
  //       isAuth
  //     ],
  //     title: 'FAQ',
  //     icon: 'faq'
  //   }
  // },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middleware, 1)
  })
})
router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router
