<template>
  <div :class="`treeview treeview--${mode}`">
    <div class="treeview__tree">
      <v-treeview
        :items="items"
        return-object
        item-key="treeId"
        item-text="title"
        :active.sync="treeviewActive"
        activatable
        selection-type="independent"
        expand-icon="add_circle"
        :item-children="childrenName"
        :open.sync="open"
        :search="search"
        :filter="filter"
        :class="`v-treeview--${mode}`"
        ref="treeview"
      >
        <template v-slot:prepend="{ item, open }">
          <picture
            v-if="item.treeType !== 'club'"
            v-html="require(`@/assets/img/icons/${item.treeType}.svg`)"
          />
          <img
            v-else
            class="treeview__club-logo"
            :src="logo"
          >
        </template>
        <template v-slot:label="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on" :class="{ 'inactive': item.title ? item.title.slice(0, 1) === '_' : false }">
                {{ item.treeType === 'terminal' ? item.code : item.title }}
              </span>
            </template>
            <span>
              {{ item.treeType === 'terminal' ? item.code : item.title }}
            </span>
          </v-tooltip>
        </template>
      </v-treeview>
    </div>
  </div>
</template>

<script>/**
 * Компонент дерева
 * @view
 * Дерево с возможностью фильтрации, одиночным/множественным выбором активных узлов
 * @props
 * mode String Тип дерева
 *    – 'structure': c одиночным выбором активных узлов
 *    – 'promotions': с множественным выбором активных узлов
 * items [Object, Array] Массив элементов, используемых для построения дерева
 * active Array Синхронизируемая prop, позволяющая контролировать, какие узлы активны
 * club-id [String, Number] Id Клуба (для mode === 'structure')
 * search - Данные для поиска
 */
// import Input from '@/components/UI-Kit/inputs/Input'

export default {
  name: 'Treeview',
  components: {
    // 'app-input': Input
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator (value) {
        return ['structure', 'promotions'].indexOf(value) !== -1
      }
    },
    items: {
      type: [Object, Array],
      required: true
    },
    active: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    clubId: {
      type: [String, Number],
      required: false
    },
    search: {
      type: String,
      reqiured: false
    },
    openNetwork: {
      type: Array,
      required: false
    }
  },
  mounted () {
    if (this.openNetwork && this.openNetwork.length) this.open = [this.openNetwork[0]]
    else this.open = [this.items[0]]
    if (this.terminalIds.length) this.$emit('updateStructure', this.terminalIds)
  },
  data () {
    return {
      open: []
    }
  },
  computed: {
    treeviewActive: {
      get () {
        return this.active
      },
      set (newVal) {
        this.$emit('update:active', newVal)
      }
    },
    filter () {
      return (item, search, textKey) => {
        if (item.treeType === 'terminal') {
          return item['code'].indexOf(search) > -1
        }
        return item['title'].toLowerCase().indexOf(search.toLowerCase()) > -1
      }
    },
    childrenName () {
      if (this.items.length && this.items[0].hasOwnProperty('terminals')) return 'terminals'
      else return 'children'
    },
    logo () {
      return this.clubId ? this.$store.getters[`${this.clubId}/global/getClubLogo`] : '/img/emptyClub.jpg'
    },
    terminals () {
      return this.$store.getters[`${this.clubId}/global/getTerminals`]
    },
    terminalIds () {
      if (this.active[0].treeType === 'club') {
        return this.terminals
      } else if (this.active[0].treeType === 'terminal') {
        return [this.active[0].id]
      } else {
        return this.setTerminalIds(this.active[0].terminals, this.active[0].treeType)
      }
    }
  },
  methods: {
    setTerminalIds (data, type) {
      const terminals = []
      if (type === 'retail') {
        data.forEach(item => {
          item.terminals.forEach(terminal => {
            terminals.push(terminal.id)
          })
        })
      } else if (type === 'point') {
        data.forEach(item => {
          terminals.push(item.id)
        })
      }
      return terminals
    }
  },
  watch: {
    mode: {
      handler () {
        if (this.mode === 'structure' && !this.clubId) console.error(`Prop 'club-id' is required.`)
      },
      immediate: true
    },
    items: {
      handler () {
        if (this.mode === 'structure' && !this.active.length && this.items[0].id) {
          this.open = [this.items[0]]
          this.treeviewActive = [this.items[0]]
        }
      },
      immediate: true
    },
    open: {
      handler () {
        if (this.mode === 'promotions' && this.open.length > 1) this.open.shift()
        else if (this.mode === 'promotions') this.$store.dispatch(`${this.clubId}/promotions/setOpenNetwork`, { ...this.open[0] })
      },
      immediate: true,
      deep: true
    },
    openNetwork: {
      deep: true,
      handler () {
        if (this.mode === 'structure' && this.openNetwork.length) this.open.push(this.openNetwork[0])
      }
    },
    terminalIds: {
      deep: true,
      handler () {
        this.$emit('updateStructure', this.terminalIds)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.treeview {
  height: 100%;
  &__tree {
    box-shadow: $shadow;
    // min-height: $club-height;
  }
  &__club-logo {
    width: 24px;
    height: 24px;
  }
}
.treeview::v-deep {
  .v-treeview {
    &-node {
      &__root::before {
        background: transparent;
      }

      &__toggle {
        color: $accent;

        &--open {
          transform: rotate(-45deg);
        }
      }

      &--active {
        color: $accent;
        fill: $accent;
        font-weight: 700;
        pointer-events: none;
        & .v-treeview-node__toggle {
          pointer-events: all;
        }
      }

      &__root {
        padding: 0;
      }

      &__content {
        cursor: pointer;
      }

      &__prepend {
        margin-left: 7px;
        display: flex;
        picture {
          display: flex;
        }
      }

      &--leaf {
        .v-treeview-node__root {
          .v-treeview-node__level:first-child {
            display: none;
          }
        }
      }
    }
    &--structure {
      > .v-treeview-node {
        .v-treeview-node__root {
          padding: 0 30px;
        }
        > .v-treeview-node__root {
          box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
        }
        > .v-treeview-node__children {
          > .v-treeview-node {

            &[aria-expanded="true"] {
              > .v-treeview-node__root {
                box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
              }
            }

            > .v-treeview-node__children {
              border-left: 2px solid $accent;
              background: rgba(158, 158, 158, 0.05);
              max-height: 400px;
              overflow: auto;
            }
          }
        }
      }
    }
    &--promotions {
      > .v-treeview-node {
        .v-treeview-node__root {
          padding: 0 30px;
        }
        &[aria-expanded="true"] {
          > .v-treeview-node__root {
            box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
          }
        }

        > .v-treeview-node__children {
          border-left: 2px solid $accent;
          background: rgba(158, 158, 158, 0.05);
          max-height: 400px;
          overflow: auto;
        }
        .v-treeview-node--active {
          pointer-events: all;
        }
      }
    }
  }
}
.inactive {
  color: $icon;
}
</style>
