import { logsApi } from '@/api/logs'

const getDefaultLogs = () => []

const getDefaultState = () => {
  return {
    logs: getDefaultLogs(),
    timeout: 1500,
    page: 0,
    size: 25,
    dates: {
      from: 0,
      to: 0
    },
    login: '',
    club: '',
    module: '',
    sortBy: 'date',
    sortDesc: true,
    totalElements: 0,
    status: ''
  }
}

export default {
  namespaced: true,
  state: getDefaultState,
  mutations: {
    setLogs (state, data) {
      const arr = [...data]
      if (state.page === 0) {
        state.logs = []
      }
      arr.forEach(element => state.logs.push(element))
    },
    setTotalElements (state, data) {
      state.totalElements = data
    },
    setStatus (state, data) {
      state.status = data
    },
    setParams (state, data) {
      state.dates.from = data.from
      state.dates.to = data.to
      state.login = data.login
      state.module = data.module
      state.club = data.club
    },
    setPage (state, data) {
      if (data !== undefined) {
        state.page = data
      } else {
        state.page += 1
      }
    },
    setSort (state, data) {
      if (data.desc === false) {
        state.sortBy = data.load
      } else {
        state.sortDesc = data.load
      }
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    async loadLogs ({ commit, dispatch, getters, state }) {
      commit('setStatus', 'loading')

      let data = {
        login: state.login || '',
        from: state.dates.from || '',
        to: state.dates.to || '',
        club: state.club || '',
        module: state.module || ''
      }

      try {
        let response = await logsApi.all(data, state.sortBy, state.sortDesc, state.page, state.size)
        if (response.data.content.length) {
          commit('setTotalElements', response.data.totalElements)
          commit('setLogs', response.data.content)
          commit('setStatus', '')
        } else {
          commit('setStatus', '404')
        }
      } catch (e) {
        commit('setLogs', getDefaultLogs())
        commit('setStatus', '500')
      }
    },
    changePage ({ commit }, data) {
      commit('setPage', data)
    },
    changeParams ({ commit }, data) {
      commit('setParams', data)
    },
    changeSort ({ commit }, data) {
      commit('setSort', data)
    }
  },
  getters: {
    getLogs (state) {
      return state.logs
    },
    getErrorText (state) {
      return state.errorText
    },
    getIsLoading (state) {
      return state.isLoading
    },
    getIsError (state) {
      return state.isError
    },
    getTimeout (state) {
      return state.timeout
    },
    getPage (state) {
      return state.page
    },
    getIsLastPage (state) {
      return state.isLastPage
    },
    getLogin (state) {
      return state.login
    },
    getStatus: state => state.status,
    getTotalElements: state => state.totalElements
  }
}
