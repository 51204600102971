<template>
  <v-text-field
    :class="[
      `input input--${mode}`,
      {
        'disabled': disabled,
        'input--offset': label.length && placeholder.length,
        'input--unnamed': !label.length
       },
    ]"
    v-model="inputValue"
    :label="inputLabel"
    :placeholder="placeholder"
    :type="password ? 'password' : 'text'"
    rounded
    filled
    :disabled="disabled"
    :hide-details="mode === 'search' || hideDetails"
    :rules="rules"
    v-mask="mask"
    @input="$emit('change', $event)"
    :prefix="prefix"
    :maxlength="maxlength"
    :autocomplete="autocomplete"
    v-on:keyup.enter="$emit('enter', $event)"
    @update:error="$emit('error', $event)"
    :error-messages="errorMessages"
  >
    <template
      v-if="icon"
      v-slot:prepend-inner
    >
      <div
        class="input__icon d-flex"
        v-html="require(`@/assets/img/icons/${inputIcon}.svg`)"
      />
    </template>
    <template
      v-if="mode === 'search' || (mode === 'password' && inputValue.length )"
      v-slot:append
    >
      <div
        class="input__append-icon d-flex"
        v-html="require(`@/assets/img/icons/${inputAppend}.svg`)"
        @click="showPassword"
      />
    </template>
    <template
      v-else-if="tooltip"
      v-slot:append
    >
      <app-button-tooltip
        icon="info"
        :message="tooltip"
        :size="[18, 18]"
      />
    </template>
  </v-text-field>
</template>

<script>
/**
 * Компонент инпута с валидацией
 * @view
 * Инпут
 * @actions
 * setInputBehavior: Добавляет маску и правила валидации
 * showPassword: Показывает/скрывает пароль
 * @props
 * mode String Тип инпута (для добавления иконок, стилей, маски и правил валидации соответствующих выбранному типу)
 * value Any Возвращаемое значение
 * label String Лейбл
 * placeholder Плейсхолдер
 * icon Boolean Для добавления иконки в начале инпута
 * required Boolean
 * disabled Boolean
 * tooltip String
 * error-messages String Если передано, отображает сообщение об ошибке
 */
import { mask } from 'vue-the-mask'
import ButtonTooltip from '@/components/UI-Kit/buttons/ButtonTooltip'

export default {
  name: 'Input',
  components: {
    'app-button-tooltip': ButtonTooltip
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator (value) {
        return [
          'text',
          'phone',
          'email',
          'password',
          'card',
          'inn',
          'kpp',
          'index',
          'search',
          'login',
          'number',
          'hours',
          'rrn',
          'bill',
          'priority',
          'bill',
          'productCode'
        ].indexOf(value) !== -1
      }
    },
    value: {
      type: [String, Number],
      required: true,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltip: {
      type: String,
      required: false,
      default: ''
    },
    prefix: {
      type: String,
      required: false,
      default: ''
    },
    maxlength: {
      type: Number,
      required: false
    },
    autocomplete: {
      type: String,
      required: false
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    errorMessages: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      icons: {
        login: 'login',
        text: 'login',
        phone: 'phone',
        password: 'password',
        card: 'cards',
        rrn: 'rrn',
        bill: 'bill'
      },
      password: false,
      max: 100000,
      mask: {
        mask: '',
        tokens: {
          F: {
            pattern: /.*/
          }
        }
      },
      patterns: {
        email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        login: /^[A-Za-z0-9_.-]+$/,
        number: /^[0-9]*\.?[0-9]*$/,
        lettersAndNumbers: /^[а-яa-z0-9]+$/
      },
      rules: [],
      rulesList: {
        required: val => !!val || 'Обязательное поле',
        maxLength: val => (!val.length || val.length === this.max) || 'Введены не все символы',
        phone: val => (!val.length || val.length === 18) || 'Введены не все символы',
        email: val => (!val.length || this.patterns.email.test(val)) || 'Неверный формат почты',
        password: val => (!val.length || val.length >= 6) || 'Длина не менее 6 символов',
        login: val => (!val.length || this.patterns.login.test(val)) || 'Неверный формат',
        number: val => (!val.length || this.patterns.number.test(val)) || 'Только цифры',
        hours: val => (!val.length || (this.patterns.number.test(val) && parseInt(val, 10) < 24)) || 'Неверное значение',
        priority: val => (!val.length || (parseInt(val) >= 0 && parseInt(val) <= 100)) || 'Введите число от 0 до 100',
        productCode: val => (!val.length || (this.patterns.lettersAndNumbers.test(val))) || 'Только цифры и буквы'
      }
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      async set (newVal) {
        let checkedPhone = await this.checkPhoneNumber(newVal)
        this.$emit('update:value', checkedPhone)
      }
    },
    inputLabel () {
      return this.required && this.label.length ? this.label + '*' : this.label
    },
    inputIcon () {
      if (this.icon) {
        return this.icons[this.mode]
      } else {
        return ''
      }
    },
    inputAppend () {
      switch (this.mode) {
        case 'password':
          return 'visibility'
        case 'search':
          return 'search'
        default:
          return ''
      }
    }
  },
  beforeMount () {
    this.setInputBehavior()
  },
  methods: {
    setInputBehavior () {
      this.mask.mask = 'F'.repeat(this.max)

      if (this.required && this.mode !== 'search') {
        this.rules.push(this.rulesList.required)
      }

      switch (this.mode) {
        case 'phone':
          this.mask = '+7 (###) ###-##-##'
          this.rules.push(this.rulesList.phone)
          break
        case 'email':
          this.rules.push(this.rulesList.email)
          break
        case 'password':
          this.password = true
          this.rules.push(this.rulesList.password)
          break
        case 'card':
          this.max = 19
          this.mask = '#### #### #### ####'
          this.rules.push(this.rulesList.maxLength)
          break
        case 'inn':
          this.max = 17
          this.mask = '#'.repeat(this.max)
          this.rules.push(this.rulesList.maxLength)
          break
        case 'kpp':
          this.max = 9
          this.mask = '#'.repeat(this.max)
          this.rules.push(this.rulesList.maxLength)
          break
        case 'index':
          this.max = 6
          this.mask = '#'.repeat(this.max)
          this.rules.push(this.rulesList.maxLength)
          break
        case 'login':
          this.rules.push(this.rulesList.login)
          break
        case 'number':
          this.rules.push(this.rulesList.number)
          break
        case 'hours':
          this.mask = '##'
          this.rules.push(this.rulesList.hours)
          break
        case 'priority':
          this.rules.push(this.rulesList.priority)
          break
        case 'productCode':
          this.rules.push(this.rulesList.productCode)
      }
    },
    showPassword () {
      if (this.mode === 'password') {
        this.password = !this.password
      }
    },
    async checkPhoneNumber (number) {
      let str = number.replace(/-|\s/g, '')
      if (this.mode === 'phone' && str.length > 10 && str[0] === '8') {
        return str.slice(-10)
      } else return number
    }
  },
  directives: { mask }
}
</script>

<style lang="scss">
.input {
  margin-top: 0 !important;
  padding: 0 !important;
  transition: opacity $primary-transition;

  & > .v-input__control {
    & > .v-input__slot {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 50px !important;
      height: 50px;
      margin-bottom: 2px;
      padding: 0 20px !important;
      background-color: $base !important;
      border: 1px solid $input;
      border-radius: 50px !important;
      transition: border $primary-transition, box-shadow $primary-transition !important;
      &::before,
      &::after {
        display: none;
      }

      .v-input__prepend-inner {
        align-self: center;
        margin-top: 0;
        padding-right: 16px;

        svg {
          fill: $icon;
        }
      }

      .v-input__append-inner {
        align-self: center;
        margin: 0;
        margin-left: 10px;
        padding-left: 0;

        svg {
          transition: fill $primary-transition;

          g {
            opacity: 1;
          }
        }
      }

      .v-text-field__slot {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 50px;
          background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
          pointer-events: none;
        }
      }

      .v-label,
      input {
        font-weight: 400;
        font-size: $font-size-root;
        line-height: 1;
      }

      .v-label {
        top: 50%;
        color: $icon;
        transform: translateY(-40%);
        z-index: 1;

        &--active {
          transform: translateY(-90%) scale(0.70);
        }
      }

      input {
        align-self: center;
        max-height: 100% !important;
        height: auto;
        margin-top: 0;
        padding: 0;
        color: $primary;
        transition: margin-top $primary-transition;

        &::placeholder {
          color: $icon;
        }
      }

      &:hover {
        border: 1px solid transparent;
        box-shadow: $shadow-input;
      }
      .v-text-field__prefix {
        font-size: 17px;
      }
    }

    .v-text-field__details {
      margin-bottom: 0 !important;
      padding: 0 !important;

      .v-messages__message {
        color: $accent;
      }
    }
  }

  .v-input__icon--clear {
    display: flex;
    height: 85%;
    min-width: auto;
    width: auto;
    border-radius: 0;

    .v-icon {
      position: relative;
      width: 40px;
      height: 40px;
      color: $accent-disabled;
      font-size: 14px;
      transition: color $primary-transition;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        height: 40px;
        width: 1px;
        background-color: $separator;
        border-radius: 0;
        opacity: 1;
        transform: translateY(-50%);
      }

      &:hover {
        color: $accent;
      }
    }
  }

  &--password {
    .v-input__append-inner {
      svg:not(.goodFill) {
        fill: $accent-disabled;
      }

      &:hover {
        cursor: pointer;

        svg:not(.goodFill) {
          fill: $accent;
        }
      }
    }
  }

  &--search {
    & > .v-input__control {
      & > .v-input__slot {
        .v-input__append-inner {
          svg {
            fill: $icon;
          }
        }

        .v-text-field__slot {
          input {
            margin-top: 0 !important;
          }
        }
      }
    }

    &.v-input--is-dirty {
      .v-input__append-inner {
        svg {
          fill: $accent !important;
        }
      }
    }
  }

  &.v-input--is-focused {
    & > .v-input__control {
      & > .v-input__slot {
        border: 1px solid transparent;
        box-shadow: $shadow-input;
      }
    }
  }

  &.v-input--is-focused,
  &.v-input--is-dirty,
  &.input--offset {
    & > .v-input__control {
      & > .v-input__slot {
        .v-text-field__slot {
          input,
          .v-text-field__prefix {
            margin-top: 13px;
          }
        }
      }
    }
  }
  &.input--unnamed {
    & > .v-input__control {
      & > .v-input__slot {
        .v-text-field__slot {
          input,
          .v-text-field__prefix {
            margin-top: 0px;
          }
        }
      }
    }
  }
  &.v-text-field--filled {
    .v-text-field__prefix {
      margin-top: 0px;
    }
  }

  &.error--text {
    & > .v-input__control {
      & > .v-input__slot {
        border: 1px solid $accent;
      }
    }
  }

  &.v-input--is-disabled {
    opacity: 0.5;
  }

  &__tooltip {
    &__icon {
      cursor: pointer;
      svg {
        fill: none;
        circle {
          stroke: #ED5656;
        }
        path {
          fill: #ED5656;
        }
      }
    }
  }
}
</style>
