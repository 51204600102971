<template>
  <v-select
    v-model="v"
    ref="select"
    :class="`input select select--single select--${mode}`"
    :items="itemsComputed"
    :item-text="itemText"
    :item-value="itemValue"
    rounded
    :clearable="clearable"
    :disabled="disabled"
    :rules="rules"
    :label="selectLabel"
    :return-object="returnObject"
    :menu-props="{
      contentClass: `select__menu select__menu--single select__menu--${mode} ${search ? 'select__menu--search' : ''}`,
      offsetY: true,
      // maxHeight: 250,
      // // maxWidth: width,
      // // minWidth: width,
      // closeOnClick: true,
      nudgeBottom: 10,
      nudgeWidth: -16, // 'auto' prop adds 16px to menu min-width
      auto: true, // needs for showing all items immediately
      //'v-model': menu
    }"
    :hide-details="hideDetails"
    v-resize="setWidth"
    @change="$emit('change', $event)"
    @click:clear="clear"
    @keydown.prevent="() => {}"
    v-scroll="onScroll"
  >
    <template
      v-if="['club', 'retail', 'clock'].indexOf(mode) !== -1"
      v-slot:prepend-inner
    >
      <div
        class="select__prepend-icon"
        v-html="require(`@/assets/img/icons/${mode}.svg`)"
      />
    </template>
    <template v-slot:append>
      <div class="select__tooltip" v-if="tooltip.length">
        <app-button-tooltip
          icon="info"
          :message="tooltip"
          :size="[18, 18]"
        />
      </div>
      <div
        class="select__append-icon"
        v-html="require(`@/assets/img/icons/arrow.svg`)"
      />
    </template>
    <template v-slot:no-data>
      <div class="select__no-data">Нет данных</div>
    </template>
    <template
      v-slot:prepend-item
      v-if="search"
    >
      <div class="select__prepend-item">
        <app-input
          mode="search"
          :value.sync="filter"
          placeholder="Начните вводить название..."
        />
      </div>
    </template>
  </v-select>
</template>

<script>/**
 * Компонент селекта с возможностью выбора одного значения
 * @view
 * Селекта с возможностью выбора одного значения
 * @actions
 * setWidth: Устанавливает ширину выпадающего меню равную инпуту
 * @props
 * mode String Тип селекта (для добавления иконок, стилей соответствующих выбранному типу)
 * value Any Возвращаемое значение
 * items Array Object Список отображаемых значений в селекте
 * label String Плейсхолдер
 * clearable Boolean
 * requred Boolean
 * disabled Boolean
 * hide-details Boolean Скрывает место, отведенное для сообщения об ошибке
 * search - параметр для поиска
 * item-value String поле value из массива items
 * item-text String поле text из массива items
 * return-object Boolean Возвращает полный объект выбора
 * return-array Boolean Возвращает полный объект выбора
 * tooltip String Текст для подсказки
 */

import Input from '../inputs/Input'
import ButtonTooltip from '@/components/UI-Kit/buttons/ButtonTooltip'

export default {
  name: 'SelectSingle',
  components: {
    'app-input': Input,
    'app-button-tooltip': ButtonTooltip
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: '',
      validator (value) {
        return ['', 'club', 'retail', 'card', 'time', 'header', 'clock'].indexOf(value) !== -1
      }
    },
    value: {
      required: true
    },
    items: {
      type: [Array, Object],
      required: true
    },
    label: {
      type: String,
      required: true
    },
    clearable: Boolean,
    required: Boolean,
    disabled: Boolean,
    hideDetails: Boolean,
    search: {
      type: String,
      required: false
    },
    itemValue: {
      type: String,
      required: false,
      default: 'id'
    },
    itemText: {
      type: String,
      required: false,
      default: 'title'
    },
    returnObject: {
      type: Boolean,
      required: false
    },
    returnArray: {
      type: Boolean,
      required: false
    },
    tooltip: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      width: '',
      rules: [],
      filter: '',
      // open: false,
      rulesList: {
        required: value => !!value || 'Обязательно для заполнения.'
        // time: value => {
        //   return (value && value !== '00:00') || 'Обязательно для заполнения.'
        // }
      }
    }
  },
  computed: {
    v: {
      get () {
        if (this.returnArray) {
          return this.value[0] || null
        }
        return this.value
      },
      set (newVal) {
        if (this.returnArray) {
          this.$emit('update:value', [newVal])
        } else {
          this.$emit('update:value', newVal)
        }
      }
    },
    selectLabel () {
      return this.required && this.label.length ? this.label + '*' : this.label
    },
    itemsComputed () {
      if (this.search && this.filter.length > 0) {
        return this.items.filter((item) => {
          console.log(item.id)
          console.log(this.value)
          return item.id === this.value || item.id === this.value.id || item[this.search].toLowerCase().includes(this.filter.toLowerCase())
        })
      } else return this.items
    }
  },
  watch: {
    required: {
      handler () {
        // if (this.required && this.mode !== 'time') this.rules.push(this.rulesList.required)
        // else if (this.required && this.mode === 'time') this.rules.push(this.rulesList.time)
        if (this.required) this.rules.push(this.rulesList.required)
        else this.rules = []
      },
      immediate: true
    }
  },
  methods: {
    setWidth () {
      this.width = this.$refs.select.$el.offsetWidth
    },
    clear () {
      this.$refs.select.blur()
      this.$emit('clear')
    },
    onScroll () {
      this.$refs.select.blur()
    }
  }
}
</script>

<style lang="scss">
</style>
