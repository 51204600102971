<template>
  <v-list
    v-if="clubRoutes && clubRoutes.length"
    class="nav-list nav-list__menu-list overflow-y-auto"
  >
    <slot />

    <nav-list-item
      v-for="route in clubRoutes"
      :key="route.title"
      :item="route.meta"
      :link="`/clubs/${club.id}/${route.path}`"
      :active="$route.fullPath.includes(`/clubs/${club.id}/${route.path}`)"
      mode="child"
    />

    <!-- <nav-list-item
      v-if="isBMSAdmin"
      :item="{ title: 'Удалить', icon: 'trash'}"
      :popup="{
        name: 'PopupConfirmation',
        data: { action: 'removeClub', id: club.id }
      }"
      :active="false"
      mode="child"
      :mob="true"
    /> -->
  </v-list>
</template>

<script>
import NavListItem from '@/components/nav/components/NavListItem'

export default {
  name: 'NavListClubModules',
  components: {
    'nav-list-item': NavListItem
  },
  props: {
    club: {
      type: Object,
      required: true
    },
    clubRoutes: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
</style>
