<template>
  <div :class="{'dashboard__data' : !report}">
    <template
      v-if="report"
    >
      <div class="reports__row">
        <span>Повторные покупки:</span>
        <span>{{ repeatPurchases.all.toLocaleString() }} / {{ purchases.length.toLocaleString() }}</span>
      </div>
      <div class="reports__row">
        <span>Совершили по 2 покупки:</span>
        <span>{{ repeatPurchases.two.toLocaleString() }} ({{ repeatPurchases.twoPercent }}%)</span>
      </div>
      <div class="reports__row">
        <span>Совершили по 3 покупки:</span>
        <span>{{ repeatPurchases.three.toLocaleString() }} ({{ repeatPurchases.threePercent }}%)</span>
      </div>
      <div class="reports__row">
        <span>Совершили по 4 и более покупок:</span>
        <span>{{ repeatPurchases.four.toLocaleString() }} ({{ repeatPurchases.fourPercent }}%)</span>
      </div>
    </template>
    <template
      v-else
    >
      <div>
        <span class="dashboards__text-big dashboards__text-green">{{ repeatPurchases.all.toLocaleString() }}</span>
        <span class="dashboards__text-med">/{{ purchases.length.toLocaleString() }}</span>
      </div>
      <app-progress-bar
        :title="'Совершили по 2 покупки'"
        :subtitle="'Участники'"
        :beforePercent="repeatPurchases.two"
        :percent="repeatPurchases.twoPercent"
      />
      <app-progress-bar
        :title="'Совершили по 3 покупки'"
        :subtitle="'Участники'"
        :beforePercent="repeatPurchases.three"
        :percent="repeatPurchases.threePercent"
      />
      <app-progress-bar
        :title="'Совершили по 4 и более покупок'"
        :subtitle="'Участники'"
        :beforePercent="repeatPurchases.four"
        :percent="repeatPurchases.fourPercent"
      />
    </template>
  </div>
</template>

<script>
import DashboardProgressBar from '@/components/Club/ClubDashboards/DashboardProgressBar'

export default {
  name: 'ClubDashboardsModuleUsersPurchases',
  props: {
    report: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: [Number, String],
      require: false
    }
  },
  components: {
    'app-progress-bar': DashboardProgressBar
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    purchases () {
      return this.$store.getters[`${this.clubId}/dashboards/getPurchases`]
    },
    repeatPurchases () {
      const all = this.purchases ? this.purchases.filter(i => i.count > 1).length : 0
      const two = this.purchases ? this.purchases.filter(i => i.count === 2).length : 0
      const three = this.purchases ? this.purchases.filter(i => i.count === 3).length : 0
      const four = this.purchases ? this.purchases.filter(i => i.count >= 4).length : 0
      return {
        all: all,
        two: two,
        twoPercent: two > 0 ? ((two / all) * 100).toFixed(1) : '0',
        three: three,
        threePercent: three > 0 ? ((three / all) * 100).toFixed(1) : '0',
        four: four,
        fourPercent: four > 0 ? ((four / all) * 100).toFixed(1) : '0'
      }
    }
  }
}
</script>
