<template>
  <div class="users-main">
    <FilterPanel title="Параметры для пользователей" >
      <v-form>
        <v-row class="mt-0 mb-0">
          <v-col cols="12" sm="6" lg="4" xl="3" class="pt-0 pb-0 order-2 order-sm-1 mb-4 mb-lg-0">
            <app-input
              mode="search"
              :value="getSearch.query"
              placeholder="Имя пользователя или логин"
              @change="changeSearch('query', $event)"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="4" xl="3" class="pt-0 pb-0 order-3 order-sm-2">
            <app-select-single
              mode="card"
              :value="getSearch.club"
              :items="getClubs"
              label="Доступ к программам"
              item-value="_id"
              clearable
              hide-details
              @change="changeSearch('club', $event)"
            />
          </v-col>
          <v-col cols="12" sm="auto" class="pt-0 pb-0 order-1 order-sm-3 mb-4 mb-sm-0">
            <app-button-circle
              v-if="isWritePermission()"
              link="/users/new"
              mode="fill"
            >
              Добавить пользователя
            </app-button-circle>
          </v-col>
        </v-row>
      </v-form>
    </FilterPanel>

    <v-list v-if="getUsers.length">
      <user-item
        v-for="user in getUsers"
        :key="user.id"
        :user="user"
      />
      <load-observer
        @intersect="changePagination"
        v-if="getUsers.length < getTotalUsers"
      />
    </v-list>

    <app-loader
      v-if="getRequestStatus === 'pending'"
      status="loading"
      :class="getUsers.length > 0 ? 'users-main__loader-sm' : ''"
    />
    <app-loader
      v-else-if="getUsers.length === 0 && getRequestStatus === 'ok'"
      status="404"
    />
    <app-loader
      v-else-if="getRequestStatus === 'error'"
      status="500"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Input from '@/components/UI-Kit/inputs/Input'
import SelectSingle from '@/components/UI-Kit/selects/SelectSingle'
import ButtonCircle from '@/components/UI-Kit/buttons/ButtonCircle'
import UserItem from '@/components/Users/UserItem'
import LoadOnScroll from '@/components/LoadOnScroll'
import Loader from '@/components/UI-Kit/loaders/LoaderUpdated'
import FilterPanel from '@/components/FilterPanel'

export default {
  name: 'UsersMain',
  components: {
    'app-input': Input,
    'app-select-single': SelectSingle,
    'app-button-circle': ButtonCircle,
    'user-item': UserItem,
    'load-observer': LoadOnScroll,
    'app-loader': Loader,
    FilterPanel
  },
  async created () {
    if (this.getUsers.length === 0) {
      await this.searchUsers()
    }
  },
  computed: {
    ...mapGetters([
      'getUsers',
      'getPagination',
      'getSearch',
      'getTotalUsers',
      'getRequestStatus',
      'getClubs',
      'isWritePermission'
    ])
  },
  methods: {
    ...mapActions([
      'searchUsers',
      'setSearchParams'
    ]),
    async changeSearch (name, e) {
      this.setSearchParams({
        search: {
          [name]: e
        },
        page: 0
      })
      await this.searchUsers()
    },
    async changePagination (e) {
      this.setSearchParams({
        page: this.getPagination.page + 1
      })
      await this.searchUsers()
    }
  }
}
</script>

<style scoped lang="scss">
  .users-main {
    &__loader-sm {
      padding: 20px 0 30px;
    }
    .button-circle {
      width: 100%;
    }
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      .button-circle {
        width: auto;
      }
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {

    }
  }
  .users-main::v-deep {
    .v-card {
      padding: 30px 30px 12px 30px;
      box-shadow: $shadow;
      transition: box-shadow $primary-transition;
      &__title {
        padding: 0;
      }
      &__text {
        padding: 0;
      }
    }
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      .v-list {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {

    }
  }
</style>
