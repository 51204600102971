<template>
  <div :class="scrollClass" class="filters">
    <v-expansion-panels  v-model="open">
      <v-expansion-panel @click="click=!click" popout>
        <v-expansion-panel-header>
          <div class="filters__title">
            <template v-if="backBtn">
              <app-button-tooltip
                :size="[15,25]"
                @click="$router.go(-1)"
                class-name="club-header__back"
                icon="arrow-right"
              />
            </template>
            <template v-if="backLink">
              <app-button-tooltip
                :size="[15,25]"
                @click="$router.push(backLink)"
                class-name="club-header__back"
                icon="arrow-right"
              />
            </template>
            <div class="filters__title-text">{{ title }}</div>
            <div
              class="v-expansion-panel-header__arrow"
              v-html="require(`@/assets/img/icons/arrow.svg`)"
            />
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <slot></slot>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ButtonTooltip from '@/components/UI-Kit/buttons/ButtonTooltip'
export default {
  name: 'FilterPanel',
  components: {
    'app-button-tooltip': ButtonTooltip
  },
  props: {
    title: {
      type: String,
      required: true
    },
    backBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    backLink: {
      type: Object,
      required: false,
      default: null
    }
  },
  mounted () {
    window.addEventListener('scroll', this.debounce)
    this.height = this.$el.scrollHeight
  },
  destroyed () {
    window.removeEventListener('scroll', this.debounce)
  },
  data () {
    return {
      scroll: false,
      open: 0,
      click: false,
      height: 0,
      debounceInterval: 500
    }
  },
  computed: {
    scrollClass () {
      if (this.$vuetify.breakpoint.lgAndUp && this.scroll) return 'filters--top'
      else return ''
    }
  },
  methods: {
    onScroll (e) {
      if (!this.$vuetify.breakpoint.lgAndUp) return
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      let scrollHeight = document.body.scrollHeight
      let clientHeight = window.innerHeight
      // Если есть скролл, но он исчезнет когда панель свернется, добавляем отступ снизу у элемента родителя.
      if (scrollHeight > clientHeight) {
        if (scrollHeight - this.height < clientHeight) {
          this.$el.parentElement.style.paddingBottom = (this.height - 30) + 'px'
        }
      }
      // скролл для сворачивания/открытия панели с фильтрами
      if (currentScrollPosition > 0) {
        this.scroll = true
        if (!this.click) {
          this.open = null
        }
      } else {
        this.click = false
        this.scroll = false
        this.open = 0
      }
    },
    close () {
      this.open = null
    },
    debounce () {
      if (this.scroll) {
        setTimeout(() => {
          this.onScroll()
        }, this.debounceInterval)
      } else {
        this.onScroll()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filters {
  z-index: 5;
  position: sticky;
  margin: 0;
  &--top {
    top: 50px;
  }
  &__title {
    display: flex;
    align-items: center;
    &-text {
      font-size: $font-18;
    }
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    //top: 120px;
    margin: 0;
    &--top {
      top: 30px;
    }
    &__title {
      &-text {
        font-size: $font-24;
      }
    }
  }
}
.filters::v-deep {
  .v-expansion-panel {
    box-shadow: $shadow;
    &::before {
      display: none;
    }
  }
  .v-expansion-panel-header {
    padding: 20px 15px 15px;
    &__icon {
      display: none;
    }
    &__arrow {
      display: flex;
      margin-left: auto;
      transition: transform $primary-transition;

      svg {
        fill: $icon;
      }
    }
    &--active {
      box-shadow: none !important;
      .v-expansion-panel-header__arrow {
        transform: rotate(180deg);
      }
    }
  }
  .v-expansion-panel-content__wrap {
    padding: 0 15px 20px;
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    .v-expansion-panel-header {
      padding: 24px 30px 25px;
    }
    .v-expansion-panel-content__wrap {
      padding: 0 31px 30px;
    }
  }
}
</style>
