import Vue from 'vue'
import { usersApi } from '@/api/users'

const getDefaultState = () => {
  return {
    list: [],
    statusUserAction: null,
    totalUsers: 0,
    search: {
      query: '',
      club: ''
    },
    pagination: {
      page: 0,
      limit: 10
    },
    requestStatus: '' // 'pending', 'ok', 'error'
  }
}

const state = getDefaultState()

const mutations = {
  'SET_USERS_TOTAL' (state, data) {
    state.totalUsers = data
  },
  'SET_USERS' (state, data) {
    if (state.pagination.page === 0) {
      state.list = data.content
    } else {
      state.list.push(...data.content)
    }
    state.totalUsers = data.total
  },
  'SET_USER' (state, data) {
    state.list.push(data)
  },
  'ADD_USER' (state, data) {
    state.list.push(data)
    state.status = 'success'
  },
  'EDIT_USER' (state, data) {
    let index = state.list.findIndex(user => user._id === data._id)
    Vue.set(state.list, index, data)
  },
  'REMOVE_USER' (state, data) {
    let index = state.list.findIndex(user => user._id === data._id)
    Vue.delete(state.list, index)
  },
  SET_SEARCH_PARAMS (state, data) {
    for (let item in data.search) {
      Vue.set(state.search, item, data.search[item])
    }
    Vue.set(state.pagination, 'page', data.page)
  },
  SET_REQUEST_STATUS (state, val) {
    state.requestStatus = val
  },
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // async setUsers ({ commit, state }) {
  //   if (!state.list.length) {
  //     let list = await usersApi.all()
  //     commit('SET_USERS', list.data)
  //   }
  // },
  setUsersTotal ({ commit }, data) {
    commit('SET_USERS_TOTAL', data)
  },
  async searchUsers ({ commit, state }) {
    try {
      commit('SET_REQUEST_STATUS', 'pending')
      let users = await usersApi.search(state.pagination, state.search)
      commit('SET_USERS', users.data)
      commit('SET_REQUEST_STATUS', 'ok')
      return true
    } catch (e) {
      commit('SET_REQUEST_STATUS', 'error')
      console.log(e)
      throw e
    }
  },
  async searchUserByLogin ({ commit }, login) {
    try {
      commit('SET_REQUEST_STATUS', 'pending')
      let user = await usersApi.searchOne(login)
      commit('SET_USER', user.data)
      commit('SET_REQUEST_STATUS', 'ok')
    } catch (e) {
      console.log(e)
    }
  },
  async setSearchParams ({ commit }, data) {
    commit('SET_SEARCH_PARAMS', data)
  },
  async addUser ({ commit, dispatch }, data) {
    try {
      await usersApi.add(data)
      // commit('ADD_USER', add.data)
      commit('SHOW_ALERT', { text: 'Запись успешно создана', type: 'success' })
      await dispatch('searchUsers')
      return true
    } catch (e) {
      if (e.data.code === 11000) {
        commit('SHOW_ALERT', { text: 'Пользователь с таким никнеймом уже существует.', type: 'error' })
      } else {
        commit('SHOW_ALERT', { text: 'Ошибка создания. Пожалуйста, попробуйте позже.', type: 'error' })
      }
      throw e
    }
  },
  async editUser ({ commit, dispatch }, data) {
    try {
      let edit = await usersApi.edit(data)
      commit('EDIT_USER', edit.data)
      commit('SHOW_ALERT', { text: 'Изменения сохранены', type: 'success' })
      await dispatch('searchUsers')
    } catch (e) {
      commit('SHOW_ALERT', { text: 'Ошибка сохранения. Попробуйте повторить попытку.', type: 'error' })
      throw e
    }
  },
  async removeUser ({ commit }, login) {
    try {
      let del = await usersApi.remove(login)
      commit('REMOVE_USER', del.data)
      commit('SHOW_ALERT', { text: 'Запись успешно удалена', type: 'success' })
    } catch (e) {
      commit('SHOW_ALERT', { text: 'Ошибка удаления. Попробуйте повторить попытку.', type: 'error' })
      throw e
    }
  },
  async generateAPIKey ({ commit }, user) {
    try {
      let res = await usersApi.generateAPIKey(user)
      return res.data.key
    } catch (e) {
      commit('SHOW_ALERT', { text: 'Ошибка генерации API ключа. Попробуйте повторить попытку.', type: 'error' })
      throw e
    }
  }
}

const getters = {
  getUsers: state => {
    return state.list
  },
  getUserByLogin: state => (login) => {
    return state.list.find(user => {
      return user.login === login
    })
  },
  getSearch: state => state.search,
  getPagination: state => state.pagination,
  getTotalUsers: state => state.totalUsers,
  getRequestStatus: state => state.requestStatus
}

export default {
  state,
  mutations,
  actions,
  getters
}
