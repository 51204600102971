<template>
  <div class="detail">
    <div class="detail__header">
      <app-structure
        class="detail__structure"
        :club="clubId"
        :value="requestData.structureData.ids"
        terminals
        @change="setStructureData($event)"
      />
    </div>
    <div class="detail__body">
      <app-loader
        class="detail__loader"
        v-if="loader || activeComponent === null"
        status="loading"
      />
      <component
        v-else
        :is="activeComponent.component"
        :id="clubId"
        :activeComponentId="activeComponent.id"
        :requestData="requestData"
      />
    </div>
    <div class="detail__footer">
      <span>
        Показана статистика “{{ activeComponent.name }}”
      </span>
        <span>
        Изменяйте параметры фильтрации для более детального отображения
      </span>
    </div>
    <app-vue-html-2-pdf
      v-if="$vuetify.breakpoint.mdAndUp && !loader && activeComponent !== null"
      :show-layout="false"
      :manual-pagination="false"
      :paginate-elements-by-height="1400"
      :enable-download="true"
      :pdf-quality="2"
      pdf-content-width="1060px"
      :html-to-pdf-options="pdfOptions"
      ref="detail"
    >
      <section
        slot="pdf-content"
        style="font-family: Proxima Nova, sans-serif"
      >
        <app-dashboard-report-header
          :requestData="requestData"
          :chart="activeComponent.name"
          :id="id"
        />
        <component
          class="detail__pdf"
          :is="activeComponent.component"
          :activeComponentId="activeComponent.id"
          :id="id"
          :requestData="requestData"
        />
      </section>
    </app-vue-html-2-pdf>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'
import Structure from '@/components/UI-Kit/form/Structure'
import Loader from '@/components/UI-Kit/loaders/LoaderUpdated'
import DashboardReportHeader from '@/components/Club/ClubDashboards/DashboardReportHeader'
// Dynamical Components
import DetailChart from '@/components/Club/ClubDashboards/DetailComponents/DetailChart'

export default {
  name: 'ClubDashboardDetail',
  components: {
    'app-loader': Loader,
    'app-detail-chart': DetailChart,
    'app-structure': Structure,
    'app-vue-html-2-pdf': VueHtml2pdf,
    'app-dashboard-report-header': DashboardReportHeader
  },
  props: {
    requestData: {
      type: Object,
      require: true
    },
    id: {
      type: [Number, String],
      require: false
    }
  },
  data: () => ({
    pdfOptions: {
      margin: [0, 5],
      filename: `detail`,
      enableLinks: false,
      html2canvas: {
        scale: 1,
        useCORS: true
      },
      jsPDF: {
        format: 'a4',
        orientation: 'landscape'
      }
    }
  }),
  mounted () {
    this.setHeader(false)
  },
  beforeDestroy () {
    this.setHeader(true)
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    loader () {
      return this.$store.getters[`${this.clubId}/dashboards/getLoading`]
    },
    activeComponent () {
      return this.$store.getters[`${this.clubId}/dashboards/getDetailParams`]
    }
  },
  methods: {
    ...mapActions([
      'setHeader'
    ]),
    setStructureData (e) {
      this.$emit('setStructureData', {
        type: 'terminal',
        ids: e
      })
    },
    downloadPDF () {
      console.log('downloadPDF')
      this.$refs.detail.generatePdf()
    }
  }
}
</script>

<style scoped lang="scss">
.detail {
  background: #FFFFFF;
  padding: 15px 50px;
  &__header {
    display: flex;
    align-items: center;
    & + & {
      margin-top: 15px;
    }
  }
  &__pdf {
    font-family: sans-serif;
    margin-top: 30px;
    padding: 0 30px;
  }
  &__body {
    position: relative;
    min-height: 550px;
    margin-top: 15px;
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &__footer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    color: #9E9E9E;
  }
  &__datePiker {
    &::v-deep {
      .input {
        width: 360px;
      }
    }
  }
  &__structure {
    ::v-deep {
      .structure {
        width: 1140px;
      }
    }
  }
}
</style>
