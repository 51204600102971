import { server } from './index'

let clubsApi = {
  // available () {
  //   return server.get('/user/available/clubs')
  // },
  add (data) {
    return server.post('/clubs/add', data)
  },
  edit (data) {
    return server.patch('/clubs/edit', data)
  },
  remove (data) {
    return server.post('/clubs/remove', data)
  },
  getRemoteClubData (clubId) {
    return server.get(`/structure/club/${clubId}`)
  },
  editRemoteClubData (clubId, data) {
    return server.patch(`/structure/club/${clubId}`, data)
  }
}

export { clubsApi }
