var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.setWidth),expression:"setWidth"},{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],ref:"select",class:("input select select--multiple select--" + _vm.mode),attrs:{"items":_vm.itemsComputed,"item-text":_vm.itemText,"item-value":_vm.itemValue,"rounded":"","label":_vm.selectLabel,"multiple":"","clearable":"","disabled":_vm.disabled,"menu-props":_vm.getMenuProps,"return-object":_vm.returnObject,"hide-details":_vm.hideDetails,"rules":_vm.rules},on:{"change":function($event){return _vm.$emit('change', $event)},"click:clear":_vm.clear,"blur":function($event){_vm.clearOnBlur ? _vm.clear() : null},"update:error":function($event){return _vm.$emit('error', $event)}},scopedSlots:_vm._u([(['point', 'terminal', 'template'].indexOf(_vm.mode) !== -1)?{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"select__prepend-icon",domProps:{"innerHTML":_vm._s(require(("@/assets/img/icons/" + _vm.mode + ".svg")))}})]},proxy:true}:null,{key:"append",fn:function(){return [_c('div',{staticClass:"select__append-icon",domProps:{"innerHTML":_vm._s(require("@/assets/img/icons/arrow.svg"))}})]},proxy:true},(_vm.mode === 'terminal')?{key:"item",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-simple-checkbox',{attrs:{"value":attrs.inputValue},on:{"change":function($event){return parent.$emit('select')}}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.code)+" - "+_vm._s(item.title)+" ")])],1)],1)]}}:null,{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"select__prepend-item"},[_c('app-input',{attrs:{"mode":"search","value":_vm.search,"placeholder":"Начните вводить название..."},on:{"update:value":function($event){_vm.search=$event}}})],1),(_vm.selectAll)?_c('v-list-item',{staticClass:"v-list-item-select-all",class:{ 'v-list-item--active': _vm.selectAllItems },attrs:{"ripple":""},on:{"change":function($event){return _vm.toggleSelectAll()}}},[_c('v-list-item-action',[_c('v-simple-checkbox',{on:{"click":function($event){return _vm.toggleSelectAll()}}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.selectAllItems ? 'Отменить выделение' : 'Выбрать все')+" ")])],1)],1):_vm._e(),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(item.title || item.text || item)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"select__selection-quantity"},[_vm._v(" (еще "+_vm._s(_vm.v.length - 1)+") ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"select__no-data"},[_vm._v("Нет данных")])]},proxy:true}],null,true),model:{value:(_vm.v),callback:function ($$v) {_vm.v=$$v},expression:"v"}})}
var staticRenderFns = []

export { render, staticRenderFns }