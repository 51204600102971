import { server } from './index'

let newsApi = {
  all (id) {
    return server.get(
      `/news/${id}`
    )
  },
  search (id, data) {
    return server.post(`/news/search/${id}`, data)
  },
  item (newsItemId) {
    return server.get(`/news/item/${newsItemId}`)
  },
  add (data) {
    return server.post(`/news/add`, data)
  },
  edit (data) {
    return server.patch(`/news/edit`, { ...data.data })
  },
  remove (data) {
    return server.delete(`/news/remove`, { data })
  },
  removeImage (id) {
    return server.post(`/news/removeImage`, { id })
  },
  addImage (data) {
    return server.post(`/news/addImage`, data)
  }
}

export { newsApi }
