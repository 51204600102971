<template>
  <div class="dashboards">
    <app-loader
      v-if="!chartData.data.length"
      status="404"
    />
    <template
      v-else
    >
      <div class="dashboards__info">
        <div class="dashboards__infoBox">
          <span>
            min: {{ minMax.min.date }}
          </span>
          <span class="dashboards__infoNum">
            {{ Math.round(minMax.min.sum).toLocaleString() }} ₽
          </span>
        </div>
        <div class="dashboards__infoBox">
          <span>
            max: {{ minMax.max.date }}
          </span>
          <span class="dashboards__infoNum dashboards__text-green">
            {{ Math.round(minMax.max.sum).toLocaleString() }} ₽
          </span>
        </div>
        <app-dashboard-num
          class="dashboards__num"
          :number="chartData.num"
          :comma="true"
          text="₽"
        />
      </div>
      <div class="dashboards__chart">
        <app-dashboard-graph
          :data="chartData.data"
          :label="chartData.label"
          :key="activeComponentId"
        />
      </div>
    </template>
  </div>
</template>

<script>
import DashboardGraphModule from '@/components/Club/ClubDashboards/DashboardGraphModule'
import DashboardNum from '@/components/Club/ClubDashboards/DashboardNum'
import Loader from '@/components/UI-Kit/loaders/LoaderUpdated'

export default {
  name: 'ClubDashboardDetailChart',
  components: {
    'app-loader': Loader,
    'app-dashboard-graph': DashboardGraphModule,
    'app-dashboard-num': DashboardNum
  },
  props: {
    activeComponentId: {
      type: String,
      require: true
    },
    requestData: {
      type: Object,
      require: true
    },
    id: {
      type: [Number, String],
      required: false
    }
  },
  data: () => ({
    retailId: null,
    pointId: null,
    terminalId: null,
    activeClub: null,
    userModules: []
  }),
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    billSum () {
      return this.$store.getters[`${this.clubId}/dashboards/getBillSum`]
    },
    purchasesByDay () {
      return this.$store.getters[`${this.clubId}/dashboards/getPurchasesByDay`]
    },
    averageBillSum () {
      return this.$store.getters[`${this.clubId}/dashboards/getAverageBillSum`]
    },
    averageBillByDay () {
      return this.$store.getters[`${this.clubId}/dashboards/getAverageBillByDay`]
    },
    chartData () {
      switch (this.activeComponentId) {
        case 'purchasesAmount':
          return {
            data: this.purchasesByDay,
            label: 'Выручка',
            num: this.billSum ? this.billSum : 0
          }
        case 'averageCheck':
          return {
            data: this.averageBillByDay,
            label: 'Сумма',
            num: this.averageBillSum ? this.averageBillSum : 0
          }
        case 'revenueDynamics':
          return {
            data: this.averageBillByDay,
            label: 'Сумма',
            num: this.averageBillSum ? this.averageBillSum : 0
          }
        default:
          return {}
      }
    },
    minMax () {
      return {
        max: this.chartData.data.reduce((prev, current) => {
          return (prev.sum > current.sum) ? prev : current
        }),
        min: this.chartData.data.reduce((prev, current) => {
          return (prev.sum < current.sum) ? prev : current
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dashboards {
  &__chart {
    margin-top: 30px;
  }
  &__info {
    display: flex;
  }
  &__num {
    margin-left: 200px;
  }
  &__infoBox {
    margin-left: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #9E9E9E;
    & + & {
      margin-left: 90px;
    }
  }
  &__infoNum {
    font-weight: 800;
    font-size: 26px;
    line-height: 32px;
  }
}
</style>
