<template>
  <div>
    <v-app-bar
      class="nav nav--mob nav--header"
      app
      :height="50"
      flat
    >
      <app-nav-burger
        class="ml-auto"
        @click="drawerParent = !drawerParent"
        :drawer="drawerParent"
      />

      <v-spacer />

      <app-nav-logo/>
      <v-spacer />
    </v-app-bar>

    <v-navigation-drawer
      class="nav nav--mob nav--menu"
      v-model="drawerParent"
      app
      :width="drawerWidth"
    >
      <v-fade-transition>
        <app-nav-list-clubs
          class="nav__list"
          :clubs="clubs"
          :clubs-route="clubsRoute"
          @click="changeActiveClub($event)"
        >
          <v-navigation-drawer
            class="nav nav--mob nav--menu"
            v-model="drawerChild"
            app
            :width="drawerWidth"
          >
            <v-fade-transition>
              <app-nav-list-club-modules
                class="nav__list"
                :club="club"
                :club-routes="clubRoutes"
                :drawer="drawerChild"
              >
                <v-toolbar
                  tag="footer"
                  class="nav__footer"
                  :height="47"
                  flat
                  @click="drawerChild = false"
                >
                  <app-button
                    mode="link"
                    icon="arrow-prev"
                    left
                  >
                    {{ club.title }}
                  </app-button>
                </v-toolbar>
              </app-nav-list-club-modules>
            </v-fade-transition>
          </v-navigation-drawer>

          <v-toolbar
            tag="footer"
            class="nav__footer"
            :height="80"
            flat
          >
            <app-nav-user :mob="true" />
            <v-spacer />
            <app-nav-logout class="ml-auto" />
          </v-toolbar>
        </app-nav-list-clubs>
      </v-fade-transition>
    </v-navigation-drawer>

    <v-app-bar
      tag="footer"
      class="nav nav--mob nav--footer"
      app
      flat
      bottom
      :height="50"
    >
      <app-nav-list-modules
        class="nav__list"
        :routes="routes"
      />
    </v-app-bar>
  </div>
</template>

<script>
import NavLogo from '@/components/nav/components/NavLogo'
import NavUser from '@/components/nav/components/NavUser'
import NavBurger from '@/components/nav/components/NavBurger'
import NavLogout from '@/components/nav/components/NavLogout'
import NavListModules from '@/components/nav/mobile/navLists/Modules'
import NavListClubs from '@/components/nav/mobile/navLists/Clubs'
import NavListClubModules from '@/components/nav/mobile/navLists/ClubModules'
import Button from '@/components/UI-Kit/buttons/Button'

export default {
  name: 'NavMobile',
  components: {
    'app-nav-logo': NavLogo,
    'app-nav-user': NavUser,
    'app-nav-burger': NavBurger,
    'app-nav-logout': NavLogout,
    'app-nav-list-modules': NavListModules,
    'app-nav-list-clubs': NavListClubs,
    'app-nav-list-club-modules': NavListClubModules,
    'app-button': Button
  },
  props: {
    routes: {
      type: Array,
      required: true
    },
    clubs: {
      type: Array,
      required: true
    },
    clubsRoute: {
      type: Object,
      required: true
    },
    clubRoutes: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      drawerWidth: 320,
      drawerParent: false,
      drawerChild: false,
      club: {}
    }
  },
  methods: {
    changeActiveClub (club) {
      this.drawerChild = true
      this.club = club
      this.$emit('change', club)
    }
  }
}
</script>

<style lang="scss" scope>
// @media #{map-get($display-breakpoints, 'sm-and-up')} {

// }
</style>
