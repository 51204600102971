import { server } from './index'

let logsApi = {
  all (data, sortBy, sortDesc, page, size) {
    let direction
    if (sortDesc) {
      direction = 'DESC'
    } else {
      direction = 'ASC'
    }
    return server.post(`/actions?sortProperty=${sortBy}&sortDirection=${direction}&num=${page}&limit=${size}`, data)
  },
  statistics () {
    return server.get('/actions/statistics')
  }
}

export { logsApi }
