import { server } from './index'

let participantsApi = {
  all (id, data) {
    return server.post(`/remote/participants/${id}`, data)
  },
  findOne (id, participantId) {
    return server.get(`/remote/participants/${id}/${participantId}`)
  },
  edit (id, data) {
    return server.patch(`/remote/participants/edit/${id}`, data)
  },
  getCardHistory (data) {
    return server.get(`/remote/cardHistory/${data.id}/${data.cardId}`)
  },
  editCardHistory (clubId, data) {
    return server.patch(`/remote/cardHistory/${clubId}`, data)
  }
}

export { participantsApi }
