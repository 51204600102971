import { server } from './index'

let retailsApi = {
  all (id) {
    return server.get(`/user/available/retails/${id}`)
  },
  edit (clubId, data) {
    return server.patch(`/structure/retail/${clubId}`, data)
  },
  add (clubId, data) {
    console.log(data)
    return server.post(`/structure/retail/${clubId}`, data)
  },
  deleteRetail (clubId, ids) {
    return server.delete(`/structure/retail/delete/${clubId}`, { data: { ids } })
  }
}

export { retailsApi }
