import { server } from './index'

let pushApi = {
  search (id, data) {
    return server.post(`/remote/push/search/${id}`, data)
  },
  add (id, data) {
    return server.post(`/remote/push/add/${id}`, data)
  },
  edit (id, data) {
    return server.patch(`/remote/push/edit/${id}`, data)
  },
  remove (id, data) {
    return server.post(`/remote/push/remove/${id}`, data)
  },
  searchMessengers (id, data) {
    return server.post(`/remote/push/search-messengers/${id}`, data)
  },
  addMessengers (id, data) {
    return server.post(`/remote/push/add-messengers/${id}`, data)
  },
  editMessengers (id, data) {
    return server.patch(`/remote/push/edit-messengers/${id}`, data)
  },
  removeMessengers (id, data) {
    return server.post(`/remote/push/remove-messengers/${id}`, data)
  }
}

export { pushApi }
