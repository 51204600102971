<template>
  <div class="reports">
    <template
      v-for="(moduleGroup, name) in activeUserModules"
    >
      <div
        v-if="moduleGroup.length > 0"
        :key="name"
        class="reports__item"
      >
        <div class="reports__title">
          {{ getName(name) }}
        </div>
        <app-dashboard-block
          v-for="module in moduleGroup"
          :key="module.id"
          :requestData="requestData"
          :component="'app-module-' + name + '-' + module.id"
          :report="true"
          :id="clubId"
        />
      </div>
    </template>
  </div>
</template>

<script>
import DashboardBlock from '@/components/Club/ClubDashboards/DashboardBlock'

export default {
  name: 'ClubDashboardsReportsAll',
  props: {
    requestData: {
      type: Object,
      required: true
    },
    id: {
      type: [Number, String],
      require: false
    }
  },
  components: {
    'app-dashboard-block': DashboardBlock
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    activeUserModules () {
      return this.$store.getters[`${this.clubId}/dashboards/getActiveUserModules`]
    }
  },
  methods: {
    getName (name) {
      return this.$store.getters[`${this.clubId}/dashboards/getModuleName`](name)
    }
  }
}
</script>

<style lang="scss">
.reports {
  display: flex;
  flex-direction: column;
  padding: 20px 30px 0;
  &__item {
    width: 100%;
    & + & {
      margin-top: 20px;
    }
  }
  &__title {
    font-weight: bold;
  }
  &__row {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
