const getDefaultState = () => {
  return {
    drawer: true,
    header: true
  }
}

const state = getDefaultState()

const mutations = {
  'SET_DRAWER' (state, data) {
    state.drawer = data
  },
  'SET_HEADER' (state, data) {
    state.header = data
  }
}

const actions = {
  setDrawer ({ commit }, data) {
    commit('SET_DRAWER', data)
  },
  setHeader ({ commit }, data) {
    commit('SET_HEADER', data)
  }
}
const getters = {
  getDrawer: state => state.drawer,
  getHeader: state => state.header
}

export default {
  state,
  mutations,
  actions,
  getters
}
