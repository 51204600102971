<template>
  <div
    class="d-flex justify-center align-baseline"
    :style="{
      color: color,
      fill: color
    }"
  >
    <span v-if="arrow=== 'top'" class="dashboard__angle">▲</span>
    <span v-if="arrow=== 'bot'" class="dashboard__angle">▼</span>
    <span class="dashboard__text-big">
      {{ comma ? numBeforeComma.toLocaleString() : number.toLocaleString() }}
    </span>
    <span v-if="comma" class="dashboard__text-med">,{{ commaNum }} </span>
    <span v-if="text" class="dashboard__icon dashboard__text-med">{{ text }}</span>
    <div
      v-if="icon"
      class="dashboard__icon"
      v-html="require(`@/assets/img/icons/${ icon }.svg`)"
    />
    <div v-if="today !== undefined" class="dashboard__today">
      <span class="dashboard__angle">▲</span>
      <span class="dashboard__text-small">{{ today.toLocaleString() }} сегодня</span>
    </div>
  </div>
</template>

<script>
/**
 * Компонент количества для дашборда
 * @props
 * number number Число
 * today Number увеличение числа
 * comma Boolean включить/отключить цифры после запятой
 * color String цвет текста и иконки
 * icon String название иконки которая будет вставлена после числа
 * text String текст после числа
 * arrow String тип стрелки перед числом (top или bot)
 */
export default {
  name: 'DashboardNum',
  props: {
    number: {
      type: Number,
      require: true
    },
    today: {
      type: Number,
      require: false
    },
    comma: {
      type: Boolean,
      require: false,
      default: false
    },
    color: {
      type: String,
      require: false
    },
    icon: {
      type: String,
      require: false
    },
    text: {
      type: String,
      require: false
    },
    arrow: {
      type: String,
      require: false
    }
  },
  computed: {
    numBeforeComma () {
      return Number(this.number.toFixed(0))
    },
    commaNum () {
      return this.number.toFixed(2).slice(-2)
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard {
  &__text {
    &-big {
      font-weight: 800;
      font-size: 32px;
      line-height: 39px;
    }
    &-med {
      font-weight: 800;
      font-size: 20px;
      line-height: 100%;
    }
    &-small {
      font-size: 17px;
      font-weight: normal;
      line-height: 100%;
    }
  }
  &__icon {
    margin-left: 5px;
  }
  &__today {
    margin-left: 5px;
    color: #26BC22;
    fill: #26BC22;
  }
  &__angle {
    font-size: 17px;
    padding-right: 3px;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .dashboards {
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .dashboards {
  }
}

@media #{map-get($display-breakpoints, 'xl-only')} {
  .dashboard {
    &__text {
      &-big {
        font-size: 44px;
        line-height: 100%;
      }
      &-med {
        font-size: 30px;
        line-height: 100%;
      }
    }
  }
}
</style>
