<template>
  <div class="nav">
    <component
      v-if="getUserModules"
      :is="component"
      :routes="getRoutes"
      :clubs="getClubsSorted"
      :clubs-route="getClubsRoute"
      :club-routes="getClubRoutes(club)"
      @change="club = $event"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NavDesktop from '@/components/nav/desktop/Nav'
import NavMobile from '@/components/nav/mobile/Nav'

export default {
  name: 'Nav',
  components: {
    'app-nav-desktop': NavDesktop,
    'app-nav-mobile': NavMobile
  },
  data: () => ({
    club: {}
  }),
  computed: {
    ...mapGetters([
      'getUser',
      'isBMSAdmin',
      'getClubs',
      'getUserModules',
      'getTotalClubs',
      'getTotalUsers'
    ]),
    component () {
      return this.$vuetify.breakpoint.smAndDown ? 'app-nav-mobile' : 'app-nav-desktop'
    },
    getRoutes () {
      if (!this.getUserModules) return []
      let routes = this.$router.options.routes
        .filter(el => {
          if (this.isBMSAdmin) return el.meta && el.meta.title && el.name !== 'Clubs'
          else if (!el.BMSAdmin) return el.meta && el.meta.title && el.name !== 'Clubs'
        })

      routes = JSON.parse(JSON.stringify(routes))

      if (this.getUserModules.length) {
        routes = routes.filter(route => {
          if (['Home', 'Settings', 'Faq'].includes(route.name)) return route
          else return this.getUserModules.find(el => route.name.includes(el.name))
        })
      }

      let usersRoute = routes.find(el => el.name === 'Users')
      if (usersRoute) usersRoute.meta.quantity = this.getTotalUsers

      if (this.$vuetify.breakpoint.smAndDown) {
        routes.find(el => el.name === 'Home').meta = this.$router.options.routes.find(el => el.name === 'Clubs').meta
      } else {
        routes.find(el => el.name === 'Home').meta = this.$router.options.routes.find(el => el.name === 'Home').meta
      }

      return routes
    },
    getClubsRoute () {
      let clubsRoute = this.$router.options.routes
        .find(el => el.name === 'Clubs')

      clubsRoute.meta.quantity = this.getTotalClubs
      return clubsRoute
    },
    getClubsSorted () {
      const res = this.getClubs.slice()
      return res.sort((a, b) => {
        return a.title.localeCompare(b.title)
      })
    }
  },
  created () {
    this.loadUser()
  },
  methods: {
    ...mapActions([
      'loadUser'
    ]),
    getClubRoutes (club) {
      let routes = this.$router.options.routes
        .find(el => el.name === 'Club').children
        .filter(el => {
          if (el.name === 'ClubWebshop') {
            if (club && club.webShopAPI) {
              if (this.isBMSAdmin) return el
              else if (!el.BMSAdmin) return el
            }
          } else if (el.name === 'ClubSettings') {
            if (this.isBMSAdmin) return el
          } else {
            if (this.isBMSAdmin) return el
            else if (!el.BMSAdmin) return el
          }
        })

      if (this.getUserModules.length) {
        routes = routes.filter(route => {
          return this.getUserModules.find(el => {
            return route.name.includes(el.name) ||
              (route.children && route.children.find(child => child.name.includes(el.name)))
          })
        })
      }
      return routes.filter(el => el.name !== 'ClubPushMessengers')
    }
  }
}
</script>

<style lang="scss">

</style>
