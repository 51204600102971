export const formatMixin = {
  methods: {
    formatPhoneForServer (phone) {
      if (!phone) return ''
      else return phone.trim().replace(/[\s-+()]/g, '')
    },
    formatPhonesForServer (phones) {
      let array = phones.trim().split(',').filter(el => el).map(el => el.trim())
      array = array.map(el => {
        el = el.replace(/[\s-+()]/g, '')
        el = '7' + el.slice(el.length - 10, 10)
        return el
      })
      return array
    },
    formatCardForServer (card) {
      if (!card) return ''
      else return card.replace(/\s/g, '')
    }
  }
}

export const compareMixin = {
  methods: {
    compareArrays (arr1, arr2) {
      if (!arr1 || !arr2) return false
      else if (arr1.length !== arr2.length) return false

      let result = true
      arr1.forEach(el => {
        if (!arr2.includes(el)) result = false
        else result = true
      })

      return result
    },
    compareArraysWithObjects (arr1, arr2) {
      if (!arr1 || !arr2) return false
      else if (arr1.length !== arr2.length) return false

      let result = true
      arr1.some(el1 => {
        let found = arr2.find(el2 => JSON.stringify(el2) === JSON.stringify(el1))

        if (!found) {
          result = false
          return true
        } else {
          result = true
        }
      })

      return result
    },
    compareStructures (arr1, arr2) {
      if (!arr1 || !arr2) return false
      else if (arr1.length !== arr2.length) return false

      let result = true
      arr1.some(el1 => {
        let found = arr2.find(el2 => el1.club.id === el2.club.id)

        if (found) {
          let v1 = { retails: [], points: [] }
          el1.retails.forEach(el => {
            v1.retails.push(el.id)
            v1.points = v1.points.concat(el.points)
          })

          let v2 = { retails: [], points: [] }
          found.retails.forEach(el => {
            v2.retails.push(el.id)
            v2.points = v2.points.concat(el.points)
          })

          if (
            !this.compareArrays(v1.retails, v2.retails) ||
            !this.compareArrays(v1.points, v2.points)
          ) {
            result = false
            return true
          }
        } else {
          result = false
          return true
        }
      })

      return result
    }
  }
}
