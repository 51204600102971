<template>
  <v-list-item
    :class="`clubs-item__activator clubs-item__activator--${mode}`"
    two-line
  >
    <v-list-item-avatar
      class="clubs-item__activator-avatar"
      tile
      :size="$vuetify.breakpoint.mdAndUp ? 60 : 40"
    >
      <v-img
        :src="clubLogo"
        lazy-src="logo"
        aspect-ratio="1"
      >
        <!-- <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-row>
        </template> -->
      </v-img>
    </v-list-item-avatar>

    <v-list-item-content class="clubs-item__activator-content">
      <textarea
        class="clubs-item__activator__input"
        :disabled="state!=='edit'"
        v-model="newTitle"
        maxlength="40"
        ref="textarea"
        :style="{height: heightInput + 'px'}"
        @input="changeHeight()"
      />
    </v-list-item-content>

    <slot />

    <div
      v-if="mode === 'table'"
      class="clubs-item__activator-arrow"
      v-html="require(`@/assets/img/icons/arrow.svg`)"
    />
<!--    Realise it when back made methods to change categories-->
<!--    <div-->
<!--      v-if="mode === 'categories'"-->
<!--      class="clubs-item__activator-actions"-->
<!--    >-->
<!--      <template v-if="state==='edit'">-->
<!--        <app-button-save status="success" className="mr-5" :disabled="!valid" @click="saveEdit()"/>-->
<!--        <app-button-save status="error" @click="cancelEdit()"/>-->
<!--      </template>-->
<!--      <template v-else>-->
<!--        <app-button-tooltip-->
<!--          :icon="`edit`"-->
<!--          :size="[20, 20]"-->
<!--          className="mr-5"-->
<!--          message="Редактировать"-->
<!--          @click="edit()"-->
<!--        />-->
<!--        <app-button-tooltip-->
<!--          :icon="`trash`"-->
<!--          :size="[16, 20]"-->
<!--          message="Удалить"-->
<!--          @click="emit('remove', this.club)"-->
<!--        />-->
<!--      </template>-->
<!--    </div>-->
  </v-list-item>
</template>

<script>
// import ButtonTooltip from '../../UI-Kit/buttons/ButtonTooltip'
// import ButtonSave from '../../UI-Kit/buttons/ButtonSave'
export default {
  name: 'ClubsItemActivator',
  components: {
    // 'app-button-tooltip': ButtonTooltip,
    // 'app-button-save': ButtonSave
  },
  props: {
    mode: {
      // list, table, categories
      type: String,
      required: true
    },
    club: {
      type: Object,
      required: true
    }
  },
  created () {
    this.newTitle = this.club.title
  },
  mounted () {
    this.$nextTick(() => {
      this.heightInput = this.$refs.textarea.scrollHeight
    })
  },
  data () {
    return {
      state: 'default',
      newTitle: '',
      heightInput: 0
    }
  },
  computed: {
    clubLogo () {
      if (this.mode !== 'categories') {
        return this.$store.getters[`${this.club.id}/global/getClubLogo`] || {}
      } else {
        // change it when categories will have an image
        return '/img/emptyClub.jpg'
      }
    },
    valid () {
      return this.newTitle.length > 0
    }
  },
  methods: {
    edit () {
      this.state = 'edit'
      this.$nextTick(() => this.$refs.textarea.focus())
    },
    cancelEdit () {
      this.state = 'default'
      this.newTitle = this.club.title
    },
    saveEdit () {
      // send request to change title of category
      this.state = 'default'
    },
    changeHeight () {
      this.heightInput = this.$refs.textarea.scrollHeight
    }
  }
}
</script>

<style lang="scss">
.clubs-item__activator {
  width: 100%;
  padding: 0 30px;
  border-radius: $border-radius;
  box-shadow: $shadow;
  transition: box-shadow $primary-transition;

  .clubs-item__activator-avatar {
    background-color: $base;
    border-radius: $border-radius !important;
    box-shadow: $shadow-card-logo;
  }

  .clubs-item__activator-content {
    flex-grow: 2;
    textarea {
      color: $primary !important;
    }
  }

  .clubs-item__activator-subtitle {
    color: $icon !important;
  }

  &:hover {
    position: relative;
    z-index: 1;
    cursor: pointer;
  }

  &--table {
    height: 120px !important;

    .clubs-item__activator-avatar {
      position: relative;
      top: -20px;
      width: 100px !important;
      height: 100px !important;
      margin-right: 20px;
    }

    .clubs-item__activator-arrow {
      display: flex;
      transition: transform $primary-transition;

      svg {
        fill: $icon;
      }
    }

    &.active {
      .clubs-item__activator-arrow {
        transform: rotate(180deg);
      }
    }

    &:hover:not(.active) {
      box-shadow: $shadow-popup;
    }

    &.mob {
      height: 70px !important;
      padding: 0 15px;
      .clubs-item__activator-avatar {
        position: static;
        width: 40px !important;
        height: 40px !important;
        margin-right: 15px;
      }
    }

    @media #{map-get($display-breakpoints, 'md-and-down')} {
      padding: 0 15px;
    }
  }

  &--list {
    height: 90px !important;

    .clubs-item__activator-avatar {
      margin-right: 30px;
    }

    &:hover {
      box-shadow: $shadow-popup;
    }
  }
  &__input {
    color: $primary;
    overflow: hidden;
    resize: none;
    border: none;
    height: 25px;
    width: 100%;
    font-size: $font-20;
    &:focus,
    &:active {
      outline: none;
    }
    &:disabled {
      -webkit-text-fill-color: $primary;
      opacity: 1; /* required on iOS */
    }
    & > div {
      opacity: 1;
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .clubs-item__activator:not(.clubs-item__activator--categories) {
    .clubs-item__activator__input {
      font-size: $font-28;
    }
  }
}
</style>
