<template>
  <div class="dashboard">
    <app-mobile-filters
      v-if="mobileFilterOpen"
      :requestData="requestData"
      @updateAll="setRequestData($event)"
      @close="mobileFilterOpen = false"
    />
    <template
      v-else
    >
      <div
        v-if="activePage !== 'app-dashboard-main'"
        class="dashboard__nav"
      >
        <div class="dashboard__right">
          <div
            class="dashboard__prev"
            v-html="require(`@/assets/img/icons/arrow-right.svg`)"
            @click="goToMain"
          />
          <div class="dashboard__title">
            {{ activeComponent ? activeComponent.name : '' }}
          </div>
        </div>
        <div class="dashboard__left">
          <app-select-single
            class="dashboard__select"
            label="Раздел для отображения"
            item-text="name"
            item-value="id"
            :return-object="true"
            :value.sync="activeComponent"
            :items="activeComponents"
          />
          <app-button
            class="dashboard__pdf"
            icon="download"
            :left="true"
            @click="downloadPDF"
          >
            Скачать PDF
          </app-button>
        </div>
      </div>
      <div class="dashboard__filters">
        <div class="dashboard__filterControls">
          <app-period-piker
            :default="{
              from: requestData.date.from,
              to: requestData.date.to
            }"
            :mode="$vuetify.breakpoint.lgAndUp ? 'buttons' : 'select'"
            @update="setDates($event)"
          />
          <div class="dashboard__datePiker">
            <app-date-picker
              :default="[requestData.date.from, requestData.date.to]"
              @update="setDates($event)"
            />
          </div>
        </div>
        <div
          v-if="activePage === 'app-dashboard-main'"
          class="dashboard__filterBtns"
        >
          <div
            class="dashboard__config"
            @click="editModules"
            v-html="require(`@/assets/img/icons/control-2.svg`)"
          />
          <app-button
            class="dashboard__pdf"
            icon="download"
            :left="true"
            @click="downloadPDF"
          >
            Скачать PDF
          </app-button>
        </div>
      </div>
      <div class="dashboards__mobileFilters">
        <div class="dashboards__mobileTop">
          <span>Аналитика</span>
          <div
            class="dashboards__mobileConfig"
            @click="editModules"
            v-html="require(`@/assets/img/icons/control-2.svg`)"
          />
        </div>
        <app-button
          :size="'s'"
          :mode="'outline'"
          class="dashboards__mobileBtn"
          @click="mobileFilterOpen = true"
        >
          Показать фильтры
        </app-button>
      </div>
      <component
        :id="clubId"
        :is="activePage"
        :requestData="requestData"
        @setStructureData="setStructureData"
        ref="dashboardComponent"
      />
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions } from 'vuex'
import Button from '@/components/UI-Kit/buttons/Button'
import SelectSingle from '@/components/UI-Kit/selects/SelectSingle'
import SearchDatePicker from '@/components/UI-Kit/pickers/SearchDatePicker'
import DashboardPeriodPiker from '@/components/Club/ClubDashboards/DashboardPeriodPiker'
import DashboardMain from '@/components/Club/ClubDashboards/DashboardMain'
import DashboardDetail from '@/components/Club/ClubDashboards/DashboardDetail'
import DashboardMobileFilters from '@/components/Club/ClubDashboards/DashboardMobileFilters'

export default {
  name: 'ClubDashboard',
  components: {
    'app-dashboard-main': DashboardMain,
    'app-dashboard-detail': DashboardDetail,
    'app-button': Button,
    'app-select-single': SelectSingle,
    'app-date-picker': SearchDatePicker,
    'app-period-piker': DashboardPeriodPiker,
    'app-mobile-filters': DashboardMobileFilters
  },
  data: () => ({
    requestData: {
      date: {
        from: `${dayjs().format('YYYY-MM-DD')}`,
        to: `${dayjs().format('YYYY-MM-DD')}`
      },
      previousDate: {
        from: `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`,
        to: `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`
      },
      structureData: {
        ids: [],
        type: ''
      }
    },
    activeClub: null,
    mobileFilterOpen: false
  }),
  async mounted () {
    await this.$store.dispatch(`${this.clubId}/dashboards/setUserDashboards`, {
      userId: this.user._id,
      clubId: this.club._id
    })
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.$attrs.id
    },
    user () {
      return this.$store.getters.getUser
    },
    club () {
      return this.$store.getters[`${this.clubId}/global/getClubData`]
    },
    userModules () {
      return this.$store.getters[`${this.clubId}/dashboards/getUserModules`]
    },
    activeUserModules () {
      return this.$store.getters[`${this.clubId}/dashboards/getActiveUserModules`]
    },
    activeComponent: {
      get () {
        return this.$store.getters[`${this.clubId}/dashboards/getDetailParams`]
      },
      set (newVal) {
        console.log(newVal)
        this.$store.commit(`${this.clubId}/dashboards/SET_DETAIL_PARAMS`, {
          name: newVal.name,
          component: newVal.component,
          id: newVal.id
        })
      }
    },
    activeComponents () {
      let components = []
      for (let modules in this.activeUserModules) {
        this.activeUserModules[modules].forEach(module => {
          if (module.enable && module.detail !== undefined) {
            components.push({
              name: module.customName,
              component: module.detail,
              id: module.id
            })
          }
        })
      }
      return components
    },
    activePage () {
      return this.$store.getters[`${this.clubId}/dashboards/getDashboardPage`]
    }
  },
  watch: {
    requestData: {
      deep: true,
      handler () {
        this.setData()
      }
    },
    activeUserModules: {
      deep: true,
      handler () {
        this.setData()
      }
    }
  },
  methods: {
    ...mapActions({
      showPopup: 'showPopup'
    }),
    editModules () {
      this.showPopup({
        name: 'PopupDashboardsModules',
        data: {
          id: this.clubId,
          clubId: this.club._id,
          userId: this.user._id,
          modules: this.userModules
        }
      })
    },
    setDates (e) {
      const from = dayjs(e.from.slice(0, 10)).format('YYYY-MM-DD')
      const to = dayjs(e.to.slice(0, 10)).format('YYYY-MM-DD')
      this.requestData.date.from = from
      this.requestData.date.to = to
      let difference = dayjs(to).diff(from, 'month')
      if (difference >= 1) {
        this.requestData.previousDate.from = dayjs(from).subtract(difference, 'month').format('YYYY-MM-DD')
        this.requestData.previousDate.to = dayjs(to).subtract(difference, 'month').format('YYYY-MM-DD')
      } else {
        difference = dayjs(to).diff(from, 'day') + 1
        this.requestData.previousDate.from = dayjs(from).subtract(difference, 'day').format('YYYY-MM-DD')
        this.requestData.previousDate.to = dayjs(to).subtract(difference, 'day').format('YYYY-MM-DD')
      }
    },
    async setData () {
      await this.$store.dispatch(`${this.clubId}/dashboards/loadDashboards`, { clubId: this.clubId, data: this.requestData, activeModules: this.activeUserModules })
    },
    setStructureData (data) {
      this.requestData.structureData = data
    },
    setRequestData (data) {
      let newRequestData = data
      newRequestData.previousDate = {}
      let difference = dayjs(newRequestData.date.to).diff(newRequestData.date.from, 'month')
      if (difference >= 1) {
        newRequestData.previousDate.from = dayjs(newRequestData.date.from).subtract(difference, 'month').format('YYYY-MM-DD')
        newRequestData.previousDate.to = dayjs(newRequestData.date.to).subtract(difference, 'month').format('YYYY-MM-DD')
      } else {
        difference = dayjs(newRequestData.date.to).diff(newRequestData.date.from, 'day') + 1
        newRequestData.previousDate.from = dayjs(newRequestData.date.from).subtract(difference, 'day').format('YYYY-MM-DD')
        newRequestData.previousDate.to = dayjs(newRequestData.date.to).subtract(difference, 'day').format('YYYY-MM-DD')
      }
      this.requestData = newRequestData
    },
    goToMain () {
      this.$store.commit(`${this.clubId}/dashboards/SET_DASHBOARD_PAGE`, 'app-dashboard-main')
    },
    downloadPDF () {
      this.$refs.dashboardComponent.downloadPDF()
    }
  }
}
</script>

<style lang="scss">
.dashboard {
  background: #FFFFFF;
  &__nav {
    height: 90px;
    padding-bottom: 13px;
    background: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__right {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
  }
  &__left {
    display: flex;
    align-items: flex-start;
  }
  &__prev {
    height: 25px;
    width: 15px;
    fill: #9E9E9E;
    cursor: pointer;
  }
  &__title {
    margin-left: 30px;
    font-size: 26px;
    line-height: 32px;
    color: #484848;
  }
  &__filters {
    padding: 30px 50px 0;
    display: none;
    align-items: center;
    justify-content: space-between;
  }
  &__filterControls {
    display: flex;
    align-items: center;
  }
  &__filterBtns {
    display: flex;
    align-items: center;
  }
  &__pdf {
    margin-left: 30px;
    .button__icon, .button__icon svg {
      width: 20px !important;
      height: 20px !important;
    }
  }
  &__select {
    width: 360px;
  }
  &__config {
    margin-right: 30px;
    cursor: pointer;
    fill: #9E9E9E;
    width: 30px;
    height: 30px;
    transition: 0.3s;
    &:hover {
      fill: #ED5656;
    }
  }
  &__datePiker {
    .input {
      width: 360px;
    }
  }
  &__data {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.dashboards {
  position: relative;
  width: 100%;
  height: 100%;
  &__text {
    &-big {
      font-weight: 800;
      font-size: 32px;
      line-height: 39px;
    }
    &-comma {
      font-weight: 800;
      font-size: 30px;
    }
    &-med {
      font-weight: 800;
      font-size: 20px;
      line-height: 35px;
    }
    &-small {
      font-size: 17px;
      line-height: 21px;
    }
    &-green {
      color: #26BC22;
      fill: #26BC22;
    }
    &-gray {
      color: #9E9E9E;
      fill: #9E9E9E;
    }
    &-red {
      color: #ED5656;
    }
  }
  &__reset {
    margin-left: 30px;
    cursor: pointer;
  }
  &__btn {
    margin-top: 15px;
  }
  &__descr {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #9E9E9E;
  }
  &__mobileFilters {
    padding: 20px 15px 15px;
    background: #FFFFFF;
  }
  &__mobileTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    line-height: 27px;
    color: #484848;
  }
  &__mobileConfig {
    cursor: pointer;
    fill: #9E9E9E;
    width: 30px;
    height: 30px;
    transition: 0.3s;
    &:hover {
      fill: #ED5656;
    }
  }
  &__mobileBtn {
    margin-top: 15px;
    width: 100%;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .dashboards {
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .dashboard {
    background: transparent;
    &__filters {
      padding: 30px 25px 0;
      display: flex;
      flex-direction: column;
    }
    &__filterBtns {
      margin-left: auto;
      margin-top: 15px;
    }
    &__filterControls {
      width: 100%;
      justify-content: space-between;
    }
  }
  .dashboards {
    &__mobileFilters {
      display: none;
    }
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .dashboard {
    &__filters {
      padding: 30px 50px 0;
      display: flex;
      flex-direction: row;
    }
    &__filterBtns {
      margin-left: unset;
      margin-top: unset;
      margin-right: unset;
    }
    &__filterControls {
      width: auto;
      justify-content: flex-start;
    }
    &__datePiker .input {
      width: 300px;
    }
    &__config {
      margin-right: 0;
    }
  }
  .dashboards {
    &__text {
      &-big {
        font-size: 44px;
        line-height: 100%;
      }
    }
  }
}
@media #{map-get($display-breakpoints, 'xl-only')} {
  .dashboard {
    &__datePiker .input {
      width: 360px;
    }
    &__config {
      margin-right: 30px;
    }
}
.dashboard {

}
}
</style>
