<template>
  <v-tabs
    :class="`tabs tabs--${mode}`"
    :value="value"
    @change="$emit('update:value', $event)"
    :mobile-breakpoint="1024"
  >
    <v-tab
      v-for="item in items"
      :key="item.id"
      :to="item.link ? item.link : null"
      exact
      :class="[
       `tabs__tab tab tab--${item.name}`
      ]"
    >
      <div
        v-if="item.icon && $vuetify.breakpoint.mdAndUp"
        class="tab__icon d-flex"
        v-html="require(`@/assets/img/icons/${item.icon}.svg`)"
      />
      <span class="tab__title">
        {{ item.title }}
      </span>
      <span
        v-if="item.quantity"
        class="tab__quantity"
      >
        {{ item.quantity }}
      </span>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    mode: {
      type: String,
      required: false,
      default: 's',
      validator (value) {
        return ['s', 'm'].indexOf(value) !== -1
      }
    },
    value: {
      type: [Number, String],
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    links: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style lang="scss">
.tabs {
  margin: 0 !important;
  overflow: hidden;

  .v-tabs-bar {
    height: auto;
    .v-slide-group {
      &__prev,
      &__next {
        display: none !important;
      }
    }
  }

  .v-tabs-bar__content {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $separator;
    }
  }

  .v-tabs-slider-wrapper {
    height: 1px !important;
    color: $accent !important;
  }

  .tab {
    min-width: auto;
    max-width: 100%;
    letter-spacing: normal;

    &::before,
    .v-ripple__container {
      display: none;
    }

    .tab__icon {
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      margin-right: 16px;

      svg {
        width: 100%;
        height: 100%;
        fill: $icon;
        transition: fill $primary-transition;
      }
    }

    .tab__title {
      color: $primary !important;
      text-transform: none;
      white-space: nowrap;
      transition: color $primary-transition;
    }

    .tab__quantity {
      margin-left: 10px;
      color: $icon;
      font-weight: 300;
    }

    &--faq {
      margin-left: auto;
    }

    &:hover {
      .tab__title {
        color: $accent !important;
      }
    }

    &.v-tab--active {
      .tab__title {
        color: $accent !important;
      }

      .tab__icon {
        svg {
          fill: $accent;
        }
      }
    }
  }

  &--s {
    .tab {
      padding: 15px;
      font-size: $font-14;
      font-weight: 400;
    }
  }

  &--m {
    background: $base;
    .tab {
      padding: 10px;
      font-size: $font-18;
      font-weight: 400;
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .tabs {
    .v-tabs-bar__content {
      padding-left: 0;
      padding-right: 0;
    }
    &--s {
      .tab {
        font-size: $font-17;
      }
    }
    &--m {
      .tab {
        padding: 15px;
        font-size: $font-26;
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .tabs {
    &--m {
      .tab {
        padding: 30px;
      }
    }
  }
}
</style>
