<template>
  <v-card class="clubs-item">

    <!-- list view -->
    <keep-alive>
      <clubs-item-activator
        v-if="mode === 'list'"
        :mode="mode"
        :club="club"
      >
        <clubs-item-list
          :mode="mode"
          :club="club"
        />
      </clubs-item-activator>
    </keep-alive>

    <!-- table view -->
    <v-menu
      v-if="$vuetify.breakpoint.mdAndUp"
      v-model="menu"
      content-class="clubs-item__menu"
      offset-y
      bottom
      internal-activator
      transition="fade-transition"
      offset-overflow
    >
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <keep-alive>
            <clubs-item-activator
              v-if="mode === 'table'"
              :mode="mode"
              :club="club"
              :class="{ 'active': menu }"
            />
          </keep-alive>
        </div>
      </template>

      <keep-alive>
        <clubs-item-list
          v-if="mode === 'table'"
          :mode="mode"
          :club="club"
        />
      </keep-alive>
    </v-menu>

    <!-- mobile table view -->
    <v-expansion-panels
      v-model="expansion"
      v-if="!$vuetify.breakpoint.mdAndUp"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <clubs-item-activator
            v-if="mode === 'table'"
            :mode="mode"
            :club="club"
            :class="['mob', { 'active': expansion === 0 }]"
          />
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <clubs-item-list
            v-if="mode === 'table'"
            :mode="mode"
            :club="club"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import ClubsItemActivator from '@/components/clubs/components/ClubsItemActivator'
import ClubsItemList from '@/components/clubs/components/ClubsItemList'

export default {
  name: 'ClubsItemTable',
  components: {
    'clubs-item-activator': ClubsItemActivator,
    'clubs-item-list': ClubsItemList
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    club: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      menu: false,
      expansion: ''
    }
  }
}
</script>

<style lang="scss">
.clubs-item {
  position: relative !important;
  width: 100%;
  box-shadow: none !important;
  .v-expansion-panels {
    .v-expansion-panel {
      &::before {
        display: none;
      }
    }
    .v-expansion-panel-header {
      padding: 0;
      &__icon {
        display: none;
      }
    }
    .v-expansion-panel-content {
      box-shadow: $shadow-popup;
      &__wrap {
        padding: 0;
      }
    }
  }
}

.clubs-item__menu {
  box-shadow: $shadow-popup;
}
</style>
