import Vue from 'vue'
import dayjs from 'dayjs'

Vue.filter('dateRangeISO', v => {
  if (!v) return ''
  let res = dayjs(v[0]).format('DD.MM.YYYY')
  if (v[1] && v[1] !== Vue.prototype.$infinityDate) {
    res += ` - ${dayjs(v[1]).format('DD.MM.YYYY')}`
  }
  return res
})
Vue.filter('date', v => {
  if (!v) return ''
  return dayjs(new Date().setTime(v)).format('DD/MM/YYYY - HH:mm')
})
Vue.filter('dateTimestamp', v => {
  if (!v) return ''
  return dayjs(new Date().setTime(v)).format('DD.MM.YYYY в HH:mm')
})
Vue.filter('dateISO', v => {
  if (!v) return ''
  return dayjs(v).format('DD.MM.YYYY в HH:mm')
})
Vue.filter('dateBirth', v => {
  if (!v) return ''
  return dayjs(v).format('DD.MM.YYYY')
})
Vue.filter('happyDays', v => {
  if (!v) return ''
  return v.map(day => {
    return dayjs(day.happyDay).format('DD.MM')
  }).join(', ')
})
Vue.filter('arrayToString', v => {
  if (!v) return ''
  return v.join(', ')
})
Vue.filter('phone', v => {
  if (!v) return ''
  return v.replace(/[^0-9]/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5')
})
Vue.filter('hundred', v => {
  if (!v.toString().length) return ''
  return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
})
Vue.filter('capitalize', v => {
  if (!v) return ''
  return v.charAt(0).toUpperCase() + v.slice(1).toLowerCase()
})
Vue.filter('sliceNumber', v => {
  // return v ? parseInt(v).toFixed(2) : 0
  if (!v) return 0
  v = String(v)
    .replace(/[^\d,.]/g, '')
    .replace(/,/g, '.')

  if ((/\./g).test(v)) {
    //  console.log((/\./).test(s))
    //  console.log(s.indexOf('.'))
    v = v.split('')
      .splice(0, v.indexOf('.') + 3)
      .join('')
    // indexOf + 3 это  2 знака после точки
  }
  return Number(v)
//  из числа 123.444444 сделает 123.44
//  из строки '123.00' сделает 123
//  из строки 'abc123.09' сделает 123.09
})
Vue.filter('headerMenuTitle', v => {
  switch (v) {
    case 'Участники клуба':
      return 'Участники'
    case 'Структура клуба':
      return 'Структура'
    case 'Веб витрина':
      return 'Витрина'
    case 'Настройки клуба':
      return 'Настройки'
    case 'Push-уведомления':
      return 'Push'
    default:
      return v
  }
})
Vue.filter('roundTo2', v => {
  return v ? (+v.toFixed(2)).toLocaleString() : 0
})
