<template>
  <v-list
    :class="[
      `clubs-item-list clubs-item-list--${mode}`,
      { 'admin': isBMSAdmin },
      { 'mob': !$vuetify.breakpoint.xl }
    ]"
  >
    <clubs-item-list-item
      :class="[
        `clubs-item-list__item clubs-item-list__item--route`,
        { 'admin': isBMSAdmin }
      ]"
      v-for="(clubRoute, i) in getClubRoutes"
      :key="i"
      :style="`order: ${i}`"
      :mode="mode"
      :item="clubRoute.meta"
      :link="`/clubs/${club.id}/${clubRoute.path}`"
    />

    <v-list-item
      v-show="mode === 'list' && isBMSAdmin && $vuetify.breakpoint.xl"
      class="clubs-item-list__item clubs-item-list__item--api"
      :style="`order: ${order}`"
    >
      <v-list-item-content>
        <v-list-item-subtitle class="clubs-item-list__item-subtitle body-2">
          Адрес бека
        </v-list-item-subtitle>
        <v-list-item-title>
          {{ club.api }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!-- <clubs-item-list-item
      v-if="isBMSAdmin"
      class="clubs-item-list__item admin"
      :style="`order: ${getClubRoutes.length + 1}`"
      :mode="mode"
      :item="{ title: 'Удалить', icon: 'trash'}"
      :popup="{
        name: 'PopupConfirmation',
        data: { action: 'removeClub', id: club.id }
      }"
    /> -->
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'
import ClubItemListItem from '@/components/clubs/components/ClubsItemListItem.vue'

export default {
  name: 'ClubsItemList',
  components: {
    'clubs-item-list-item': ClubItemListItem
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    club: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'isBMSAdmin',
      'getUserModules'
    ]),
    getClubRoutes () {
      let routes = this.$router.options.routes
        .find(el => el.name === 'Club').children
        .filter(el => {
          if (el.name === 'ClubWebshop') {
            if (this.club.webShopAPI) {
              if (this.isBMSAdmin) return el
              else if (!el.BMSAdmin) return el
            }
          } else {
            if (this.isBMSAdmin) return el
            else if (!el.BMSAdmin) return el
          }
        })

      if (this.getUserModules && this.getUserModules.length) {
        routes = routes.filter(route => {
          return this.getUserModules.find(el => {
            return route.name.includes(el.name) ||
              (route.children && route.children.find(child => child.name.includes(el.name)))
          })
        })
      }

      return routes.filter(el => el.name !== 'ClubPushMessengers')
    },
    order () {
      if (this.mode === 'list' && this.isBMSAdmin) {
        return this.getClubRoutes.length - 2
      } else {
        return this.getClubRoutes.length
      }
    }
  }
}
</script>

<style lang="scss">
.clubs-item-list {
  padding: 0;

  &--table {

  }

  &--list {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    .clubs-item-list__item {
      .clubs-item-list__item-subtitle {
        color: $icon;
      }

      &--route {
        margin-right: 30px;
        &:last-child,
        &:nth-last-child(2) {
          margin-right: 0;
        }
      }

      &--api {
        justify-content: flex-end;

        .v-list-item__content {
          flex: 0 0 auto;
        }
      }
    }

    &.admin {
      justify-content: flex-start;

      .clubs-item-list__item {
        &--route {
        }
        &--api {
          justify-content: center;
        }
      }
    }

    &.mob {
      flex-grow: 0;
    }
  }
}
</style>
