<template>
  <div class="template-info">
    <v-row class="mt-0 mb-0">
      <v-col class="col-12 col-sm-6">Название</v-col>
      <v-col class="col-12 col-sm-6 template-info__value">{{ data.title }}</v-col>
    </v-row>
    <v-row class="mt-0 mb-0">
      <v-col class="col-12 col-sm-6">Тип шаблона</v-col>
      <v-col class="col-12 col-sm-6 template-info__value">{{ data.reportType }}</v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'TemplateInfo',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
</style>
