<template>
  <div :class="{'dashboard__data' : !report}">
    <template
      v-if="report"
    >
      <div class="reports__row">
        <span>Сумма покупок:</span>
        <span>{{ billSum | roundTo2 }} ₽</span>
      </div>
    </template>
    <template
      v-else
    >
      <app-dashboard-num
        :number="billSum ? billSum : 0"
        :comma="true"
        text="₽"
      />
      <app-dashboard-graph
        :data="purchasesByDay"
        :label="'Выручка'"
        :mode="'simple'"
        descr="Динамика суммы покупок"
      />
    </template>
  </div>
</template>

<script>
import DashboardNum from '@/components/Club/ClubDashboards/DashboardNum'
import DashboardGraphModule from '@/components/Club/ClubDashboards/DashboardGraphModule'

export default {
  name: 'ClubDashboardsModulePurchasesAmount',
  props: {
    report: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: [Number, String],
      require: false
    }
  },
  components: {
    'app-dashboard-num': DashboardNum,
    'app-dashboard-graph': DashboardGraphModule
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    billSum () {
      return this.$store.getters[`${this.clubId}/dashboards/getBillSum`]
    },
    purchasesByDay () {
      return this.$store.getters[`${this.clubId}/dashboards/getPurchasesByDay`]
    }
  }
}
</script>
