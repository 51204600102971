const getDefaultState = () => {
  return {
    alert: {
      text: '',
      type: 'success',
      value: false,
      timeout: 3000,
      halfSuccess: false,
      serverError: {}
    }
  }
}

const state = getDefaultState()

const mutations = {
  SHOW_ALERT (state, { text, type, halfSuccess = false, timeout = 3000 }) {
    state.alert.text = text
    state.alert.timeout = timeout
    state.alert.type = type
    state.alert.value = true
    state.alert.halfSuccess = halfSuccess
  },
  SHOW_ADMIN_ALERT (state, { type = 'error', serverError = {} }) {
    state.alert.timeout = 0
    state.alert.type = type
    state.alert.value = true
    state.alert.serverError = serverError
    if (Object.keys(serverError).length) {
      console.log(serverError)
    }
  },
  HIDE_ALERT (state) {
    state.alert.value = false
    state.alert.halfSuccess = false
    state.alert.serverError = {}
  }
}

const actions = {
  /**
   * @param commit
   * @param data
   * @param {string} data.text
   * @param {string} data.type success || error
   */
  showAlert (context, data) {
    context.commit('SHOW_ALERT', data)
  },
  showAdminAlert (context, data) {
    context.commit('SHOW_ADMIN_ALERT', data)
  },
  hideAlert ({ commit }) {
    commit('HIDE_ALERT')
  }
}

const getters = {
  getAlert: state => state.alert
}

export default {
  state,
  mutations,
  actions,
  getters
}
