<template>
  <div
    class="dashboards__modules"
    :class="[`${modulesClass}`, { 'pdf': pdf }]"
    ref="modules"
  >
    <template
      v-for="(moduleGroup, name) in activeUserModules"
    >
      <component
        :is="$vuetify.breakpoint.mdAndUp ? 'v-spacer' : 'v-expansion-panels'"
        v-if="moduleGroup.length > 0"
        :key="name"
        ref="module"
      >
        <component
          :is="$vuetify.breakpoint.mdAndUp ? 'v-spacer' : 'v-expansion-panel'"
          ref="box"
          class="dashboards__box"
        >
          <component
            :is="$vuetify.breakpoint.mdAndUp ? 'v-spacer' : 'v-expansion-panel-header'"
            class="dashboards__title"
          >
            {{ getName(name) }}
          </component>
          <component
            :is="$vuetify.breakpoint.mdAndUp ? 'v-spacer' : 'v-expansion-panel-content'"
            class="dashboards__modulesContent"
          >
            <app-dashboard-block
              v-for="module in moduleGroup"
              :key="module.id"
              :moduleId="module.id"
              :title="module.customName"
              :detail="module.detail"
              :id="clubId"
              :requestData="requestData"
              :component="'app-module-'+ name + '-' + module.id"
              :pdf="pdf"
            />
          </component>
        </component>
      </component>
    </template>
  </div>
</template>

<script>
import DashboardBlock from '@/components/Club/ClubDashboards/DashboardBlock'
import { VSpacer, VExpansionPanels, VExpansionPanel, VExpansionPanelHeader, VExpansionPanelContent } from 'vuetify/lib'

export default {
  name: 'DashboardModulesWrapper',
  components: {
    'app-dashboard-block': DashboardBlock,
    'v-spacer': VSpacer,
    'v-expansion-panels': VExpansionPanels,
    'v-expansion-panel': VExpansionPanel,
    'v-expansion-panel-header': VExpansionPanelHeader,
    'v-expansion-panel-content': VExpansionPanelContent
  },
  props: {
    requestData: {
      type: Object,
      required: true
    },
    id: {
      type: [Number, String],
      required: false
    },
    pdf: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    activeUserModules () {
      return this.$store.getters[`${this.clubId}/dashboards/getActiveUserModules`]
    },
    modulesClass () {
      return Object.getOwnPropertyNames(this.activeUserModules).length < 3 ? 'dashboards__modules-small' : 'dashboards__modules-big'
    }
  },
  mounted () {
    this.setModulesPosition()
  },
  watch: {
    activeUserModules: {
      deep: true,
      handler () {
        this.$nextTick(() => {
          this.setModulesPosition()
        })
      }
    }
  },
  methods: {
    getName (name) {
      return this.$store.getters[`${this.clubId}/dashboards/getModuleName`](name)
    },
    setModulesPosition () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        const boxes = this.$refs.module
        boxes.forEach(box => {
          const boxHeight = box.childNodes[0].clientHeight
          box.style.gridRow = 'span ' + Math.round(boxHeight / 5)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dashboards {
  &__modules {
    width: 100%;
    position: relative;
  }
  &__modulesContent {
    margin-top: 20px;
    &::v-deep {
      .v-expansion-panel-content__wrap {
        padding: 0;
      }
    }
  }
  &__title {
    padding: 0;
    width: 100%;
    min-height: 30px;
    height: fit-content;
    font-size: 18px;
    line-height: 22px;
    color: #484848;
  }
  &__modules {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    grid-auto-rows: 5px;
    grid-template-columns: 100%;
  }
  &__box {
    padding: 20px 15px;
    margin-bottom: 10px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 0 16px rgb(0 0 0 / 10%);
  }
}

.pdf {
  padding: 30px;
  display: flex;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .dashboards {
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .dashboards {
    &__box {
      padding: 0 5px 30px;
      margin-bottom: 0;
      width: 100%;
      box-shadow: none;
    }
    &__modules {
      grid-template-columns: 50% 50%;
    }
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .dashboards {
    &__modules {
      display: grid;
      &-small {
        grid-template-columns: 50% 50%;
      }
      &-big {
        grid-template-columns: 50% 50%;
      }
    }
    &__modulesContent {
      margin-top: 0;
    }
    &__title {
      margin-bottom: 15px;
      font-size: 24px;
      line-height: 29px;
    }
    &__box {
      background: transparent;
    }
  }
}

@media #{map-get($display-breakpoints, 'xl-only')} {
  .dashboards {
    &__modules {
      &-small {
        grid-template-columns: 50% 50%;
      }
      &-big {
        grid-template-columns: 33.33% 33.33% 33.33%;
      }
    }
  }
}
</style>
