<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-app-bar-nav-icon
        class="nav-logout ma-0"
        v-html="require(`@/assets/img/icons/logout.svg`)"
        width="24"
        height="24"
        tile
        v-on="on"
        @click="unAuth"
      />
    </template>
    <span>Выход</span>
  </v-tooltip>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'NavLogout',
  methods: {
    ...mapActions([
      'logout'
    ]),
    async unAuth () {
      await this.logout()
    }
  }
}
</script>

<style lang="scss">
.nav-logout {
  svg {
    fill: $icon;
    transition: fill $primary-transition;
  }

  &:hover {
    svg {
      fill: $base;
    }
  }
}
</style>
