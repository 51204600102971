<template>
  <div class="template-info">
    <div v-for="(field, index) in fields" :key="index">
      <template v-if="field.name === 'structure'">
        <v-row class="mt-0 mb-0">
          <v-col class="col-12 col-sm-6">Сеть</v-col>
          <v-col class="col-12 col-sm-6 template-info__value">
            {{ data.params.currentNetworkName || 'Все сети'}}
          </v-col>
        </v-row>
        <v-row class="mt-0 mb-0">
          <v-col class="col-12 col-sm-6">Точка</v-col>
          <v-col class="col-12 col-sm-6 template-info__value">
            {{ data.params.currentPointName || 'Все точки'}}
          </v-col>
        </v-row>
      </template>
      <v-row v-else class="mt-0 mb-0">
        <template v-if="field.name !== 'date'">
          <v-col v-if="field.text" class="col-12 col-sm-6">{{ field.text }}</v-col>
          <v-col v-if="field.name === 'juridical'" class="col-12 col-sm-6 template-info__value">
            <div v-for="(item, index) in juridical" :key="index">
              {{ item.title }}
            </div>
          </v-col>
          <v-col v-else-if="field.name === 'turn'" class="col-12 col-sm-6 template-info__value">{{ setTurn(data.params[field.name]) }}</v-col>
          <v-col v-else-if="field.name === 'cardNum'" class="col-12 col-sm-6 template-info__value">
            <div>от {{ data.params[field.name].from }}</div>
            <div>до {{ data.params[field.name].to }}</div>
          </v-col>
          <v-col v-else-if="field.name === 'dayAgo'" class="col-12 col-sm-6 template-info__value">
            {{ data.params.dayAgo }}
          </v-col>
          <v-col v-else-if="field.name === 'partners'" class="col-12 col-sm-6 template-info__value">
            <div v-for="(partner, index) in partners" :key="index">
              {{ partner }}
            </div>
          </v-col>
          <v-col v-else class="col-12 col-sm-6 template-info__value">{{ data[field.name] }}</v-col>
        </template>
      </v-row>
    </div>

    <v-row class="mt-0 mb-0">
      <v-col class="col-12 col-sm-6">Отправка отчета на e-mail</v-col>
      <v-col class="col-12 col-sm-6 template-info__value">{{ period }}</v-col>
    </v-row>
  </div>
</template>

<script>
import reports from '@/json/reports/reports.json'
export default {
  name: 'ParamsInfo',
  props: {
    data: {
      type: Object,
      required: true
    },
    clubId: {
      type: [Number, String],
      required: true
    }
  },
  methods: {
    setTurn (turn) {
      return turn < 10 ? `0${turn}:00` : `${turn}:00`
    }
  },
  computed: {
    fields () {
      return reports.find(report => report.text === this.data.reportType).fields
    },
    juridical () {
      if (this.data.params.juridical && this.data.params.juridical.length !== this.$store.getters[`${this.clubId}/global/getJuridicalByAvailablePoints`].length) {
        return this.data.params.juridical.map(item => {
          return this.$store.getters[`${this.clubId}/global/getJuridicalById`](item)
        })
      }
      return [
        {
          title: 'Все юридические лица'
        }
      ]
    },
    partners () {
      return this.data.params.partners.length ? this.data.params.partners : ['Все партнеры']
    },
    period () {
      switch (this.data.period) {
        case 'day':
          return 'Ежедневно'
        case 'week':
          return 'Еженедельно'
        case 'month':
          return 'Ежемесячно'
        default:
          return ''
      }
    }
  }
}
</script>
