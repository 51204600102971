<template>
  <div :class="`result result--${mode} result--${status}`">
    <v-tooltip
      top
      :disabled="mode === 'default'"
    >
      <template v-slot:activator="{ on }">
        <div
          v-if="mode === 'tooltip'"
          :class="`result__activator`"
          v-html="require(`@/assets/img/icons/${icon}.svg`)"
          v-on="on"
        />

      </template>
      <span>{{ message }}</span>
    </v-tooltip>

    <p
      v-if="mode === 'default'"
      class="result__message"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Result',
  props: {
    mode: {
      type: String,
      required: false,
      default: 'default',
      validator (value) {
        // Значение должно соответствовать одной из этих строк
        return ['default', 'tooltip'].indexOf(value) !== -1
      }
    },
    status: {
      type: String,
      required: true,
      validator (value) {
        // Значение должно соответствовать одной из этих строк
        return ['success', 'error', 'cancel', 'wait', 'new', 'in_progress', 'completed', 'cancelled'].indexOf(value) !== -1
      }
    }
  },
  data () {
    return {
      messages: {
        success: 'Успешно',
        error: 'Завершено с ошибкой',
        cancel: 'Отменено',
        wait: 'Ожидает отправки',
        new: 'Ожидает отправки',
        'in_progress': 'Отправляется',
        completed: 'Успешно',
        cancelled: 'Отменено'
      },
      icons: {
        success: 'success',
        error: 'error',
        cancel: 'cancel',
        wait: 'clock',
        new: 'clock',
        'in_progress': 'clock',
        completed: 'success',
        cancelled: 'cancel'
      }
    }
  },
  computed: {
    message () {
      return this.messages[this.status]
    },
    icon () {
      return this.icons[this.status]
    }
  }
}
</script>

<style lang="scss">
.result {
  &__activator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    transition: border $primary-transition;
    &:hover {
      cursor: pointer;
    }
  }

  &__message {
    margin-bottom: 0 !important;
    font-size: $font-17;
  }

  &--success,
  &--completed {
    .result__activator {
      border: 1px solid $success-disabled;

      svg {
        fill: $success;
      }

      &:hover {
        border: 1px solid $success;
      }
    }
    .result__message {
      color: $success;
    }
  }

  &--error {
    .result__activator {
      border: 1px solid $accent-disabled;

      svg {
        fill: $accent;
      }

      &:hover {
        border: 1px solid $accent;
      }
    }
    .result__message {
      color: $accent;
    }
  }

  &--cancel,
  &--cancelled {
    .result__activator {
      border: 1px solid $accent-disabled;

      svg {
        fill: $accent;
      }

      &:hover {
        border: 1px solid $accent;
      }
    }
    .result__message {
      color: $accent;
    }
  }

  &--wait,
  &--new,
  &--in_progress {
    .result__activator {
      border: 1px solid $admin-disabled;

      svg {
        fill: $admin;
      }

      &:hover {
        border: 1px solid $admin;
      }
    }
    .result__message {
      color: $admin;
    }
  }
}
</style>
