import { server } from './index'

let dashboardsApi = {
  getUserDashboards (data) {
    return server.post(`/dashboards`, data)
  },
  editUserDashboards (data) {
    return server.patch(`/dashboards/edit/${data.clubId}`, data)
  },
  usersCount (id) {
    return server.get(`/dashboards/users/count/${id}`)
  },
  todayUsersCount (id) {
    return server.get(`/dashboards/users/today/${id}`)
  },
  newUsersCount (clubId, data) {
    return server.post(`/dashboards/users/count/new/${clubId}`, data)
  },
  mobileUsersCount (clubId) {
    return server.get(`/dashboards/users/count/mobile/${clubId}`)
  },
  todayMobileUsersCount (clubId) {
    return server.get(`/dashboards/users/count/mobile/today/${clubId}`)
  },
  purchases (clubId, data) {
    return server.post(`/dashboards/purchases/${clubId}`, data)
  },
  billSum (clubId, data) {
    return server.post(`/dashboards/purchases/billSum/${clubId}`, data)
  },
  bonusSum (clubId, data) {
    return server.post(`/dashboards/purchases/bonusSum/${clubId}`, data)
  },
  spendBonusSum (clubId, data) {
    return server.post(`/dashboards/purchases/spendBonusSum/${clubId}`, data)
  },
  averageBill (clubId, data) {
    return server.post(`/dashboards/purchases/averageBill/${clubId}`, data)
  },
  averageBillForLastPeriod (clubId, data) {
    return server.post(`/dashboards/purchases/lastAverageBill/${clubId}`, data)
  },
  averageBillByDay (clubId, data) {
    return server.post(`/dashboards/purchases/averageBill/byDay/${clubId}`, data)
  },
  purchasesByDay (clubId, data) {
    return server.post(`/dashboards/purchases/byDay/${clubId}`, data)
  },
  pointsWithSpendEarn (clubId) {
    return server.get(`/dashboards/purchases/popularPoints/${clubId}`)
  }
}

export { dashboardsApi }
