<template>
  <v-dialog
    v-model="v"
    :width="width"
    scrollable
    @click:outside="hidePopup"
  >
    <app-button-close
      v-if="!['PopupConfirmation', 'PopupCancel'].includes(popup.name)"
      @click="hidePopup"
    />

    <component
      :is="popup.name"
      :key="popup.key"
    />
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ButtonClose from '@/components/UI-Kit/buttons/ButtonClose'
import PopupBonuses from '@/components/UI-Kit/popups/components/PopupBonuses'
import PopupConfirmation from '@/components/UI-Kit/popups/components/PopupConfirmation'
import PopupReceipt from '@/components/UI-Kit/popups/components/PopupReceipt'
import PopupPromotion from '@/components/UI-Kit/popups/components/PopupPromotion'
import PopupCancel from '@/components/UI-Kit/popups/components/PopupCancel'
import PopupPush from '@/components/UI-Kit/popups/components/PopupPush'
import PopupMessengersPush from '@/components/UI-Kit/popups/components/PopupMessengersPush'
import PopupAutoReport from '@/components/UI-Kit/popups/components/PopupAutoReport'
import PopupProducts from '@/components/UI-Kit/popups/components/PopupProducts'
import PopupProductsPromotion from '@/components/UI-Kit/popups/components/PopupProductsPromotion'
import PopupDashboardsName from '@/components/UI-Kit/popups/components/PopupDashboardsName'
import PopupDashboardsModules from '@/components/UI-Kit/popups/components/PopupDashboardsModules'
import PopupDeliveryCategories from '@/components/UI-Kit/popups/components/PopupDeliveryCategories'

export default {
  name: 'Popup',
  // components: {
  //   'PopupBonuses': () => import('@/components/UI-Kit/popups/components/PopupBonuses.vue'),
  //   'PopupConfirmation': () => import('@/components/UI-Kit/popups/components/PopupConfirmation.vue'),
  //   'PopupReceipt': () => import('@/components/UI-Kit/popups/components/PopupReceipt.vue')
  // },
  components: {
    'app-button-close': ButtonClose,
    PopupBonuses,
    PopupConfirmation,
    PopupReceipt,
    PopupPromotion,
    PopupCancel,
    PopupPush,
    PopupMessengersPush,
    PopupAutoReport,
    PopupProducts,
    PopupProductsPromotion,
    PopupDashboardsName,
    PopupDashboardsModules,
    PopupDeliveryCategories
  },
  computed: {
    ...mapGetters([
      'popup'
    ]),
    v: {
      get () {
        return this.popup.value
      },
      set (newVal) {
        let data = {
          name: this.popup.name,
          key: this.popup.key,
          value: newVal,
          data: this.popup.data
        }
        this.togglePopup(data)
      }
    },
    width () {
      switch (this.popup.name) {
        case 'PopupConfirmation':
        case 'PopupCancel':
          return 380 + 50
        case 'PopupBonuses':
        case 'PopupStructure':
          return 574 + 50
        case 'PopupRewards':
          return 650 + 50
        default:
          return 530 + 50
      }
    }
  },
  watch: {
    $route () {
      this.hidePopup()
    }
  },
  methods: {
    ...mapActions([
      'togglePopup',
      'hidePopup'
    ])
  }
}
</script>

<style lang="scss">
</style>
