<template>
  <div :class="{'dashboard__data' : !report}">
    <template
      v-if="report"
    >
      <div class="reports__row">
        <span>Новые участники:</span>
        <span>{{ countNewUsers.toLocaleString() }}
          <span
            v-if="todayUsers !== undefined"
          >
            / {{ todayUsers.toLocaleString() }} сегодня
          </span>
        </span>
      </div>
    </template>
    <template
      v-else
    >
      <app-dashboard-num
        :number="countNewUsers"
        :today="todayUsers"
      />
    </template>
  </div>
</template>

<script>
import DashboardNum from '@/components/Club/ClubDashboards/DashboardNum'
import dayjs from 'dayjs'

export default {
  name: 'ClubDashboardsModuleUsersNew',
  components: {
    'app-dashboard-num': DashboardNum
  },
  props: {
    requestData: {
      type: Object,
      require: true
    },
    report: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: [Number, String],
      require: false
    }
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    countNewUsers () {
      return this.$store.getters[`${this.clubId}/dashboards/getNewUsersCount`]
    },
    countTodayUsers () {
      return this.$store.getters[`${this.clubId}/dashboards/getTodayUsersCounts`]
    },
    todayUsers () {
      const today = dayjs().format('YYYY-MM-DD')
      if (this.requestData.date.to === today) {
        return this.countTodayUsers
      } else {
        return undefined
      }
    }
  }
}
</script>
