<template>
  <div class="promotions-info__rewards">
    <div
      v-for="cardType in cardTypesFiltered"
      :key="cardType.value"
    >
      <v-row class="mt-0 mb-0 d-flex align-center">
        <v-col class="pt-0 pb-0">Тип карты</v-col>
        <v-col class="d-flex justify-end pt-0 pb-0">
          <app-card :mode="cardType.value" />
        </v-col>
      </v-row>

      <v-row
        v-if="$vuetify.breakpoint.smAndUp"
        class="promotions-info__table-header mt-0 mb-0"
      >
        <v-col class="text-left pt-0 pb-0">
          Тип бонуса
        </v-col>
        <v-col class="text-center pt-0 pb-0">
          Размер бонуса
        </v-col>
        <v-col class="text-right pt-0 pb-0">
          Тип начисления
        </v-col>
        <v-col cols="12 pt-2 pb-2"><v-divider /></v-col>
      </v-row>
      <v-row
        v-for="reward in data.rewards"
        :key="reward.bonusType"
        class="promotions-info__table-body mt-0 mb-0"
      >
        <template v-if="reward.cardType === cardType.value">
          <v-col v-if="$vuetify.breakpoint.xsOnly" cols="6" class="text-left pt-0 pb-0">Тип бонуса</v-col>
          <v-col cols="6" sm="4" class="text-right text-sm-left pt-0 pb-0 mb-3">
            {{ bonusTypes.find(el => el.value === reward.bonusType).text }}
          </v-col>
          <v-col v-if="$vuetify.breakpoint.xsOnly" cols="6" class="text-left pt-0 pb-0">Размер бонуса</v-col>
          <v-col v-if="$vuetify.breakpoint.xsOnly" cols="6" class="text-right pt-0 pb-0">Тип начисления</v-col>
          <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12" class="pb-1 pt-1"><v-divider /></v-col>
          <v-col cols="6" sm="4" class="text-left text-sm-center pt-0 pb-0">
            {{ reward.amount }}
          </v-col>
          <v-col cols="6" sm="4" class="text-right pt-0 pb-0">
            {{ calculationTypes.find(el => el.value === reward.calculationType).text }}
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>

<script>
import CardTypes from '@/json/promotions/rewards/cardTypes.json'
import BonusTypes from '@/json/promotions/rewards/bonusTypes.json'
import CalculationTypes from '@/json/promotions/rewards/calculationTypes.json'
import Card from '@/components/UI-Kit/table/Card'

export default {
  name: 'PromotionsInfoRewards',
  components: {
    'app-card': Card
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    cardTypes: CardTypes,
    bonusTypes: BonusTypes,
    calculationTypes: CalculationTypes
  }),
  computed: {
    cardTypesFiltered () {
      return this.cardTypes.filter(cardType => this.data.rewards.find(reward => reward.cardType === cardType.value))
    }
  }
}
</script>

<style lang="scss">
.promotions-info {
  &__table-header {
    font-size: $font-12;
  }
  &__table-body {
    font-size: $font-14;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .promotions-info {
    &__table-header.row.mb-0.mt-0 {
      margin-bottom: 0 !important;
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .promotions-info {
    &__table-header {
      & .col {
        font-size: $font-14 !important;
      }
    }
  }
}
</style>
