import { pushApi } from '@/api/push'
import dayjs from 'dayjs'
import Vue from 'vue'

const getDefaultState = () => {
  return {}
}

export default {
  namespaced: true,
  state: () => ({
    params: {
      text: '',
      startDate: {
        from: dayjs().subtract('30', 'day').format('YYYY-MM-DDTHH:mm:ss'),
        to: dayjs().add('30', 'day').format('YYYY-MM-DDTHH:mm:ss')
      }
    },
    page: {
      page: 0,
      size: 25,
      sort: [{
        'property': 'startDate',
        'direction': 'DESC'
      }]
    },
    pushs: [],
    totalElements: 0,
    status: ''
  }),
  mutations: {
    SET_PARAMS (state, data) {
      state.params = data
    },
    LOAD (state, data) {
      if (data && data.content) {
        state.pushs.push(...data.content)
        state.totalElements = data.totalElements
      } else {
        state.pushs = []
      }
    },
    SET_STATUS (state, data) {
      state.status = data
    },
    SET_PAGE (state, val) {
      if (val === undefined) {
        state.page.page = state.page.page + 1
      } else {
        state.page.page = val
      }
    },
    SET_SORT (state, val) {
      state.page.sort[0] = val
      Vue.set(state.page.sort, 0, val)
    },
    CLEAR (state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    async load ({ state, commit }, { id }) {
      commit('SET_STATUS', 'loading')
      try {
        const data = {
          search: state.params,
          page: state.page
        }
        const res = await pushApi.search(id, data)
        if (res.data && res.data.content && res.data.content.length) {
          commit('LOAD', res.data)
          commit('SET_STATUS', '')
        } else {
          commit('SET_STATUS', '404')
        }
      } catch (e) {
        console.log('e', e)
        commit('SET_STATUS', '500')
      } finally {

      }
    },
    async add ({ commit, dispatch }, { id, data }) {
      commit('SET_STATUS', 'loading')
      console.log('ONE', data)
      try {
        const formData = new FormData()
        if (data.img !== null) {
          formData.append('img', data.img)
        }
        delete data.img
        formData.append('data', JSON.stringify(data))

        await pushApi.add(id, formData)
        dispatch('addSuccess')
        commit('SET_STATUS', '')
        dispatch('setPage', 0)
        commit('LOAD')
      } catch (e) {
        console.log('e', e)
        dispatch('addError')
        commit('SET_STATUS', '500')
      } finally {

      }
    },
    edit () {

    },
    remove () {

    },
    async loadMessengers ({ state, commit }, { id }) {
      commit('SET_STATUS', 'loading')
      try {
        const data = {
          search: state.params,
          page: state.page
        }
        const res = await pushApi.searchMessengers(id, data)
        if (res.data && res.data.content && res.data.content.length) {
          commit('LOAD', res.data)
          commit('SET_STATUS', '')
        } else {
          commit('SET_STATUS', '404')
        }
      } catch (e) {
        console.log('e', e)
        commit('SET_STATUS', '500')
      } finally {

      }
    },
    async addMessengersPush ({ commit, dispatch }, { id, data }) {
      commit('SET_STATUS', 'loading')
      console.log('TWO', data)
      try {
        const formData = new FormData()
        if (data.img !== null) {
          formData.append('img', data.img)
        }
        delete data.img
        formData.append('data', JSON.stringify(data))

        await pushApi.addMessengers(id, formData)
        dispatch('addSuccess')
        commit('SET_STATUS', '')
        dispatch('setPage', 0)
        commit('LOAD')
      } catch (e) {
        console.log('e', e)
        dispatch('addError')
        commit('SET_STATUS', '500')
      } finally {

      }
    },
    setParams ({ commit, dispatch }, data) {
      commit('SET_PARAMS', data)
      dispatch('setPage', 0)
    },
    setPage ({ commit }, data) {
      if (data === 0) {
        commit('LOAD')
      }
      commit('SET_PAGE', data)
    },
    setSort ({ commit, dispatch }, data) {
      commit('SET_SORT', data)
      dispatch('setPage', 0)
    },
    addSuccess ({ commit }, data) {
      this.dispatch('showAlert', { text: 'Запись успешно создана.', type: 'success' })
    },
    addError ({ commit }, data) {
      this.dispatch('showAlert', { text: 'Произошла ошибка.', type: 'error' })
    }
  },
  getters: {
    getPushs: (state) => state.pushs,
    getStatus: (state) => state.status,
    getParams: (state) => state.params,
    getTotalElements: (state) => state.totalElements,
    getPage: (state) => state.page
  }
}
