<template>
  <div class="graph">
    <app-dashboard-diagram
      class="graph__pie"
      :data="data"
    />
    <div class="graph__mobileUsers">
      <div
        v-for="(item, name) in data"
        :key="name"
        class="graph__mobileUser"
      >
        <div class="graph__mobile">
          <div class="graph__dot"
            :style="{background: item.color}"
          />
          {{ name }}
        </div>
        <span>
          {{ item.count.toLocaleString() }} ({{ item.percent }}%)
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardPieDiagram from '@/components/Club/ClubDashboards/DashboardPieDiagram'

export default {
  name: 'DashboardDiagramModule',
  components: {
    'app-dashboard-diagram': DashboardPieDiagram
  },
  props: {
    data: {
      type: Object,
      require: true
    }
  }
}
</script>

<style scoped lang="scss">
.graph {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__pie {
    margin-top: 15px;
    width: 190px;
    height: 190px;
  }
  &__mobileUsers {
    margin-top: 15px;
    width: 100%;
  }
  &__mobileUser {
    width: 100%;
    display: flex;
    justify-content: space-between;
    & + & {
      margin-top: 10px;
    }
  }
  &__mobile {
    display: flex;
    align-items: baseline;
  }
  &__dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 8px;
  }
}
</style>
