<template>
  <v-simple-table class="push-popup">
    <template v-slot:default>
      <tbody>
      <tr
        v-for="header in headers"
        :key="header.value"
      >
        <td>
          {{ header.text }}
        </td>
        <td v-if="['startDate'].indexOf(header.value) !== -1">
          {{ item[header.value] | dateISO }}
        </td>
        <td v-else-if="['recipients'].indexOf(header.value) !== -1">
          {{ item[header.value].slice(0, 2) | arrayToString }}
          <div v-if="item[header.value].length > 2">
            <span class="text--secondary">
              ещё {{ item[header.value].length - 2 }}
            </span>
            <app-button-tooltip
              icon="info"
              :message="item[header.value] | arrayToString"
              :size="[18, 18]"
            />
          </div>
        </td>
        <td v-else-if="['status'].indexOf(header.value) !== -1">
          <app-result :status="item.status.toLowerCase()" />
        </td>
        <td v-else>
          {{ item[header.value] }}
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import Result from '@/components/UI-Kit/table/Result'
import ButtonTooltip from '@/components/UI-Kit/buttons/ButtonTooltip'

export default {
  name: 'ClubPushPopup',
  components: {
    'app-result': Result,
    'app-button-tooltip': ButtonTooltip
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      headers: [
        { text: 'Текст уведомления', value: 'text' },
        // { text: 'Дата создания', value: 'date_create' },
        { text: 'Дата отправки', value: 'startDate' },
        // { text: 'Получатели', value: 'recipients' },
        { text: 'Охват', value: 'receiverCount' },
        { text: 'Результат', value: 'status' }
      ],
      sendNow: false
    }
  }
}
</script>

<style lang="scss">
.push-popup {
  .v-data-table__wrapper {
    table {
      tbody {
        tr {
          td {
            display: block;
            height: auto;
            padding-bottom: 10px;
            font-size: $font-14;
            line-height: $font-17;
            vertical-align: top;
            border-bottom: none !important;
            &:first-child {
              padding-left: 0 !important;
              padding-bottom: 0 !important;
              color: $icon;
            }
            &:last-child {
              padding-left: 0 !important;
              padding-right: 0 !important;
            }
          }
          &:last-child {
            td {
              padding-bottom: 0;
            }
          }
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .push-popup {
    .v-data-table__wrapper {
      table {
        tbody {
          tr {
            td {
              display: table-cell;
              font-size: $font-17;
              line-height: $font-21;
              &:first-child {
                width: 39%;
                padding-bottom: 10 !important;
                color: $primary;
              }
              &:last-child {
                padding-left: 16px !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
