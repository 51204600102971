<template>
  <div :class="{'dashboard__data' : !report}">
    <template
      v-if="report"
    >
      <div class="reports__row">
        <span>Средний чек:</span>
        <span>{{ averageBillSum | roundTo2 }} ₽</span>
      </div>
    </template>
    <template
      v-else
    >
      <app-dashboard-num
        :number="averageBillSum ? averageBillSum : 0"
        :comma="true"
        text="₽"
      />
      <div
        v-if="averageBillSum && averageBillSumForLAstPeriod"
        class="dashboards__descr"
      >
        на {{ prevPercent }} чем в пред. период (за {{ prevDates }})
      </div>
      <app-dashboard-graph
        :data="averageBillByDay"
        :label="'Сумма'"
        :mode="'simple'"
        descr="Динамика среднего чека"
      />
    </template>
  </div>
</template>

<script>
import DashboardNum from '@/components/Club/ClubDashboards/DashboardNum'
import DashboardGraphModule from '@/components/Club/ClubDashboards/DashboardGraphModule'
import { dashboardMixin } from '../../../../mixins/dashboard'

export default {
  name: 'ClubDashboardsModulePurchasesAverageCheck',
  components: {
    'app-dashboard-num': DashboardNum,
    'app-dashboard-graph': DashboardGraphModule
  },
  mixins: [dashboardMixin],
  props: {
    requestData: {
      type: Object,
      require: true
    },
    report: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: [Number, String],
      require: false
    }
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    averageBillSum () {
      return this.$store.getters[`${this.clubId}/dashboards/getAverageBillSum`]
    },
    averageBillSumForLAstPeriod () {
      return this.$store.getters[`${this.clubId}/dashboards/getAverageBillSumForLastPeriod`]
    },
    averageBillByDay () {
      return this.$store.getters[`${this.clubId}/dashboards/getAverageBillByDay`]
    }
  }
}
</script>
