<template>
  <FilterPanel title="Параметры для программ лояльности">
    <v-row class="clubs-header ma-0">
      <v-col class="clubs-header__search col-auto pa-0">
        <app-input
          mode="search"
          :value.sync="pickedSearch"
          placeholder="Начните вводить название программы лояльности..."
        />
      </v-col>
      <v-col class="clubs-header__add col-auto pa-0">
        <app-button-circle
          v-if="isBMSAdmin"
          link="clubs/new"
          mode="fill"
        >
          Добавить программу
        </app-button-circle>
      </v-col>
      <v-col
        v-if="!$vuetify.breakpoint.mdAndDown"
        class="clubs-header__view col-auto pa-0"
      >
        <v-btn-toggle
          v-model="pickedView"
          mandatory
          borderless
          dense
          tile
        >
          <v-btn
            value="table"
            v-html="require(`@/assets/img/icons/table.svg`)"
            text
            tile
          />
          <v-btn
            value="list"
            v-html="require(`@/assets/img/icons/list.svg`)"
            text
            tile
          />
        </v-btn-toggle>
      </v-col>
    </v-row>
  </FilterPanel>
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonCircle from '@/components/UI-Kit/buttons/ButtonCircle'
import Input from '@/components/UI-Kit/inputs/Input'
import FilterPanel from '@/components/FilterPanel'

export default {
  name: 'AllClubsHeader',
  components: {
    'app-button-circle': ButtonCircle,
    'app-input': Input,
    FilterPanel
  },
  props: {
    view: {
      type: String,
      required: true
    },
    search: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      disabled: false
    }
  },
  computed: {
    ...mapGetters([
      'isBMSAdmin'
    ]),
    pickedView: {
      get () {
        return this.view
      },
      set (newVal) {
        this.$emit('update:view', newVal)
      }
    },
    pickedSearch: {
      get () {
        return this.search
      },
      set (newVal) {
        this.$emit('update:search', newVal)
      }
    }
  }
}
</script>

<style lang="scss">
.clubs-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  &__search {
    order: 2;
    width: 100%;
    margin-right: 0;
  }

  &__add {
    width: 100%;
    order: 1;
    margin-right: 0;
    margin-bottom: 15px;
    .v-btn {
      width: 100%;
    }
  }

  &__view {
    order: 3;
    display: flex;
    margin-left: auto;

    .v-btn {
      min-width: auto !important;
      margin-right: 20px;
      padding: 0 !important;

      &:last-child {
        margin-right: 0;
      }

      svg {
        fill: $icon;
        transition: fill $primary-transition;
      }

      &:hover {
        svg {
          fill: $accent;
        }
      }

      &--active {
        svg {
          fill: $accent;
        }
      }
    }
  }
}
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .clubs-header {
    &__search {
      order: 1;
      flex-grow: 1;
      width: auto;
      margin-right: 30px;
    }

    &__add {
      order: 2;
      width: auto;
      margin-bottom: 0;
      .v-btn {
        width: auto;
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .clubs-header {
    &__search {
      flex-grow: 0;
      width: 41%;
    }
  }
}
</style>
