const getDefaultState = () => {
  return {
    allOptions: {
      visibleParticipantsColumns: [
        { text: 'ID', value: 'id' },
        { text: 'Номер карты', value: 'cards[0].cardNum' },
        { text: 'Имя', value: 'firstName' },
        { text: 'Фамилия', value: 'lastName' },
        { text: 'Номер телефона', value: 'phone' },
        { text: 'E-mail', value: 'email' },
        // { text: 'Счастливые дни', value: 'happyDates' },
        { text: 'Дата рождения', value: 'birthDate' },
        { text: 'Статус карты', value: 'cards[0].locked' },
        { text: 'Тип карты', value: 'cards[0].cardType' },
        { text: 'Действия', value: 'action', sortable: false }
      ],
      visibleTransactionsColumns: [
        { text: 'Статус', value: 'requestState' },
        { text: 'Дата операции', value: 'billDate' },
        { text: 'Номер карты', value: 'cardNum' },
        { text: 'Сумма', value: 'billSum' },
        { text: 'Начислено', value: 'earn' },
        { text: 'Потрачено', value: 'spend' },
        { text: 'Точка', value: 'retailPointTitle' },
        { text: 'Оффлайн', value: 'offline' },
        { text: '', value: 'data-table-expand' }
      ]
      // visibleTransactionsColumns: [
      //   { text: 'Телефон', value: 'mobilePhone' },
      //   { text: 'Номер карты', value: 'cardNum' },
      //   { text: 'Ретейл', value: 'retailNetworkTitle' },
      //   { text: 'Точка', value: 'retailPointTitle' },
      //   { text: 'Дата операции', value: 'billDate' },
      //   { text: 'Дата обработки', value: 'insdate' },
      //   { text: 'Статус операции', value: 'requestState' },
      //   { text: 'Тип операции', value: 'requestType' },
      //   { text: 'Сумма операции', value: 'billSum' },
      //   { text: 'Получено бонусов', value: 'earn' },
      //   { text: 'Потрачено бонусов', value: 'spend' },
      //   { text: 'Номер операции', value: 'extRequestId' },
      //   { text: 'Номер чека', value: 'billCode' },
      //   { text: 'Юридическое лицо', value: 'legacyClientTitle' },
      //   { text: 'Номер терминала', value: 'terminalCode' },
      //   { text: 'Маска карты', value: 'cardMask' },
      //   { text: 'Вознаграждение организатора', value: 'organizerFee' },
      //   { text: 'Статус оффлайна', value: 'offline' },
      //   { text: 'Сумма возврата', value: 'cashBackSum' },
      //   { text: 'Сумма накопленных бонусов от доплаты', value: 'earnExtraPayment' },
      //   { text: 'Сумма возврата доплаченных средств', value: 'extraAmountReverse' },
      //   { text: 'Комиссия банка-эквайера', value: 'extraAmountWithdrawFee' }
      // ]
      // visibleTransactionsColumns: [
      //   { text: 'Телефон', value: 'mobilePhone' },
      //   { text: 'Номер карты', value: 'cardNum' },
      //   { text: 'Ретейл', value: 'retailNetworkTitle' },
      //   { text: 'Точка', value: 'retailPointTitle' },
      //   { text: 'Дата операции', value: 'billDate' },
      //   { text: 'Дата обработки', value: 'insdate' },
      //   { text: 'Статус операции', value: 'requestState' },
      //   { text: 'Тип операции', value: 'requestType' },
      //   { text: 'Сумма операции', value: 'billSum' },
      //   { text: 'Получено бонусов', value: 'earn' },
      //   { text: 'Потрачено бонусов', value: 'spend' },
      //   { text: 'Номер операции', value: 'extRequestId' },
      //   { text: 'Номер чека', value: 'billCode' },
      //   { text: 'Юридическое лицо', value: 'legacyClientTitle' },
      //   { text: 'Номер терминала', value: 'terminalCode' },
      //   { text: 'Маска карты', value: 'cardMask' },
      //   { text: 'Вознаграждение организатора', value: 'organizerFee' },
      //   { text: 'Статус оффлайна', value: 'offline' },
      //   { text: 'Сумма возврата', value: 'cashBackSum' },
      //   { text: 'Сумма накопленных бонусов от доплаты', value: 'earnExtraPayment' },
      //   { text: 'Сумма возврата доплаченных средств', value: 'extraAmountReverse' },
      //   { text: 'Комиссия банка-эквайера', value: 'extraAmountWithdrawFee' }
      // ]
    },
    defaultOptions: {
      visibleParticipantsColumns: [
        { text: 'ID', value: 'id' },
        { text: 'Номер карты', value: 'cards[0].cardNum' },
        { text: 'Имя', value: 'firstName' },
        { text: 'Фамилия', value: 'lastName' },
        { text: 'Номер телефона', value: 'phone' },
        { text: 'E-mail', value: 'email' },
        // { text: 'Счастливые дни', value: 'happyDates' },
        { text: 'Дата рождения', value: 'birthDate' },
        { text: 'Статус карты', value: 'cards[0].locked' },
        { text: 'Тип карты', value: 'cards[0].cardType' },
        { text: 'Действия', value: 'action', sortable: false }
      ],
      visibleTransactionsColumns: [
        { text: 'Статус', value: 'requestState' },
        { text: 'Дата операции', value: 'billDate' },
        { text: 'Номер карты', value: 'cardNum' },
        { text: 'Сумма', value: 'billSum' },
        { text: 'Начислено', value: 'earn' },
        { text: 'Потрачено', value: 'spend' },
        { text: 'Точка', value: 'retailPointTitle' },
        { text: 'Оффлайн', value: 'offline' },
        { text: '', value: 'data-table-expand' }
      ]
      // visibleTransactionsColumns: [
      //   { text: 'Телефон', value: 'mobilePhone' },
      //   { text: 'Номер карты', value: 'cardNum' },
      //   { text: 'Точка', value: 'retailPointTitle' },
      //   { text: 'Дата операции', value: 'billDate' },
      //   { text: 'Сумма операции', value: 'billSum' },
      //   { text: 'Получено бонусов', value: 'earn' },
      //   { text: 'Потрачено бонусов', value: 'spend' },
      //   { text: 'Вознаграждение организатора', value: 'organizerFee' },
      //   { text: 'Статус оффлайна', value: 'offline' },
      //   { text: 'Сумма возврата', value: 'cashBackSum' },
      //   { text: 'Сумма накопленных бонусов от доплаты', value: 'earnExtraPayment' },
      //   { text: 'Сумма возврата доплаченных средств', value: 'extraAmountReverse' },
      //   { text: 'Комиссия банка-эквайера', value: 'extraAmountWithdrawFee' }
      // ]
    },
    userOptions: {
      user: '',
      visibleParticipantsColumns: [],
      visibleTransactionsColumns: []
    }
  }
}

const state = getDefaultState()

const mutations = {
  'SET_USER_OPTIONS' (state) {
    let user = this.getters.getUserLogin
    let options = JSON.parse(localStorage.getItem('userOptions'))
    if (options) {
      let userOptions = options.find(elem => elem.user === user)
      if (userOptions) {
        options.splice(options.indexOf(userOptions), 1, state.userOptions)
      } else {
        options.push(state.userOptions)
      }
    } else {
      options = [state.userOptions]
    }
    localStorage.setItem('userOptions', JSON.stringify(options))
  },
  'GET_USER_OPTIONS' (state) {
    let user = this.getters.getUserLogin
    let options = JSON.parse(localStorage.getItem('userOptions'))
    if (options) {
      let userOptions = options.find(elem => elem.user === user)
      if (userOptions) {
        Object.keys(state.userOptions).map((key) => {
          if (key === 'user') return false
          if (!userOptions[key]) {
            state.userOptions[key] = state.allOptions[key]
          } else {
            state.userOptions[key] = []
            userOptions[key].forEach(elem => {
              let item = state.allOptions[key].find(item => item.value === elem.value)
              state.userOptions[key].push(item)
            })
          }
        })
      } else {
        this.commit('SET_DEFAULT_OPTIONS')
      }
    } else {
      this.commit('SET_DEFAULT_OPTIONS')
    }
  },
  'SET_DEFAULT_OPTIONS' (state) {
    Object.keys(state.userOptions).map((key) => {
      if (key === 'user') {
        state.userOptions[key] = this.getters.getUserLogin
      } else {
        state.userOptions[key] = state.defaultOptions[key]
      }
    })
  },
  'SET_NEW_OPTION' (state, data) {
    data.data.sort(function (a, b) {
      return state.allOptions[data.name].indexOf(a) - state.allOptions[data.name].indexOf(b)
    })
    state.userOptions[data.name] = data.data
    this.commit('SET_USER_OPTIONS')
  },
  'RESET_STATE' (state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  getUserOptions ({ commit }) {
    commit('GET_USER_OPTIONS')
  },
  setNewOption ({ commit }, data) {
    commit('SET_NEW_OPTION', data)
  }
}

const getters = {
  allOptions: state => state.allOptions,
  defaultOptions: state => state.defaultOptions,
  userOptions: state => state.userOptions
}

export default {
  state,
  mutations,
  actions,
  getters
}
