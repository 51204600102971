<template>
  <v-radio
    :class="`button-permission button-permission--${value.toLowerCase()} button-permission--${mode}`"
    :value="value"
    :disabled="disabled"
  >
    <template v-slot:label>
      <v-tooltip
        top
        max-width="130"
      >
        <template v-slot:activator="{ on }">
          <div
            v-html="require(`@/assets/img/icons/${permission[value].icon}.svg`)"
            v-on="on"
          />
        </template>
        <span>
          {{ permission[value].label }}
        </span>
      </v-tooltip>
    </template>
  </v-radio>
</template>

<script>
export default {
  name: 'ButtonPermission',
  props: {
    mode: {
      type: String,
      required: false,
      default: 'static',
      validator: (value) => {
        return ['static', 'dynamic'].indexOf(value) !== -1
      }
    },
    value: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      requires: false,
      default: false
    }
  },
  data: () => ({
    permission: {
      READ: {
        label: 'Только просмотр',
        icon: 'visibility'
      },
      WRITE: {
        label: 'Просмотр и редактирование',
        icon: 'edit'
      }
    }
  })
}
</script>

<style lang="scss">
.button-permission {
  margin: 0 !important;
  .v-input--selection-controls__input {
    display: none;
  }
  .v-label {
    width: 100%;
    height: 100% !important;
    padding: 0 !important;
    font-size: 0;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    svg {
      transition: fill $primary-transition;
    }
  }
  &--read {
    .v-label {
      svg {
        width: 20px;
        height: 12px;
      }
    }
  }
  &--write {
    .v-label {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  &--static {
    &.button-permission--read {
      .v-label {
        svg {
          fill: $accent;
        }
      }
    }
    &.button-permission--write {
      .v-label {
        svg {
          width: 16px;
          height: 16px;
          fill: $success;
        }
      }
    }
  }
  &--dynamic {
    width: 38px;
    height: 38px !important;
    background-color: $base;
    border-radius: $border-radius;
    box-shadow: $shadow;
    transition: background-color $primary-transition, box-shadow $primary-transition;
    .v-label {
      svg {
        fill: $icon;
      }
    }
    &:hover {
      box-shadow: $shadow-popup;
      .v-label {
        svg {
          fill: $primary;
        }
      }
    }
    &.v-item--active {
      .v-label {
        svg {
          fill: $base;
        }
      }
      &.button-permission--read {
        background-color: $accent;
      }
      &.button-permission--write {
        background-color: $success;
      }
      &:hover {
        box-shadow: $shadow;
      }
    }
    &.v-radio--is-disabled {
      opacity: 0.5;
    }
  }
}
</style>
