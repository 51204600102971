import { usersApi } from '@/api/users'
import router from '@/router'

/**
 * @param {string} permission Необходимое разрешение (READ/WRITE)
 * @param {object} user Данные пользователя
 * @param {string} moduleName Название модуля, для которого нужно проверить разрешение (по дефолту активный route)
 * @return {boolean}
 */
function checkPermission (permission, user, moduleName) {
  if (!Object.keys(user).length) return false

  let module = moduleName.length ? moduleName : router.currentRoute.name

  if (user.BMSAdmin || user.modules.length === 0) {
    return true
  } else {
    // let contentModules = router.options.routes
    //   .find(el => el.name === 'Club').children
    //   .find(el => el.name === 'ClubContent').children
    //   .map(el => { return el.name })
    // if (contentModules.includes(module)) module = 'ClubContent'

    let found = user.modules.find(el => module.includes(el.name))
    return !!(found && (found.permission === 'WRITE' || found.permission === permission))
  }
}

/**
 * @return {array} Все модули с метаданными (title, icon)
 */
function getAllModulesWithMeta () {
  let modules = []

  modules = [
    { ...router.options.routes.find(el => el.name === 'Users'), permission: 'WRITE' }
  ]

  modules = modules
    .concat(router.options
      .routes.find(el => el.name === 'Club')
      .children.reduce((res, el) => {
        if (el.name === 'ClubContent') {
          const content = el.children.filter(el => el.meta.title).map(el => {
            return { ...el, permission: 'WRITE' }
          })

          res.push(...content)
        } else {
          res.push({ ...el, permission: 'WRITE' })
        }

        return res
      }, [])
    )

  return modules
}

/**
 * @param {array} modules Модули, для которых нужно поличить метаданные (title, icon) из роутера
 * @return {array} Модули с метаданными (title, icon)
 */
function getModulesWithMeta (modules) {
  if (!modules.length) return []

  return modules.reduce((res, module) => {
    let found = {}

    router.options.routes.some(route => {
      if (module.name === route.name) {
        found = route

        return true
      } else if (route.children) {
        route.children.some(route => {
          if (module.name === route.name) {
            found = route

            return true
          } else if (route.children) {
            route.children.some(route => {
              if (module.name === route.name) {
                found = route

                return true
              }
            })
          }
        })
      }
    })

    if (found) {
      module.meta = found.meta
      res.push(module)
    }

    return res
  }, [])
}

const getDefaultState = () => {
  return {
    user: {},
    status: ''
  }
}

const state = getDefaultState()

const mutations = {
  LOAD_USER (state, data) {
    state.user = data
  },
  SET_STATUS (state, data) {
    state.status = data
  },
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  async loadUser ({ state, commit }) {
    if ((!state.user || !Object.keys(state.user).length) && state.status !== 'loading') {
      try {
        commit('SET_STATUS', 'loading')
        let res = await usersApi.profile()
        if (res.data && res.data.error) return
        commit('LOAD_USER', res.data.user)
        this.dispatch('registerModules', res.data.clubs)
        this.dispatch('setUsersTotal', res.data.usersTotal)
        commit('SET_STATUS', '')
      } catch (e) {
        commit('SET_STATUS', '500')
        console.log(e)
      }
    }
  }
}

const getters = {
  isBMSAdmin: state => state.user && state.user.BMSAdmin,
  getUser: state => state.user,
  getUserStructure: state => state.user.structure,
  getUserRetails: state => {
    return state.user.structure
      .map(el => { return el.retails })
      .flat(1)
      .map(el => { return el.id })
  },
  getUserPoints: state => {
    return state.user.structure
      .map(el => { return el.retails })
      .flat(1)
      .map(el => { return el.points })
      .flat(1)
  },
  getUserModules: state => state.user.modules,
  getUserModulesWithMeta: (state, getters) => (value) => {
    let userModules = JSON.parse(JSON.stringify(value))
    if (!userModules.length) {
      return getAllModulesWithMeta()
    } else {
      return getModulesWithMeta(userModules)
    }
  },
  isReadPermission: (state, getters) => (moduleName = '') => {
    return checkPermission('READ', getters.getUser, moduleName)
  },
  isWritePermission: (state, getters) => (moduleName = '') => {
    return checkPermission('WRITE', getters.getUser, moduleName)
  },
  getUserStructureByClubId: (state, getters) => (clubId) => {
    let club = getters.getUserStructure.filter(club => {
      return club.club.id === +clubId
    })
    return club.length ? club[0] : []
  },
  singleProgramUser: (state, getters) => {
    return getters.getClubs.length === 1
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
