import { server } from './index'

let reportsApi = {
  load (data) {
    return server.post(`/remote/reports/${data.api}`, data.params)
  },
  license () {
    return server.get('/stimulsoft/license')
  },
  editGeneratedReportStatus (id, viewed) {
    return server.patch(`/genreports/edit/${id}`, { viewed })
  },
  getGeneratedReports (data, params) {
    return server.post(`/genreports`, { ...data }, { params })
  },
  removeGeneratedReport (id) {
    return server.delete(`/genreports/delete/${id}`)
  },
  getOneReport (reportId) {
    return server.get(`/genreports/${reportId}`)
  },
  loadFile (name, type) {
    return server.get(`/genreports/file/${name}/?type=${type}`, { responseType: 'blob' })
  }
}

export { reportsApi }
