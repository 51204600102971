export default async function isWebshop ({ to, next, store, nextMiddleware }) {
  const clubId = +to.params.id
  const foundClub = store.getters.getClubs.find(club => club.id === clubId)

  if (foundClub && foundClub.webShopAPI) {
    return nextMiddleware()
  } else {
    return next({
      name: 'Home'
    })
  }
}
