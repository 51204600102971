<template>
  <v-navigation-drawer
    tag="nav"
    class="nav"
    :value="true"
    app
    :width="250"
    :mini-variant.sync="isClose"
    :mini-variant-width="84"
    floating
    stateless
    :expand-on-hover="getDrawer"
  >
    <v-toolbar
      tag="header"
      class="nav__header"
      :height="70"
      flat
    >
      <v-fade-transition hide-on-leave>
        <app-nav-logo
          :clubId="clubs[0].id"
        />
      </v-fade-transition>
    </v-toolbar>

    <v-fade-transition>
      <app-nav-list
        class="nav__list"
        :drawer="isClose"
        :routes="routes"
        :clubs="clubs"
        :clubs-route="clubsRoute"
        :club-routes="clubRoutes"
        @click="$emit('change', $event)"
      />
    </v-fade-transition>

    <v-spacer/>

    <v-toolbar
      tag="footer"
      class="nav__footer"
      :height="80"
      flat
    >
      <v-fade-transition hide-on-leave>
        <app-nav-user v-if="!isClose"/>
      </v-fade-transition>
      <v-spacer v-if="!isClose"/>
      <app-nav-logout class="ml-auto"/>
    </v-toolbar>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import NavLogo from '@/components/nav/components/NavLogo.vue'
import NavUser from '@/components/nav/components/NavUser.vue'
import NavLogout from '@/components/nav/components/NavLogout.vue'
import NavList from '@/components/nav/desktop/NavList.vue'

export default {
  name: 'NavDesktop',
  components: {
    'app-nav-logo': NavLogo,
    'app-nav-user': NavUser,
    'app-nav-logout': NavLogout,
    'app-nav-list': NavList
  },
  props: {
    routes: {
      type: Array,
      required: true
    },
    clubs: {
      type: Array,
      required: true
    },
    clubsRoute: {
      type: Object,
      required: true
    },
    clubRoutes: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      isClose: true
    }
  },
  computed: {
    ...mapGetters([
      'getDrawer'
    ])
  }
}
</script>

<style lang="scss">

</style>
