<template>
  <v-card class="popup popup--confirmation">
    <v-card-title class="popup__title">
      Подтверждение удаления
    </v-card-title>
    <v-card-actions class="popup__actions">
      <app-button
        class="popup__submit"
        :loading="loading"
        @click="submit"
      >
        Удалить
      </app-button>
      <app-button
        class="popup__reset"
        mode="outline"
        @click="hidePopup"
      >
        Отменить
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Button from '@/components/UI-Kit/buttons/Button'

export default {
  name: 'PopupConfirmation',
  components: {
    'app-button': Button
  },
  computed: {
    ...mapGetters([
      'popup'
    ]),
    data () {
      return this.popup.data
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions([
      'hidePopup',
      'removeUser',
      'showAlert'
    ]),
    submit () {
      this[this.popup.data.action]()
    },
    removeClub () {
      this.$store.dispatch('removeClub', { id: this.popup.data.id })
      this.hidePopup()
    },
    removeUserAction () {
      this.removeUser(this.popup.data._id)
      this.hidePopup()
      if (this.$route.name !== 'UsersMain') {
        setTimeout(() => {
          this.$router.replace({ name: 'UsersMain' })
        }, this.popup.transition)
      }
    },
    removeCategory () {
      console.log('remove category')
      console.log(this.popup.data)
    },
    removePromotion () {
      this.$store.dispatch(`${this.data.clubId}/promotions/removePromotions`, { clubId: this.data.clubId, data: [this.data.promoId] })
      this.hidePopup()
      setTimeout(() => {
        this.$router.replace({ name: 'ClubPromotionsMain' })
      }, this.popup.transition)
    },
    removeNews () {
      this.$store.dispatch(`${this.data.clubId}/news/removeNews`, {
        id: this.data.club,
        ids: [this.data.newsItemId],
        imageIds: this.data.imageIds
      })
      this.hidePopup()
      setTimeout(() => {
        this.$router.push({ name: 'ClubNewsMain' })
      }, this.popup.transition)
    },
    removePointOrRetail () {
      this.$store.dispatch(`${this.data.clubId}/global/delete${this.data.type}`, {
        clubId: this.data.clubId,
        ids: [this.data.id]
      })
      this.hidePopup()
    },
    async removeProduct () {
      const del = await this.$store.dispatch(`${this.data.clubId}/products/removeProduct`, {
        clubId: this.data.clubId,
        id: this.data.id
      })
      this.hidePopup()
      if (del) {
        this.showAlert({
          text: `Товар ${this.data.title} успешно удален.`,
          type: 'success'
        })
      } else {
        this.showAlert({
          text: `Ошибка удаления товара ${this.data.title}. Попробуйте позже.`,
          type: 'error'
        })
      }
    },
    async removeManyProducts () {
      const del = await this.$store.dispatch(`${this.data.clubId}/products/removeSelected`, {
        clubId: this.data.clubId,
        data: this.data.selected
      })
      this.hidePopup()
      if (del) {
        this.showAlert({
          text: `Выбранные товары успешно удалены.`,
          type: 'success'
        })
      } else {
        this.showAlert({
          text: `Ошибка удаления товаров. Попробуйте позже.`,
          type: 'error'
        })
      }
    },

    async removeProductPromotion () {
      this.loading = true
      let del = await this.$store.dispatch(`${this.data.clubId}/products/removePromotions`, { clubId: this.data.clubId, data: this.data.promoIds })
      this.hidePopup()
      if (del) {
        this.showAlert({
          text: this.data.promoIds.length === 1 ? `Акция успешно удалена.` : 'Акции успешно удалены.',
          type: 'success'
        })
      } else {
        this.showAlert({
          text: `${this.data.promoIds.length === 1 ? `Ошибка удаления акции.` : 'Ошибка удаления акций.'} Попробуйте позже.`,
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss">
.popup--confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  .popup__actions {
    flex-direction: row;
    justify-content: center;
  }
}
</style>
