<template>
  <v-footer
    id="footer"
    class="footer"
    app
    inset
  >
    <p class="footer__paragraph caption mb-0">
      Административная панель BMS Group, {{ new Date().getFullYear() }}
    </p>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss">
.v-footer {
  display: flex;
  justify-content: center;
  height: 30px;
  padding: 0 !important;
  background-color: $base !important;

  .footer__paragraph {
    color: $placeholder;
  }
}

@media(min-width: $md) {
  .v-footer {
    background-color: $secondary !important;
  }
}
</style>
