<template>
  <div
    :class="[
      report ? 'dashboard__report' : 'dashboard__block',
      report ? '' : `dashboard__${mode}`
    ]"
    :style="{
      background: bgColor
    }"
  >
    <div :class="{'dashboard__head' : !report}">
      <span :class="{'dashboard__title' : !report}">
        {{ title }}
      </span>
      <div
        v-if="!report"
        class="dashboard__edit"
        @click="editName"
        v-html="require(`@/assets/img/icons/edit.svg`)"
      />
    </div>
    <div :class="{'dashboard__body' : !report}">
      <component
        :is="component"
        :id="clubId"
        :requestData="requestData"
        :report="report"
      />
      <app-button
        v-if="detail !== null && !report"
        :size="'s'"
        :mode="'outline'"
        class="dashboard__btn"
        @click="goToDetail"
      >
        Детальный просмотр
      </app-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Button from '@/components/UI-Kit/buttons/Button'
// MODULES
import ModuleUsersTotal from '@/components/Club/ClubDashboards/Modules/UsersTotal'
import ModuleUsersNew from '@/components/Club/ClubDashboards/Modules/UsersNew'
import ModuleUsersPurchases from '@/components/Club/ClubDashboards/Modules/UsersPurchases'
import ModulePurchasesAmount from '@/components/Club/ClubDashboards/Modules/PurchasesAmount'
import ModulePurchasesAccruedBonuses from '@/components/Club/ClubDashboards/Modules/PurchasesAccruedBonuses'
import ModulePurchasesSpendBonuses from '@/components/Club/ClubDashboards/Modules/PurchasesSpendBonuses'
import ModulePurchasesAverageCheck from '@/components/Club/ClubDashboards/Modules/PurchasesAverageCheck'
import ModulePurchasesAccruedStars from '@/components/Club/ClubDashboards/Modules/PurchasesAccruedStars'
import ModulePurchasesSpendStars from '@/components/Club/ClubDashboards/Modules/PurchasesSpendStars'
import ModulePurchasesPopularPoints from '@/components/Club/ClubDashboards/Modules/PurchasesPopularPoints'
import ModuleMobileUsers from '@/components/Club/ClubDashboards/Modules/MobileUsers'
import ModulePollsUsers from '@/components/Club/ClubDashboards/Modules/PollsUsers'
import ModuleParkingAverageCheck from '@/components/Club/ClubDashboards/Modules/ParkingAverageCheck'
import ModuleParkingPayWithStars from '@/components/Club/ClubDashboards/Modules/ParkingPayWithStars'
import ModuleParkingSuccessfulPaymentsWithStars from '@/components/Club/ClubDashboards/Modules/ParkingSuccessfulPaymentsWithStars'

export default {
  name: 'dashboardBlock',
  props: {
    id: {
      type: [Number, String],
      require: false
    },
    moduleId: {
      type: String,
      require: true
    },
    title: {
      type: String,
      require: true,
      default: ''
    },
    requestData: {
      type: Object,
      require: true
    },
    component: {
      type: String,
      require: true
    },
    detail: {
      type: String,
      required: false,
      default: null
    },
    pdf: {
      type: Boolean,
      required: false,
      default: false
    },
    report: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    'app-button': Button,
    // MODULES
    'app-module-users-userCount': ModuleUsersTotal,
    'app-module-users-newUsers': ModuleUsersNew,
    'app-module-users-userPurchases': ModuleUsersPurchases,
    'app-module-purchases-purchasesAmount': ModulePurchasesAmount,
    'app-module-purchases-accruedBonuses': ModulePurchasesAccruedBonuses,
    'app-module-purchases-spentBonuses': ModulePurchasesSpendBonuses,
    'app-module-purchases-averageCheck': ModulePurchasesAverageCheck,
    'app-module-purchases-accruedStars': ModulePurchasesAccruedStars,
    'app-module-purchases-spentStars': ModulePurchasesSpendStars,
    'app-module-purchases-popularPoints': ModulePurchasesPopularPoints,
    'app-module-mobile-mobileUsers': ModuleMobileUsers,
    'app-module-polls-pollsUsers': ModulePollsUsers,
    'app-module-parking-averageCheck': ModuleParkingAverageCheck,
    'app-module-parking-payWithStars': ModuleParkingPayWithStars,
    'app-module-parking-successfulPaymentsWithStars': ModuleParkingSuccessfulPaymentsWithStars
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    club () {
      return this.$store.getters[`${this.clubId}/global/getClubData`]
    },
    userId () {
      return this.$store.getters.getUser._id
    },
    color () {
      if (this.moduleId === 'spentBonuses' || this.moduleId === 'spentStars') {
        return '#9E9E9E'
      } else if (this.moduleId === 'accruedBonuses') {
        return '#26BC22'
      } else if (this.moduleId === 'accruedStars') {
        return '#FFB800'
      } else {
        return '#FFFFFF'
      }
    },
    mode () {
      if (this.moduleId === 'accruedStars' || this.moduleId === 'accruedBonuses') {
        return 'top'
      } else if (this.moduleId === 'spentStars' || this.moduleId === 'spentBonuses') {
        return 'bot'
      } else {
        return ''
      }
    },
    bgColor () {
      let color
      if (this.color.substring(0, 1) === '#') {
        color = this.color.substring(1)
      } else {
        color = this.color
      }
      let rgbColor = {}
      /* Grab each pair (channel) of hex values and parse them to ints using hexadecimal decoding */
      rgbColor.r = parseInt(color.substring(0, 2), 16)
      rgbColor.g = parseInt(color.substring(2, 4), 16)
      rgbColor.b = parseInt(color.substring(4), 16)
      return `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.15)`
    }
  },
  methods: {
    ...mapActions({
      showPopup: 'showPopup'
    }),
    editName () {
      this.showPopup({
        name: 'PopupDashboardsName',
        data: {
          moduleId: this.moduleId,
          name: this.title,
          userId: this.userId,
          club: this.club._id,
          clubId: this.clubId
        }
      })
    },
    goToDetail () {
      this.$store.commit(`${this.clubId}/dashboards/SET_DASHBOARD_PAGE`, 'app-dashboard-detail')
      this.$store.commit(`${this.clubId}/dashboards/SET_DETAIL_PARAMS`, {
        name: this.title,
        component: this.detail,
        id: this.moduleId
      })
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard {
  &__block {
    padding: 15px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    & + & {
      margin-top: 10px;
    }
  }
  &__top {
    border-radius: 10px 10px 0 0;
    box-shadow: none;
    & + .dashboard__bot {
      margin-top: 0;
      border-top: 1px solid #FFFFFF;
      border-radius: 0 0 10px 10px;
    }
  }
  &__bot {
    box-shadow: none;
  }
  &__head {
    padding: 3px 0 10px;
    border-bottom: 1px solid rgba(158, 158, 158, 0.15);
    min-height: 35px;
    display: flex;
    justify-content: flex-start;
  }
  &__title {
    flex-grow: 1;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    color: #484848;
  }
  &__edit {
    flex-basis: 30px;
    cursor: pointer;
    fill: #9E9E9E;
    transition: 0.25s;
    display: flex;
    justify-content: flex-end;
    &:hover {
      fill: #ED5656;
    }
  }
  &__body {
    width: 100%;
    padding: 15px 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__btn {
    display: none;
    margin-top: 15px;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .dashboard {
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .dashboard {
  }
}

@media #{map-get($display-breakpoints, 'xl-only')} {
  .dashboard {
    &__title {
      font-size: 20px;
      line-height: 24px;
    }
    &__btn {
      display: block;
    }
  }
}
</style>
