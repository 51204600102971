<template>
  <v-text-field
   :class="`input input-quantity input-quantity--${mode} input-quantity--${size}`"
   v-model="inputValue"
   rounded
   filled
   hide-details
   :disabled="disabled"
   v-mask="mask"
  >
    <template
      v-slot:append
    >
      <div class="input-quantity__icons">
        <div
          class="input-quantity__icon input-quantity__icon--plus d-flex"
          :class="{ 'disabled': inputValue >= max }"
          v-html="require(`@/assets/img/icons/arrow.svg`)"
          @click="inputValue++"
        />
        <div
          class="input-quantity__icon input-quantity__icon--minus d-flex"
          :class="{ 'disabled': inputValue <= 0 }"
          v-html="require(`@/assets/img/icons/arrow.svg`)"
          @click="inputValue--"
        />
      </div>
    </template>
  </v-text-field>
</template>

<script>
/**
 * Компонент инпута типа 'number' с кнопками больше/меньше
 * @view
 * Инпут
 * @actions
 * setInputBehavior: Добавляет маску и максимальное значение
 * @props
 * mode String Тип инпута ('number', 'percent')
 * value Any Возвращаемое значение
 * size String Высота инпута ('s' - 30px, 'm' - 50px)
 * disabled Boolean
 */
import { mask } from 'vue-the-mask'

export default {
  name: 'InputQuantity',
  props: {
    mode: {
      type: String,
      required: false,
      default: 'number',
      validator (value) {
        return ['number', 'percent'].indexOf(value) !== -1
      }
    },
    value: {
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 's',
      validator (value) {
        return ['s', 'm'].indexOf(value) !== -1
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      mask: '',
      max: ''
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('update:value', newVal)
      }
    }
  },
  beforeMount () {
    this.setInputBehavior()
  },
  methods: {
    setInputBehavior () {
      if (!this.inputValue) this.inputValue = 0
      switch (this.mode) {
        case 'number':
          this.max = 999999999
          this.mask = '#'.repeat(this.max.toString().length)
          break
        case 'percent':
          this.max = 99
          this.mask = '##'
          break
      }
    }
  },
  directives: { mask }
}
</script>

<style lang="scss">
  .input.input-quantity {

    .v-text-field__slot {
      input {
        margin-top: 0 !important;
      }
    }

    .input-quantity__icons {
      display: flex;
      flex-direction: column;

      .input-quantity__icon {
        cursor: pointer;

        svg {
          width: 9px;
          height: 6px;
          fill: $accent;
          transition: fill $primary-transition;
        }

        &--plus {
          margin-bottom: 8px;
          transform: rotate(180deg);
        }

        &.disabled {
          pointer-events: none;

          svg {
            fill: $accent-disabled;
          }
        }
      }
    }

    & > .v-input__control {
      & > .v-input__slot {
        margin-bottom: 0;
      }
    }

    &--s {
      & > .v-input__control {
        & > .v-input__slot {
          min-height: 30px !important;
          height: 30px;
        }
      }
    }
  }
</style>
