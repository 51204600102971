<template>
  <v-btn
    :class="[
      `button button--${mode} button--${size}`,
      { 'disabled': disabled }
    ]"
    :href="link"
    @click="route"
  >
    <span class="button__text">
      <div
        v-if="loading"
        :class="['spinner spinner--button center', {'spinner--accent': mode === 'outline'}]"
      >
        <div
          v-for="i in 12"
          :key="i"
          class="spinner-blade"
        />
      </div>
      <slot v-else />
    </span>
    <div
      v-if="icon.length"
      class="button__icon"
      :class="{ 'button__icon--left': left }"
      v-html="require(`@/assets/img/icons/${icon}.svg`)"
    />
    <input
      type="file"
      v-if="file"
      class="button__file"
      ref="file"
      @change="$emit('change', $event.target.files[0])"
    >
  </v-btn>
</template>

<script>/**
 * Компонент кнопки
 * @view
 * 4 вида кнопок (см. 'mode') с возможностью добавления иконки слева или справа от текста
 * @actions
 * route: Перенаправляет на другую страницу или возвращает событие 'click'
 * @props
 * mode String Тип кнопки (с бекраундом, с бордером, в виде ссылки, показать еще, зеленая(подтверждение)
 * size String Размер кнопки ('m' - высота 50px, 's' - высота 30px)
 * icon String Иконка
 * left Boolean Если иконка стоит перед текстом
 * link String
 * disabled Boolean
 * file Boolean Признак для загрузки файла
 */
import { mapActions } from 'vuex'

export default {
  name: 'Button',
  props: {
    mode: {
      type: String,
      required: false,
      default: 'fill',
      validator (value) {
        // Значение должно соответствовать одной из этих строк
        return ['fill', 'outline', 'flat', 'link', 'more', 'confirm'].indexOf(value) !== -1
      }
    },
    size: {
      type: String,
      required: false,
      default: 'm',
      validator (value) {
        // Значение должно соответствовать одной из этих строк
        return ['m', 's'].indexOf(value) !== -1
      }
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    left: {
      type: Boolean,
      required: false,
      default: false
    },
    link: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    file: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    ...mapActions([
      'togglePopup'
    ]),
    route () {
      if (this.link.length) {
        this.$router.push({ path: this.link })
      } else if (this.file) {
        this.$refs.file.click()
      } else {
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="scss">

</style>
