<template>
  <div class="card">
    <p class="card__label">{{ mode }}</p>
    <div
      :class="`card__icon card__icon--${mode}`"
      v-html="require(`@/assets/img/icons/card.svg`)"
    />
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    mode: {
      type: String,
      required: false,
      default: 'GENERAL',
      validator (value) {
        // Значение должно соответствовать одной из этих строк
        return [
          'GENERAL',
          'GENERAL_GOLD',
          'GENERAL_PREMIUM',
          'SOCIAL',
          'LOCAL',
          'GIFT'
        ].indexOf(value) !== -1
      }
    }
  }
}
</script>

<style lang="scss">
.card {
  display: flex;
  align-items: center;

  &__label {
    margin-bottom: 0 !important;
    margin-right: 6px;
    color: $primary;
    font-weight: 400;
    font-size: 0.941rem; // 16px
    line-height: 1;
    text-transform: uppercase;
  }

  &__icon {
    display: flex;

    &--GENERAL {
      svg {
        fill: $card-general;
      }
    }

    &--GENERAL_GOLD {
      svg {
        fill: $card-gold;
      }
    }

    &--GENERAL_PREMIUM {
      svg {
        fill: $card-premium;
      }
    }

    &--SOCIAL {
      svg {
        fill: $card-social;
      }
    }

    &--LOCAL {
      svg {
        fill: $card-local;
      }
    }

    &--GIFT {
      svg {
        fill: $card-gift;
      }
    }
  }
}
</style>
