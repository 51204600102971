<template>
  <v-card
    class="popup popup--productsPromo"
  >
    <v-card-title class="popup__title">
      <div>{{getTitle}}</div>
    </v-card-title>
    <v-card-text class="popup__content">
      <app-select-multiple
        :items="getPoints"
        label="Точки"
        :value.sync="promo.retailPoints"
        :required=true
        select-all
        @error="setError($event, 2)"
      ></app-select-multiple>
      <div
        :class="{'popup__disabled': !this.promo.retailPoints.length}"
      >
        <app-combobox
          mode="promocode"
          :items="promo.promoCodes"
          :value.sync="promo.promoCodes"
          label="Промокоды"
          @clear="clearField('promoCodes')"
          :menuProps="{'z-index': 1000}"
          @error="setError($event, 0)"
        ></app-combobox>
        <app-select-multiple
          :items="getCategories"
          :value.sync="promo.categories"
          label="Категория"
          @clear="clearField('categories')"
          :menuProps="{'z-index': 1000}"
          @change="categoryChange($event)"
          :clearOnBlur="false"
        ></app-select-multiple>
        <app-combobox
          :items="getProducts"
          :value.sync="promo.products"
          label="Товары"
          @clear="clearField('promoCodes')"
          :menuProps="{'z-index': 1000}"
          @error="setError($event, 1)"
          tooltip="Выберите товары из списка или введите код товара вручную"
          select-all
          @change="pointsChange($event)"
          itemValue="code"
          itemText="title"
        ></app-combobox>
        <app-select
          :items="promoTypes"
          :value.sync="promo.promoType"
          :returnObject="true"
          label="Тип начисления"
          tooltip="При фикс. начислении работает только данная акция, при доп. - акция суммируется с другими акциями"
        ></app-select>
        <div
          v-for="(reward, i) in promo.rewards"
          :key="reward.id"
          class="cards"
          :class="{'cards__disabled': !promo.retailPoints.length}"
        >
          <div class="cards__header">
            <span class="grey--text body-2 mb-3">Бонусирование по типу карты {{reward.cardType}}</span>
            <app-button-tooltip
              icon="trash"
              :message="`Удалить ${reward.cardType}`"
              :size="[16, 20]"
              @click="removeReward(i)"
              v-if="promo.rewards.length > 1"
            />
          </div>
          <div class="cards__body">
            <app-select
              :items="availableCardType(reward.cardType)"
              :value.sync="promo.rewards[i].cardType"
              :returnObject="true"
              label="Тип карты"
            ></app-select>
            <v-row>
              <v-col cols="6">
                <app-input
                  :value.sync="promo.rewards[i].amount"
                  label="Значение"
                  mode="number"
                  required
                ></app-input>
              </v-col>
              <v-col cols="6">
                <app-select
                  :items="calculationTypes"
                  :value.sync="promo.rewards[i].calculationType"
                  label="Единицы измерения"
                >
                </app-select>
              </v-col>
            </v-row>
          </div>
        </div>
        <app-button-circle
          v-if="promo.rewards.length < 3"
          @click="addReward()"
        >Добавить еще тип карты</app-button-circle>
      </div>

      <div class="popup__error" v-if="error.length">
        {{ error }}
      </div>
    </v-card-text>
    <v-card-actions class="popup__actions">
      <app-button
        v-if="isNew"
        @click="add()"
        :loading="loading"
        :disabled="!valid || loading"
      >Добавить акцию</app-button>
      <app-button
        @click="edit()"
        v-else
      >Сохранить изменения</app-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Button from '@/components/UI-Kit/buttons/Button'
import ButtonCircle from '@/components/UI-Kit/buttons/ButtonCircle'
import ButtonTooltip from '@/components/UI-Kit/buttons/ButtonTooltip'
import SelectCombobox from '@/components/UI-Kit/selects/SelectCombobox'
import SelectMultiple from '@/components/UI-Kit/selects/SelectMultiple'
import SelectSingle from '@/components/UI-Kit/selects/SelectSingle'
import Input from '@/components/UI-Kit/inputs/Input'
export default {
  name: 'PopupProductsPromotion',
  components: {
    'app-button': Button,
    'app-button-circle': ButtonCircle,
    'app-button-tooltip': ButtonTooltip,
    'app-combobox': SelectCombobox,
    'app-select-multiple': SelectMultiple,
    'app-select': SelectSingle,
    'app-input': Input
  },
  created () {
    if (this.data) {
      this.promo.promoCodes = this.data.promoCodes
      this.promo.retailPoints = this.data.retailPoints
      this.promo.categories = this.setCurrentCategory()
      this.promo.products = this.getCurrentProduct
      this.promo.promoType = this.data.promoType
      this.promo.rewards = [ ...this.data.rewards ]
    }
  },
  data () {
    return {
      error: '',
      errorField: [false, false, true],
      promo: {
        promoCodes: [],
        categories: [],
        retailPoints: [],
        products: [],
        promoType: 'PRODUCT_FIXED',
        rewards: [
          {
            amount: 0,
            calculationType: 'FIXED',
            bonusType: 'GLOBAL',
            cardType: 'GENERAL'
          }
        ]
      },
      promoTypes: [
        {
          title: 'Дополнительное',
          id: 'ADD_BONUS_GOOD'
        },
        {
          title: 'Фиксированное',
          id: 'PRODUCT_FIXED'
        }
      ],
      calculationTypes: [
        {
          title: '%',
          id: 'PERCENT'
        },
        {
          title: '₽',
          id: 'FIXED'
        }
      ],
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'popup'
    ]),
    data () {
      return this.popup.data
    },
    clubId () {
      return this.$route.params.id
    },
    storePath () {
      return `${this.clubId}/products`
    },
    isNew () {
      return !this.data
    },
    getTitle () {
      return this.isNew ? 'Редактирование акции' : 'Добавление акции'
    },
    getCategories () {
      const categories = new Set()
      this.getProducts.forEach(product => {
        categories.add(...product.categories)
      })
      return Array.from(categories)
    },
    getProducts () {
      return this.$store.getters[`${this.storePath}/getProductsByRetailPoints`](this.promo.retailPoints)
    },
    getCurrentProduct () {
      let products = new Set()
      this.data.productCodes.forEach(code => {
        let find = this.getProducts.find(product => product.code === code)
        if (find) products.add(find)
        else products.add(code)
      })
      return Array.from(products)
    },
    getPoints () {
      return this.$store.getters[`${this.clubId}/global/getPoints`]
    },
    valid () {
      return this.errorField.every(error => !error)
    },
    getProductCodes () {
      let codes = []
      this.promo.products.forEach(currentProduct => {
        let find = false
        this.getProducts.forEach(product => {
          if (product.title === currentProduct.title) {
            codes.push(product.code)
            find = true
          }
        })
        if (!find) {
          codes.push(currentProduct)
        }
      })
      return codes
    }
  },
  methods: {
    ...mapActions([
      'hidePopup',
      'showAlert'
    ]),
    clearField (field) {
      this.$set(this.promo, field, [])
    },
    removeReward (i) {
      this.$delete(this.promo.rewards, i)
    },
    availableCardType (currentType) {
      const cardTypes = new Set(['GENERAL_GOLD', 'GENERAL', 'GENERAL_PREMIUM'])
      this.promo.rewards.forEach(reward => {
        if (cardTypes.has(reward.cardType) && reward.cardType !== currentType) {
          cardTypes.delete(reward.cardType)
        }
      })
      return Array.from(cardTypes)
    },
    async edit () {
      this.loading = true
      const data = {
        ...this.data,
        ...this.promo,
        productCodes: this.getProductCodes
      }
      const edit = await this.$store.dispatch(`${this.storePath}/editPromotion`, { data, clubId: this.clubId })
      if (edit) {
        this.hidePopup()
        this.showAlert({
          text: `Акция успешно отредактирована.`,
          type: 'success'
        })
      } else {
        this.loading = false
        this.error = 'Произошла ошибка. Пожалуйста, попробуйте позже.'
      }
    },
    addReward () {
      this.promo.rewards.push({
        amount: 0,
        calculationType: 'FIXED',
        bonusType: 'GLOBAL',
        cardType: this.availableCardType()[0]
      })
    },
    async add () {
      this.loading = true
      const data = {
        ...this.data,
        ...this.promo,
        productCodes: this.getProductCodes
      }
      const add = await this.$store.dispatch(`${this.storePath}/addPromotion`, {
        clubId: this.clubId,
        data
      })
      if (add) {
        this.hidePopup()
        this.showAlert({
          text: `Акция успешно создана.`,
          type: 'success'
        })
      } else {
        this.loading = false
        this.error = 'Произошла ошибка. Пожалуйста, попробуйте позже.'
      }
    },
    categoryChange (e) {
      const products = []
      e.forEach(category => {
        this.getProducts.forEach(product => {
          if (product.categories.includes(category)) {
            products.push(product)
          }
        })
      })
      this.$set(this.promo, 'products', products)
    },
    pointsChange (e) {
      const categories = new Set()
      e.forEach(product => {
        if (product.categories) {
          categories.add(...product.categories)
        }
      })
      this.$set(this.promo, 'categories', Array.from(categories))
    },
    setError (error, index) {
      this.$set(this.errorField, index, error)
    },
    setCurrentCategory () {
      const categories = new Set()
      if (this.getCurrentProduct.length) {
        this.getCurrentProduct.forEach(product => {
          if (product.categories) {
            categories.add(...product.categories)
          }
        })
      }
      return Array.from(categories)
    }
  }
}
</script>

<style lang="scss">
.popup {
  &__disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &--productsPromo {
    height: 100%;

    .v-card__text.popup__content {
      margin-bottom: 15px;
      padding-top: 10px !important;
      overflow-y: hidden !important;
    }

    .popup__title {
      margin-bottom: 10px !important;
    }

    .popup__actions {
      display: block;

      .v-btn {
        width: 100%;
      }
    }

    .popup__error {
      margin-bottom: 10px;
      color: $accent;
    }

    .popup__form {
      margin-bottom: 30px;
      padding-bottom: 10px;
      border-bottom: 1px solid $separator;
    }

    .cards {
      &__header {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
