<template>
  <v-card
    class="popup popup--report"
  >
    <v-card-title class="popup__title">
      <div>{{ data.reportType }}</div>
      <app-button-tooltip
        class="popup__title-icon"
        v-if="isWritePermission()"
        icon="edit"
        message="Редактировать"
        :size="[20, 20]"
        @click="edit"
      />
    </v-card-title>
    <v-card-text class="popup__content">
      <app-auto-reports-info
        :data="data"
        :clubId="clubId"
      />
    </v-card-text>
    <v-card-actions class="popup__actions">
      <app-button
        mode="outline"
        @click="repeat()"
        :loading="loader"
        :disabled="loader"
        v-if="data.lastSend"
      >
        Повторить отправку на e-mail
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import ButtonTooltip from '@/components/UI-Kit/buttons/ButtonTooltip'
import AutoReportsInfo from '@/components/Club/ClubReports/ReportsInfo/Index'
import Button from '@/components/UI-Kit/buttons/Button'
import { mapGetters } from 'vuex'

export default {
  name: 'PopupAutoReport',
  components: {
    'app-button-tooltip': ButtonTooltip,
    'app-auto-reports-info': AutoReportsInfo,
    'app-button': Button
  },
  computed: {
    ...mapGetters([
      'popup',
      'isWritePermission'
    ]),
    data () {
      return this.popup.data
    },
    clubId () {
      return this.$route.params.id
    },
    loader () {
      return this.$store.getters[`${this.clubId}/reports/getLoader`]
    }
  },
  methods: {
    edit () {
      this.$router.push({ name: 'ClubReportsEdit', params: { autoReportId: this.data._id } })
    },
    repeat () {
      this.$store.dispatch(`${this.clubId}/reports/repeatAutoReport`, this.data._id)
    }
  }
}
</script>

<style lang="scss">
.popup--report {
  height: 50vh;
  .popup__title {
    word-break: normal;
    justify-content: space-between;
    & div {
      width: 90%;
    }
  }
  .popup__actions {
    display: flex;
    justify-content: center;
  }
}
</style>
