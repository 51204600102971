import { deliveryApi } from '@/api/delivery'

export default {
  namespaced: true,
  state: () => ({
    catalog: [],
    categories: [],
    catalogLoading: false,
    settingsLoading: false
  }),
  mutations: {
    SET_CATALOG (state, data) {
      state.catalog = data
    },
    SET_CATEGORIES (state, data) {
      state.categories = data
    },
    SET_CATALOG_LOADING (state, data) {
      state.catalogLoading = data
    },
    SET_SETTINGS_LOADING (state, data) {
      state.settingsLoading = data
    }
  },
  actions: {
    async getDeliveryCatalog ({ commit, dispatch }, data) {
      try {
        commit('SET_CATALOG_LOADING', true)
        const res = await deliveryApi.getDeliveryCatalog(data)
        if (res.data && res.data.length) {
          commit('SET_CATALOG', res.data)
          await dispatch('getCategories', data)
        }
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_CATALOG_LOADING', false)
      }
    },

    async deleteDeliveryCatalog ({ commit }, data) {
      try {
        const res = await deliveryApi.deleteDeliveryCatalog(data)
        commit('SET_CATALOG', res.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },

    async getCategories ({ commit }, data) {
      try {
        const res = await deliveryApi.getCategories(data)
        commit('SET_CATEGORIES', res.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },

    setCategories ({ commit }, data) {
      commit('SET_CATEGORIES', data)
    },

    async deleteDeliveryItem ({ commit, state }, data) {
      try {
        const res = await deliveryApi.deleteDeliveryItem(data)
        if (res.data._id) {
          const newCatalog = state.catalog.filter(item => item._id !== res.data._id)
          commit('SET_CATALOG', newCatalog)
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },

    async addDeliveryItem ({ commit, state }, data) {
      try {
        const res = await deliveryApi.addDeliveryItem(data)
        if (res.data && res.data.length) {
          const newCatalog = state.catalog
          res.data.forEach(item => newCatalog.push(item))
          commit('SET_CATALOG', newCatalog)
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },

    async updateDeliveryItem ({ commit, state }, data) {
      try {
        const res = await deliveryApi.updateDeliveryItem(data)
        console.log(res)
        if (res.data._id) {
          console.log('updated')
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },

    async getDeliveryItem ({ commit }, data) {
      try {
        const res = await deliveryApi.getDeliveryItem(data)
        return res
      } catch (e) {
        console.log(e)
        throw e
      }
    },

    setCatalog ({ commit }, data) {
      commit('SET_CATALOG', data)
    },

    // SETTINGS
    async getSettings ({ commit }, data) {
      try {
        commit('SET_SETTINGS_LOADING', true)
        const res = await deliveryApi.getDeliverySettings(data)
        return res
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_SETTINGS_LOADING', false)
      }
    },

    async addSettings ({ commit }, data) {
      try {
        commit('SET_SETTINGS_LOADING', true)
        const res = await deliveryApi.addDeliverySettings(data)
        return res
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_SETTINGS_LOADING', false)
      }
    },

    async updateSettings ({ commit }, data) {
      try {
        commit('SET_SETTINGS_LOADING', true)
        const res = await deliveryApi.updateDeliverySettings(data)
        return res
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_SETTINGS_LOADING', false)
      }
    },
    async editDeliveryZones ({ commit }, data) {
      try {
        const res = await deliveryApi.editGeoJson(data._id, data.clubId, data.retailPointId, data.userMap)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async addDeliveryZones ({ commit }, data) {
      try {
        const res = await deliveryApi.addGeoJson(data.clubId, data.retailPointId, data.userMap)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async getDeliveryZones ({ commit }, payload) {
      try {
        const res = await deliveryApi.getGeoJson(payload.clubId, payload.retailPointId)
        return res
      } catch (e) {
        console.log(e)
      }
    }
  },
  getters: {
    getCatalogLoading: state => state.catalogLoading,
    getCatalog: state => state.catalog,
    getCategories: state => state.categories,
    getSettingsLoading: state => state.settingsLoading
  }
}
