import global from './common/global'
import transactions from './common/transactions'
import participants from './common/participants'
import promotions from './common/promotions'
import news from './common/news'
import push from './common/push'
import reports from './common/reports'
import autoreports from '@/store/modules/clubModule/common/autoreports'
import products from './common/products'
import dashboards from './common/dashboards'
import delivery from './common/delivery'

export default {
  namespaced: true,
  modules: {
    global,
    participants,
    transactions,
    promotions,
    news,
    push,
    reports,
    autoreports,
    dashboards,
    products,
    delivery
  }
}
