<template>
  <v-card class="popup popup--push">
    <v-card-title class="popup__title">
      Просмотр рассылки
    </v-card-title>

    <v-card-text class="popup__content">
      <app-club-push-popup :item="data" />
    </v-card-text>

    <!-- <v-card-actions
      v-if="['error', 'cancel'].indexOf(data.status.toLowerCase()) !== -1"
      class="popup__actions d-flex justify-center mt-5"
    >
      <app-button
        class="popup__submit"
        @click="sendPush"
      >
        Повторить рассылку
      </app-button>
    </v-card-actions>

    <v-card-actions
      v-else-if="['wait'].indexOf(data.status.toLowerCase()) !== -1"
      class="popup__actions row ma-0 mt-5"
    >
      <v-col class="col-12 pa-0">
        <v-checkbox
          v-model="sendNow"
          label="Отправить раньше срока"
        />
      </v-col>
      <v-col class="col-12 pa-0 d-flex flex-wrap justify-space-between">
        <app-button
          class="popup__submit col-12 col-sm-auto mb-3 mb-sm-0"
          :disabled="!sendNow"
          @click="sendPush"
        >
          Отправить сейчас
        </app-button>
        <app-button
          class="popup__reset col-12 col-sm-auto"
          mode="outline"
          @click="cancelPush"
        >
          Отменить отправку
        </app-button>
      </v-col>
    </v-card-actions> -->
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ClubPushPopup from '@/components/Club/ClubContent/ClubPush/Popup'
// import Button from '@/components/UI-Kit/buttons/Button'

export default {
  name: 'PopupPush',
  components: {
    'app-club-push-popup': ClubPushPopup
    // 'app-button': Button
  },
  data () {
    return {
      sendNow: false
    }
  },
  computed: {
    ...mapGetters([
      'popup',
      'isWritePermission'
    ]),
    data () {
      return this.popup.data
    },
    clubId () {
      return this.$route.params.id
    },
    storePath () {
      return `${this.clubId}/push`
    }
  },
  methods: {
    sendPush () {
      if (this.sendNow) {
        console.log('send push now')
      }
      console.log('send push')
    },
    cancelPush () {
      console.log('cancel push')
    }
  }
}
</script>

<style lang="scss">
</style>
