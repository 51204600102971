<template>
  <v-combobox
    ref="combobox"
    class="input select select--combobox"
    v-model="v"
    :items="itemsList"
    :label="label"
    clearable
    @click:clear="clear"
    multiple
    :menu-props="getMenuProps"
    @change="$emit('change', $event)"
    :rules="rules"
    @update:error="$emit('error', $event)"
    :select-all="selectAll"
    :itemText="itemText"
    :delimiters="[',']"
  >
    <template v-slot:append>
      <div class="select__tooltip" v-if="tooltip.length">
        <app-button-tooltip
          icon="info"
          :message="tooltip"
          :size="[18, 18]"
        />
      </div>
      <div
        class="select__append-icon"
        v-html="require(`@/assets/img/icons/arrow.svg`)"
      />
    </template>
    <template v-slot:selection="{ item, index }">
      <span
        v-if="index === 0"
        class="mr-1"
      >
        {{ item.title || item }}
      </span>
      <span
        v-if="index === 1 && getUniq().length > 1"
        class="select__selection-quantity"
      >
        (еще {{ v.length - 1 }})
      </span>
    </template>
    <template v-slot:prepend-item>
      <v-list-item
        ripple
        v-if="selectAll"
        @change="toggleSelectAll()"
        class="v-list-item-select-all"
        :class="{ 'v-list-item--active': selectAllItems }"
      >
        <v-list-item-action>
          <v-simple-checkbox
            @click="toggleSelectAll()"
          />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ selectAllItems ? 'Отменить выделение' : 'Выбрать все'}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" v-if="selectAll"></v-divider>
    </template>
  </v-combobox>
</template>

<script>
/**
 * Компонент для выбра значения из списка или ввода нового значения. Возможно использовать только массив значение без вложенных объектов
 * @view
 * Селект с возможностью выбора нескольких значений из списка и ввода произвольного значения
 * @props
 * mode String Тип селекта (для валидации)
 * value Any Возвращаемое значение
 * items Array Object Список отображаемых значений в селекте
 * label String Плейсхолдер
 * tooltip String Текст для подсказки
 */
import { Ripple } from 'vuetify/lib/directives'
import ButtonTooltip from '@/components/UI-Kit/buttons/ButtonTooltip'

export default {
  name: 'SelectCombobox',
  components: {
    'app-button-tooltip': ButtonTooltip
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: '',
      validator (value) {
        return ['', 'promocode'].indexOf(value) !== -1
      }
    },
    value: {
      required: true
    },
    items: {
      required: false,
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => {}
    },
    selectAll: {
      type: Boolean,
      required: false,
      default: null
    },
    tooltip: {
      type: String,
      required: false,
      default: ''
    },
    itemText: {
      type: String,
      required: false,
      default: 'title'
    },
    itemValue: {
      type: String,
      required: false,
      default: 'id'
    }
  },
  beforeMount () {
    this.setInputBehavior()
  },
  // created () {
  //   this.itemsList = [...this.items]
  // },
  data () {
    return {
      patterns: {
        lettersAndNumbers: /^[A-Za-z0-9а-яА-Я]+$/
      },
      rules: [],
      rulesList: {
        required: val => !!val || 'Обязательное поле',
        promocode: val => {
          return val.every(v => !v.length || (this.patterns.lettersAndNumbers.test(v))) || 'Только цифры и буквы'
        }
      }
    }
  },
  computed: {
    v: {
      get () {
        return this.value
      },
      set (newVal) {
        let formatValue = []
        newVal.forEach(val => {
          if (val.length) {
            formatValue.push(...val.split(', '))
          } else {
            formatValue.push(val)
          }
        })
        this.$emit('update:value', formatValue)
      }
    },
    itemsList () {
      if (!this.v) return []
      let items = new Set(this.items)
      this.v.forEach(item => {
        items.add(item)
      })
      return Array.from(items)
    },
    getMenuProps () {
      return {
        contentClass: `select__menu select__menu--combobox`,
        offsetY: true,
        closeOnClick: true,
        nudgeBottom: 10,
        nudgeWidth: -16, // 'auto' prop adds 16px to menu min-width
        auto: true, // needs for showing all items immediately
        ...this.menuProps
      }
    },
    selectAllItems () {
      if (this.items && this.v) {
        return this.items.length === this.v.length
      } else return false
    }
  },
  methods: {
    setWidth () {
      this.width = this.$refs.autocomplete.$el.offsetWidth
    },
    clear () {
      this.$refs.combobox.blur()
      this.$emit('clear')
    },
    setInputBehavior () {
      if (this.required) {
        this.rules.push(this.rulesList.required)
      }

      switch (this.mode) {
        case 'promocode':
          this.rules.push(this.rulesList.promocode)
          break
      }
    },
    toggleSelectAll () {
      if (this.selectAllItems) {
        this.v = []
      } else {
        this.v = this.items
      }
    },
    getUniq () {
      const selection = new Set()
      this.v.forEach(val => {
        if (val) {
          selection.add(val.title || val)
        }
      })
      return Array.from(selection)
    }
  },
  directives: {
    Ripple
  }
}
</script>
