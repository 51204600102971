<template>
  <div>
    <v-row
      class="mt-0 mb-0"
      v-for="field in fields"
      :key="field.title"
    >
      <v-col class="col-12 col-sm-6">
        {{ field.value === 'status' && newPromo ? '' : field.title }}
      </v-col>

      <v-col class="col-12 col-sm-6 promotions-info__value">
        <template v-if="field.value === 'templateId'">
          <span>{{ template.title }}</span>
        </template>

        <template v-else-if="field.value === 'operationType'">
          {{ operationType }}
        </template>

        <template v-else-if="field.value === 'status' && !newPromo">
          <app-status
            type="promotions"
            mode="default"
            :status="data[field.value].toLowerCase()"
          />
        </template>

        <template v-else-if="['beginDate', 'endDate', 'insDate'].includes(field.value)">
          <span>{{ data[field.value] | dateISO }}</span>
        </template>

        <template v-else>
          {{ data[field.value] }}
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import Status from '@/components/UI-Kit/table/Status'

export default {
  name: 'PromotionsInfoMain',
  components: {
    'app-status': Status
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    newPromo: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      fieldsList: [
        { title: 'Название акции', value: 'title' },
        { title: 'Дата создания', value: 'insDate' },
        { title: 'Тип операции', value: 'operationType' },
        { title: 'Шаблон акции', value: 'templateId' },
        { title: 'Статус акции', value: 'status' },
        { title: 'Даты проведения акции', value: 'beginDate' },
        { title: '', value: 'endDate' }
      ]
    }
  },
  computed: {
    clubId () {
      return this.$route.params.id
    },
    template () {
      return this.$store.getters[`${this.clubId}/promotions/getTemplate`](this.data.templateId)
    },
    operationType () {
      return this.$store.getters[`${this.clubId}/promotions/getOperationsTitle`](this.template.operationType)
    },
    fields () {
      return this.newPromo ? this.fieldsList.filter(el => el.value !== 'insDate') : this.fieldsList
    }
  }
}
</script>

<style lang="scss">

</style>
