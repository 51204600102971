<template>
  <div :class="{'dashboard__data' : !report}">
    <template
      v-if="report"
    >
      Участники программы: {{ averageBillSum ? averageBillSum : 0 }}
    </template>
    <template
      v-else
    >
      <app-dashboard-num
        :number="averageBillSum ? averageBillSum : 0"
        :comma="true"
        text="₽"
      />
      <app-dashboard-graph
        :data="averageBillByDay"
        :label="'Сумма'"
        :mode="'simple'"
        descr="Динамика среднего чека"
      />
    </template>
  </div>
</template>

<script>
import DashboardNum from '@/components/Club/ClubDashboards/DashboardNum'
import DashboardGraphModule from '@/components/Club/ClubDashboards/DashboardGraphModule'
import { dashboardMixin } from '../../../../mixins/dashboard'

export default {
  name: 'ClubDashboardsModulePollsUsers',
  components: {
    'app-dashboard-num': DashboardNum,
    'app-dashboard-graph': DashboardGraphModule
  },
  mixins: [dashboardMixin],
  props: {
    requestData: {
      type: Object,
      require: true
    },
    report: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    clubId () {
      return this.$route.params.id
    },
    averageBillSum () {
      return this.$store.getters[`${this.clubId}/dashboards/getAverageBillSum`]
    },
    averageBillSumForLAstPeriod () {
      return this.$store.getters[`${this.clubId}/dashboards/getAverageBillSumForLastPeriod`]
    },
    averageBillByDay () {
      return this.$store.getters[`${this.clubId}/dashboards/getAverageBillByDay`]
    }
  },
  methods: {
    goToDetail () {
      this.$router.push({
        name: 'ClubDashboardDetail',
        params: {
          component: {
            name: this.title,
            component: this.detail,
            id: this.id
          }
        }
      })
    }
  }
}
</script>
