<template>
  <div class="loadObserver"></div>
</template>

<script>
export default {
  name: 'LoadOnScroll',
  props: ['options'],
  mounted () {
    const options = this.options || {}
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit('intersect')
      }
    }, options)
    this.observer.observe(this.$el)
  },
  destroyed () {
    this.observer.disconnect()
  },
  data () {
    return {
      observer: null
    }
  }
}
</script>

<style scoped>
  .loadObserver {
    width: 1px;
    height: 1px;
  }
</style>
