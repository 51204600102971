import { server } from './index'

let transactionsApi = {
  transactions (id, data) {
    return server.post(`/remote/transactions/${id}`, data)
  },
  getBill (id) {
    return server.get(`/remote/bill/${id}`)
  },
  billSearch (id, data) {
    return server.post(`/remote/bill/search/${id}`, { ...data })
  },
  confirm (id, data) {
    return server.post(`/remote/transactions/confirm/${id}`, data)
  }
}

export { transactionsApi }
