var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("treeview treeview--" + _vm.mode)},[_c('div',{staticClass:"treeview__tree"},[_c('v-treeview',{ref:"treeview",class:("v-treeview--" + _vm.mode),attrs:{"items":_vm.items,"return-object":"","item-key":"treeId","item-text":"title","active":_vm.treeviewActive,"activatable":"","selection-type":"independent","expand-icon":"add_circle","item-children":_vm.childrenName,"open":_vm.open,"search":_vm.search,"filter":_vm.filter},on:{"update:active":function($event){_vm.treeviewActive=$event},"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.treeType !== 'club')?_c('picture',{domProps:{"innerHTML":_vm._s(require(("@/assets/img/icons/" + (item.treeType) + ".svg")))}}):_c('img',{staticClass:"treeview__club-logo",attrs:{"src":_vm.logo}})]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({class:{ 'inactive': item.title ? item.title.slice(0, 1) === '_' : false }},on),[_vm._v(" "+_vm._s(item.treeType === 'terminal' ? item.code : item.title)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.treeType === 'terminal' ? item.code : item.title)+" ")])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }