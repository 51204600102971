var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"top":"","disabled":_vm.drawer || _vm.singleProgram,"max-width":"194"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({class:[
        ("nav-list__item nav-list__item--" + _vm.mode),
        {
          'nav-list__item--wrap': _vm.wrap,
         'active': _vm.active,
         'nav-list__item--header': _vm.header,
         'nav-list__item--single': _vm.singleProgram
        }
      ],attrs:{"active-class":"active","href":_vm.getLink,"target":_vm.item.type ? '_blank' : ''},on:{"click":_vm.clickHandler}},on),[(_vm.item.icon)?_c('v-list-item-avatar',{class:['nav-list__item-icon', { 'nav-list__item-icon--header': _vm.header }],domProps:{"innerHTML":_vm._s(require(("@/assets/img/icons/" + (_vm.item.icon) + ".svg")))}}):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{class:['nav-list__item-title', { 'nav-list__item-title--header': _vm.header, 'nav-list__item-title--single': _vm.singleProgram }]},[(_vm.header)?_c('span',[_vm._v(_vm._s(_vm._f("headerMenuTitle")(_vm.item.title)))]):_c('span',[_vm._v(_vm._s(_vm.item.title))]),(_vm.item.quantity)?_c('span',{staticClass:"nav-list__item-quantity"},[_vm._v(" "+_vm._s(_vm.singleProgram ? '' : _vm.item.quantity)+" ")]):_vm._e(),(_vm.item.beta)?_c('v-chip',{attrs:{"color":"accent","small":""}},[_vm._v("Beta")]):_vm._e()],1)],1),(_vm.activator)?_c('v-list-item-action',{staticClass:"nav-list__item-arrow",domProps:{"innerHTML":_vm._s(require("@/assets/img/icons/arrow.svg"))}}):_vm._e()],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.item.title)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }