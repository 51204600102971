<template>
  <v-tooltip
    top
    :disabled="drawer || singleProgram"
    max-width="194"
  >
    <template v-slot:activator="{ on }">
      <v-list-item
        :class="[
          `nav-list__item nav-list__item--${mode}`,
          {
            'nav-list__item--wrap': wrap,
           'active': active,
           'nav-list__item--header': header,
           'nav-list__item--single': singleProgram
          }
        ]"
        active-class="active"
        :href="getLink"
        @click="clickHandler"
        v-on="on"
        :target="item.type ? '_blank' : ''"
      >
        <v-list-item-avatar
          v-if="item.icon"
          :class="['nav-list__item-icon', { 'nav-list__item-icon--header': header }]"
          v-html="require(`@/assets/img/icons/${item.icon}.svg`)"
        />
        <v-list-item-content>
          <v-list-item-title :class="['nav-list__item-title', { 'nav-list__item-title--header': header, 'nav-list__item-title--single': singleProgram }]">
            <span v-if="header">{{ item.title | headerMenuTitle }}</span>
            <span v-else>{{ item.title }}</span>
            <span
              v-if="item.quantity"
              class="nav-list__item-quantity"
            >
              {{ singleProgram ? '' : item.quantity }}
            </span>
            <v-chip
              v-if="item.beta"
              color="accent"
              small
            >Beta</v-chip>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action
          v-if="activator"
          class="nav-list__item-arrow"
          v-html="require(`@/assets/img/icons/arrow.svg`)"
        />
      </v-list-item>
    </template>
    <span>
      {{ item.title }}
    </span>
  </v-tooltip>
</template>

<script>/**
 * Компонент элемента списка главного меню
 * @view
 * Элемент списка с всплывающим сообщением при наведении в свернутом меню
 * @actions
 * clickHandler: Перенаправляет на другую страницу, или открывает попап, или возвращает событие 'click'
 * @props
 * mode String Тип элемента ('parent', 'child')
 * item Object Содержимое элемента
 * link String Ссылка для перенаправления на другую страницу
 * popup Object Название попапа
 * activator Boolean Добавляет иконку стрелки в конце элемента
 * drawer Boolean Значение меню (открыто/закрыто)
 * active Boolean Добавляет активный класс если ссылка активна
 * wrap Boolean Добавляет перенос текста на новую строку
 */
import { mapActions } from 'vuex'

export default {
  name: 'NavListItem',
  props: {
    mode: {
      type: String,
      required: false,
      default: 'parent',
      validator (value) {
        return ['parent', 'child'].indexOf(value) !== -1
      }
    },
    item: {
      type: Object,
      required: true
    },
    link: {
      type: String,
      required: false,
      default: ''
    },
    popup: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    activator: {
      type: Boolean,
      required: false,
      default: false
    },
    drawer: {
      type: Boolean,
      required: false,
      default: true
    },
    active: {
      type: Boolean,
      required: true
    },
    wrap: {
      type: Boolean,
      required: false,
      default: false
    },
    header: {
      type: Boolean,
      required: false,
      default: false
    },
    singleProgram: {
      type: Boolean,
      required: false,
      default: false
    },
    routeName: {
      type: String,
      required: false
    }
  },
  methods: {
    ...mapActions([
      'showPopup'
    ]),
    clickHandler (e) {
      if (this.item.type !== 'node') {
        e.preventDefault()
        if (this.link.length) {
          if (this.$route.fullPath !== this.link) {
            this.$router.push(this.link)
          }
        } else if (Object.keys(this.popup).length !== 0) {
          this.showPopup(this.popup)
        } else {
          this.$emit('click')
        }
      }
    }
  },
  computed: {
    getLink () {
      if (this.item.type === 'node') {
        return this.$baseURL + this.link
      } else return this.link
    }
  }
}
</script>

<style lang="scss">
.nav-list__item {
  display: flex;
  justify-content: flex-start;
  min-height: auto !important;
  padding-left: 30px;
  padding-right: 20px;
  transition: background-color $primary-transition;

  .nav-list__item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px !important;
    width: 24px !important;
    height: 24px !important;
    margin: 0 15px 0 0 !important;
    border-radius: 0;

    svg {
      fill: $icon;
      transition: fill $primary-transition;
    }

    &--header {
      margin: 0 auto!important;
      height: 40px!important;
    }
  }

  .nav-list__item-title {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: flex-start;
    font-weight: 600 !important;
    font-size: 1rem !important;
    transition: color $primary-transition;

    & > span {
      width: min-content;
    }

    .nav-list__item-quantity {
      margin-left: 2px;
      color: $icon;
    }
    &--header {
      & > span {
        width: min-content;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &--single {
      color: $icon!important;
    }
  }

  .nav-list__item-arrow {
    display: flex;
    align-items: center;
    min-width: auto;
    min-height: auto;
    margin: 0 0 0 15px !important;

    svg {
      width: 9px;
      height: 6px;
      fill: $icon;
      transform: rotate(-90deg);
      transition: fill $primary-transition;
    }
  }

  &--wrap {
    .nav-list__item-title {
      white-space: normal;
    }
  }

  &--header {
    max-width: 100%;
    height: 100%;
    justify-self: flex-start;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 30px;
    box-sizing: initial;
    .v-avatar {
      overflow: visible;
    }
    .v-list-item__content {
      margin: 0 auto!important;
      overflow: visible;
      padding: 0;
    }
    .v-chip {
      display: inline-block;
      position: absolute;
      top: -5px;
      right: 0;
    }
  }
  &--single {
    max-height: 40px!important;
  }

  &--parent {
    min-height: auto;
    height: 50px;

    .nav-list__item-title {
      color: $base;
    }
  }

  &--child {
    min-height: auto;
    height: 40px !important;

    .nav-list__item-title {
      color: $icon;
    }
  }

  &:hover,
  &:focus {
    background-color: $menu-hover;

    .nav-list__item-icon {
      svg {
        fill: $accent;
      }
    }

    .nav-list__item-title {
      color: $base;
    }

    .nav-list__item-title--header {
      color: $accent;
    }

    .nav-list__item-arrow {
      svg {
        fill: $base;
      }
    }
  }

  &--child.active,
  &--parent.active {
    background-color: $secondary;

    .nav-list__item-icon {
      svg {
        fill: $accent;
      }
    }

    .nav-list__item-title {
      color: $text;
      &--single {
        color: $alert!important;
      }
    }

    .nav-list__item-title--header {
      color: $accent;
      ::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background: $accent;
        bottom: -20%;
        left: 0;
        z-index: 10000;
      }
    }

    .nav-list__item-arrow {
      svg {
        fill: $icon;
      }
    }
  }
  .v-chip {
    margin-left: 10px;
  }
}

// list styles when navigation drawer collapsed
.v-navigation-drawer--mini-variant {
  .nav-list__item {
    padding-right: 30px;

    .nav-list__item-icon {
      margin-right: auto !important;
    }
  }
}
</style>
