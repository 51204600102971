<template>
  <v-btn
    :class="`button-close button-close--${mode}`"
    fab
    @click="$emit('click')"
  >
    <div
      class="d-flex"
      v-html="require(`@/assets/img/icons/error.svg`)"
    />
  </v-btn>
</template>

<script>
export default {
  name: 'ButtonClose',
  props: {
    mode: {
      type: String,
      required: false,
      default: 'popup',
      validator (value) {
        return ['popup', 'image'].indexOf(value) !== -1
      }
    }
  }
}
</script>

<style lang="scss">
.button-close {
  opacity: 1 !important;

  &--popup {
    width: 30px !important;
    height: 30px !important;
    background-color: $base !important;
    box-shadow: $shadow !important;
    svg {
      width: 14px;
      height: 14px;
      fill: $icon;
      opacity: 0.6;
      transition: fill $primary-transition, opacity $primary-transition;;
    }

    &:hover {
      svg {
        fill: $accent;
        opacity: 1;
      }
    }
  }

  &--image {
    width: 24px !important;
    height: 24px !important;
    background-color: $accent !important;
    svg {
      width: 8px;
      height: 8px;
      fill: $base;
      opacity: 1;
      transition: fill $primary-transition, opacity $primary-transition;;
    }
  }
}
</style>
