<template>
  <v-card
    class="popup popup--products"
  >
    <v-card-title class="popup__title">
      <div>{{getTitle}}</div>
    </v-card-title>
    <v-card-text class="popup__content">
      <div class="popup__form"
        v-for="(product, index) in products"
        :key="index"
      >
        <app-combobox
          :value.sync="products[index].categories"
          label="Категории"
        ></app-combobox>
        <app-input
          mode="text"
          :value.sync="products[index].title"
          required
          label="Название товара"
          @error="setError($event, 1)"
        />
        <app-input
          mode="productCode"
          :value.sync="products[index].code"
          required
          label="Код товара"
          @error="setError($event, 2)"
        />
        <app-select-multiple
          mode="point"
          :items="getPoints"
          :menuProps="{'z-index': 1000}"
          label="Точки"
          :value.sync="products[index].retailPoints"
          select-all
          :required="true"
          @error="setError($event, 3)"
        />
      </div>
      <app-button-circle
        v-if="isNew"
        :disabled="!valid"
        @click="addForm()"
      >Добавить еще товар</app-button-circle>
      <div class="popup__error" v-if="error.length" v-html="error"></div>
    </v-card-text>
    <v-card-actions class="popup__actions">
      <app-button
        v-if="isNew"
        @click="add()"
        :disabled="!valid || loading"
        :loading="loading"
      >Добавить товары</app-button>
      <app-button
        @click="edit()"
        :disabled="!valid || loading"
        :loading="loading"
        v-else
      >Сохранить изменения</app-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Input from '@/components/UI-Kit/inputs/Input'
import SelectMultiple from '@/components/UI-Kit/selects/SelectMultiple'
import Button from '@/components/UI-Kit/buttons/Button'
import ButtonCircle from '@/components/UI-Kit/buttons/ButtonCircle'
import SelectCombobox from '@/components/UI-Kit/selects/SelectCombobox'
export default {
  name: 'PopupProducts',
  components: {
    'app-button': Button,
    'app-input': Input,
    'app-select-multiple': SelectMultiple,
    'app-button-circle': ButtonCircle,
    'app-combobox': SelectCombobox
  },
  created () {
    if (this.data) {
      this.products[0].categories = this.data.categories
      this.products[0].title = this.data.title
      this.products[0].retailPoints = this.data.retailPoints
      this.products[0].code = this.data.code
      this.fieldsError = Array(4).fill(false)
    }
  },
  data () {
    return {
      error: '',
      products: [
        {
          categories: [],
          title: '',
          code: '',
          retailPoints: []
        }
      ],
      loading: false,
      fieldsError: [false, true, true, true]
    }
  },
  computed: {
    ...mapGetters([
      'popup'
    ]),
    data () {
      return this.popup.data
    },
    clubId () {
      return this.$route.params.id
    },
    storePath () {
      return `${this.clubId}/products`
    },
    loader () {
      return this.$store.getters[`${this.clubId}/reports/getLoader`]
    },
    getTitle () {
      return this.data ? 'Редактирование товара' : 'Добавление товаров'
    },
    getPoints () {
      return this.$store.getters[`${this.clubId}/global/getPoints`]
    },
    isNew () {
      return !this.data
    },
    valid () {
      return this.fieldsError.every(error => !error)
    }
  },
  methods: {
    ...mapActions([
      'hidePopup',
      'showAlert'
    ]),
    async edit () {
      this.loading = true
      const editData = {
        ...this.data,
        categories: this.products[0].categories,
        title: this.products[0].title,
        code: this.products[0].code,
        retailPoints: this.products[0].retailPoints
      }
      const edit = await this.$store.dispatch(`${this.storePath}/editProduct`, { data: editData, clubId: this.clubId })
      if (!edit.error) {
        this.hidePopup()
        this.showAlert({
          text: `Товар ${editData.title} успешно отредактирован.`,
          type: 'success'
        })
      } else {
        this.loading = false
        this.error = edit.error
      }
    },
    addForm () {
      this.products.push({
        categories: [],
        title: '',
        code: '',
        retailPoints: []
      })
    },
    async add () {
      this.loading = true
      const add = await this.$store.dispatch(`${this.storePath}/addMany`, {
        clubId: this.clubId,
        data: this.products
      })
      if (!add.error) {
        this.hidePopup()
        this.showAlert({
          text: `Товары успешно добавлены.`,
          type: 'success'
        })
      } else {
        this.loading = false
        this.error = add.error
      }
    },
    setError (error, index) {
      this.$set(this.fieldsError, index, error)
    }
  }
}
</script>

<style lang="scss">
.popup--products {
  height: 100%;
  .popup__content {
    margin-bottom: 15px;
  }
  .popup__actions {
    display: block;
    .v-btn {
      width: 100%;
    }
  }
  .popup__error {
    margin-top: 15px;
    margin-bottom: 10px;
    color: $accent;
  }
  .popup__form {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid $separator;
  }
}
</style>
