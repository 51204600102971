import Vue from 'vue'
import '@babel/polyfill'
import App from './App.vue'
import router from './router/'
import store from './store/'
import vuetify from './plugins/vuetify'
import DatetimePicker from 'vuetify-datetime-picker'
import './filters'
import VueSocketIO from 'vue-socket.io'
import io from 'socket.io-client'
import { updateRefresh } from '@/api/index'
import YmapPlugin from 'vue-yandex-maps'
import { settings } from '@/yandex-settings'
import '@/scss/app.scss'

Vue.config.productionTip = false

Vue.use(DatetimePicker)
Vue.use(YmapPlugin, settings)

Vue.prototype.$scrollPosition = 20
Vue.prototype.$infinityDate = '2199-10-20T23:59:00'
Vue.prototype.$endDate = '9999-12-31T23:59:59'
Vue.prototype.$baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8086' : ''
Vue.prototype.$minioURL = process.env.VUE_APP_MINIO_URL ? process.env.VUE_APP_MINIO_URL : 'https://stor02.srv.bms.group/'

Vue.use(new VueSocketIO({
  debug: true,
  connection: io(process.env.NODE_ENV === 'development' ? 'http://localhost:8086' : '/', {
    query: {
      auth: localStorage.getItem('bmsAdminToken')
    }
  })
}))

new Vue({
  store,
  router,
  vuetify,
  sockets: {
    exception: async function (err) {
      if (err.message === 'jwt invalid' || err.message === 'jwt expired') {
        this.$socket.disconnect()
        try {
          await updateRefresh()
          this.$socket.query.auth = localStorage.getItem('bmsAdminToken')
          this.$socket.connect()
          this.$socket.emit(err.event, err.data)
        } catch (e) {
          console.error('Error refresh', e)
        }
      }
    }
  },
  render: h => h(App)
}).$mount('#app')
