var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{class:[
    ("clubs-item-list clubs-item-list--" + _vm.mode),
    { 'admin': _vm.isBMSAdmin },
    { 'mob': !_vm.$vuetify.breakpoint.xl }
  ]},[_vm._l((_vm.getClubRoutes),function(clubRoute,i){return _c('clubs-item-list-item',{key:i,class:[
      "clubs-item-list__item clubs-item-list__item--route",
      { 'admin': _vm.isBMSAdmin }
    ],style:(("order: " + i)),attrs:{"mode":_vm.mode,"item":clubRoute.meta,"link":("/clubs/" + (_vm.club.id) + "/" + (clubRoute.path))}})}),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.mode === 'list' && _vm.isBMSAdmin && _vm.$vuetify.breakpoint.xl),expression:"mode === 'list' && isBMSAdmin && $vuetify.breakpoint.xl"}],staticClass:"clubs-item-list__item clubs-item-list__item--api",style:(("order: " + _vm.order))},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"clubs-item-list__item-subtitle body-2"},[_vm._v(" Адрес бека ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.club.api)+" ")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }