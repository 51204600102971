import { participantsApi } from '@/api/participants'

import { formatMixin } from '@/mixins/index'

const getDefaultState = () => {
  return {}
}

export default {
  namespaced: true,
  state () {
    return {
      participant: {},
      participants: [],
      search: {
        firstName: '',
        lastName: '',
        middleName: '',
        cardNum: '',
        phone: ''
      },
      page: {
        page: 0,
        size: 25,
        sort: [{
          'direction': 'DESC',
          'property': 'insDate'
        }]
      },
      status: '',
      statusPage: '',
      statusEdit: '',
      totalElements: 0,
      timeoutTime: 1000,
      inProcess: false,
      error: false,
      msg: '',
      cardHistory: null
    }
  },
  getters: {
    getParticipant: (state) => state.participant,
    getParticipants: (state) => state.participants,
    getParticipantsParams: (state) => state.search,
    getStatus: (state) => state.status,
    getStatusPage: (state) => state.statusPage,
    getStatusEdit: (state) => state.statusEdit,
    getTotalElements: (state) => state.totalElements,
    getItemsPerPage: state => state.page.size,
    getInProcess: (state) => state.inProcess,
    getError: (state) => state.error,
    getMsg: (state) => state.msg,
    getCardHistory: state => state.cardHistory
  },
  mutations: {
    LOAD_PARTICIPANT (state, data) {
      state.participant = data
    },
    LOAD_PARTICIPANTS (state, data) {
      if (!data) {
        state.participants = []
        state.totalElements = 0
      } else {
        if (state.page.page === 0) state.participants = data.content
        else state.participants.push(...data.content)
        state.totalElements = data.totalElements
      }
    },
    SET_PARTICIPANTS_PARAMS (state, data) {
      if (data.clear) {
        state.search = data.params
      } else {
        state.search = { ...state.search, ...data }
      }
    },
    SET_STATUS (state, data) {
      state.status = data
    },
    SET_STATUS_PAGE (state, status) {
      state.statusPage = status
    },
    SET_STATUS_EDIT (state, status) {
      state.statusEdit = status
    },
    SET_PAGE (state, page) {
      if (page === undefined) state.page.page++
      else state.page.page = page
    },
    SET_SORT (state, prop) {
      state.page.sort[0] = { ...state.page.sort[0], ...prop }
    },
    SET_PROCESS (state, val) {
      state.inProcess = val
    },
    SET_ERROR (state, data) {
      state.error = data.error
      state.msg = data.msg
    },
    EDIT_PARTICIPANT (state, { id, data }) {
      if (!state.participants.length) return
      let participant = state.participants.find(item => item.id === id)
      state.participants.splice(state.participants.indexOf(participant), 1, data)
    },
    CLEAR (state) {
      Object.assign(state, getDefaultState())
    },
    SET_CARD_HISTORY (state, data) {
      state.cardHistory = { ...data }
    }
  },
  actions: {
    loadTable ({ commit, dispatch }, clubId) {
      commit('SET_STATUS', 'loading')
      commit('LOAD_PARTICIPANTS')
      commit('SET_PAGE', 0)
      let p1 = dispatch('loadParticipants', clubId)
      let p2 = dispatch('timeout')
      Promise.all([p1, p2]).then(values => {
        if (values[0] && values[0].content && values[0].content.length) {
          commit('LOAD_PARTICIPANTS', values[0])
          commit('SET_STATUS', '')
        } else commit('SET_STATUS', '404')
      }, reason => commit('SET_STATUS', '500'))
    },
    loadPage ({ commit, dispatch }, clubId) {
      commit('SET_STATUS_PAGE', 'loading')
      commit('SET_STATUS', 'loading')
      dispatch('loadParticipants', clubId).then(value => {
        commit('SET_STATUS_PAGE', '')
        commit('SET_STATUS', '')
        commit('LOAD_PARTICIPANTS', value)
      }, reason => {
        commit('SET_STATUS_PAGE', '500')
        commit('SET_STATUS', '500')
      })
    },
    loadEdit ({ state, commit, getters, dispatch }, data) {
      commit('SET_STATUS_EDIT', '')
      commit('LOAD_PARTICIPANT', {})
      let participant = state.participants.find(el => el.id === +data.id)
      if (participant) commit('LOAD_PARTICIPANT', participant)
      else {
        commit('SET_STATUS_EDIT', 'loading')
        let p1 = dispatch('loadParticipant', data)
        let p2 = dispatch('timeout')
        Promise.all([p1, p2]).then(values => {
          if (values[0]) {
            commit('LOAD_PARTICIPANT', values[0])
            commit('SET_STATUS_EDIT', '')
          } else commit('SET_STATUS_EDIT', '404')
        }, reason => commit('SET_STATUS_EDIT', '500'))
      }
    },
    async loadParticipants ({ state, commit }, clubId) {
      let search = JSON.parse(JSON.stringify(state.search))
      search.phone = formatMixin.methods.formatPhoneForServer(search.phone)
      search.cardNum = formatMixin.methods.formatCardForServer(search.cardNum)

      let data = {
        search,
        page: state.page
      }

      try {
        let res = await participantsApi.all(clubId, data)
        return res.data
      } catch (err) {
        throw err
      }
    },
    async loadParticipant ({ state, commit }, { clubId, id }) {
      try {
        let res = await participantsApi.findOne(clubId, id)
        return res.data
      } catch (err) {
        throw err
      }
    },
    setParticipantsParams ({ commit }, data) {
      commit('SET_PARTICIPANTS_PARAMS', data)
    },
    async editParticipant (store, { id, data }) {
      // console.log('data', data)
      //
      // let p1, p2, p3
      // if (Object.keys(data).length !== 0) {
      //   p1 = dispatch('edit', { id, editId, data })
      // }
      // if (image !== undefined) {
      //   if (image === null) {
      //     p2 = dispatch('removeImage', { id, editId })
      //   } else {
      //     p2 = dispatch('removeImage', { id, editId })
      //     p3 = dispatch('addImage', { id, editId, image })
      //   }
      // }
      //
      // let { cards, happyDays, ...edit } = data
      //
      // if (Object.keys(data).includes('cards')) {
      //
      // }
      // if (Object.keys(data).includes('happyDays')) {
      //
      // }
      //
      // Promise.all([p1, p2, p3]).then(values => {
      //   values.forEach(el => {
      //     if (!el) return
      //     typeof el === 'string' ? dispatch('editError', el) : dispatch('editSuccess', el)
      //   })
      // }, reason => {
      //   console.log(reason)
      // })

      if (Object.keys(data.data).includes('notificationEnable')) {
        data.data.isNotificationEnable = data.data.notificationEnable
      }
      let happyDays = []
      if (Object.keys(data.data).includes('happyDays')) {
        if (!data.data.happyDays.length) data.data.happyDays = 'emptyArray'
        else if (data.data.happyDays.length) {
          data.data.happyDays.map(day => {
            if (typeof day === 'string') happyDays.push({ happyDay: day, title: 'Happy Day' })
            else happyDays.push(day)
          })
          data.data.happyDays = [...happyDays]
        }
      }
      Object.keys(data.data).forEach(key => {
        if (data.data[key] === '') {
          data.data[key] = 'empty'
        }
      })
      try {
        let res = await participantsApi.edit(id, data)
        store.commit('EDIT_PARTICIPANT', { id: data.id, data: res.data })
        store.dispatch('showAlert', {
          type: 'success',
          text: 'Изменения успешно сохранены'
        }, { root: true })
      } catch (e) {
        console.log(e)
        store.dispatch('showAlert', {
          type: 'error',
          text: 'Произошла ошибка. Попробуйте позже.'
        }, { root: true })
      }
    },
    async edit () {

    },
    async editCards () {

    },
    async editHappyDates () {

    },
    setPage ({ commit }, page) {
      commit('SET_PAGE', page)
    },
    setSort ({ commit }, prop) {
      commit('SET_SORT', prop)
      commit('SET_PAGE', 0)
    },
    timeout ({ state }) {
      return new Promise((resolve, reject) => {
        setTimeout(() => resolve(true), state.timeoutTime)
      })
    },
    async getCardHistory ({ commit }, data) {
      try {
        const res = await participantsApi.getCardHistory(data)
        commit('SET_CARD_HISTORY', res.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async editCardHistory ({ commit, state }, { clubId, percent }) {
      const cardHistory = JSON.parse(JSON.stringify(state.cardHistory))
      cardHistory.cardCurrentBonusPercent = percent
      try {
        const res = await participantsApi.editCardHistory(clubId, cardHistory)
        console.log(res.data)
        commit('SET_CARD_HISTORY', res.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    }
  }
}
