export default async function isBMSAdmin ({ next, store, nextMiddleware }) {
  await store.dispatch('loadUser')

  if (!store.getters.isBMSAdmin) {
    return next({
      name: 'Home'
    })
  }

  return nextMiddleware()
}
