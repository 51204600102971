<template>
  <div
    v-if="status"
    class="loader overlay"
  >
    <div
      v-if="status === 'loading'"
      class="loader__block"
    >
      <div class="spinner center">
        <div
          v-for="i in 12"
          :key="i"
          class="spinner-blade"
        />
      </div>
      <p class="loader__message">
        {{ loadingMessage }}
      </p>
      <p
        v-if="mode ==='default' && getSubtitle.length"
        class="loader__subtitle body-1"
      >
        {{ getSubtitle }}
      </p>
    </div>

    <div
      v-else-if="['404', '500'].includes(status.toString())"
      class="loader__block"
    >
      <div
        class="loader__icon"
        v-html="require(`@/assets/img/icons/${icon}.svg`)"
      />
      <p class="loader__title">
        {{ title }}
      </p>
      <p
        v-if="mode ==='default'"
        class="loader__subtitle"
      >
        {{ getSubtitle }}
      </p>
    </div>
  </div>
</template>

<script>/**
 * Компонент прелоадера
 * @view
 * Прелоадер и сообщение в случае ошибки загрузки
 * @props
 * mode String Тип прелоадера ('default' для главной страницы, 'edit' для страницы редактирования)
 * status String Статус загрузки
 * loading-message String Отображаемое сообщение во время загрузки
 * error-message String Отображаемое сообщение в случае 404 ошибки
 * subtitle String Нижний текст
 */

export default {
  name: 'Loader',
  props: {
    mode: {
      type: String,
      required: false,
      default: 'default',
      validator (value) {
        return ['default', 'edit'].indexOf(value) !== -1
      }
    },
    status: {
      type: String,
      required: true,
      validator (value) {
        return ['', 'loading', '404', '500', 'ok'].indexOf(value) !== -1
      }
    },
    loadingMessage: {
      type: String,
      required: false,
      default: 'Загрузка...'
    },
    errorMessage: {
      type: String,
      required: false,
      default: 'Данные не найдены'
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      data: {
        404: {
          icon: 'smile',
          title: 'Данные не найдены',
          subtitle: 'Попробуйте другие параметры поиска'
        },
        500: {
          icon: 'server_error',
          title: 'Ошибка сервера',
          subtitle: 'Попробуйте перезагрузить страницу'
        }
      }
    }
  },
  computed: {
    icon () {
      return ['404', '500'].includes(this.status.toString()) ? this.data[this.status].icon : ''
    },
    title () {
      if (['404', '500'].includes(this.status.toString())) {
        return this.status.toString() === '404' ? this.errorMessage : this.data[this.status].title
      } else {
        return ''
      }
    },
    getSubtitle () {
      return ['404', '500'].includes(this.status.toString()) ? this.data[this.status].subtitle : this.subtitle
    }
  }
}
</script>

<style lang="scss">
  .loader {
    width: 100%;
    padding: 100px 0;

    &__block {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 15px;
    }

    &__message {
      margin-top: 10px;
      margin-bottom: 0 !important;
      color: $icon;
    }

    &__icon {
      svg {
        fill: $accent;
      }
    }

    &__title,
    &__subtitle {
      margin-bottom: 0 !important;
      text-align: center;
    }

    &__title {
      margin-top: 10px;
      color: $text;
    }

    &__subtitle {
      margin-top: 6px;
      font-size: $font-14;
      color: $icon;
    }

    &__message,
    &__title {
      font-size: $font-18;
    }
  }

  $spinner-size: 36px !default;
  $spinner-color: $accent !default;
  $spinner-size-button: $spinner-size / 2 !default;
  $spinner-color-button: $base !default;
  //$overlay-color: black !default;

  @mixin absolute-center {
    /*position: absolute;*/
    /*left: 0;*/
    /*right: 0;*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*margin: auto;*/
  }

  .overlay {
    //background: rgba($overlay-color,.9);
    //@include absolute-center;
  }

  .spinner {
    font-size: $spinner-size;
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    &--button {
      font-size: calc(#{$spinner-size-button});

      .spinner-blade {
        animation: spinner-fade-button 1s infinite linear;
      }
    }

    &.center {
      //  @include absolute-center;
    }
    &--accent {
      .spinner-blade {
        animation: spinner-fade 1s infinite linear;
      }
    }
  }

  .spinner-blade {
    position: absolute;
    left: .4629em;
    bottom: 0;
    width: .052em;
    height: .2557em;
    border-radius: .5em;
    background-color: transparent;
    transform-origin: center -.2222em;
    animation: spinner-fade 1s infinite linear;

    $animation-delay: 0s;
    $blade-rotation: 0deg;

    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        animation-delay: $animation-delay;
        transform: rotate($blade-rotation);
        $blade-rotation: $blade-rotation + 30;
        $animation-delay: $animation-delay + .083;
      }
    }
  }

  @keyframes spinner-fade {
    0% {
      background-color: $spinner-color;
    }

    100% {
      background-color: transparent;
    }
  }

  @keyframes spinner-fade-button {
    0% {
      background-color: $spinner-color-button;
    }

    100% {
      background-color: transparent;
    }
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    .loader {
      &__message,
      &__title {
        font-size: $font-24;
      }
      &__subtitle {
        font-size: $font-17;
      }
    }

    .spinner {
      font-size: 50px;
      &--button {
        font-size: calc(50px / 2);
      }
    }
  }
</style>
