<template>
  <v-container class="home" v-if="!singleProgramUser">
    <div
      v-if="!$vuetify.breakpoint.smAndDown"
      class="home__header"
    />
    <v-app-bar
      v-if="!$vuetify.breakpoint.smAndDown"
      :height="$vuetify.breakpoint.lgAndUp ? 143 : 100"
      flat
      color="white"
    >
      <app-tabs
        class="header__tabs"
        mode="m"
        :value.sync="tab"
        :items="tabs"
      />
    </v-app-bar>

    <div
      v-if="$vuetify.breakpoint.smAndDown"
      class="home__title"
    >
      <span class="home__title-text">{{ clubsTitle }}</span>
      <span class="home__title-number"> {{ getTotalClubs }}</span>
    </div>

    <component :is="main" />
  </v-container>
  <v-container v-else>
    <app-dashboards
      :id="getClubs[0].id"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Tabs from '@/components/UI-Kit/Tabs'
import Clubs from '@/components/clubs/Clubs'
import Users from '@/views/Users/main'
import ClubDashboard from '@/views/Club/ClubDashboards/Index'

export default {
  name: 'Home',
  components: {
    'app-tabs': Tabs,
    'clubs': Clubs,
    'users': Users,
    'app-dashboards': ClubDashboard
  },
  data () {
    return {
      tab: 0
    }
  },
  computed: {
    ...mapGetters([
      'isBMSAdmin',
      'getTotalClubs',
      'getTotalUsers',
      'isReadPermission',
      'singleProgramUser',
      'getClubs'
    ]),
    main () {
      return this.tabs[this.tab].name
    },
    tabs () {
      let moduleNames = ['Clubs', 'Settings']

      if (this.isReadPermission('Users')) {
        moduleNames.splice(2, 0, 'Users')
      }

      let routes = this.$router.options.routes
        .filter(el => moduleNames.includes(el.name))
        .sort((a, b) => moduleNames.indexOf(a.name) - moduleNames.indexOf(b.name))

      return routes.map((route, i) => {
        return {
          id: i,
          name: route.name.toLowerCase(),
          title: route.meta.title,
          icon: route.meta.icon,
          quantity: this[`getTotal${route.name}`] ? this[`getTotal${route.name}`] : ''
        }
      })
    },
    clubsTitle () {
      return this.$router.options.routes.find(el => el.name === 'Clubs').meta.title
    }
  }
}
</script>

<style lang="scss">
.home {
  margin-top: 30px;
  padding: 0 !important;
  &__header {
    position: fixed;
    height: 30px;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background: $secondary;
  }
  &__title {
    margin-bottom: 20px;
    padding: 0 15px;
    font-size: $font-22;
    &-text {
      color: $primary;
    }
    &-number {
      color: $icon;
    }
  }
  .v-app-bar {
    padding: 0 !important;
  }
  .tabs {
    padding: 0;
  }
  .header__tabs {
    height: 80px;
    background-color: $base;
  }
  .header__nav {
    position: sticky;
    height: 30px;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .home {
    padding: 0 30px !important;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .home {
    .header__tabs {
      height: 113px;
    }
  }
}

@media #{map-get($display-breakpoints, 'xl-only')} {
  .home {
    padding: 0 50px !important;
  }
}
</style>
