import { server } from './index'

const deliveryApi = {
  // CATALOG
  getDeliveryCatalog (data) {
    return server.post(`/delivery/search/${data.clubId}`, { retailPointId: data.retailPointId })
  },
  uploadDeliveryCatalog (data) {
    return server.post(`/delivery/upload`, data)
  },
  deleteDeliveryCatalog (data) {
    return server.post(`/delivery/delete`, data)
  },
  getCategories (data) {
    return server.post(`/delivery/categories/${data.clubId}`, { retailPointId: data.retailPointId })
  },

  // CATALOG ITEM
  deleteDeliveryItem (data) {
    return server.delete(`/delivery/deleteItem`, { data })
  },
  addDeliveryItem (data) {
    return server.post(`/delivery/addItem`, data)
  },
  updateDeliveryItem (data) {
    return server.post(`/delivery/updateItem`, data)
  },
  getDeliveryItem (data) {
    return server.post('/delivery/getItem', data)
  },

  // SETTINGS
  getDeliverySettings (data) {
    return server.post('/delivery/settings/get', data)
  },
  addDeliverySettings (data) {
    return server.post('/delivery/settings/add', data)
  },
  updateDeliverySettings (data) {
    return server.post('/delivery/settings/update', data)
  },

  // GEO JSON
  getGeoJson (clubId, retailPointId) {
    return server.get(`/delivery/zones/${clubId}/?retail_point_id=${retailPointId}`)
  },
  addGeoJson (clubId, retailPointId, data) {
    return server.post(`/delivery/zones/add/${clubId}/?retail_point_id=${retailPointId}`,
      { userMap: data }
    )
  },
  editGeoJson (_id, clubId, retailPointId, data) {
    return server.patch(`/delivery/zones/${_id}/${clubId}/?retail_point_id=${retailPointId}`,
      data
    )
  }
}

export { deliveryApi }
