<template>
  <div class="dashboards__main">
    <app-loader
      v-if="loading && !errorLoadData"
      status="loading"
    />
    <app-loader
      v-if="errorLoadData"
      status="500"
    />
    <template v-if="!loading && !errorLoadData">
      <div class="dashboards__content">
        <div class="dashboards__boxes">
          <div v-if="loader" class="dashboards__loader">
            <app-loader
              status="loading"
            />
          </div>
          <app-dashboard-modules-wrapper
            v-else
            :id="id"
            :requestData="requestData"
          />
        </div>
        <div class="dashboards__tree">
          <app-tree-view
            v-if="$vuetify.breakpoint.mdAndUp"
            mode="structure"
            :items="structure"
            :club-id="clubId"
            :active.sync="activeClub"
            @updateStructure="setStructureData($event)"
          />
        </div>
      </div>
    </template>
    <app-vue-html-2-pdf
      v-if="!loader && $vuetify.breakpoint.mdAndUp"
      :show-layout="false"
      :manual-pagination="false"
      :paginate-elements-by-height="1400"
      filename="dashboards"
      :enable-download="true"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-content-width="800px"
      ref="dashboards"
    >
      <section
        slot="pdf-content"
        style="font-family: Proxima Nova, sans-serif"
      >
        <app-dashboard-report-header
          :id="clubId"
          :requestData="requestData"
        />
        <app-dashboard-reports-all
          :id="clubId"
          :requestData="requestData"
        />
      </section>
    </app-vue-html-2-pdf>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import TreeView from '@/components/UI-Kit/Treeview'
import Loader from '@/components/UI-Kit/loaders/LoaderUpdated'
import DashboardModulesWrapper from '@/components/Club/ClubDashboards/DashboardModulesWrapper'
import DashboardReportHeader from '@/components/Club/ClubDashboards/DashboardReportHeader'
import DashboardReportsAll from '@/components/Club/ClubDashboards/DashboardReportsAll'

export default {
  name: 'ClubDashboardMain',
  components: {
    'app-tree-view': TreeView,
    'app-loader': Loader,
    'app-dashboard-modules-wrapper': DashboardModulesWrapper,
    'app-vue-html-2-pdf': VueHtml2pdf,
    'app-dashboard-report-header': DashboardReportHeader,
    'app-dashboard-reports-all': DashboardReportsAll
  },
  props: {
    requestData: {
      type: Object,
      require: true
    },
    id: {
      type: [Number, String],
      required: false
    }
  },
  data: () => ({
    loading: true,
    errorLoadData: false,
    activeClub: null
  }),
  async mounted () {
    this.activeClub = JSON.parse(JSON.stringify(this.structure))
    await this.init()
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    user () {
      return this.$store.getters.getUser
    },
    club () {
      return this.$store.getters[`${this.clubId}/global/getClubData`]
    },
    structure () {
      return this.$store.getters[`${this.clubId}/global/getStructureWithClub`]
    },
    loader () {
      return this.$store.getters[`${this.clubId}/dashboards/getLoading`]
    }
  },
  methods: {
    async init () {
      try {
        this.loading = true
        await this.$store.dispatch(`${this.clubId}/global/setRemoteClub`, this.clubId)
        await this.$store.dispatch(`${this.clubId}/global/setRetails`, this.clubId)
        await this.$store.dispatch(`${this.clubId}/global/setPoints`, this.clubId)
        this.loading = false
      } catch (e) {
        this.errorLoadData = true
      }
    },
    setStructureData (e) {
      this.$emit('setStructureData', {
        ids: e,
        type: 'terminal'
      })
    },
    downloadPDF () {
      this.$refs.dashboards.generatePdf()
    }
  }
}
</script>

<style scoped lang="scss">
.dashboards {
  &__main {
    &::v-deep {
      .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-top: 0;
      }
    }
  }
  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__boxes {
    flex-basis: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__tree {
    display: none;
    flex-basis: 33%;
    max-width: 33%;
  }
  &__loader {
    position: relative;
    height: 700px;
    width: 100%;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .dashboards {
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .dashboards {
    &__main {
      padding: 15px 25px;
    }
    &__tree {
      display: block;
      background: #FFF;
      padding: 0 0 0 5px;
    }
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .dashboards {
    &__main {
      padding: 30px 50px;
      background: transparent;
    }
    &__boxes {
      flex-basis: 75%;
      width: 75%;
    }
    &__tree {
      flex-basis: 25%;
      max-width: 25%;
      padding: 45px 5px 0;
    }
  }
}
@media #{map-get($display-breakpoints, 'xl-only')} {

}
</style>
