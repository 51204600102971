import { server } from './index'
// import * as prom from '../json/promotions/response_1588750465373.json'

const promotionsApi = {
  getTemplates (clubId) {
    return server.get(`/remote/promotions/templates/${clubId}`)
  },
  getAllPromotions (clubId, query) {
    return server.get(
      `/remote/promotions/${clubId}`,
      { params: query }
    )
  },
  searchPromotions (clubId, data) {
    return server.post(`/remote/promotions/search/${clubId}`, data)
  },
  addPromotion (clubId, data) {
    return server.post(`/remote/promotions/add/${clubId}`, data)
  },
  editPromotion (clubId, unitId, data) {
    return server.put(`/remote/promotions/edit/${clubId}/${unitId}`, data)
  },
  editValues (clubId, data) {
    return server.patch(`/remote/promotions/values/${clubId}`, { data })
  },
  removePromotions (clubId, data) {
    return server.delete(`/remote/promotions/remove/${clubId}`, { data })
  }
}

export { promotionsApi }
