<template>
  <v-btn
    class="pa-0"
    to="/"
    text
    tile
    depressed
    x-large
    :min-width="40"
  >
    <v-avatar
      width="47"
      height="42"
      left
      tile
    >
      <img
        v-if="singleProgramUser"
        :src="clubLogo"
        :alt="clubData.title"
      />
      <div v-else v-html="require(`@/assets/img/nav/logo-menu.svg`)"/>
    </v-avatar>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavLogo',
  props: {
    clubId: {
      type: [Number, String],
      requires: false
    }
  },
  computed: {
    ...mapGetters([
      'singleProgramUser'
    ]),
    clubData () {
      return this.$store.getters[`${this.clubId}/global/getClubData`] || {}
    },
    clubLogo () {
      return this.$store.getters[`${this.clubId}/global/getClubLogo`] || {}
    }
  }
}
</script>
