import { reportsApi } from '@/api/reports'

export default {
  namespaced: true,
  state: () => ({
    generatedReports: [],
    loading: false,
    sortBy: 'requestDate',
    sortDesc: true,
    page: 0,
    isLastPage: false
  }),
  mutations: {
    'SET_GENERATED_REPORTS' (state, data) {
      console.log(!state.page)
      if (!state.page) state.generatedReports = [...data]
      else state.generatedReports = [...state.generatedReports, ...data]
    },
    'SET_LOADING' (state, data) {
      state.loading = data
    },
    'SET_PAGE' (state, data) {
      if (typeof data !== 'undefined') state.page = data
      else state.page++
    },
    'SET_IS_LAST_PAGE' (state, data) {
      state.isLastPage = data
    },
    'SET_SORT' (state, data) {
      if (!data.desc) state.sortBy = data.load
      else state.sortDesc = data.load
    },
    'REMOVE_REPORT' (state, id) {
      state.generatedReports = state.generatedReports.filter(item => item._id !== id)
    },
    'EDIT_REPORT' (state, id) {
      state.generatedReports.forEach(item => {
        if (item._id === id) item.viewed = true
      })
    }
  },
  actions: {
    async editGeneratedReportStatus ({ commit }, { id, viewed }) {
      try {
        await reportsApi.editGeneratedReportStatus(id, viewed)
        commit('EDIT_REPORT', id)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async removeGeneratedReport ({ commit }, id) {
      commit('SET_LOADING', true)
      try {
        await reportsApi.removeGeneratedReport(id)
        commit('REMOVE_REPORT', id)
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async getGeneratedReports ({ commit, state }, data) {
      commit('SET_LOADING', true)
      const params = {
        sortBy: state.sortBy,
        sort: state.sortDesc ? 'DESC' : 'ASC',
        num: state.page
      }
      try {
        const res = await reportsApi.getGeneratedReports(data, params)
        if (!res.data.length && state.page) commit('SET_IS_LAST_PAGE', true)
        else if (!res.data.length) commit('SET_GENERATED_REPORTS', [])
        else commit('SET_GENERATED_REPORTS', res.data)
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async getReport ({ commit, state }, reportId) {
      commit('SET_LOADING', true)
      try {
        const report = await reportsApi.getOneReport(reportId)
        commit('SET_GENERATED_REPORTS', [report.data])
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
    setPage ({ commit }, data) {
      commit('SET_PAGE', data)
    },
    setSort ({ commit }, data) {
      commit('SET_SORT', data)
    },
    setIsNotLastPage ({ commit }) {
      commit('SET_IS_LAST_PAGE', false)
    }
  },
  getters: {
    getGeneratedReports: s => s.generatedReports,
    getGeneratedReportById: s => id => s.generatedReports.find(r => r._id === id),
    getLoading: s => s.loading,
    getIsLastPage: s => s.isLastPage
  }
}
