import { authApi } from '@/api/auth'
import { parseJwt } from '@/helper/parseJwt'
import router from '@/router/index'

const getDefaultState = () => {
  return {
    isAuthorised: !!localStorage.getItem('bmsAdminRefresh'),
    // authError: false,
    userCreds: JSON.parse(localStorage.getItem('bmsAdminUser')) || {}
  }
}

const state = getDefaultState()

const mutations = {
  AUTH_SUCCESS (state, creds) {
    localStorage.setItem('bmsAdminToken', creds.token)
    localStorage.setItem('bmsAdminRefresh', creds.refreshToken)
    localStorage.setItem('bmsAdminUser', JSON.stringify(parseJwt(creds.token)))

    state.userCreds = parseJwt(creds.token)
    state.isAuthorised = true
  },
  AUTH_ERROR (state) {
    state.isAuthorised = false

    // state.authError = {
    //   status: true,
    //   msg: 'Неверный пароль и/или логин'
    // }
  },
  UNAUTH_SUCCESS (state) {
    localStorage.removeItem('bmsAdminUser')
    localStorage.removeItem('bmsAdminToken')
    localStorage.removeItem('bmsAdminRefresh')

    state.isAuthorised = false
  },
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  async login ({ commit }, creds) {
    try {
      let res = await authApi.login(creds)
      commit('AUTH_SUCCESS', res.data)
      return true
    } catch (e) {
      commit('AUTH_ERROR')
      return false
    }
  },
  async logout (store) {
    let creds = {
      login: store.state.userCreds.login,
      refreshToken: localStorage.getItem('bmsAdminRefresh')
    }
    try {
      await authApi.logout(creds)
    } catch (e) {
      console.log(e)
    } finally {
      store.commit('UNAUTH_SUCCESS')
    }
    router.push('/login')
    store.commit('RESET_STATE')
    store.commit('RESET_REGISTER_MODULES')
  }
}

const getters = {
  isAuthorised: state => {
    return state.isAuthorised
  },
  getUserLogin: state => state.userCreds.login,
  getUserName: state => state.userCreds.name
}

export default {
  state,
  mutations,
  actions,
  getters
}
