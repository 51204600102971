<template>
  <div class="mobile">
    <div class="mobile__header">
      <span>
        Фильтры
      </span>
      <app-button
        :size="'s'"
        :mode="'outline'"
        @click="close"
      >
        Закрыть
      </app-button>
    </div>
    <div class="mobile__main">
      <app-period-piker
        class="mobile__periodPiker"
        :default="newDates"
        mode="select"
        @update="setDates($event)"
      />
      <app-date-picker
        :default="[newDates.from, newDates.to]"
        @update="setDates($event)"
      />
      <app-structure
        class="mobile__structure"
        :club="clubId"
        :value="requestData.structureData.ids"
        terminals
        @change="setStructureData($event)"
      />
    </div>
    <div class="mobile__footer">
      <app-button
        :size="'m'"
        :mode="'outline'"
        @click="resetFilters"
      >
        Сбросить
      </app-button>
      <app-button
        :size="'m'"
        :mode="'fill'"
        @click="acceptFilters"
      >
        Применить
      </app-button>
    </div>
  </div>
</template>

<script>
import SearchDatePicker from '@/components/UI-Kit/pickers/SearchDatePicker'
import DashboardPeriodPiker from '@/components/Club/ClubDashboards/DashboardPeriodPiker'
import Structure from '@/components/UI-Kit/form/Structure'
import Button from '@/components/UI-Kit/buttons/Button'
import dayjs from 'dayjs'

export default {
  name: 'dashboardMobileFilters',
  components: {
    'app-button': Button,
    'app-date-picker': SearchDatePicker,
    'app-period-piker': DashboardPeriodPiker,
    'app-structure': Structure
  },
  props: {
    requestData: {
      type: Object,
      require: true
    }
  },
  data: () => ({
    newDate: {
      from: '',
      to: ''
    },
    newStructureData: {}
  }),
  computed: {
    clubId () {
      return this.$route.params.id
    },
    newDates () {
      if (this.newDate.from !== '' && this.newDate.to !== '') {
        return this.newDate
      } else {
        return this.requestData.date
      }
    },
    newStructure () {
      if (this.newStructureData !== {}) {
        return this.newStructureData
      } else {
        return this.requestData.structureData
      }
    }
  },
  methods: {
    setDates (e) {
      this.newDate.from = dayjs(e.from.slice(0, 10)).format('YYYY-MM-DD')
      this.newDate.to = dayjs(e.to.slice(0, 10)).format('YYYY-MM-DD')
    },
    setStructureData (structureData) {
      this.newStructureData = structureData
    },
    reset () {
      this.newDate = {
        from: '',
        to: ''
      }
      this.newStructureData = {}
    },
    close () {
      this.reset()
      this.$emit('close')
    },
    acceptFilters () {
      this.$emit('updateAll', {
        date: this.newDates,
        structureData: {
          ids: this.newStructureData,
          type: 'terminal'
        }
      })
      this.reset()
      this.$emit('close')
    },
    resetFilters () {
      this.$emit('updateAll', {
        date: {
          from: `${dayjs().format('YYYY-MM-DD')}`,
          to: `${dayjs().format('YYYY-MM-DD')}`
        },
        structureData: {
          ids: [],
          type: 'terminal'
        }
      })
      this.reset()
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.mobile {
  padding: 0 15px;
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    font-size: 22px;
    line-height: 27px;
    color: #484848;
  }
  &__main {
    display: flex;
    flex-direction: column;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
  }
  &__periodPiker {
    width: 100%;
    margin-bottom: 16px;
  }
  &__structure {
    margin-top: 16px;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .mobile {
    padding: 0;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {

}

@media #{map-get($display-breakpoints, 'xl-only')} {

}
</style>
