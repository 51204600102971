<template>
  <div :class="{'dashboard__data' : !report}">
    <template
      v-if="report"
    >
      Участники программы: {{ countUsers }}
    </template>
    <template
      v-else
    >
      <app-dashboard-num
        :number="countUsers"
      />
    </template>
  </div>
</template>

<script>
import DashboardNum from '@/components/Club/ClubDashboards/DashboardNum'

export default {
  name: 'ClubDashboardsModuleParkingSuccessfulPaymentsWithStars',
  props: {
    report: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: [Number, String],
      require: false
    }
  },
  components: {
    'app-dashboard-num': DashboardNum
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    countUsers () {
      return this.$store.getters[`${this.clubId}/dashboards/getUsersCount`]
    }
  }
}
</script>
