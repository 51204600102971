<template>
  <v-card class="popup popup--cancel">
    <v-card-title class="popup__title">
      Отменить изменения?
    </v-card-title>
    <v-card-actions class="popup__actions">
      <app-button
        class="popup__submit"
        mode="outline"
        @click="submit"
      >
        Да
      </app-button>
      <app-button
        class="popup__reset"
        @click="hidePopup"
      >
        Нет
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Button from '@/components/UI-Kit/buttons/Button'

export default {
  name: 'PopupCancel',
  components: {
    'app-button': Button
  },
  computed: {
    ...mapGetters([
      'popup'
    ]),
    data () {
      return this.popup.data
    }
  },
  methods: {
    ...mapActions([
      'hidePopup'
    ]),
    submit () {
      this.hidePopup()
      setTimeout(() => {
        this.data.next()
      }, this.popup.transition)
    }
  }
}
</script>

<style lang="scss">
.popup--cancel {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
