<template>
  <div
    v-if="data"
    :class="[
      'promotions-info',
      { 'promotions-info--new': newPromo }
    ]"
  >
    <app-tabs
      class="promotions-info__tabs"
      mode="s"
      :value.sync="tab"
      :items="tabs"
    />

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="tab in tabs"
        :key="tab.val"
      >
        <keep-alive>
          <component
            :is="`app-${tab.val}`"
            :data="data"
            :newPromo="newPromo"
          />
        </keep-alive>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Tabs from '@/components/UI-Kit/Tabs'
import Main from '@/components/Club/ClubPromotions/PromotionsInfo/Main'
import Parameters from '@/components/Club/ClubPromotions/PromotionsInfo/Parameters'
import Rewards from '@/components/Club/ClubPromotions/PromotionsInfo/Rewards'
import Points from '@/components/Club/ClubPromotions/PromotionsInfo/Points'

export default {
  name: 'PromotionsInfo',
  components: {
    'app-tabs': Tabs,
    'app-general': Main,
    'app-parameters': Parameters,
    'app-rewards': Rewards,
    'app-points': Points
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    newPromo: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      tab: 0,
      tabs: [
        { val: 'general', title: 'Общие' },
        { val: 'parameters', title: 'Параметры' },
        { val: 'rewards', title: 'Бонусирование по картам' },
        { val: 'points', title: 'Связь с точкой' }
      ]
    }
  }
}
</script>

<style lang="scss">
  .promotions-info {
    width: 100%;
    .v-tabs {
    }
    .v-tabs-items {
      padding-top: 20px;
      & .row {
        margin-bottom: 10px !important;
      }
      & .col {
        padding: 0 12px;
        font-size: $font-14;
        color: $icon;
      }
      .promotions-info__value {
        text-align: left;
        color: $primary;
        &--accent {
          color: $accent !important;
          font-weight: 600;
        }
        &--wrap {
          white-space: pre-wrap;
        }
        & > div {
          display: inline-flex;
        }
      }
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    .promotions-info {
      .v-tabs {
      }
      .v-tabs-items {
        & .col {
          color: $primary;
        }
        .promotions-info__value {
          text-align: right;
        }
      }
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    .promotions-info {
      .v-tabs {
      }
      .v-tabs-items {
        & .col {
          font-size: $font-17;
        }
        .promotions-info__value {
          color: $primary;
        }
      }
    }
  }
</style>
