<template>
  <v-card class="popup popup--cancel">
    <v-card-title class="popup__title">
      Категории товаров
    </v-card-title>
    <v-card-actions class="popup__actions">
      <div v-for="(category, index) in newCategories" :key="index" class="popup__category">
        <app-input
          class="popup__input"
          mode="text"
          label="Категория"
          :value.sync="category"
          @change="onInput($event, index)"
        />
        <div
          class="popup__icon d-flex"
          v-html="require(`@/assets/img/icons/trash.svg`)"
          @click="deleteItemCategory(index)"
        />
      </div>
      <div class="popup__category">
        <app-button-circle
          icon="plus"
          class="popup__add"
          @click="addCategory"
        >
          Добавить еще категорию
        </app-button-circle>
      </div>
      <app-button
        class="popup__btn"
        mode="fill"
        @click="save"
      >
        Сохранить изменения
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Button from '@/components/UI-Kit/buttons/Button'
import Input from '@/components/UI-Kit/inputs/Input'
import ButtonCircle from '@/components/UI-Kit/buttons/ButtonCircle'

export default {
  name: 'PopupDeliveryCategories',
  components: {
    'app-button': Button,
    'app-input': Input,
    'app-button-circle': ButtonCircle
  },
  computed: {
    ...mapGetters([
      'popup'
    ]),
    clubId () {
      return this.$route.params.id || this.$attrs.id
    },
    data () {
      return this.popup.data
    }
  },
  data () {
    return {
      newCategories: []
    }
  },
  created () {
    this.newCategories = [...this.popup.data.categories]
  },
  methods: {
    ...mapActions([
      'hidePopup'
    ]),
    async save () {
      this.$store.dispatch(`${this.clubId}/delivery/setCategories`, this.newCategories)
      this.hidePopup()
    },
    deleteItemCategory (item) {
      this.newCategories = this.newCategories.filter((cat, index) => index !== item)
    },
    addCategory () {
      this.newCategories.push('')
    },
    onInput (e, index) {
      this.newCategories[index] = e
    }
  }
}
</script>

<style scoped lang="scss">
.popup {
  &__actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__category {
    width: 100%;
    display: flex;
    align-items: baseline;
  }
  &__icon {
    margin-left: 8px;
    cursor: pointer;
    stroke: #9E9E9E;
  }
  &__input, &__btn {
    width: 100%;
  }
  &__title {
    word-break: normal;
  }
  &__add {
  }
  &__btn {
    margin-top: 50px;
  }
}
</style>
