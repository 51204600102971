<template>
  <v-btn
    tag="a"
    :class="[
      `button-circle button-circle--${mode} body-1`,
      { 'active': state }
    ]"
    :disabled="disabled"
    :to="disabled ? '' : link"
    text
    @click="click"
  >
    <div
      class="button-circle__icon"
      v-html="require(`@/assets/img/icons/${icon}.svg`)"
    />
    <v-fade-transition
      v-if="mode === 'toggle' || mode === 'fill-toggle'"
      group
      leave-absolute
    >
      <p
        class="button-circle__message"
        v-if="!state"
        key="1"
      >
        <slot />
      </p>
      <p
        class="button-circle__message"
        v-else
        key="2"
      >
        {{ message }}
      </p>
    </v-fade-transition>

    <p
      v-else
      class="button-circle__message"
    >
      <slot />
    </p>
  </v-btn>
</template>

<script>
export default {
  name: 'ButtonCircle',
  props: {
    mode: {
      type: String,
      required: false,
      default: 'default',
      validator (value) {
        // Значение должно соответствовать одной из этих строк
        return ['default', 'toggle', 'fill', 'fill-toggle'].indexOf(value) !== -1
      }
    },
    icon: {
      type: String,
      required: false,
      default: 'plus'
    },
    defaultValue: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    link: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      active: false,
      message: 'Отменить добавление'
    }
  },
  computed: {
    state () {
      return this.defaultValue
    }
  },
  methods: {
    click () {
      if (this.mode === 'toggle' || this.mode === 'fill-toggle') {
        this.active = !this.active
        this.$emit('click')
      } else {
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="scss">
.button-circle {
  height: auto !important;
  padding: 0 !important;
  text-transform: none;
  color: $primary;
  background-color: transparent;
  transition: color $primary-transition, background-color $primary-transition, opacity $primary-transition;

  .button-circle__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-right: 15px;
    background-color: $accent;
    border-radius: 50%;
    transition: transform $primary-transition, background-color $primary-transition;

    svg {
      width: 50%;
      fill: $base;
      transition: transform $primary-transition;
    }
  }

  .button-circle__message {
    margin-bottom: 0;
  }

  &.v-btn.v-btn--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    color: $accent;

    svg {
      transform: scale(1.2);
    }
  }

  // &--toggle {
  //   .button-circle__icon {
  //     transform: rotate(-45deg);
  //   }

  //  &.active {
  //    .button-circle__icon {
  //      transform: rotate(45deg);
  //    }
  //  }
  // }

  &--fill,
  &--fill-toggle {
    background-color: $accent;
    color: $base !important;
    font-weight: 600 !important;

    .button-circle__icon {
      width: 50px;
      height: 50px;
      margin-right: 0;
    }

    .button-circle__message {
      padding-right: 15px;
    }

    &:hover {
      background-color: $accent-hover;

      .button-circle__icon {
        background-color: $accent-hover;
      }
    }
  }

  &.active {
    .button-circle__icon {
      transform: rotate(45deg);
    }
  }
}
@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .button-circle {
    .button-circle__icon {
      width: 50px;
      height: 50px;
    }
  }
}
</style>
