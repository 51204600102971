<template>
  <v-snackbar
    :top="true"
    :value="getAlert.value"
    class="alert"
    app
    :timeout="isBMSAdmin && getAlert.type=== 'error'? 0 : getAlert.timeout"
    @input="change($event)"
  >
    <div
      :class="`alert__icon alert__icon--${getAlert.type}`"
      v-html="require(`@/assets/img/icons/${getAlert.type}.svg`)"
      @click="hideAlert"
    />
    <div>
<!--     defalut message-->
    <span class="alert__text" v-html="getAlert.text"></span>
<!--     defalut message-->

<!--     serverError message for BMSAdmin-->
          <span v-if="isBMSAdmin && Object.keys(getAlert.serverError).length" >
              <h1 v-if="!!getAlert.serverError.config"  class="alert__text" > url: {{ getAlert.serverError.config.url }} </h1>
              <h1 v-if="!!getAlert.serverError.status"  class="alert__text" > status: {{ getAlert.serverError.status }} </h1>
              <h1 v-if="!!getAlert.serverError.data.code"  class="alert__text" > code: {{ getAlert.serverError.data.code }} </h1>
              <pre class="alert__text" v-if="!!getAlert.serverError.data.cause">
                 {{getAlert.serverError.data.cause}}
              </pre>
          </span>
<!--           serverError message for BMSAdmin-->
    </div>
    <div
      class="alert__close"
      v-html="require(`@/assets/img/icons/del.svg`)"
      @click="hideAlert"
    />
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Alert',
  components: {},

  computed: {
    ...mapGetters([
      'getAlert',
      'isBMSAdmin'
    ])
  },
  methods: {
    ...mapActions([
      'hideAlert'
    ]),
    change (e) {
      if (!e) {
        this.hideAlert()
      }
    }
  }
}
</script>

<style lang="scss">
  .alert {
    overflow: hidden;
    &__text {
      font-size: 17px;
      padding:.5em 1em;
      max-width: 100%;
      white-space: pre-wrap;
      word-break: break-all;
      word-wrap: break-word;
    }
    &__icon {
      width: 36px;
      height: 36px;
      border: 1px solid;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      flex-grow: 0;
      flex-shrink: 0;
      &--error {
        color: $accent-disabled;
        svg {
          fill: $accent;
        }
      }
      &--success {
        color: $success-disabled;
        svg {
          fill: $success;
        }
      }
      &--new {
        color: $wait;
        svg {
          fill: $wait;
        }
      }
    }
    &__close {
      margin-top: 2px;
      margin-left: auto;
      cursor: pointer;
      svg {
        width: 12px;
        height: 12px;
        fill: $icon;
        transition: fill $primary-transition;
      }
      &:hover {
        svg {
          fill: $base;
        }
      }
    }
  }
  .alert {
    .v-snack__wrapper {
      max-width: 100%;
    }
    .v-snack__content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

</style>
