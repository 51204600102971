<template>
  <v-card class="popup popup--bonuses">
    <v-card-title class="popup__title">
      Порог бонусирования по карте
    </v-card-title>

    <v-card-text class="popup__content">
      <v-simple-table>
        <thead v-if="$vuetify.breakpoint.mdAndUp">
          <tr>
            <th
              v-for="th in headers"
              :key="th.value"
              :class="[{ 'text-right': th.value === 'percent' }]"
            >
              {{ th.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in data"
            :key="i"
          >
            <td
              v-for="td in headers"
              :key="td.value"
            >
              <span v-if="$vuetify.breakpoint.smAndDown && td.value !== 'retail'">{{ td.text }}</span>
              <template v-if="['percent'].indexOf(td.value) !== -1">
                <app-input-quantity
                  mode="number"
                  :value.sync="item[td.value]"
                />
              </template>
              <template v-else>
                {{ item[td.value] }}
              </template>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <app-button
        class="save-button"
        :disabled="disabled"
        @click="save"
      >
        Сохранить
      </app-button>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InputQuantity from '@/components/UI-Kit/inputs/InputQuantity'
import Button from '@/components/UI-Kit/buttons/Button'

export default {
  name: 'PopupBonuses',
  components: {
    'app-input-quantity': InputQuantity,
    'app-button': Button
  },
  data () {
    return {
      data: {},
      headers: [
        { text: 'Сеть', value: 'retail' },
        { text: 'Сумма (₽)', value: 'sum' },
        { text: 'Ставка (%)', value: 'percent' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'popup'
    ]),
    clubId () {
      return this.$route.params.id
    },
    disabled () {
      if (this.data[0]) {
        return this.data[0].percent === this.popup.data[0].percent
      } else return false
    }
  },
  mounted () {
    this.data = JSON.parse(JSON.stringify(this.popup.data))
  },
  methods: {
    ...mapActions([
      'editData',
      'hidePopup'
    ]),
    async save () {
      await this.$store.dispatch(`${this.clubId}/participants/editCardHistory`, {
        percent: +this.data[0].percent,
        clubId: this.clubId
      })
      this.hidePopup()
    }
  }
}
</script>

<style lang="scss">
.popup--bonuses {
  height: 70vh;
  max-height: 581px !important;
  .popup__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .popup__title {
    margin-bottom: 0 !important;
  }
  .v-data-table {
    & > .v-data-table__wrapper {
      overflow: visible;
      & > table {
        & > thead {
          & > tr {
            & > th {
              height: auto;
              padding: 0 10px 5px 0;
              font-size: $font-14;
              font-weight: 400;
              color: $icon !important;
              border-bottom: 1px solid $separator;
              &:first-child {
                width: 40%;
              }
              &:last-child {
                padding-right: 0;
                width: 30%;
              }
            }
          }
        }
        & > tbody {
          & > tr {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            padding-bottom: 10px;
            border-bottom: 1px solid $separator;
            & > td {
              height: auto;
              padding: 10px 10px 0 0;
              font-size: $font-14;
              font-weight: 400;
              border: none !important;
              span {
                font-size: $font-12;
                color: $icon;
              }
              &:first-child {
                grid-column-start: 1;
                grid-column-end: 3;
              }
              &:last-child {
                padding-right: 0;
                padding-left: 10px;
              }
            }
            &:last-child {
              border-bottom: none;
            }
            &:hover {
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }
  .save-button {
    margin: 0 auto;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .popup--bonuses {
    .popup__title {
      margin-bottom: 20px !important;
    }
    .v-data-table {
      & > .v-data-table__wrapper {
        & > table {
          & > tbody {
            & > tr {
              display: table-row;
              border-bottom: none;
              & > td {
                display: table-cell;
                font-size: $font-17;
              }
            }
          }
        }
      }
    }
  }
}
</style>
