<template>
  <v-card class="popup popup--push">
    <v-card-title class="popup__title">
      Просмотр рассылки в мессенджеры
    </v-card-title>

    <v-card-text class="popup__content">
      <app-club-push-messengers-popup :item="data" />
    </v-card-text>

  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ClubMessengersPushPopup from '@/components/Club/ClubContent/ClubPush/MessengersPopup'
// import Button from '@/components/UI-Kit/buttons/Button'

export default {
  name: 'PopupMessengersPush',
  components: {
    'app-club-push-messengers-popup': ClubMessengersPushPopup
    // 'app-button': Button
  },
  data () {
    return {
      sendNow: false
    }
  },
  computed: {
    ...mapGetters([
      'popup',
      'isWritePermission'
    ]),
    data () {
      return this.popup.data
    },
    clubId () {
      return this.$route.params.id
    },
    storePath () {
      return `${this.clubId}/push`
    }
  },
  methods: {
    sendPush () {
      if (this.sendNow) {
        console.log('send push now')
      }
      console.log('send push')
    },
    cancelPush () {
      console.log('cancel push')
    }
  }
}
</script>

<style lang="scss">
</style>
