import { server } from './index'

let authApi = {
  /**
   * Login user
   * @param creds
   * @param creds.login String
   * @param creds.password String
   * @returns {Promise<AxiosResponse<T>>}
   */
  login (creds) {
    return server.post('/auth/login', creds)
  },
  /**
   * Logout user
   * @param creds
   * @param creds.login String Login
   * @param creds.refreshToken String Refresh token
   * @returns {Promise<AxiosResponse<T>>}
   */
  logout (creds) {
    return server.post('/auth/logout')
  }
}

export { authApi }
