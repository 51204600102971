<template>
  <div :class="{'dashboard__data' : !report}">
    <template
      v-if="report"
    >
      <div class="reports__row">
        <span>Начислено бонусов:</span>
        <span>{{ bonusSum | roundTo2 }}</span>
      </div>
    </template>
    <template
      v-else
    >
      <app-dashboard-num
        :number="bonusSum ? bonusSum : 0"
        :comma="true"
        arrow="top"
        icon="bonus-icon"
        color="#26BC22"
      />
    </template>
  </div>
</template>

<script>
import DashboardNum from '@/components/Club/ClubDashboards/DashboardNum'

export default {
  name: 'ClubDashboardsModulePurchasesAccruedBonuses',
  props: {
    report: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: [Number, String],
      require: false
    }
  },
  components: {
    'app-dashboard-num': DashboardNum
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    bonusSum () {
      return this.$store.getters[`${this.clubId}/dashboards/getBonusSum`]
    }
  }
}
</script>
