<template>
  <div class="report">
    <div class="report__top">
      <div class="report__right">
        <div class="report__title">
          BMSGroup
        </div>
      </div>
      <div class="report__left">
        <span>
          +7 (812) 322-10-10
          <span class="report__gray">
            (тех. поддержка)
          </span>
        </span>
        <span>
          HelpDesk@groupbms.ru
        </span>
      </div>
    </div>
    <div class="report__bot">
      <div class="report__row">
        <span class="report__subtitle">
          Отчет сформирован:&nbsp;
        </span>
        <span>
          {{ today }}
        </span>
      </div>
      <div class="report__row">
        <span class="report__subtitle">
          За период:&nbsp;
        </span>
        <span>
          от {{ requestData.date.from }} до {{ requestData.date.to }}
        </span>
      </div>
      <div class="report__row">
        <span class="report__subtitle">
          Торговая сеть:&nbsp;
        </span>
        <span>
          {{ clubName }}
        </span>
      </div>
      <div class="report__row" v-if="pointNamesString">
        <span class="report__subtitle">
          Точка обслуживания:&nbsp;
        </span>
        <span>
          {{ pointNamesString }}
        </span>
      </div>
      <div class="report__row" v-if="chart">
        <span class="report__subtitle">
          График:&nbsp;
        </span>
        <span>
          {{ chart }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'ClubDashboardsReportHeader',
  props: {
    requestData: {
      type: Object,
      require: true
    },
    chart: {
      type: String,
      require: false,
      default: ''
    },
    id: {
      type: [Number, String],
      require: false
    }
  },
  data () {
    return {
      today: dayjs().format('YYYY-MM-DD HH:mm'),
      structure: { id: '', points: [], terminals: [] },
      pointNames: [],
      pointNamesString: ''
    }
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    remoteClub () {
      return this.$store.getters[`${this.clubId}/global/getRemoteClub`]
    },
    allPoints () {
      return this.$store.getters[`${this.clubId}/global/getPoints`]
    },
    allRetails () {
      return this.$store.getters[`${this.clubId}/global/getRetails`]
    },
    clubName () {
      if (this.structure.id) {
        return this.allRetails.find(retail => retail.id === this.structure.id).title
      }
      return this.remoteClub.title
    }
  },
  created () {
    this.update()
  },
  watch: {
    requestData: {
      handler () {
        this.update()
      },
      deep: true
    }
  },
  methods: {
    update () {
      this.terminalToStructures()
      this.getPointNames()
      this.getPointNamesString()
    },
    terminalToStructures () {
      const points = []
      let retailId
      this.allPoints.forEach(point => {
        this.requestData.structureData.ids.forEach(terminal => {
          if (point.terminals[0].id === terminal) {
            points.push(point.id)
            retailId = point.networkId
          }
        })
      })
      if (points.length !== this.allPoints.length) {
        this.structure = {
          id: retailId,
          points,
          terminals: this.requestData.structureData.ids
        }
      }
    },
    getPointNames () {
      this.pointNames = this.allPoints.filter(el => this.structure.points.includes(el.id)).map(el => el.title)
    },
    getPointNamesString () {
      const allClubPoints = this.allPoints.filter(el => el.networkId === this.structure.id)
      if (this.pointNames.length) {
        if (allClubPoints.length !== this.pointNames.length) {
          this.pointNamesString = ''
          this.pointNames.forEach((el) => {
            this.pointNamesString += el + ', '
          })
          this.pointNamesString = this.pointNamesString.slice(0, -2)
        } else {
          this.pointNamesString = 'Все точки'
        }
      } else {
        this.pointNamesString = ''
      }
    }
  }
}
</script>

<style lang="scss">
.report {
  display: flex;
  flex-direction: column;
  padding: 20px 30px 0;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__bot {
    margin-top: 20px;
  }
  &__gray {
    color: #9E9E9E;
  }
  &__left {
    display: flex;
    flex-direction: column;
    color: #D24646;
  }
  &__row {
    display: flex;
    font-size: 17px;
    line-height: 21px;
    & + & {
      margin-top: 5px;
    }
  }
  &__title {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
  }
  &__subtitle {
    font-weight: 600;
  }
}
</style>
