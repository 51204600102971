import reports from '@/json/reports/reports.json'
import { autoReportsApi } from '@/api/autoreports'

export default {
  namespaced: true,
  state: () => ({
    autoReports: [],
    loading: false
  }),
  mutations: {
    'SET_AUTO_REPORTS' (state, data) {
      state.autoReports = data.reverse()
    },
    'EDIT_AUTO_REPORT' (state, data) {
      const { id } = data
      state.autoReports.map(r => {
        if (r.id === id) {
          state.autoReports[id - 1] = { ...data }
        }
      })
    },
    'SET_LOADING' (state, data) {
      state.loading = data
    }
  },
  actions: {
    async addAutoReport ({ commit }, report) {
      const { emails, time, period, club, name, params, sending, title } = report
      if (name === 'RASB') {
        params.juridicalId = params.juridical[0]
        delete params.juridical
      }
      try {
        await autoReportsApi.addAutoReport({
          emails,
          time: +(time.slice(0, -3)),
          period,
          params,
          club,
          name,
          sending,
          title
        })
        this.dispatch('showAlert', { text: 'Отправка успешно создана.', type: 'success' })
      } catch (e) {
        this.dispatch('showAlert', { text: 'Ошибка создания. Попробуйте повторить попытку.', type: 'error' })
      }
    },
    async editAutoReport ({ commit }, report) {
      const { _id, emails, time, period, params, sending, title } = report
      try {
        await autoReportsApi.editAutoReport(_id, {
          emails,
          time: typeof time === 'string' ? +(time.slice(0, -3)) : time,
          period,
          params,
          sending,
          title
        })
        // commit('EDIT_AUTO_REPORT', report)
        this.dispatch('showAlert', { text: 'Отправка успешно изменена.', type: 'success' })
      } catch (e) {
        this.dispatch('showAlert', { text: 'Ошибка редактирования. Попробуйте повторить попытку.', type: 'error' })
      }
    },
    async removeAutoReport ({ commit }, id) {
      try {
        await autoReportsApi.removeReport(id)
        this.dispatch('showAlert', { text: 'Отправка успешно удалена.', type: 'success' })
      } catch (e) {
        this.dispatch('showAlert', { text: 'Ошибка удаления. Попробуйте повторить попытку.', type: 'error' })
      }
    },
    async getAutoReports ({ commit }, id) {
      commit('SET_LOADING', true)
      try {
        const res = await autoReportsApi.getAutoReports(id)
        res.data.map(item => {
          item.reportType = reports.filter(r => r.value === item.name)[0].text
        })
        commit('SET_AUTO_REPORTS', res.data)
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async getAutoReport ({ commit }, reportId) {
      commit('SET_LOADING', true)
      try {
        const res = await autoReportsApi.searchReport(reportId)
        res.data.reportType = reports.filter(r => r.value === res.data.name)[0].text
        commit('SET_AUTO_REPORTS', [res.data])
        return res.data
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async repeatAutoReport ({ commit }, id) {
      commit('SET_LOADING', true)
      try {
        const res = await autoReportsApi.repeatReport(id)
        if (res) {
          this.dispatch('showAlert', { text: 'Отчет успешно отправлен.', type: 'success' })
        } else {
          this.dispatch('showAlert', { text: 'Ошибка повторной отправки.', type: 'error' })
        }
      } catch (e) {
        this.dispatch('showAlert', { text: 'Ошибка повторной отправки.', type: 'error' })
      } finally {
        commit('SET_LOADING', false)
      }
    }
  },
  getters: {
    getAutoReports: s => s.autoReports,
    getAutoReportById: s => id => {
      return s.autoReports.find(r => r._id === id)
    },
    getAutoReportsByFilter: s => type => {
      if (typeof type !== 'undefined') {
        return s.autoReports.filter(r => r.reportType === type)
      } else return s.autoReports
    },
    getLoading: s => s.loading
  }
}
