<template>
  <v-list
    class="nav-list overflow-y-auto"
    dense
  >
    <template>
      <nav-list-item
        v-for="route in routes"
        :key="route.name"
        :item="route.meta"
        :link="route.path"
        :active="$route.name === route.name || ('children' in route && route.children.some(el => el.name === $route.name))"
        mode="parent"
      />
    </template>
  </v-list>
</template>

<script>
import NavListItem from '@/components/nav/components/NavListItem'

export default {
  name: 'NavListModules',
  components: {
    'nav-list-item': NavListItem
  },
  props: {
    routes: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
</style>
