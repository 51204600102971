<template>
  <v-card class="popup popup--cancel">
    <v-card-title class="popup__title">
      Редактирование заголовка модуля
    </v-card-title>
    <v-card-actions class="popup__actions">
      <app-input
        class="popup__input"
        mode="text"
        label="Заголовок модуля*"
        :value.sync="_value"
      />
      <app-button
        class="popup__btn"
        mode="fill"
        :disabled="!newName || newName === data.name"
        @click="save"
      >
        Сохранить изменения
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Button from '@/components/UI-Kit/buttons/Button'
import Input from '@/components/UI-Kit/inputs/Input'

export default {
  name: 'PopupDashboardsName',
  components: {
    'app-button': Button,
    'app-input': Input
  },
  data () {
    return {
      newName: null
    }
  },
  computed: {
    ...mapGetters([
      'popup'
    ]),
    data () {
      return this.popup.data
    },
    _value: {
      get () {
        return this.data.name
      },
      set (newVal) {
        this.newName = newVal
      }
    }
  },
  methods: {
    ...mapActions([
      'hidePopup'
    ]),
    async save () {
      await this.$store.dispatch(`${this.data.clubId}/dashboards/editDashboardTitle`, {
        moduleId: this.data.moduleId,
        clubId: this.data.clubId,
        userId: this.data.userId,
        club: this.data.club,
        name: this.newName
      })
      this.hidePopup()
    }
  }
}
</script>

<style scoped lang="scss">
.popup {
  &__actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__input, &__btn {
    width: 100%;
  }
  &__title {
    word-break: normal;
  }
}
</style>
