import clubModule from '@/store/modules/clubModule'

const getDefaultState = () => {
  return {
    isRegisteredModules: false,
    modules: []
  }
}

const state = getDefaultState()

const mutations = {
  SET_MODULES (state, data) {
    state.modules.push(data)
  },
  SET_REGISTERED_MODULES (state, value) {
    state.isRegisteredModules = value
  },
  RESET_REGISTER_MODULES (state) {
    state.modules.forEach(club => {
      this.unregisterModule(club.toString())
    })
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  registerModules (store, clubs) {
    if (!store.state.isRegisteredModules) {
      store.dispatch('setClubs', clubs)
      clubs.forEach(club => {
        if (!this.state[club.id.toString()]) {
          this.registerModule(club.id.toString(), clubModule)
          store.commit('SET_MODULES', club.id)
          store.dispatch(`${club.id}/global/setClubData`, club)
        }
      })
      store.commit('SET_REGISTERED_MODULES', true)
      return true
    }
  },
  registerModule (store, club) {
    if (!this.state[club.id.toString()]) {
      this.registerModule(club.id.toString(), clubModule)
      store.commit('SET_MODULES', club.id)
      store.dispatch(`${club.id}/global/setClubData`, club)
    }
    return true
  }
}

const getters = {
  isRegisteredModules: state => state.isRegisteredModules
}

export default {
  state,
  mutations,
  actions,
  getters
}
