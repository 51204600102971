<template>
  <v-simple-table class="push-popup">
    <template v-slot:default>
      <tbody>
      <tr
        v-for="header in headers"
        :key="header.value"
      >
        <td>
          {{ header.text }}
        </td>
        <td v-if="['startDate'].indexOf(header.value) !== -1">
          {{ item[header.value] | dateISO }}
        </td>
        <td v-if="['message'].indexOf(header.value) !== -1">
          {{ item[header.value] }}
        </td>
        <td v-if="['userSearchParams'].indexOf(header.value) !== -1">
          <code>{{ item.userSearchParams }}</code>
        </td>
        <td v-if="['status'].indexOf(header.value) !== -1">
          {{ item.status ? 'отправлено' : 'отправка не удалась' }}
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>

export default {
  name: 'ClubPushPopupMessengers',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      headers: [
        { text: 'Дата отправки', value: 'startDate' },
        { text: 'Сообщение', value: 'message' },
        { text: 'Настройки', value: 'userSearchParams' },
        { text: 'Статус', value: 'status' }
      ],
      sendNow: false,
      jsonData: ''
    }
  },
  methods: {
    userSearchParamsToJson (item) {
      this.jsonData = JSON.stringify(item.userSearchParams, null, 4)
    }
  }
}
</script>

<style lang="scss">
.push-popup {
  .v-data-table__wrapper {
    table {
      tbody {
        tr {
          td {
            display: block;
            height: auto;
            padding-bottom: 10px;
            font-size: $font-14;
            line-height: $font-17;
            vertical-align: top;
            border-bottom: none !important;
            &:first-child {
              padding-left: 0 !important;
              padding-bottom: 0 !important;
              color: $icon;
            }
            &:last-child {
              padding-left: 0 !important;
              padding-right: 0 !important;
            }
          }
          &:last-child {
            td {
              padding-bottom: 0;
            }
          }
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .push-popup {
    .v-data-table__wrapper {
      table {
        tbody {
          tr {
            td {
              display: table-cell;
              font-size: $font-17;
              line-height: $font-21;
              &:first-child {
                width: 39%;
                padding-bottom: 10 !important;
                color: $primary;
              }
              &:last-child {
                padding-left: 16px !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
