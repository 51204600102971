<template>
  <a
    class="link"
    :class="{ 'disabled': !link.length }"
    :href="link"
  >
    <div
      v-if="icon.length"
      class="link__icon d-flex"
      v-html="require(`@/assets/img/icons/${icon}.svg`)"
    />
    <slot />
  </a>
</template>

<script>
export default {
  name: 'Link',
  props: {
    link: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.link {
  display: inline-flex;
  align-items: center;
  color: $accent;
  text-decoration: none;
  white-space: nowrap;
  transition: color $primary-transition;

  .link__icon {
    margin-right: 6px;
  }

  &:hover {
    color: $primary;
  }

  &.disabled {
    color: $primary;
    pointer-events: none;
  }
}
</style>
