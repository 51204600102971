import { dashboardsApi } from '@/api/dashboards'
export default {
  namespaced: true,
  state: () => ({
    usersCount: 0,
    todayUsersCount: 0,
    newUsersCount: 0,
    mobileUsersCount: 0,
    todayMobileUsersCount: 0,
    androidUsers: 0,
    iosUsers: 0,
    harmonyUsers: 0,
    purchases: 0,
    billSum: 0,
    bonusSum: 0,
    spendBonusSum: 0,
    averageBillSum: 0,
    averageBillSumForLastPeriod: 0,
    averageBillByDay: 0,
    userModules: null,
    purchasesByDay: [],
    popularPoints: [],
    loading: true,
    moduleNames: [
      { text: 'users', value: 'Программа лояльности' },
      { text: 'purchases', value: 'Оборот средств' },
      { text: 'mobile', value: 'Мобильное приложение' },
      { text: 'polls', value: 'Опросы' },
      { text: 'parking', value: 'Парковка' }
    ],
    dashboardPage: 'app-dashboard-main', // app-dashboard-main | app-dashboard-detail
    detailParams: {
      name: '',
      component: '',
      id: ''
    }
  }),
  mutations: {
    SET_USER_MODULES (state, data) {
      state.userModules = JSON.parse(JSON.stringify(data))
    },
    'SET_USERS_COUNT' (state, count) {
      state.usersCount = count
    },
    SET_TODAY_USERS_COUNT (state, count) {
      state.todayUsersCount = count
    },
    SET_NEW_USERS_COUNT (state, data) {
      state.newUsersCount = data
    },
    SET_MOBILE_USERS (state, data) {
      let count = 0
      data.forEach(item => {
        count = count + item.count
      })
      state.mobileUsersCount = count
      state.androidUsers = data.filter(i => i.push_type.toLowerCase() === 'android')[0].count
      state.iosUsers = data.filter(i => i.push_type.toLowerCase() === 'ios')[0].count
    },
    SET_TODAY_MOBILE_USERS (state, data) {
      state.todayMobileUsersCount = data
    },
    SET_PURCHASES (state, data) {
      state.purchases = data
    },
    SET_BILL_SUM (state, data) {
      state.billSum = data
    },
    SET_BONUS_SUM (state, data) {
      state.bonusSum = data
    },
    SET_SPEND_BONUS_SUM (state, data) {
      state.spendBonusSum = data
    },
    SET_AVERAGE_BILL_SUM (state, data) {
      state.averageBillSum = data
    },
    SET_AVERAGE_BILL_SUM_FOR_LAST_PERIOD (state, data) {
      state.averageBillSumForLastPeriod = data
    },
    SET_AVERAGE_BILL_BY_DAY (state, data) {
      state.averageBillByDay = data
    },
    SET_MODULES (state, data) {
      state.modules = data
    },
    SET_LOADING (state, data) {
      state.loading = data
    },
    SET_PURCHASES_BY_DAY (state, data) {
      state.purchasesByDay = data
    },
    SET_POPULAR_POINTS (state, data) {
      state.popularPoints = data
    },
    SET_DASHBOARD_PAGE (state, data) {
      state.dashboardPage = data
    },
    SET_DETAIL_PARAMS (state, data) {
      state.detailParams = data
    }
  },
  actions: {
    async setUserDashboards ({ commit, state }, data) {
      try {
        const res = await dashboardsApi.getUserDashboards(data)
        commit('SET_USER_MODULES', res.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async editUserDashboards ({ commit, state }, data) {
      try {
        const res = await dashboardsApi.editUserDashboards({
          club: data.club,
          clubId: data.clubId,
          userId: data.userId,
          modules: data.modules
        })
        commit('SET_USER_MODULES', res.data.modules)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async editDashboardTitle ({ commit, state }, data) {
      try {
        const modules = JSON.parse(JSON.stringify(state.userModules))
        for (let group in modules) {
          modules[group].forEach(item => {
            if (item.id === data.moduleId) {
              item.customName = data.name
            }
          })
        }
        const res = await dashboardsApi.editUserDashboards({
          club: data.club,
          clubId: data.clubId,
          userId: data.userId,
          modules
        })
        commit('SET_USER_MODULES', res.data.modules)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async loadDashboards ({ commit, dispatch }, { clubId, data, activeModules }) {
      commit('SET_LOADING', true)
      try {
        for (let group in activeModules) {
          await Promise.all(activeModules[group].map(async module => {
            await dispatch(`load${module.id.charAt(0).toUpperCase() + module.id.slice(1)}`, { clubId, data })
          }))
        }
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async loadUserCount ({ commit }, { clubId }) {
      try {
        const count = await dashboardsApi.usersCount(clubId)
        const todayCount = await dashboardsApi.todayUsersCount(clubId)
        commit('SET_USERS_COUNT', count.data)
        commit('SET_TODAY_USERS_COUNT', todayCount.data)
      } catch (e) {
        console.log(e)
      }
    },
    async loadNewUsers ({ commit }, { clubId, data }) {
      try {
        const count = await dashboardsApi.newUsersCount(clubId, data)
        commit('SET_NEW_USERS_COUNT', count.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async loadMobileUsers ({ commit }, { clubId }) {
      try {
        const count = await dashboardsApi.mobileUsersCount(clubId)
        const todayCount = await dashboardsApi.todayMobileUsersCount(clubId)
        commit('SET_MOBILE_USERS', count.data)
        commit('SET_TODAY_MOBILE_USERS', todayCount.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async loadUserPurchases ({ commit }, { clubId, data }) {
      try {
        const count = await dashboardsApi.purchases(clubId, data)
        const countByDay = await dashboardsApi.purchasesByDay(clubId, data)
        commit('SET_PURCHASES', count.data)
        commit('SET_PURCHASES_BY_DAY', countByDay.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async loadPurchasesAmount ({ commit }, { clubId, data }) {
      try {
        const sum = await dashboardsApi.billSum(clubId, data)
        commit('SET_BILL_SUM', sum.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async loadAccruedBonuses ({ commit }, { clubId, data }) {
      try {
        const sum = await dashboardsApi.bonusSum(clubId, data)
        commit('SET_BONUS_SUM', sum.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async loadSpentBonuses ({ commit }, { clubId, data }) {
      try {
        const sum = await dashboardsApi.spendBonusSum(clubId, data)
        commit('SET_SPEND_BONUS_SUM', sum.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async loadAverageCheck ({ commit }, { clubId, data }) {
      try {
        const averageCheck = await dashboardsApi.averageBill(clubId, data)
        const averageCheckByDay = await dashboardsApi.averageBillByDay(clubId, data)
        const lastAverageCheck = await dashboardsApi.averageBillForLastPeriod(clubId, data)
        commit('SET_AVERAGE_BILL_SUM', averageCheck.data)
        commit('SET_AVERAGE_BILL_BY_DAY', averageCheckByDay.data)
        commit('SET_AVERAGE_BILL_SUM_FOR_LAST_PERIOD', lastAverageCheck.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async loadPopularPoints ({ commit }, clubId) {
      try {
        const res = await dashboardsApi.pointsWithSpendEarn(clubId)
        commit('SET_POPULAR_POINTS', res.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    }
  },
  getters: {
    getUsersCount: (state) => state.usersCount,
    getTodayUsersCounts: state => state.todayUsersCount,
    getNewUsersCount: state => state.newUsersCount,
    getPurchases: state => state.purchases,
    getMobileUsersCount: state => state.mobileUsersCount,
    getTodayMobileUsersCount: state => state.todayMobileUsersCount,
    getAndroidUsers: state => state.androidUsers,
    getIosUsers: state => state.iosUsers,
    getBillSum: state => state.billSum,
    getBonusSum: state => state.bonusSum,
    getSpendBonusSum: state => state.spendBonusSum,
    getAverageBillSum: state => state.averageBillSum,
    getAverageBillSumForLastPeriod: state => state.averageBillSumForLastPeriod,
    getAverageBillByDay: state => state.averageBillByDay,
    getModules: state => state.modules,
    getLoading: state => state.loading,
    getPurchasesByDay: state => state.purchasesByDay,
    getPopularPoints: state => state.popularPoints,
    getUserModules: state => state.userModules,
    getActiveUserModules: state => {
      const activeUserModules = {}
      if (state.userModules) {
        for (let module in state.userModules) {
          let activeModules = state.userModules[module].filter(module => module.enable)
          if (activeModules.length) {
            activeUserModules[module] = activeModules
          }
        }
      }
      return activeUserModules
    },
    getModuleName: state => module => {
      return state.moduleNames.filter(mod => mod.text === module)[0].value
    },
    getDashboardPage: state => state.dashboardPage,
    getDetailParams: state => state.detailParams
  }
}
