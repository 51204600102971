import { server } from './index'

let autoReportsApi = {
  addAutoReport (data) {
    return server.post('/autoreports/add', data)
  },
  editAutoReport (reportId, data) {
    return server.patch(`/autoreports/edit/${reportId}`, data)
  },
  getAutoReports (clubId) {
    return server.get(`/autoreports/${clubId}`)
  },
  searchReport (reportId) {
    return server.get(`/autoreports/search/${reportId}`)
  },
  removeReport (reportId) {
    return server.delete(`/autoreports/delete/${reportId}`)
  },
  repeatReport (reportId) {
    return server.post(`/autoreports/repeat/${reportId}`)
  }
}

export { autoReportsApi }
