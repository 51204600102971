import { promotionsApi } from '@/api/promotions'
import Vue from 'vue'
import * as codeRu from '@/json/promotions/code.json'
import * as operationType from '@/json/promotions/operationType.json'

// function timeout (ms) {
//   return new Promise(resolve => setTimeout(resolve, ms))
// }

function checkValues (values) {
  let v = false
  values.forEach(el => {
    if (el && (el.length || Object.keys(el))) v = true
  })
  return v
}

const getDefaultState = () => {
  return {}
}

export default {
  namespaced: true,
  state: () => ({
    templates: [],
    promotions: [],
    promotion: {},
    operations: operationType,
    page: {
      page: 0,
      size: 25,
      sort: [{
        'direction': 'DESC',
        'property': 'insDate'
      }]
    },
    search: {
      insDate: {},
      templateIds: [],
      retailPointIds: [],
      title: '',
      description: '',
      ids: [],
      status: ''
    },
    status: '',
    statusAll: '',
    statusTable: '',
    statusPage: '',
    statusEdit: '',
    totalElements: 0,
    timeoutTime: 1000,
    openNetwork: [],
    activePoints: [],
    treeview: 'structure'
  }),
  mutations: {
    LOAD_TEMPLATES (state, data) {
      if (!state.templates.length) {
        data.map(tmp => {
          tmp.treeType = 'template'
          tmp.treeId = 'template' + tmp.id
          tmp.title = codeRu.default[tmp.code]
          return tmp.parameters.sort((a, b) => {
            if (a.sortOrder > b.sortOrder) {
              return 1
            } else return -1
          })
        })
        state.templates = data
      }
    },
    LOAD_PROMOTIONS (state, data) {
      if (!data) {
        state.promotions = []
        state.totalElements = 0
      } else {
        if (state.page.page === 0) state.promotions = data.content
        else state.promotions.push(...data.content)
        state.totalElements = data.totalElements
      }
    },
    LOAD_PROMOTION (state, data) {
      state.promotion = data
    },
    ADD_PROMOTION (state, data) {
      state.promotions.unshift(data)
    },
    EDIT_PROMOTION (state, data) {
      let index = state.promotions.findIndex(item => item.id === data.id)
      if (index >= 0) Vue.set(state.promotions, index, data)
    },
    // EDIT_VALUES (state, payload) {
    //   let index = state.promotions.findIndex(item => item.id === payload.id)
    //   if (index >= 0) {
    //     payload.data.forEach(value => {
    //       let indexValue = state.promotions[index].values.findIndex(item => {
    //         return item.valueId === value.valueId
    //       })
    //       Vue.set(state.promotions[index].values, indexValue, value)
    //     })
    //   }
    // },
    REMOVE_PROMOTION (state, { page, data }) {
      state.promotions.splice(state.page.size * page, state.page.size, ...data.content)
      state.totalElements = data.totalElements
    },
    REMOVE_PROMOTIONS (state, data) {
      state.promotions = data.content
      state.totalElements = data.totalElements
    },
    SET_SEARCH (state, data) {
      data.forEach(item => {
        Vue.set(state.search, item.key, item.value)
        if (state.treeview === 'structure') {
          state.search.templateIds = []
        } else if (state.treeview === 'templates') {
          state.search.retailPointIds = []
        }
      })
    },
    CLEAR_SEARCH (state) {
      Vue.set(state.search, 'insDate', {})
      Vue.set(state.search, 'templateIds', [])
      Vue.set(state.search, 'retailPointIds', [])
      Vue.set(state.search, 'title', '')
      Vue.set(state.search, 'ids', [])
      Vue.set(state.search, 'status', '')
    },
    CLEAR_FILTER (state) {
      Vue.set(state.search, 'status', '')
    },
    SET_STATUS (state, status) {
      state.status = status
    },
    SET_STATUS_ALL (state, status) {
      state.statusAll = status
    },
    SET_STATUS_TABLE (state, status) {
      state.statusTable = status
    },
    SET_STATUS_PAGE (state, status) {
      state.statusPage = status
    },
    SET_STATUS_EDIT (state, status) {
      state.statusEdit = status
    },
    SET_PAGE (state, page) {
      if (page === undefined) state.page.page++
      else state.page.page = page
    },
    SET_SORT (state, prop) {
      state.page.sort[0] = { ...state.page.sort[0], ...prop }
    },
    CLEAR (state) {
      Object.assign(state, getDefaultState())
    },
    SET_OPEN_NETWORK (state, data) {
      state.openNetwork = [data]
    },
    SET_ACTIVE_POINTS (state, data) {
      state.activePoints = data
    },
    SET_TREEVIEW (state, data) {
      state.treeview = data
    }
  },
  actions: {
    loadAll ({ commit, dispatch }, clubId) {
      commit('SET_STATUS_ALL', 'loading')
      commit('LOAD_PROMOTIONS')
      let p1 = this.dispatch(`${clubId}/global/setRetails`, clubId)
      let p2 = this.dispatch(`${clubId}/global/setPoints`, clubId)
      let p3 = dispatch('loadTemplates', clubId)
      let p4 = dispatch('loadPromotions', clubId)
      let p5 = dispatch('timeout')
      Promise.all([p1, p2, p3, p4, p5]).then(values => {
        if (checkValues(values) && values[3].content) {
          commit('LOAD_PROMOTIONS', values[3])
          commit('SET_STATUS_ALL', '')
        } else commit('SET_STATUS_ALL', '500')
      }, reason => commit('SET_STATUS_ALL', '500'))
    },
    loadTable ({ commit, dispatch }, clubId) {
      commit('SET_STATUS_TABLE', 'loading')
      commit('LOAD_PROMOTIONS')
      commit('SET_PAGE', 0)
      commit('CLEAR_FILTER')
      let p1 = dispatch('loadPromotions', clubId)
      let p2 = dispatch('timeout')
      Promise.all([p1, p2]).then(values => {
        if (values[0] && values[0].content && values[0].content.length) {
          commit('LOAD_PROMOTIONS', values[0])
          commit('SET_STATUS_TABLE', '')
        } else commit('SET_STATUS_TABLE', '404')
      }, reason => commit('SET_STATUS_TABLE', '500'))
    },
    loadPage ({ commit, dispatch }, clubId) {
      commit('SET_STATUS_PAGE', 'loading')
      dispatch('loadPromotions', clubId).then(value => {
        commit('SET_STATUS_PAGE', '')
        commit('LOAD_PROMOTIONS', value)
      }, reason => commit('SET_STATUS_PAGE', '500'))
    },
    loadEdit ({ state, commit, getters, dispatch }, data) {
      commit('SET_STATUS_EDIT', '')
      let promotion = state.promotions.find(el => el.id === +data.id)
      if (promotion) commit('LOAD_PROMOTION', promotion)
      else {
        commit('SET_STATUS_EDIT', 'loading')
        let p1 = dispatch('loadPromotion', data)
        let p2 = dispatch('timeout')
        Promise.all([p1, p2]).then(values => {
          if (values[0] && values[0].content && values[0].content.length) {
            commit('LOAD_PROMOTION', values[0].content[0])
            commit('SET_STATUS_EDIT', '')
          } else commit('SET_STATUS_EDIT', '404')
        }, reason => commit('SET_STATUS_EDIT', '500'))
      }
    },
    async loadTemplates ({ state, commit }, clubId) {
      if (!state.templates.length) {
        try {
          let res = await promotionsApi.getTemplates(clubId)
          commit('LOAD_TEMPLATES', res.data)
          return res.data
        } catch (err) {
          throw err
        }
      }
    },
    async loadPromotions ({ state, commit, getters }, clubId) {
      try {
        let sort = state.page.sort.map(item => `${item.property},${item.direction}`).join('')
        let res = getters.isEmptySearch
          ? await promotionsApi.getAllPromotions(clubId, { ...state.page, sort: [sort, 'id,ASC'] })
          : await promotionsApi.searchPromotions(clubId, { page: state.page, search: state.search })
        return res.data
      } catch (err) {
        throw err
      }
    },
    async loadPromotion ({ state, commit, getters, dispatch }, { clubId, id }) {
      try {
        dispatch('setSearch', [{ key: 'ids', value: [id] }])
        let res = await promotionsApi.searchPromotions(clubId, { page: state.page, search: state.search })
        dispatch('setSearch', [{ key: 'ids', value: [] }])
        return res.data
      } catch (err) {
        throw err
      }
    },
    async addPromotion ({ state, commit }, payload) {
      try {
        delete payload.data.status
        let res = await promotionsApi.addPromotion(payload.clubId, payload.data)
        commit('ADD_PROMOTION', res.data)
        this.dispatch('showAlert', { text: 'Запись успешно создана.', type: 'success' })
      } catch (e) {
        this.dispatch('showAlert', { text: 'Ошибка создания. Попробуйте повторить попытку.', type: 'error' })
      }
    },
    async editPromotion ({ state, commit }, payload) {
      console.log('edit promotion', payload)
      try {
        delete payload.data.insDate
        delete payload.data.status
        let res = await promotionsApi.editPromotion(payload.clubId, payload.unitId, payload.data)
        commit('EDIT_PROMOTION', res.data)
        this.dispatch('showAlert', { text: 'Изменения сохранены.', type: 'success' })
        console.log('edit promotion res', res)
      } catch (e) {
        this.dispatch('showAlert', { text: 'Ошибка. Попробуйте повторить попытку.', type: 'error' })
      }
    },
    async removePromotions ({ state, commit, dispatch }, { clubId, data }) {
      let currentPage = state.page.page
      let replacedPage = 0
      if (data.length === 1) {
        replacedPage = Math.floor(state.promotions.findIndex(find => find.id === data[0]) / state.page.size)
      }
      try {
        let res = await promotionsApi.removePromotions(clubId, { data: { ids: data } })
        commit('SET_PAGE', replacedPage)
        commit('SET_STATUS_PAGE', 'loading')
        dispatch('loadPromotions', clubId).then(value => {
          commit('SET_STATUS_PAGE', '')
          commit('SET_PAGE', currentPage)
          if (data.length === 1) commit('REMOVE_PROMOTION', { page: replacedPage, data: value })
          else commit('REMOVE_PROMOTIONS', value)
        }, reason => commit('SET_STATUS_PAGE', '500'))
        return res.data
      } catch (err) {
        throw err
      }
    },
    setPage ({ commit }, page) {
      commit('SET_PAGE', page)
    },
    setSearch ({ state, commit }, payload) {
      commit('SET_SEARCH', payload)
      commit('SET_PAGE', 0)
    },
    setSort ({ commit }, prop) {
      commit('SET_SORT', prop)
      commit('SET_PAGE', 0)
    },
    clearSearch ({ commit, getters }) {
      if (!getters.isEmptySearch) commit('CLEAR_SEARCH')
    },
    timeout ({ state }) {
      return new Promise((resolve, reject) => {
        setTimeout(() => resolve(true), state.timeoutTime)
      })
    },
    setOpenNetwork ({ commit }, data) {
      commit('SET_OPEN_NETWORK', data)
    },
    setActivePoints ({ commit }, data) {
      commit('SET_ACTIVE_POINTS', data)
    },
    setTreeview ({ commit }, data) {
      commit('SET_TREEVIEW', data)
    }
  },
  getters: {
    getTemplatesArray (state) {
      let res = []
      state.templates.forEach(tmp => {
        let issetIndex = res.findIndex(item => tmp.operationType === item.id)
        if (issetIndex >= 0) res[issetIndex].children.push(tmp)
        else {
          res.push({
            title: state.operations.default[tmp.operationType],
            id: tmp.operationType,
            treeType: 'operation',
            treeId: 'operation' + tmp.operationType,
            children: [
              tmp
            ]
          })
        }
      })
      return res
    },
    getTemplate: state => templateId => {
      return state.templates.find(tmp => {
        return tmp.id === templateId
      })
    },
    getOperationsArray: state => {
      return Object.keys(state.operations.default).map(item => {
        return {
          id: item,
          value: item,
          title: state.operations.default[item]
        }
      })
    },
    getTemplatesByOperationsType: state => operationType => {
      return state.templates.filter(tmp => {
        return tmp.operationType === operationType
      })
    },
    getOperationsByTemplate: state => templateId => {
      return state.templates.find(tmp => {
        return tmp.id === templateId
      })
    },
    getPromotions: state => state.promotions,
    getPromotion: state => state.promotion,
    getPromotionById: state => (promoId) => {
      return state.promotions.find(promo => {
        return promo.id === +promoId
      })
    },
    getSearch: state => state.search,
    getStatusAll: state => state.statusAll,
    getStatusTable: state => state.statusTable,
    getStatusPage: state => state.statusPage,
    getStatusEdit: state => state.statusEdit,
    getOperationsTitle: state => (type) => {
      return state.operations.default[type]
    },
    getSort: state => state.page.sort,
    getTotalElements: state => state.totalElements,
    getItemsPerPage: state => state.page.size,
    isEmptySearch: (state) => {
      let search = state.search
      if (search.ids.length === 0 &&
        !Object.keys(search.insDate).length &&
        search.templateIds.length === 0 &&
        search.retailPointIds.length === 0 &&
        search.title === '' &&
        search.status === ''
      ) return true
    },
    getOpenNetwork: state => state.openNetwork,
    getActivePoints: state => state.activePoints,
    getTreeview: state => state.treeview
  }
}
