<template>
  <div class="template-info">
    <v-row class="mt-0 mb-0">
      <v-col class="col-12 col-sm-6">Получатели</v-col>
      <v-col class="col-12 col-sm-6 template-info__value">
        <ul>
          <li
            v-for="item in data.emails"
            :key="item"
          >{{ item }}</li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'RecipientsInfo',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
