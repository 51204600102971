var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.setWidth),expression:"setWidth"},{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],ref:"select",class:("input select select--single select--" + _vm.mode),attrs:{"items":_vm.itemsComputed,"item-text":_vm.itemText,"item-value":_vm.itemValue,"rounded":"","clearable":_vm.clearable,"disabled":_vm.disabled,"rules":_vm.rules,"label":_vm.selectLabel,"return-object":_vm.returnObject,"menu-props":{
    contentClass: ("select__menu select__menu--single select__menu--" + _vm.mode + " " + (_vm.search ? 'select__menu--search' : '')),
    offsetY: true,
    // maxHeight: 250,
    // // maxWidth: width,
    // // minWidth: width,
    // closeOnClick: true,
    nudgeBottom: 10,
    nudgeWidth: -16, // 'auto' prop adds 16px to menu min-width
    auto: true, // needs for showing all items immediately
    //'v-model': menu
  },"hide-details":_vm.hideDetails},on:{"change":function($event){return _vm.$emit('change', $event)},"click:clear":_vm.clear,"keydown":function($event){$event.preventDefault();return (function () {}).apply(null, arguments)}},scopedSlots:_vm._u([(['club', 'retail', 'clock'].indexOf(_vm.mode) !== -1)?{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"select__prepend-icon",domProps:{"innerHTML":_vm._s(require(("@/assets/img/icons/" + _vm.mode + ".svg")))}})]},proxy:true}:null,{key:"append",fn:function(){return [(_vm.tooltip.length)?_c('div',{staticClass:"select__tooltip"},[_c('app-button-tooltip',{attrs:{"icon":"info","message":_vm.tooltip,"size":[18, 18]}})],1):_vm._e(),_c('div',{staticClass:"select__append-icon",domProps:{"innerHTML":_vm._s(require("@/assets/img/icons/arrow.svg"))}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"select__no-data"},[_vm._v("Нет данных")])]},proxy:true},(_vm.search)?{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"select__prepend-item"},[_c('app-input',{attrs:{"mode":"search","value":_vm.filter,"placeholder":"Начните вводить название..."},on:{"update:value":function($event){_vm.filter=$event}}})],1)]},proxy:true}:null],null,true),model:{value:(_vm.v),callback:function ($$v) {_vm.v=$$v},expression:"v"}})}
var staticRenderFns = []

export { render, staticRenderFns }