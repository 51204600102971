<template>
  <div>
    <clubs-header
      :view.sync="view"
      :search.sync="search"
    />
    <v-row :class="`clubs clubs--${view}`">
      <clubs-item
        v-for="club in clubs"
        :key="club.id"
        class="clubs__item col-12 pl-0 pr-0 pl-md-3 pr-md-3"
        :class="{ 'col-md-6 col-xl-4': view === 'table' }"
        :club="club"
        :mode="view"
      />
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClubsHeader from '@/components/clubs/components/ClubsHeader.vue'
import ClubsItem from '@/components/clubs/components/ClubsItem'

export default {
  name: 'Clubs',
  components: {
    'clubs-header': ClubsHeader,
    'clubs-item': ClubsItem
  },
  data () {
    return {
      clubs: '',
      view: 'table',
      search: ''
    }
  },
  computed: {
    ...mapGetters([
      'getClubs'
    ]),
    isMobile () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    getClubsSorted () {
      const res = this.getClubs.slice()
      return res.sort((a, b) => {
        return a.title.localeCompare(b.title)
      })
    }
  },
  watch: {
    search (newVal) {
      if (newVal !== '') {
        this.clubs = this.getClubsSorted.filter(elem => elem.title.toUpperCase().includes(newVal.toUpperCase()))
      } else {
        this.clubs = this.getClubsSorted
      }
    },
    isMobile () {
      this.view = 'table'
    }
  },
  mounted () {
    this.clubs = this.getClubsSorted
  }
}
</script>

<style lang="scss">
.clubs {
  &--table {
    margin: 0;
    padding: 10px 0 20px;
  }

  &--list {
    padding: 10px 0 20px;

    .clubs__item {
      padding-top: 0 !important;
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .clubs {
    &--table {
      margin: 0 -12px;
    }
  }
}
</style>
