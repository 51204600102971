import Vue from 'vue'
import { clubsApi } from '@/api/clubs'
import router from '../../router'

const getDefaultState = () => {
  return {
    clubs: []
  }
}

const state = getDefaultState()

const mutations = {
  'SET_CLUBS' (state, data) {
    state.clubs = data
  },
  'ADD_CLUB' (state, data) {
    state.clubs.push(data)
  },
  'EDIT_CLUB' (state, data) {
    let index = state.clubs.findIndex(club => club.id === data.id)
    Vue.set(state.clubs, index, data)
  },
  'REMOVE_CLUB' (state, data) {
    let index = state.clubs.findIndex(club => club.id === data.id)
    console.log(index)
    Vue.delete(state.clubs, index)
    console.log(router)
    // router.push({ name: 'Home' })
  },
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  setClubs ({ commit }, data) {
    commit('SET_CLUBS', data)
  },
  async addClub ({ commit }, data) {
    try {
      let formData = new FormData()
      for (let key in data) {
        if (data[key] !== '') {
          formData.append(key, data[key])
        }
      }
      let add = await clubsApi.add(formData)
      commit('ADD_CLUB', add.data)
      this.dispatch('registerModule', add.data)
      router.push({ name: 'Home' })
      commit('SHOW_ALERT', { text: `Программа успешно добавлена`, type: 'success' })
    } catch (e) {
      console.log(e)
      commit('SHOW_ALERT', { text: `Ошибка добавления программы. Попробуйте позже.`, type: 'error' })
    }
  },
  async editClub ({ commit }, data) {
    try {
      console.log(data)
      let formData = new FormData()
      for (let key in data) {
        if (data[key] !== '') {
          console.log(key)
          formData.append(key, data[key])
        }
      }
      let edit = await clubsApi.edit(formData)
      commit('EDIT_CLUB', edit.data)
      commit(`${edit.data.id}/global/SET_CLUB_DATA`, edit.data)
      commit('SHOW_ALERT', { text: `Данные успешно сохранены`, type: 'success' })
    } catch (e) {
      commit('SHOW_ALERT', { text: 'Ошибка сохранения. Попробуйте позже.', type: 'error' })
      console.log(e)
    }
  },
  async removeClub ({ commit }, id) {
    try {
      let remove = await clubsApi.remove(id)
      commit('REMOVE_CLUB', remove.data)
      commit('SHOW_ALERT', { text: `Клуб успешно удален`, type: 'success' })
      if (router.history.current.name === 'ClubSettings') {
        router.push({ name: 'Home' })
      }
    } catch (e) {
      commit('SHOW_ALERT', { text: 'Ошибка удаления. Попробуйте позже.', type: 'error' })
      console.log(e)
    }
  }
}

const getters = {
  getClubs: state => state.clubs,
  getClubById: state => (id) => {
    return state.clubs.find(club => {
      return club.id === id
    })
  },
  getTotalClubs: state => state.clubs.length,
  getClubByObjectId: state => (_id) => {
    return state.clubs.find(club => {
      return club._id === _id
    })
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
