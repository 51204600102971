<template>
  <div :class="{'dashboard__data' : !report}">
    <template
      v-if="report"
    >
      Популярные точки: {{ countMobileUsers }}
    </template>
    <template
      v-else
    >
      <app-dashboard-num
        :number="countMobileUsers"
        :today="countTodayMobileUsers"
      />
      <app-dashboard-diagram
        :data="mobileUsers"
      />
    </template>
  </div>
</template>

<script>
import DashboardNum from '@/components/Club/ClubDashboards/DashboardNum'
import DashboardDiagramModule from '@/components/Club/ClubDashboards/DashboardDiagramModule'

export default {
  name: 'ClubDashboardsModulePurchasesPopularPoints',
  props: {
    report: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: [Number, String],
      require: false
    }
  },
  components: {
    'app-dashboard-num': DashboardNum,
    'app-dashboard-diagram': DashboardDiagramModule
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    countMobileUsers () {
      return this.$store.getters[`${this.clubId}/dashboards/getMobileUsersCount`]
    },
    countTodayMobileUsers () {
      return this.$store.getters[`${this.clubId}/dashboards/getTodayMobileUsersCount`]
    },
    countAndroidUsers () {
      return this.$store.getters[`${this.clubId}/dashboards/getAndroidUsers`]
    },
    percentAndroidUsers () {
      return this.countAndroidUsers === 0 ? 0 : ((this.countAndroidUsers / this.countMobileUsers) * 100).toFixed(1)
    },
    countIosUsers () {
      return this.$store.getters[`${this.clubId}/dashboards/getIosUsers`]
    },
    percentIosUsers () {
      return this.countIosUsers === 0 ? 0 : ((this.countIosUsers / this.countMobileUsers) * 100).toFixed(1)
    },
    mobileUsers () {
      return {
        iOS: {
          color: '#898989',
          count: this.countIosUsers,
          percent: this.percentIosUsers
        },
        Android: {
          color: '#3BD480',
          count: this.countAndroidUsers,
          percent: this.percentAndroidUsers
        }
      }
    }
  }
}
</script>
