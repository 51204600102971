<template>
  <div :class="`structures structures--${mode}`">
    <v-row class="mt-0 mb-0">
      <div
        v-for="(structure, i) in structures"
        :key="structure.id"
        class="structure"
      >
        <v-col
          v-if="mode === 'dynamic'"
          class="structure__title col-12 mb-2"
        >
          Сеть {{ i + 1 }}
        </v-col>
        <div class="structure__content">
        <div :class="`structure__retail pt-0 pb-0 col-12 col-lg-${12/fields.length}`">
          <app-select-single
            mode="retail"
            search="title"
            :label="retailNetworkLabel"
            :items="setCurrentRetails(structure)"
            :value.sync="structure.id"
            :disabled="disabled || !clubId"
            clearable
            @change="changeRetails(structure)"
            @clear="removeStructure(structure)"
          />
        </div>
        <div
          v-if="fields.indexOf('points') > 0"
          :class="`structure__point pt-0 pb-0 col-12 col-lg-${12/fields.length}`"
        >
          <app-select-single
            mode="retail"
            v-if="singlePoint"
            label="Выберете точку*"
            :items="setCurrentPoints(structure)"
            :value.sync="structure.points"
            :disabled="disabled || !clubId"
            return-array
            @change="changePoints(structure)"
          />
          <app-select-multiple
            v-else
            mode="point"
            :label="pointLabel"
            :items="setCurrentPoints(structure)"
            :headers="allRetails"
            :value.sync="structure.points"
            :disabled="disabled || !clubId"
            clearable
            @change="changePoints(structure)"
          />
        </div>
        <div
          v-if="fields.indexOf('terminals') > 0"
          :class="`structure__terminal pt-0 pb-0 col-12 col-lg-${12/fields.length}`"
        >
          <app-select-multiple
            mode="terminal"
            label="Выберите терминал"
            :items="setCurrentTerminals(structure)"
            :headers="setCurrentPoints(structure)"
            :value.sync="structure.terminals"
            :disabled="disabled || !clubId"
            clearable
            @change="changeTerminals(structure)"
          />
        </div>
        <div
          v-if="mode === 'dynamic' && structures.length > 1"
          class="col-3 d-flex align-center pt-0 pb-0 mb-4 mb-lg-0"
        >
          <app-button
            mode="flat"
            icon="trash"
            left
            :disabled="disabled"
            @click="removeStructure(structure)"
          >
            Удалить сеть
          </app-button>
        </div>
        </div>
      </div>
    </v-row>

    <div
      v-if="mode === 'dynamic'"
      class="d-flex align-start"
    >
      <app-button-circle
        :disabled="!structures[structures.length - 1].id || structures.length === allRetails.length || disabled"
        @click="addStructure()"
      >
        Добавить cеть
      </app-button-circle>
    </div>
  </div>
</template>

<script>/**
 * Компонент выбора сети, точки и терминала
 * @view
 * Три не связанных селекта с возможностью динамического добавления полей
 * @actions
 * updateClubId: Загружает сети и точки и устанавливает дефолтные значения при изменении клуба
 * loadAllRetails: Загружает сети
 * loadAllPoints: Загружает точки
 * setDefault: Устанавливает дефолтные значения в зависимости от выбранного значения value
 * setDefaultPoints: Устанавливает дефолтные выбранные точки
 * setCurrentRetails: Возвращает список доступных для выбора сетей в отдельной структуре
 * setCurrentPoints: Возвращает список доступных для выбора точек в отдельной структуре
 * setCurrentTerminals: Возвращает список доступных для выбора терминалов в отдельной структуре
 * changeRetails: Удаляет точки и терминалы при изменении сети
 * changePoints: Удаляет терминалы и добавляет сеть, если такая не выбрана, при изменении точек
 * changeTerminals: Добавляет точки и сети, если они не выбраны, при изменении терминалов
 * addStructure: Добавляет новую структуру для выбора сетей, точек и терминалов
 * addRetail: Добавляет выбранную сеть
 * addPoints: Добавляет выбранные точки
 * removeStructure: Удаляет структуру
 * removePoints: Удаляет выбранные точки и терминалы при изменении сети
 * removeTerminals: Фильтрует выбранные теминалы при изменении точек
 * getFormatStructures: Если выбраны все точки, заменяет points на пустой массив
 * setFormatStructures: Если выбраны все точки заменяет пустой массив на массив с точками
 * pointsToStructures: Формирует массив с сетями и точками из массива с точками
 * structuresToPoints: Формирует массив с точками из массива сетей и точек
 * structuresToTerminals: Формирует массив с терминалами из массива сетей, точек и терминалов
 * update: Возвращает значение
 * @props
 * mode String Тип структуры (с/без возможности динамического добавления полей)
 * value Array Возвращаемое значение
 *    points ? массив с сетями и точками : массив с точками
 *    terminals ? массив с терминалами
 * fields Array Список необходимых полей (сети, точки, терминалы) [retails, points, terminals]
 * points Boolean Если в value массив с id точек
 * terminals Boolean Если в value массив с id терминалов
 * club Number Id Клуба
 * outlined Boolean
 * disabled Boolean
 * singlePoint Boolean делает select-single для выбора точки
 */

import SelectSingle from '@/components/UI-Kit/selects/SelectSingle'
import SelectMultiple from '@/components/UI-Kit/selects/SelectMultiple'
import Button from '@/components/UI-Kit/buttons/Button'
import ButtonCircle from '@/components/UI-Kit/buttons/ButtonCircle'

export default {
  name: 'Structure',
  components: {
    'app-select-single': SelectSingle,
    'app-select-multiple': SelectMultiple,
    'app-button': Button,
    'app-button-circle': ButtonCircle
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: 'default',
      validator (value) {
        return ['default', 'dynamic'].indexOf(value) !== -1
      }
    },
    value: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    fields: {
      type: Array,
      required: false,
      default () {
        return ['retails', 'points', 'terminals']
      }
    },
    points: {
      type: Boolean,
      required: false
    },
    terminals: {
      type: Boolean,
      required: false
    },
    club: {
      type: [String, Number],
      required: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    singlePoint: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      retailsProgress: false,
      pointsProgress: false,
      terminalsProgress: false,
      structures: [
        { id: '', points: [], terminals: [] }
      ],
      defaultStructure: { id: '', points: [], terminals: [] }
    }
  },
  created () {
    if (this.terminals && typeof this.value[0] !== 'object') {
      this.terminalToStructures()
    }
  },
  computed: {
    clubId () {
      return this.club || this.$route.params.id
    },
    allRetails () {
      return this.$store.getters[`${this.clubId}/global/getRetails`]
    },
    restRetails () {
      return this.allRetails.filter(el => !this.structures.find(structure => structure.id === el.id))
    },
    allPoints () {
      return this.fields.indexOf('points') > 0 ? this.$store.getters[`${this.clubId}/global/getPoints`] : []
    },
    allTerminals () {
      return this.allPoints.map(el => el.terminals).flat(1)
    },
    valueAndAllPoints () {
      return `${this.value}|${this.allPoints}`
    },
    pointsAndTerminals () {
      return `${this.points}|${this.terminals}`
    },
    retailNetworkLabel () {
      if (!this.structures[0].id) return 'Все сети'
      else return 'Выберите сеть'
    },
    pointLabel () {
      if (!this.structures[0].points.length) return 'Все точки'
      else return 'Выберите точку'
    },
    userPoints () {
      return this.$store.getters['getUserPoints']
    }
  },
  watch: {
    clubId: {
      handler () {
        if (this.clubId) {
          this.updateClubId()
        }
      },
      immediate: true
    },
    structures: {
      handler () {
        this.update()
      },
      deep: true
    },
    pointsAndTerminals: {
      handler () {
        if (!this.value.length) return
        if (this.points && this.terminals) {
          console.error(`Pass "points" or "terminals" prop, not both props at the same time.`)
        } else if (!(this.points || this.terminals) && ['string', 'number'].indexOf(typeof this.value[0]) !== -1) {
          console.error('Pass "points" or "terminals" prop if "value" is array of points or terminals ids.')
        } else if ((this.points || this.terminals) && ['object'].indexOf(typeof this.value[0]) !== -1) {
          console.error('Do not pass "points" or "terminals" prop if "value" is array of retails.')
        }
      },
      immediate: true
    },
    fields: {
      handler () {
        if (this.fields.indexOf('terminals') > 0) {
          if (this.mode !== 'default') {
            console.error('To work with terminals pass "mode" prop with value "default".')
          }
          if (!this.terminals) {
            console.error('To work with terminals pass "terminals" prop with value "true".')
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    async updateClubId () {
      await this.loadAllRetails()
      await this.loadAllPoints()
      this.setDefault()
      this.setCurrentRetails(this.structures[0])
      this.setCurrentPoints(this.structures[0])
    },
    async loadAllRetails () {
      if (!this.$store.getters[`${this.clubId}/global/getRetails`].length) {
        this.retailsProgress = true
        await this.$store.dispatch(`${this.clubId}/global/setRetails`, this.clubId)
        this.retailsProgress = false
      }
    },
    async loadAllPoints () {
      if (this.fields.indexOf('points') > 0) {
        if (!this.$store.getters[`${this.clubId}/global/getPoints`].length) {
          this.pointsProgress = true
          await this.$store.dispatch(`${this.clubId}/global/setPoints`, this.clubId)
          this.pointsProgress = false
        }
      }
    },
    setDefault () {
      if (!this.points && !this.terminals && this.value.length) this.structures = this.getFormatStructures()
      if (this.points) this.setDefaultPoints()
      this.update()
    },
    setDefaultPoints () {
      let finished = false
      let unwatch = this.$watch('valueAndAllPoints', () => {
        if (this.value.length && this.allPoints.length) {
          this.structures = this.pointsToStructures()
          finished = true
        }
      }, { immediate: true })
      if (finished) unwatch()
    },
    setCurrentRetails (current) {
      if (!this.clubId) return []
      return this.allRetails.filter(el => el.id === current.id ? el : this.restRetails.includes(el))
    },
    setCurrentPoints (current) {
      if (!this.clubId) return []
      return current.id
        ? this.allPoints.filter(el => el.networkId === current.id)
        : this.allPoints.filter(el => this.restRetails.find(retail => retail.id === el.networkId))
    },
    setCurrentTerminals (current) {
      return current.points.length
        ? this.allPoints.filter(el => current.points.includes(el.id)).map(el => el.terminals).flat(1)
        : this.setCurrentPoints(current).map(el => el.terminals).flat(1)
    },
    changeRetails (current) {
      this.removePoints(current)
    },
    changePoints (current) {
      this.addRetail(current)
      if (this.fields.indexOf('terminals') > 0) {
        this.removeTerminals(current)
      }
    },
    changeTerminals (current) {
      this.addPoints(current)
      this.addRetail(current)
    },
    addStructure () {
      if (this.structures.length === this.allRetails.length) return
      this.structures.push(Object.assign({}, this.defaultStructure))
    },
    addRetail (structure) {
      if (structure.id) return
      let found = this.allPoints
        .find(point => point.id === structure.points[0])
        .networkId
      structure.id = found
    },
    addPoints (structure) {
      if (structure.points.length) return
      let founds = this.allPoints
        .filter(point => point.terminals.find(terminal => structure.terminals.includes(terminal.id)))
        .map(el => { return el.id })
      structure.points = founds
    },
    removeStructure (structure) {
      if (this.structures.length < 2) return
      this.structures.splice(this.structures.indexOf(structure), 1)
    },
    removePoints (current) {
      current.points = []
      current.terminals = []
    },
    removeTerminals (current) {
      current.terminals = current.points.length
        ? current.terminals.filter(t => current.points.includes(this.allTerminals.find(el => el.id === t).retailPointId))
        : []
    },
    getFormatStructures () {
      let array = this.value.slice(0)
      array.forEach(el => {
        let points = this.allPoints.filter(point => point.networkId === el.id)
        if (el.points.length === points.length) el.points = []
      })
      // console.log('getFormatStructures', array)
      return array
    },
    setFormatStructures () {
      // let array = []
      // if (!this.structures[0].id) {
      //   this.allRetails.forEach(el => {
      //     let points = this.allPoints.filter(point => point.networkId === el.id).map(point => { return point.id })
      //     array.push({ id: el.id, points })
      //   })
      // } else {
      //   this.structures.forEach(el => {
      //     if (el.id) {
      //       if (!el.points.length) {
      //         let points = this.allPoints.filter(point => point.networkId === el.id).map(point => { return point.id })
      //         array.push({ id: el.id, points })
      //       } else {
      //         array.push(el)
      //       }
      //     }
      //   })
      // }
      // // console.log('setFormatStructures', array)
      // return array

      let array = []
      if (this.structures[0].id) {
        this.structures.forEach(el => {
          if (el.id) {
            let points = this.allPoints.filter(point => point.networkId === el.id).map(point => { return point.id })
            if (el.points.length === points.length && el.points.length !== this.userPoints.length) {
              array.push({ id: el.id, points: [] })
            } else {
              array.push({ id: el.id, points: el.points })
            }
          }
        })
      }
      // console.log('setFormatStructures', array)
      return array
    },
    pointsToStructures () {
      let array = []
      if (!this.value.length || (this.value.length === this.allPoints.length)) array.push(Object.assign({}, this.defaultStructure))
      else {
        this.value.forEach(el => {
          let point = this.allPoints.find(point => point.id === el)
          let structure = array.find(structure => structure.id === point.networkId)
          structure ? structure.points.push(point.id) : array.push({ id: point.networkId, points: [point.id] })
        })
        array.forEach(el => {
          let points = this.allPoints.filter(point => point.networkId === el.id)
          if (el.points.length === points.length) el.points = []
        })
      }
      // console.log('ptos', array)
      return array
    },
    structuresToPoints () {
      let array = []
      if (!this.structures[0].id) {
        if (!this.structures[0].points.length) {
          array = this.allPoints.map(el => { return el.id })
        } else {
          array = this.structures[0].points
        }
      } else {
        this.structures.forEach((structure) => {
          if (structure.id) {
            array = structure.points.length
              ? array.concat(structure.points)
              : array.concat(this.allPoints
                .filter(el => el.networkId === structure.id)
                .map(el => { return el.id }))
          } else {
            array = array.concat(structure.points)
          }
        })
      }
      // console.log('stop', array)
      return array
    },
    structuresToTerminals () {
      let array = []
      if (!this.structures[0].id) {
        if (this.structures[0].terminals.length) {
          array = this.structures[0].terminals
        } else if (this.structures[0].points.length) {
          array = this.allPoints
            .filter(p => this.structures[0].points.includes(p.id))
            .map(p => { return p.terminals.map(t => { return t.id }) }).flat(1)
        } else {
          array = this.allPoints.map(p => { return p.terminals.map(t => { return t.id }) }).flat(1)
        }
      } else {
        this.structures.forEach((structure) => {
          if (structure.id) {
            if (structure.terminals.length) {
              array = array.concat(structure.terminals)
            } else if (structure.points.length) {
              array = array.concat(this.allPoints
                .filter(p => this.structures[0].points.includes(p.id))
                .map(p => { return p.terminals.map(t => { return t.id }) }).flat(1)
              )
            } else {
              array = array.concat(this.allPoints
                .filter(p => p.networkId === structure.id)
                .map(p => { return p.terminals.map(t => { return t.id }) }).flat(1)
              )
            }
          }
        })
      }
      // console.log('stot', array)
      return array
    },
    terminalToStructures () {
      const points = []
      let retailId
      this.allPoints.forEach(point => {
        this.value.forEach(terminal => {
          if (point.terminals[0].id === terminal) {
            points.push(point.id)
            retailId = point.networkId
          }
        })
      })
      if (points.length !== this.allPoints.length) {
        this.structures = [{
          id: retailId,
          points,
          terminals: this.value
        }]
      }
    },
    update () {
      let v
      if (this.terminals) {
        v = this.structuresToTerminals()
      } else if (this.points) {
        v = this.structuresToPoints()
      } else {
        v = this.setFormatStructures()
      }
      this.$emit('update:value', v)
      this.$emit('change', v)
    },
    changeSinglePoint (e) {
      this.$emit('singlePoint', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.structures {
  .structure {
    width: 100%;
    &__content {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      width: 100%;
    }

    &__title {
      font-weight: 600;
      color: $accent;
    }

    & > div {
      padding-bottom: 0;
    }

    &__retail {
      flex-grow: 1;
    }

    &__point {
      flex-grow: 1;
    }

    &__terminal {
      flex-grow: 1;
    }
  }

  &--default {
    .structure {
      & > div:last-child {
        margin-right: 0;
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .structures {
    .structure {
      &__content {
        flex-wrap: nowrap;
      }
      &__title {
        display: none;
      }
    }
  }
}
</style>
