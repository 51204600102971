<template>
  <v-list-item
    :class="`clubs-item-list-item clubs-item-list-item--${mode}`"
    @click="clickHandler"
  >
    <v-list-item-content>
      <div class="clubs-item-list-icon">
        <app-button-tooltip
          :message="tooltipMessage"
          :icon="item.icon"
          :size="$vuetify.breakpoint.mdAndUp ? [30, 30] : [24, 24]"
        />
      </div>
      <v-list-item-title
        v-if="mode === 'table'"
        class="clubs-item-list-title"
      >
        {{ item.title }}
        <v-chip
          v-if="item.beta"
          color="accent"
          small
        >Beta</v-chip>
      </v-list-item-title>
      <div
        v-if="mode === 'table'"
        class="clubs-item-list-arrow"
        v-html="require(`@/assets/img/icons/arrow.svg`)"
      />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import ButtonTooltip from '@/components/UI-Kit/buttons/ButtonTooltip'

export default {
  name: 'ClubsItemListItem',
  components: {
    'app-button-tooltip': ButtonTooltip
  },
  props: {
    mode: {
      type: String,
      required: false
    },
    item: {
      type: Object,
      required: true
    },
    link: {
      type: String,
      required: false,
      default: ''
    },
    popup: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    }
  },
  computed: {
    tooltipMessage () {
      if (this.mode === 'list') {
        return this.item.title
      } else {
        return ''
      }
    }
  },
  methods: {
    clickHandler (e) {
      if (this.link.length) {
        this.$router.push(this.link)
      } else if (Object.keys(this.popup).length !== 0) {
        this.showPopup(this.popup)
      }
    }
  }
}
</script>

<style lang="scss">
.clubs-item-list-item {
  flex: 0 0 auto !important;
  height: 60px;
  background-color: $base;
  transition: background-color $primary-transition;

  .v-list-item__content {
    padding: 0;

    & > * {
      flex: 0 0 auto;
      margin-bottom: 0;
    }
  }

  .clubs-item-list-icon {
  }

  .clubs-item-list-title {
    font-size: $font-16 !important;
  }

  &--table {
    padding: 0 15px;

    .clubs-item-list-icon {
      margin-right: 15px !important;

      svg {
        fill: $icon;
        transition: fill $primary-transition;
      }
    }

    .clubs-item-list-title {
      flex-grow: 1;
      line-height: 60px !important;
      transition: color $primary-transition;
    }

    .clubs-item-list-arrow {
      display: flex;
      width: 9px;
      transform: rotate(-90deg);
      opacity: 0;
      transition: opacity $primary-transition;

      svg {
        fill: $icon;
      }
    }

    &::before,
    &::after {
      display: block !important;
      content: '';
      position: absolute;
      left: 5px;
      right: 5px;
      min-height: auto;
      height: 1px;
      background-color: #f0f0f0;
      opacity: 1;
      transition: left $primary-transition, right $primary-transition;
    }

    &::before {
      top: -1px;
    }

    &::after {
      bottom: 0;
    }

    &:nth-last-child(1) {
      &::after {
        display: none !important;
      }
    }

    &.admin {
      // &:nth-last-child(2),
      // &:nth-last-child(1) {
      //   display: inline-block;
      //   width: 50%;

      //   .clubs-item-list-icon {
      //     .button-tooltip {
      //       width: 24px !important;
      //       height: 24px !important;
      //     }
      //   }

      //   .clubs-item-list-arrow {
      //     display: none;
      //   }

      //   &::after {
      //     display: none !important;
      //   }
      // }
    }

    &:hover {
      background-color: rgba(158, 158, 158, 0.05);

      &::before,
      &::after {
        left: 0;
        right: 0;
        opacity: 1 !important;
      }

      .clubs-item-list-icon {
        svg {
          fill: $accent;
        }
      }

      .clubs-item-list-title {
        color: $accent;
      }

      .clubs-item-list-arrow {
        opacity: 1;
      }
    }
  }

  &--list {
    padding: 0;

    &.admin {
      .clubs-item-list-icon {
        .button-tooltip {
          width: 24px !important;
          height: 24px !important;
        }
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .clubs-item-list-item {
    .clubs-item-list-title {
      font-size: $font-17 !important;
    }
    &--table {
      padding: 0 30px;
      .clubs-item-list-icon {
        margin-right: 20px !important;
      }

      &::before,
      &::after {
        left: 15px;
        right: 15px;
      }
    }
  }
}
</style>
