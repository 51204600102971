<template>
  <!-- <v-list-item
    :class="['nav-user pa-0', { 'mob': mob }]"
    :to="{ name: 'Settings' }"
  > -->
  <v-list-item
    :class="['nav-user pa-0', { 'mob': mob }]"
  >
    <v-list-item-avatar
      :class="[
        'nav-user__logo',
        { 'bmsadmin bmsadmin--top': isBMSAdmin }
      ]"
    >
      <v-avatar
        v-if="userLogo.length"
        :size="40"
        left
      >
        <img :src="require(`@/assets/img/nav/${userLogo}.png`)" alt="logo"/>
      </v-avatar>
      <v-avatar
        v-else
        :size="24"
        left
        tile
        v-html="require(`@/assets/img/icons/user.svg`)"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="nav-user__title ma-0">
        {{ getUserName }}
      </v-list-item-title>
      <v-list-item-title class="nav-user__subtitle caption ma-0">
        {{ getUserLogin }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavUser',
  props: ['mob'],
  data () {
    return {
      // userLogo: 'avatar',
      userLogo: ''
    }
  },
  computed: {
    ...mapGetters([
      'getUserLogin',
      'getUserName',
      'isBMSAdmin'
    ])
  }
}
</script>

<style lang="scss">
.nav-user {
  width: 80%;
  margin-right: 15px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30px;
    background: linear-gradient(270deg, #515151 0%, rgba(81, 81, 81, 0) 100%);
    pointer-events: none;
  }

  &__logo {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-right: 10px !important;
    background-color: $accent-disabled;
    border-radius: 50%;

    svg {
      fill: $primary;
    }
  }

  &__title,
  &__subtitle {
    color: $base;
    text-overflow: clip;
  }

  &__title {
    font-weight: 600;
  }

  &.mob {
    &::after {
      background: linear-gradient(270deg, #484848 0%, rgba(72, 72, 72, 0) 100%);
    }
  }
}
</style>
