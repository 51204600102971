<template>
  <v-tooltip
    top
    :disabled="!message.length"
    max-width="194"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        class="button-tooltip"
        :class="[className, { 'disabled': disabled }]"
        :disabled="disabled"
        v-html="require(`@/assets/img/icons/${icon}.svg`)"
        fab
        tile
        :width="size[0]"
        :height="size[1]"
        depressed
        text
        v-on="on"
        @click="$emit('click')"
      >
      </v-btn>
    </template>
    <span>
      {{ message }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ButtonTooltip',
  props: {
    message: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: String,
      required: true
    },
    size: {
      type: Array,
      required: true
    },
    className: {
      type: String,
      require: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
.button-tooltip {
  svg {
    width: 100%;
    height: 100%;
    &:not(.goodFill) {
      fill: $icon;
      transition: fill $primary-transition;
    }
    &.goodFill {
      path {
        fill: $accent-disabled;
        transition: fill $primary-transition;
      }
      circle {
        stroke: $accent-disabled;
        transition: stroke $primary-transition;
      }
    }
  }

  &:hover {
    svg {
      &:not(.goodFill) {
        fill: $accent;
      }
      &.goodFill {
        path {
          fill: $accent;
        }
        circle {
          stroke: $accent;
        }
      }
    }
  }

  &--confirm {
    svg.goodFill {
      path {
        fill: $base;
      }
      rect {
        transition: fill $primary-transition;
      }
    }
    &:hover {
      svg.goodFill {
        path {
          fill: $base;
        }
        rect {
          fill: $success-hover;
        }
      }
    }
    &.disabled {
      opacity: .5;
    }
  }

  &.v-btn--disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}
</style>
