import { server } from './index'

let productsApi = {
  all (id) {
    return server.get(`/products/${id}`)
  },
  load (id, data) {

  },
  edit (data) {
    return server.put(`/products/edit/${data.clubId}`, data.data)
  },
  remove (clubId, productId) {
    return server.delete(`/products/remove/${productId}/${clubId}`)
  },
  removeSelected (clubId, productIds) {
    return server.post(`/products/remove/many/${clubId}`, { productIds })
  },
  addMany (clubId, products) {
    return server.post(`/products/add/many/${clubId}`, { products })
  },
  getPromo (clubId) {
    return server.get(`/products/promotions/${clubId}`)
  },
  editPromo (clubId, data) {
    return server.put(`/products/promotions/edit/${clubId}`, data)
  },
  addPromo (clubId, data) {
    return server.post(`/products/promotions/add/${clubId}`, data)
  },
  removePromo (clubId, data) {
    return server.post(`/products/promotions/remove/${clubId}`, { promoIds: data })
  }
}

export { productsApi }
