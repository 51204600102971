<template>
  <div :class="{'dashboard__data' : !report}">
    <template
      v-if="report"
    >
      Потрачено звезд: {{ spendBonusSum | roundTo2 }}
    </template>
    <template
      v-else
    >
      <app-dashboard-num
        :number="spendBonusSum ? spendBonusSum : 0"
        :comma="true"
        arrow="bot"
        icon="star-icon"
        color="#9E9E9E"
      />
    </template>
  </div>
</template>

<script>
import DashboardNum from '@/components/Club/ClubDashboards/DashboardNum'

export default {
  name: 'ClubDashboardsModulePurchasesSpendStars',
  props: {
    report: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: [Number, String],
      require: false
    }
  },
  components: {
    'app-dashboard-num': DashboardNum
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    spendBonusSum () {
      return this.$store.getters[`${this.clubId}/dashboards/getSpendBonusSum`]
    }
  }
}
</script>
