<template>
  <v-btn
    class="button-scroll"
    v-scroll="onScroll"
    v-show="scrollButton"
    fab
    fixed
    bottom
    right
    @click="toTop"
  >
    <div
      class="button-scroll__icon"
      v-html="require(`@/assets/img/icons/arrow.svg`)"
    />
  </v-btn>
</template>

<script>
export default {
  name: 'ButtonScroll',
  data () {
    return {
      scrollButton: false
    }
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.scrollButton = top > 300
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style lang="scss">
.button-scroll {
  &.v-btn--fab {
    bottom: 75px;
    right: 25px;
    width: 50px;
    height: 50px;
    background-color: $base !important;
    box-shadow: $shadow-popup;
    transition: box-shadow $primary-transition;
    .button-scroll__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      border: 1px solid $accent-image-border;
      border-radius: 50%;
      transition: border $primary-transition;
      svg {
        width: 16px;
        height: 10px;
        fill: $accent;
        transform: rotate(180deg);
      }
    }
    &:hover {
      box-shadow: $shadow-button-scroll;
      .button-scroll__icon {
        border: 1px solid $accent;
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .button-scroll {
    &.v-btn--fab {
      bottom: 25px;
    }
  }
}
</style>
