<template>
  <div
    :class="`status status--${mode} status--${type} ${status}`">
    <v-tooltip
      top
      :disabled="mode === 'default'"
    >
      <template v-slot:activator="{ on }">
        <div
          :class="`status__activator status__activator--${mode} status__activator--${type} ${status}`"
          v-on="on"
        />
      </template>
      <span>{{ message }}</span>
    </v-tooltip>
    <p
      v-if="mode === 'default'"
      :class="`status__text status__text--${type} ${status}`"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Status',
  props: {
    type: {
      type: String,
      required: true,
      validator (value) {
        // Значение должно соответствовать одной из этих строк
        return ['transactions', 'promotions', 'actions', 'pushs', 'reports'].indexOf(value) !== -1
      }
    },
    mode: {
      type: String,
      required: false,
      default: 'default',
      validator (value) {
        // Значение должно соответствовать одной из этих строк
        return ['default', 'tooltip'].indexOf(value) !== -1
      }
    },
    status: {
      type: String,
      required: true,
      validator (value) {
        // Значение должно соответствовать одной из этих строк
        return [
          'new',
          'processed',
          'success',
          'ready',
          'cancel',
          'error',
          'active',
          'not_started',
          'ended',
          'deleted',
          'in_progress',
          'completed',
          'canceled',
          'postponed',
          'checked',
          'empty'
        ].indexOf(value) !== -1
      }
    }
  },
  data () {
    return {
      messages: {
        transactions: {
          new: 'Новый',
          processed: 'Подтвержден',
          success: 'Исполнен',
          cancel: 'Отменен',
          error: 'Ошибка',
          ready: 'Исполянется'
        },
        promotions: {
          active: 'Активна',
          'not_started': 'Не запущена',
          ended: 'Завершена',
          deleted: 'Удалена'
        },
        actions: {
          success: 'Успешно',
          error: 'Завершено с ошибкой'
        },
        pushs: {
          new: 'Новое',
          'in_progress': 'Ожидает отправки',
          completed: 'Успешно',
          error: 'Завершено с ошибкой',
          canceled: 'Отменено',
          postponed: 'Отложено'
        },
        reports: {
          new: 'Новый',
          checked: 'Просмотрен',
          empty: 'Пустой'
        }
      }
    }
  },
  computed: {
    message () {
      if (this.type === 'promotions' && this.mode === 'default') {
        return `Акция ${this.messages[this.type][this.status].toLowerCase()}`
      } else return this.messages[this.type][this.status]
    }
  },
  watch: {
    type: {
      handler () {
        switch (this.type) {
          case 'transactions':
            if (['new', 'processed', 'success', 'ready', 'cancel', 'error'].indexOf(this.status) === -1) {
              console.error(`Available statuses for transactions: 'new', 'processed', 'success', 'ready', 'cancel', 'error'. You've passed '${this.status}'`)
            }
            break
          case 'promotions':
            if (['active', 'not_started', 'ended', 'deleted'].indexOf(this.status) === -1) {
              console.error(`Available statuses for promotions: 'active', 'not_started', 'ended', 'deleted'. You've passed '${this.status}'`)
            }
            break
          case 'actions':
            if (['success', 'error'].indexOf(this.status) === -1) {
              console.error(`Available statuses for actions: 'success', 'error'. You've passed '${this.status}'`)
            }
            break
          case 'push':
            if (['new', 'in_progress', 'completed', 'error', 'canceled', 'postponed'].indexOf(this.status) === -1) {
              console.error(`Available statuses for pushs: 'new', 'in_progress', 'completed', 'error', 'canceled', 'postponed'. You've passed '${this.status}'`)
            }
            break
          case 'reports':
            if (['new', 'checked', 'empty'].indexOf(this.status) === -1) {
              console.error(`Available statuses for reports: 'new', 'checked', 'empty'. You've passed '${this.status}'`)
            }
            break
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.status {
  display: flex;
  align-items: center;

  &__activator {
    border-radius: 10px;

    &--default {
      width: 14px !important;
      height: 7px !important;

      // &.status__activator--promotions {
      //   display: none;
      // }
    }

    &--tooltip {
      width: 30px !important;
      height: 10px !important;

      &:hover {
        cursor: pointer;
      }
    }

    &.new,
    &.ended,
    &.postponed {
      background-color: $status-new !important;
    }

    &.ready,
    &.not_started,
    &.in_progress {
      background-color: $status-processed !important;
    }

    &.processed,
    &.success,
    &.active,
    &.completed,
    &.checked {
      background-color: $status-success !important;
    }

    &.cancel,
    &.deleted,
    &.canceled {
      background-color: $status-cancel !important;
    }

    &.error,
    &.empty {
      background-color: $status-error !important;
    }

    &.status__activator--reports {
      &.new {
        background-color: $status-processed !important;
      }
    }
  }

  &__text {
    margin-bottom: 0 !important;
    margin-left: 7px;
    color: $icon;
    font-weight: 400;
    font-size: 0.765rem; // 13px
    line-height: 0.941rem; // 16px
    white-space: nowrap;

    // &.status__text--promotions {
    //   font-size: inherit;
    //   line-height: inherit;
    //   font-weight: inherit;
    //   &.new,
    //   &.ended {
    //     color: $status-new !important;
    //   }

    //   &.processed,
    //   &.not_started {
    //     color: $status-processed !important;
    //   }

    //   &.success,
    //   &.active {
    //     color: $status-success !important;
    //   }

    //   &.cancel,
    //   &.deleted {
    //     color: $status-cancel !important;
    //   }

    //   &.error {
    //     color: $status-error !important;
    //   }
    // }
  }

  // &.status--promotions.status--default {
  //   display: block;
  // }
}
</style>
