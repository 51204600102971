<template>
  <v-card class="popup popup--cancel">
    <v-card-title>
      Отображение модулей
    </v-card-title>
    <v-card-actions class="popup__actions">
      <div
        v-for="(moduleGroup, index) in thisModules"
        :key="index"
        class="popup__items"
      >
        <div class="popup__subtitle">
          {{ getName(index) }}
        </div>
        <v-list
          class="popup__list"
        >
          <v-list-item
            v-for="module in moduleGroup"
            :light="module.enable"
            :key="module.customName"
          >
            <v-list-item-action>
              <v-checkbox
                v-model="module.enable"
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ module.customName }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

    </v-card-actions>
    <app-button
      @click="save"
    >
      Сохранить
    </app-button>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Button from '@/components/UI-Kit/buttons/Button'

export default {
  name: 'PopupDashboardsModules',
  components: {
    'app-button': Button
  },
  data () {
    return {
      thisModules: null
    }
  },
  computed: {
    ...mapGetters([
      'popup'
    ]),
    data () {
      return this.popup.data
    },
    modules () {
      return this.$store.getters[`${this.data.clubId}/dashboards/getModules`]
    }
  },
  mounted () {
    this.thisModules = JSON.parse(JSON.stringify(this.data.modules))
  },
  methods: {
    ...mapActions([
      'hidePopup'
    ]),
    async save () {
      await this.$store.dispatch(`${this.data.id}/dashboards/editUserDashboards`, {
        id: this.data.id,
        clubId: this.data.id,
        club: this.data.clubId,
        userId: this.data.userId,
        modules: this.thisModules
      })
      this.hidePopup()
    },
    getName (name) {
      return this.$store.getters[`${this.data.id}/dashboards/getModuleName`](name)
    }
  }
}
</script>

<style scoped lang="scss">
.popup {
  overflow: auto;
  &::v-deep {
    .v-card__title {
      word-break: break-word;
    }
  }
  &__actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__list {
    width: 100%;
  }
  &__items {
    width: 100%;
  }
  &__subtitle {
    padding-left: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #484848;
  }
}
</style>
