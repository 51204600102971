<template>
  <v-card class="popup popup--receipt">
<!--    <app-button-close-->
<!--      class="popup__close"-->
<!--      @click="hidePopup"-->
<!--    />-->
    <div class="popup__wrapper">
    <v-card-title class="popup__title">Состав чека</v-card-title>
    <div class="recdata-row">
      <p>Дата чека</p>
      <p>{{ popup.bill.billDate | date }}</p>
    </div>
    <div
      v-for="(good, index) in popup.bill.goods"
      :key="good.id"
    >
      <div class="recdata-row">
        <p>Позиция в чеке</p>
        <p>{{ index + 1 }}</p>
      </div>
      <div class="recdata-row">
        <p>Название позиции</p>
        <p>{{ good.goodTitle }}</p>
      </div>
      <div class="recdata-row">
        <p>Код позиции</p>
        <p>{{ good.code }}</p>
      </div>
      <div class="recdata-row">
        <p>Название на ТО</p>
        <p>Покупка</p>
      </div>
      <div class="recdata-row">
        <p>Количество</p>
        <p>{{ good.amount }} шт.</p>
      </div>
      <div class="recdata-row">
        <p>Общая стоимость</p>
        <p>{{ good.positionPrice * good.amount }} р.</p>
      </div>
      <div class="recdata-row">
        <p>Оплачено бонусами</p>
        <p>{{ good.spend }}</p>
      </div>
      <div class="recdata-row">
        <p>Бонусы за позицию</p>
        <p class="sum_green">+ {{ good.earn }}</p>
      </div>
<!--    //divisor line-->
      <div class="divisor-line">
      </div>
    </div>

<!--    <div class="rexplain-row">-->
<!--      <p>Акция</p>-->
<!--      <p>Начислено</p>-->
<!--    </div>-->
<!--    <div class="recdata-row">-->
<!--      <p>Основное бонусирование 10</p>-->
<!--      <p class="sum_green">+ 1 530</p>-->
<!--    </div>-->
<!--    <div class="recdata-row">-->
<!--      <p>Доп за покупку сверх нормы</p>-->
<!--      <p class="sum_green">+ 100</p>-->
<!--    </div>-->

<!--    <div class="divisor-line">-->
<!--    </div>-->
    <div class="recdata-row">
      <p>Позиций в чеке</p>
      <p>{{ popup.bill.goods.length }}</p>
    </div>
    <div class="recdata-row">
      <p>Сумма</p>
      <p class="sum_big">{{ popup.bill.billSum }} Р</p>
    </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import ButtonClose from '@/components/UI-Kit/buttons/ButtonClose'
import dayjs from 'dayjs'

export default {
  name: 'PopupReceipt',
  components: {
    // 'app-button-close': ButtonClose
  },
  computed: {
    ...mapGetters([
      'popup'
    ])
  },
  methods: {
    ...mapActions([
      'hidePopup'
    ])
  },
  filters: {
    date: function (date) {
      if (!date) return ''
      return dayjs(new Date(date)).format('DD.MM.YYYY в HH:mm')
    }
  }
}
</script>

<style lang="scss">
.popup__wrapper {
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
  }
}
.popup--receipt {
  position: relative;
  &::before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    border-top: 4px dotted $base;
  }

  &::before {
    top: 0;
    transform: translateY(-50%);
  }

  &::after {
    bottom: 0;
    transform: translateY(50%);
  }

  .recdata-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      margin: 0;
    }
    p:first-child {
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 30px;
      color: #9E9E9E;
      width: 50%;
      min-width: 240px;
    }
    p:last-child {
      width: 150px;
    }
  }

  .rexplain-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
    p {
      margin: 0;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: normal;
      font-size: $font-14;
      line-height: 17px;
      color: #9E9E9E;
    }
    p:first-child {
      width: 50%;
    }
    p:last-child {
      width: 150px;
    }
  }

  .divisor-line {
    border: 1px dashed #484848;
    margin: 15px 0;
  }

  .sum_green {
    color: #26BC22;
  }

  .sum_big {
    font-size: $font-21;
  }

  .v-card__title.popup__title {
    padding: 0 !important;
  }
}

  .popup.popup--receipt {
    padding: 20px 15px;
  }

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .popup--receipt {
    .recdata-row {
      flex-direction: row;
    }
  }

  .popup.popup--receipt {
    padding: 25px 30px;
  }
}
</style>
