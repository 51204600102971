import { server } from './index'

let usersApi = {
  // all () {
  //   return server.get('/user')
  // },
  profile () {
    return server.get('/user/profile')
  },
  /**
   *
   * @param page
   * @param data
   * @param {string} data.query
   * @param {string} data.role
   * @param {number} page.page
   * @param {number} page.limit
   * @returns {Promise<AxiosResponse<T>>}
   */
  search (page, data) {
    return server.post('/user/search', {
      data
    }, {
      params: page
    })
  },
  searchOne (login) {
    return server.get(`/user/search/${login}`)
  },
  add (data) {
    return server.post('/user/add', data)
  },
  edit (data) {
    return server.patch('/user/edit', data)
  },
  remove (data) {
    return server.delete('/user/remove', { data: { _id: data } })
  },
  generateAPIKey (user) {
    return server.get('/user/generateAPIKey')
  }
}

export { usersApi }
