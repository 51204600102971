import router from '@/router'

export default async function checkPermission ({ to, next, store, nextMiddleware }) {
  await store.dispatch('loadUser')

  if (Object.keys(store.getters.getUser).length) {
    if (store.getters.isBMSAdmin || !store.getters.getUserModules.length) {
      return nextMiddleware()
    } else {
      let found = store.getters.getUserModules
        .find(userModule => {
          return to.name.includes(userModule.name)
        })

      if (!found) {
        const route = router.options.routes
          .find(el => el.name === 'Club').children
          .find(el => el.name === to.name)

        if (route) {
          found = store.getters.getUserModules
            .find(userModule => {
              return route.children.find(el => el.name === userModule.name)
            })
        }
      }

      if (found && (found.permission === to.meta.permission || found.permission === 'WRITE')) {
        return nextMiddleware()
      } else {
        return next({
          name: 'Home'
        })
      }
    }
  }
}
