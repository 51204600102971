import dayjs from 'dayjs'

export const dashboardMixin = {
  computed: {
    prevPercent () {
      const percent = (this.averageBillSum - this.averageBillSumForLAstPeriod) / this.averageBillSumForLAstPeriod * 100
      let res = `${Math.abs(percent).toFixed(2)}% `
      if (percent > 0) {
        res += 'больше'
      } else {
        res += 'меньше'
      }
      return res
    },
    prevDates () {
      const from = dayjs(this.requestData.previousDate.from).format('DD.MM.YYYY')
      const to = dayjs(this.requestData.previousDate.to).format('DD.MM.YYYY')
      if (to === from) {
        return to
      } else {
        return `${from} - ${to}`
      }
    }
  }
}
