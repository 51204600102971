<template>
  <div :class="{'dashboard__data' : !report}">
    <template
      v-if="report"
    >
      <div class="reports__row">
        <span>Пользователей моб. приложения:</span>
        <span>{{ countMobileUsers.toLocaleString() }}
          <span
            v-if="todayUsers !== undefined"
          >/ {{ todayUsers.toLocaleString() }} сегодня</span>
        </span>
      </div>
      <div
        v-for="(item, name) in mobileUsers"
        :key="name"
        class="reports__row"
      >
        <span>Пользователей {{ name }}:</span><span>{{ item.count.toLocaleString() }} ({{ item.percent }}%)</span>
      </div>
    </template>
    <template
      v-else
    >
      <app-dashboard-num
        :number="countMobileUsers"
        :today="todayUsers"
      />
      <app-dashboard-diagram
        :data="mobileUsers"
      />
    </template>
  </div>
</template>

<script>
import DashboardNum from '@/components/Club/ClubDashboards/DashboardNum'
import DashboardDiagramModule from '@/components/Club/ClubDashboards/DashboardDiagramModule'
import dayjs from 'dayjs'

export default {
  name: 'ClubDashboardsModuleMobileUsers',
  props: {
    requestData: {
      type: Object,
      require: true
    },
    report: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: [Number, String],
      require: false
    }
  },
  components: {
    'app-dashboard-num': DashboardNum,
    'app-dashboard-diagram': DashboardDiagramModule
  },
  computed: {
    clubId () {
      return this.$route.params.id || this.id
    },
    countMobileUsers () {
      return this.$store.getters[`${this.clubId}/dashboards/getMobileUsersCount`]
    },
    countTodayMobileUsers () {
      return this.$store.getters[`${this.clubId}/dashboards/getTodayMobileUsersCount`]
    },
    countAndroidUsers () {
      return this.$store.getters[`${this.clubId}/dashboards/getAndroidUsers`]
    },
    percentAndroidUsers () {
      return this.countAndroidUsers === 0 ? 0 : ((this.countAndroidUsers / this.countMobileUsers) * 100).toFixed(1)
    },
    countIosUsers () {
      return this.$store.getters[`${this.clubId}/dashboards/getIosUsers`]
    },
    percentIosUsers () {
      return this.countIosUsers === 0 ? 0 : ((this.countIosUsers / this.countMobileUsers) * 100).toFixed(1)
    },
    todayUsers () {
      const today = dayjs().format('YYYY-MM-DD')
      if (this.requestData.date.to === today) {
        return this.countTodayMobileUsers
      } else {
        return undefined
      }
    },
    mobileUsers () {
      return {
        iOS: {
          color: '#898989',
          count: this.countIosUsers,
          percent: this.percentIosUsers
        },
        Android: {
          color: '#3BD480',
          count: this.countAndroidUsers,
          percent: this.percentAndroidUsers
        }
      }
    }
  }
}
</script>

<style lang="scss">

</style>
