<script>
import { Line } from 'vue-chartjs'

export default {
  name: 'dashboardGraph',
  extends: Line,
  data: () => ({
    gradient: null,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      title: {
        display: false
      },
      layout: {
        padding: { // отступы по краям
          left: 5,
          right: 5,
          top: 5,
          bottom: 5
        }
      },
      elements: {
        line: {
          tension: 0
        }
      },
      tooltips: {
        intersect: false,
        mode: 'nearest',
        axis: 'x',
        backgroundColor: 'rgba(72, 72, 72, 0.9)',
        titleColor: '#FFFFFF',
        bodyColor: '#E0E0E0',
        titleFont: {
          size: 17
        },
        bodyFont: {
          size: 17
        },
        xPadding: 10,
        yPadding: 7,
        cornerRadius: 4,
        displayColors: false,
        callbacks: {
          // изменение тултипа
          title: function (tooltipItem) {
            return (+tooltipItem[0].value).toLocaleString('ru-RU') + ' ₽'
          },
          label: function (tooltipItem, data) {
            return data.datasets[0].label + ' за: ' + tooltipItem.label
          }
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            fontColor: '#9E9E9E',
            // Функция для замены нулей в числах на буквы
            callback: function (label) {
              const lookup = [
                { value: 1, symbol: '' },
                { value: 1e3, symbol: 'k' },
                { value: 1e6, symbol: 'M' },
                { value: 1e9, symbol: 'G' },
                { value: 1e12, symbol: 'T' },
                { value: 1e15, symbol: 'P' },
                { value: 1e18, symbol: 'E' }
              ]
              const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
              let item = lookup.slice().reverse().find(function (item) {
                return label >= item.value
              })
              return item ? (label / item.value).toFixed(0).replace(rx, '$1') + item.symbol : '0'
            }
          }
        }],
        xAxes: [{
          ticks: {
            display: false
          }
        }]
      }
    }
  }),
  props: {
    data: {
      type: Array,
      require: true,
      default: () => []
    },
    label: {
      type: String,
      require: true,
      default: ''
    },
    mode: {
      type: String,
      require: false,
      default: 'full' // simple or full
    }
  },
  computed: {
    labels () {
      if (this.data !== []) {
        if (this.data.length >= 300) {
          const everyNth = this.data.filter((e, i) => i % 30 === 29)
          return everyNth.map(data => data.date)
        } else if (this.data.length > 32) {
          const everyNth = this.data.filter((e, i) => i % 7 === 6)
          return everyNth.map(data => data.date)
        } else {
          return this.data.map(data => data.date)
        }
      } else {
        return []
      }
    },
    nums () {
      if (this.data !== []) {
        if (this.data.length >= 300) {
          const everyNth = this.data.filter((e, i) => i % 30 === 29)
          return everyNth.map(data => Math.round(data.sum))
        } else if (this.data.length > 32) {
          const everyNth = this.data.filter((e, i) => i % 7 === 6)
          return everyNth.map(data => Math.round(data.sum))
        } else {
          return this.data.map(data => Math.round(data.sum))
        }
      } else {
        return []
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.drawChart()
      }
    }
  },
  mounted () {
    this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 360)
    if (this.mode === 'simple') {
      this.gradient.addColorStop(0, 'rgba(38, 188, 34, 0.6)')
      this.gradient.addColorStop(0.7, 'rgba(255, 223, 0, 0)')
      this.gradient.addColorStop(1, 'rgba(0, 0, 0, 0)')
    } else {
      this.gradient.addColorStop(0, 'rgba(38, 188, 34, 0.6)')
      this.gradient.addColorStop(1, 'rgba(255, 223, 0, 0.1)')
    }
    this.drawChart()
  },
  methods: {
    drawChart () {
      this.renderChart({
        labels: this.labels,
        datasets: [
          {
            label: this.label,
            fill: true,
            backgroundColor: this.gradient,
            borderColor: 'rgba(38, 188, 34, 0.7)',
            pointBackgroundColor: '#FFFFFF',
            pointHoverBackgroundColor: '#FFFFFF',
            pointBorderColor: '#26BC22',
            pointHoverBorderColor: '#ED5656',
            hitRadius: 10,
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBorderWidth: 2,
            pointHoverBorderWidth: 2,
            data: this.nums
          }
        ] }, this.options)
    }
  }
}
</script>
