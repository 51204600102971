import { server } from './index'

let pointsApi = {
  all (id, data) {
    return server.get(`/user/available/points/${id}`, data)
  },
  category (id) {
    return server.get(`/structure/category/${id}`)
  },
  edit (id, data) {
    return server.patch(`/structure/point/${id}`, data)
  },
  add (id, data) {
    return server.post(`/structure/point/${id}`, data)
  },
  terminalEdit (id, data) {
    return server.patch(`/structure/terminal/${id}`, data)
  },
  terminalAdd (id, data) {
    return server.post(`/structure/terminal/${id}`, data)
  },
  addImage (id, data) {
    return server.post(`/structure/point/addImage/${id}`, data)
  },
  removeImage (id, data) {
    return server.post(`/structure/point/removeImage/${id}`, data)
  },
  addLogo (id, data) {
    return server.post(`/structure/point/addLogo/${id}`, data)
  },
  removeLogo (id, data) {
    return server.post(`/structure/point/removeLogo/${id}`, data)
  },
  deletePoint (clubId, ids) {
    return server.delete(`/structure/point/delete/${clubId}`, { data: { ids } })
  },
  getCashiers (clubId) {
    return server.get(`/remote/cashiers/${clubId}`)
  },
  addCashiers (clubId, data) {
    return server.post(`/remote/cashiers/${clubId}`, data)
  },
  deleteCashiers (clubId, data) {
    return server.delete(`/remote/cashiers/${clubId}`, { data: { ids: data } })
  }
}

export { pointsApi }
