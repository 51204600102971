<template>
  <div class="graph">
    <template
      v-if="data.length"
    >
      <div
        :class="{'graph__simple' : mode === 'simple'}"
      >
        <app-dashboard-graph
          :data="data"
          :label="label"
          :mode="mode"
        />
      </div>
      <div
        v-if="descr"
        class="graph__descr"
      >
        {{ descr }}
      </div>
    </template>
    <div
      v-else
      class="graph__empty"
    >
      Данные для отображения отсутствуют
    </div>
  </div>
</template>

<script>
import DashboardGraph from '@/components/Club/ClubDashboards/DashboardGraph'

export default {
  name: 'DashboardDiagramModule',
  components: {
    'app-dashboard-graph': DashboardGraph
  },
  props: {
    data: {
      type: Array,
      require: true,
      default: () => []
    },
    label: {
      type: String,
      require: true,
      default: ''
    },
    mode: {
      type: String,
      require: false,
      default: 'full' // simple or full
    },
    descr: {
      type: String,
      require: true,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.graph {
  margin-top: 15px;
  width: 100%;
  &__empty {
    padding: 50px 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &__descr {
    width: 100%;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: #9E9E9E;
    padding-right: 5px;
  }
  &__simple {
    height: 130px;
    width: 100%;
    position: relative;
    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
