import { retailsApi } from '@/api/retails'
import { pointsApi } from '@/api/points'
import { juridicalApi } from '@/api/juridical'
import { clubsApi } from '../../../../api/clubs'
import Vue from 'vue'
import { formatMixin } from '@/mixins'

const getDefaultState = () => {
  return {}
}

export default {
  namespaced: true,
  state: () => ({
    clubData: {},
    retails: [],
    points: [],
    juridical: [],
    categories: [],
    remoteClub: {},
    loadingRetails: false,
    loadingPoints: false,
    loader: false,
    pointsStatus: '', // ok loading error
    deleteConfirm: false,
    cashiers: []
  }),
  getters: {
    getClubData: (state) => state.clubData || {},
    getClubLogo: (state) => {
      if (state.clubData.logo !== 'null') {
        let path = process.env.NODE_ENV === 'development' ? 'http://localhost:8086/files/' : '/files/'
        return path + state.clubData.logo
      } else {
        return '/img/emptyClub.jpg'
      }
    },
    getRemoteClub: (state) => state.remoteClub,
    getRetails: state => state.retails,
    getRetailById: state => (retailId) => {
      return state.retails.find(retail => retail.id === retailId)
    },
    getRetailsIds: state => {
      return state.retails.map(r => {
        return r.id
      })
    },
    getRetailsLength: state => {
      return state.retails.length
    },
    getPoints: state => state.points,
    getPointsByRetailId: state => (networkId) => {
      return state.points.filter(point => {
        if (point.networkId === networkId) {
          return point
        }
      })
    },
    getPointTitle: state => (id) => {
      let point = state.points.find(point => {
        return point.id === id
      })
      if (point) return point.title
    },
    getPointById: state => (id) => {
      return state.points.find(point => {
        return point.id === +id
      })
    },
    getPointsTitle: state => {
      return state.points.map(p => {
        return p.title
      })
    },
    getPoint: state => pointId => {
      return state.points.find(p => {
        return p.id === pointId
      })
    },
    getPointsLength: state => {
      return state.points.length
    },
    getTerminals: state => {
      return state.points
        .map(el => el.terminals)
        .flat(1)
        .map(el => { return el.id })
    },
    getJuridical: state => state.juridical,
    getJuridicalByAvailablePoints: (state, getters, rootState, rootGetters) => {
      if (rootGetters.getUserPoints.length) {
        const availableJuridical = new Set()
        rootGetters.getUserPoints.forEach(point => {
          state.juridical.forEach(jur => {
            let pointById = getters.getPoint(point)
            if (pointById && jur.id === pointById.legacyCliId) {
              availableJuridical.add(jur)
            }
          })
        })
        return [...availableJuridical]
      } else {
        return getters.getJuridical
      }
    },
    getJuridicalById: state => (id) => {
      return state.juridical.find(jur => {
        return jur.id === id
      })
    },
    getCategories: state => state.categories,
    getCategoryById: state => (id) => {
      return state.categories.find(cat => {
        return cat.id === id
      })
    },
    getStructureWithClub: (state, getters) => {
      let club = state.remoteClub
      let ret = JSON.parse(JSON.stringify(state.retails)).map(ret => {
        ret.treeType = 'retail'
        ret.treeId = 'retail' + ret.id
        let points = JSON.parse(JSON.stringify(getters.getPointsByRetailId(ret.id))).map(point => {
          point.treeType = 'point'
          point.treeId = 'point' + point.id
          point.terminals.map(term => {
            term.treeType = 'terminal'
            term.treeId = 'terminal' + term.title + term.id
            return term
          })
          return point
        })
        ret.terminals = points
        return ret
      })
      return [
        {
          ...club,
          treeType: 'club',
          id: club.clubId,
          treeId: 'club' + club.clubId,
          terminals: ret
        }
      ]
    },
    getStructure: (state, getters) => {
      let structure = JSON.parse(JSON.stringify(state.retails))
      return structure.map(ret => {
        ret.treeType = 'retail'
        ret.treeId = 'retail' + ret.id
        let points = JSON.parse(JSON.stringify(getters.getPointsByRetailId(ret.id))).map(point => {
          point.treeType = 'point'
          point.treeId = 'point' + point.id
          return point
        })
        ret.children = points
        return ret
      })
    },
    getLoader: state => state.loader,
    getPointsStatus: state => state.pointsStatus,
    getDeleteConfirm: state => state.deleteConfirm,
    getCashiersByPointId: state => id => {
      return state.cashiers.filter(el => el.retailPointId === id)
    }
  },
  mutations: {
    SET_CLUB_DATA (state, data) {
      state.clubData = data
    },
    SET_REMOTE_CLUB (state, data) {
      state.remoteClub = { ...state.remoteClub, ...data, treeType: 'club' }
    },
    SET_JURIDICAL (state, data) {
      state.juridical = data
    },
    SET_RETAILS (state, data) {
      state.retails = data.sort((a, b) => {
        if (/^_/i.test(a.title)) {
          return 1
        } else if (/^_/i.test(b.title)) {
          return -1
        }
        return ('' + a.title.replace(' ', '')).localeCompare(b.title.replace(' ', ''))
      })
    },
    SET_LOADING_RETAILS (state, data) {
      state.loadingRetails = data
    },
    EDIT_RETAILS (state, data) {
      let index = state.retails.findIndex((item, i) => item.id === data.id)
      Vue.set(state.retails, index, data)
    },
    ADD_RETAILS (state, data) {
      state.retails.push(data)
      state.retails = state.retails.sort((a, b) => {
        if (/^_/i.test(a.title)) {
          return 1
        } else if (/^_/i.test(b.title)) {
          return -1
        }
        return ('' + a.title.replace(' ', '')).localeCompare(b.title.replace(' ', ''))
      })
    },
    DELETE_RETAIL (state, data) {
      state.retails = state.retails.filter(r => r.id !== data)
    },
    SET_POINTS (state, data) {
      state.points = data.sort((a, b) => {
        if (a.title < b.title) {
          return -1
        } else {
          return 1
        }
      })
    },
    SET_LOADING_POINTS (state, data) {
      state.loadingPoints = data
    },
    ADD_POINT (state, data) {
      Vue.set(state.points, state.points.length, data)
    },
    EDIT_POINT (state, data) {
      let index = state.points.findIndex(point => point.id === data.id)
      Vue.set(state.points, index, data)
    },
    DELETE_POINT (state, data) {
      state.points = state.points.filter(p => p.id !== data)
    },
    SET_CATEGORIES (state, data) {
      state.categories = data
    },
    ADD_TERMINAL (state, data) {
      let index = state.points.findIndex(point => point.id === data.retailPointId)
      state.points[index].terminals.push(data)
    },
    EDIT_TERMINAL (state, data) {
      let indexPoint = state.points.findIndex(point => point.id === data.retailPointId)
      let indexTerm = state.points[indexPoint].terminals.findIndex(term => term.id === data.id)
      // state.points[indexPoint].terminals[indexTerm] = data
      Vue.set(state.points[indexPoint].terminals, indexTerm, data)
    },
    CLEAR (state) {
      Object.assign(state, getDefaultState())
    },
    SET_LOADER (state, data) {
      state.loader = data
    },
    SET_POINTS_STATUS (state, data) {
      state.pointsStatus = data
    },
    SET_DELETE_CONFIRM (state, data) {
      state.deleteConfirm = data
    },
    SET_CASHIERS (state, data) {
      state.cashiers = data
    }
  },
  actions: {
    async setClubData ({ commit }, club) {
      commit('SET_CLUB_DATA', club)
    },
    async setRemoteClub ({ commit }, id) {
      try {
        let res = await clubsApi.getRemoteClubData(id)
        commit('SET_REMOTE_CLUB', res.data)
        return res
      } catch (e) {
        throw e
      }
    },
    async editRemoteClubData ({ commit }, data) {
      try {
        let res = await clubsApi.editRemoteClubData(data.clubId, data.data)
        commit('SET_REMOTE_CLUB', res.data)
        return res
      } catch (e) {
        throw e
      }
    },
    async setJuridical ({ commit }, id) {
      try {
        let res = await juridicalApi.all(id)
        let data = res ? res.data.content : []
        commit('SET_JURIDICAL', data)
      } catch (e) {
        commit('SET_JURIDICAL', [])
        throw e
      }
    },
    async setRetails ({ commit, state }, id) {
      if (!state.retails.length && !state.loadingRetails) {
        commit('SET_LOADING_RETAILS', true)
        try {
          // await timeout(3000)
          let res = await retailsApi.all(id)
          let data = res ? res.data.content : []
          commit('SET_RETAILS', data)
          commit('SET_LOADING_RETAILS', false)
          return data
        } catch (e) {
          commit('SET_RETAILS', [])
          commit('SET_LOADING_RETAILS', false)
        }
      }
    },
    async addRetail ({ commit }, data) {
      try {
        let res = await retailsApi.add(data.clubId, data.data)
        commit('ADD_RETAILS', res.data)
        return res
      } catch (err) {
        console.log(err)
        throw err
      }
    },
    async editRetail ({ commit }, data) {
      commit('SET_LOADER', true)
      try {
        let res = await retailsApi.edit(data.clubId, data.data)
        commit('EDIT_RETAILS', res.data)
        return true
      } catch (err) {
        console.log(err)
        throw err
      } finally {
        commit('SET_LOADER', false)
      }
    },
    async deleteRetail ({ commit, state }, { clubId, ids }) {
      commit('SET_LOADER', true)
      try {
        const res = await retailsApi.deleteRetail(clubId, ids)
        commit('DELETE_RETAIL', res.data[0].id)
        commit('SET_DELETE_CONFIRM', !state.deleteConfirm)
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_LOADER', false)
      }
    },
    async setPoints ({ commit, state }, clubId) {
      if (!state.points.length && !state.loadingPoints) {
        commit('SET_LOADING_POINTS', true)
        commit('SET_POINTS_STATUS', 'loading')
        try {
          let res = await pointsApi.all(clubId)
          let points = res ? res.data.content : []
          commit('SET_POINTS', points)
          commit('SET_LOADING_POINTS', false)
          commit('SET_POINTS_STATUS', 'ok')
          return points
        } catch (e) {
          commit('SET_POINTS', [])
          commit('SET_LOADING_POINTS', false)
          commit('SET_POINTS_STATUS', 'error')
          throw e
        }
      }
    },
    async addPoint ({ commit, dispatch, getters }, { clubId, data }) {
      commit('SET_LOADER', true)
      const { image, logotype } = data
      try {
        let res = await pointsApi.add(clubId, data)
        let { id } = res.data
        await dispatch('setLogoAndImage', { logotype, image, clubId, id })
        commit('SET_POINTS', [])
        commit('SET_LOADING_POINTS', false)
        await dispatch('setPoints', clubId)
        const point = getters.getPointById(id)
        return point
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_LOADER', false)
      }
    },
    async editPoint ({ commit, dispatch }, { clubId, data }) {
      commit('SET_LOADER', true)
      const { id, image, logotype } = data
      Object.keys(data.address).forEach(i => {
        if (!data.address[i]) data.address[i] = ''
      })
      try {
        const logoRes = await dispatch('setLogoAndImage', { logotype, image, clubId, id })
        if (logoRes) data.logotype = logoRes
        let res = await pointsApi.edit(clubId, data)
        commit('EDIT_POINT', res.data)
        return res
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_LOADER', false)
      }
    },
    async deletePoint ({ commit, state }, { clubId, ids }) {
      commit('SET_LOADER', true)
      try {
        const res = await pointsApi.deletePoint(clubId, ids)
        commit('DELETE_POINT', res.data[0].id)
        commit('SET_DELETE_CONFIRM', !state.deleteConfirm)
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_LOADER', false)
      }
    },
    async setCategories ({ commit }, id) {
      try {
        let res = await pointsApi.category(id)
        let data = res ? res.data : []
        commit('SET_CATEGORIES', data)
      } catch (err) {
        commit('SET_CATEGORIES', [])
      }
    },
    async addTerminal ({ commit }, data) {
      commit('SET_LOADER', true)
      try {
        let res = await pointsApi.terminalAdd(data.clubId, data.data)
        commit('ADD_TERMINAL', res.data)
        return res
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_LOADER', false)
      }
    },
    async editTerminal ({ commit }, data) {
      try {
        let res = await pointsApi.terminalEdit(data.clubId, data.data)
        commit('EDIT_TERMINAL', res.data)
        return res
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async addPointImage ({ commit }, { clubId, id, image }) {
      let form = new FormData()
      form.append('image', image)
      form.append('pointId', id)
      try {
        let res = await pointsApi.addImage(clubId, form)
        return res
      } catch (e) {
        console.log(e)
        return 'Не удалось загрузить изображение.'
      }
    },
    async removePointImage ({ commit }, { clubId, id }) {
      try {
        let res = await pointsApi.removeImage(clubId, { id })
        return res
      } catch (e) {
        console.log(e)
        return 'Не удалось загрузить изображение.'
      }
    },
    async addPointLogo ({ commit }, { clubId, id, logotype }) {
      let form = new FormData()
      form.append('image', logotype)
      form.append('pointId', id)
      try {
        let res = await pointsApi.addLogo(clubId, form)
        return res
      } catch (err) {
        console.log(err)
        return 'Не удалось загрузить изображение.'
      }
    },
    async removePointLogo ({ commit }, { clubId, id }) {
      try {
        let res = await pointsApi.removeLogo(clubId, { id })
        return res
      } catch (e) {
        console.log(e)
        return 'Не удалось загрузить изображение.'
      }
    },
    async setLogoAndImage ({ dispatch }, { logotype, image, clubId, id }) {
      let logoRes
      try {
        if (logotype !== undefined && typeof logotype !== 'string') {
          if (logotype === null) {
            await dispatch('removePointLogo', { clubId, id })
          } else {
            await dispatch('removePointLogo', { clubId, id })
            logoRes = await dispatch('addPointLogo', { clubId, id, logotype })
          }
        }
        if (image !== undefined && typeof image !== 'string') {
          if (image === null) {
            await dispatch('removePointImage', { clubId, id })
          } else {
            await dispatch('removePointImage', { clubId, id })
            await dispatch('addPointImage', { clubId, id, image })
          }
        }
        if (logoRes) return logoRes.data.logotype
        else return ''
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async setCashiers ({ commit }, clubId) {
      commit('SET_LOADER', true)
      try {
        const res = await pointsApi.getCashiers(clubId)
        commit('SET_CASHIERS', res.data.content)
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_LOADER', false)
      }
    },
    async addCashiers ({ commit, dispatch }, { clubId, cashiers, id }) {
      commit('SET_LOADER', true)
      try {
        cashiers.forEach((item, index) => {
          cashiers[index].phone = formatMixin.methods.formatPhoneForServer(item.phone)
        })
        await pointsApi.addCashiers(clubId, { cashiers, id })
        await dispatch('setCashiers', clubId)
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_LOADER', false)
      }
    },
    async deleteCashiers ({ commit, dispatch }, data) {
      commit('SET_LOADER', true)
      try {
        await pointsApi.deleteCashiers(data.clubId, data.ids)
        await dispatch('setCashiers', data.clubId)
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_LOADER', false)
      }
    }
  }
}
