import { transactionsApi } from '@/api/transactions'
import Vue from 'vue'

import { formatMixin } from '@/mixins/index'

const getDefaultState = () => {
  return {}
}

export default {
  namespaced: true,
  state () {
    return {
      transactions: [],
      transactionsParams: {
        requestDate: {
          from: '',
          to: ''
        },
        fio: '',
        cardNum: '',
        phone: '',
        externalRequestId: '',
        billCode: '',
        terminalIds: []
      },
      requestStatus: '', // ok, pending, error
      errorStatus: '', // 404, 500
      page: {
        page: 0,
        size: 25,
        sort: [{
          'property': 'requestDate',
          'direction': 'DESC'
        }]
      },
      totalElements: 0,
      total: {},
      transactionBill: null,
      allTransactions: []
    }
  },
  getters: {
    getTransactions: (state) => state.transactions,
    getTotalElements: (state) => state.totalElements,
    getRequestStatus: (state) => state.requestStatus,
    getErrorStatus: (state) => state.errorStatus,
    getTotal: (state) => state.total,
    getTransactionsParams: (state) => state.transactionsParams,
    getPage: (state) => state.page,
    getTransactionBill: state => state.transactionBill,
    getAllTransactions: state => state.allTransactions
  },
  mutations: {
    SET_TRANSACTIONS (state, data) {
      if (data.length) {
        data.forEach(el => {
          el.isSelectable = el.requestState === 'READY'
        })
        state.transactions.push(...data)
      } else {
        state.transactions = []
      }
    },
    SET_REQUEST_STATUS (state, val) {
      state.requestStatus = val
    },
    SET_ERROR_STATUS (state, val) {
      state.errorStatus = val
    },
    SET_TOTAL_ELEMENTS (state, val) {
      state.totalElements = val
    },
    SET_TOTAL (state, val) {
      state.total = val
    },
    SET_TRANSACTIONS_PARAMS (state, data) {
      if (data.clear) {
        state.transactionsParams = data.params
      } else {
        state.transactionsParams = { ...state.transactionsParams, ...data }
      }
    },
    CLEAR (state) {
      Object.assign(state, getDefaultState())
    },
    SET_PAGE (state, val) {
      if (val === undefined) {
        state.page.page = state.page.page + 1
      } else {
        state.page.page = val
      }
    },
    /**
     *
     * @param state
     * @param val
     * @param val.property
     * @param val.direction
     * @constructor
     */
    SET_SORT (state, val) {
      state.page.sort[0] = val
      Vue.set(state.page.sort, 0, val)
    },
    SET_TRANSACTION_BILL (state, data) {
      state.transactionBill = { ...data }
    },
    CONFIRM_TRANSACTIONS (state, data) {
      data.forEach(transaction => {
        transaction.isSelectable = transaction.requestState === 'READY'
        const found = state.transactions.find(el => el.requestId === transaction.requestId)
        if (found) state.transactions.splice(state.transactions.indexOf(found), 1, transaction)
      })
    },
    SET_ALL_TRANSACTIONS (state, data) {
      state.allTransactions = data
    }
  },
  actions: {
    async loadTransactions (store, data) {
      store.commit('SET_REQUEST_STATUS', 'pending')
      if (store.state.page.page === 0) {
        store.commit('SET_TRANSACTIONS', [])
      }

      // TODO: Проверять доступные точки в ноде.

      let phone = ''
      if (store.state.transactionsParams.phone) {
        phone = formatMixin.methods.formatPhoneForServer(store.state.transactionsParams.phone)
      }

      let { ...search } = store.state.transactionsParams
      if (data.nonConfirmed) search.requestState = 'NEW'

      try {
        let r = await transactionsApi.transactions(data.id, { search: { ...search, phone }, page: store.state.page })
        if (r.data.page.content.length) {
          console.log(r.data)
          store.commit('SET_REQUEST_STATUS', 'ok')
          store.commit('SET_TRANSACTIONS', r.data.page.content)
          store.commit('SET_TOTAL_ELEMENTS', r.data.page.totalElements)
          store.commit('SET_TOTAL', r.data.total)
        } else {
          store.commit('SET_REQUEST_STATUS', 'error')
          store.commit('SET_ERROR_STATUS', 404)
        }
      } catch (e) {
        store.commit('SET_REQUEST_STATUS', 'error')
        store.commit('SET_ERROR_STATUS', 500)
      }
    },
    async loadAllTransactions (store, id) {
      // этот запрос используется для скачивания данных в ексель, он cкопирован из заапросе loadTransactions,
      // отличается тем что запрашивает все данные в соответсвии с фильтром
      if (store.state.totalElements.length === 0) {
        this.dispatch('showAlert', { text: `Нет данных для скачивания.`, type: 'error' })
        return false
      }
      this.dispatch('showAlert', { text: `Началась загрузка Excel таблицы, пожалуйста не покидайте страницу.`, type: 'success' })
      let phone = ''
      if (store.state.transactionsParams.phone) {
        phone = formatMixin.methods.formatPhoneForServer(store.state.transactionsParams.phone)
      }
      let { ...search } = store.state.transactionsParams

      try {
        let r = await transactionsApi.transactions(id, { search: { ...search, phone },
          page: {
            page: 0,
            size: store.state.totalElements,
            sort: [{
              'property': 'requestDate',
              'direction': 'DESC'
            }] }
        })
        if (r.data.page.content.length) {
          const data = Array.from(r.data.page.content)
          store.commit('SET_ALL_TRANSACTIONS', data)
        } else {
          this.dispatch('showAlert', { text: `Произошла ошибка при попытке скачать таблицу Excel.`, type: 'error' })
        }
      } catch (e) {
        this.dispatch('showAlert', { text: `Произошла ошибка при попытке скачать таблицу Excel.`, type: 'error' })
      }
    },
    setTransactionsParams (store, data) {
      store.commit('SET_TRANSACTIONS_PARAMS', data)
      store.commit('SET_PAGE', 0)
    },
    setPage ({ commit }, val) {
      commit('SET_PAGE', val)
    },
    setSort ({ commit }, val) {
      commit('SET_SORT', val)
      commit('SET_PAGE', 0)
      commit('SET_TRANSACTIONS', [])
    },
    async setTransactionBill ({ commit }, { id, billInfo }) {
      const body = {
        data: { requestIds: [`${billInfo.requestId}`] }
      }
      try {
        const res = await transactionsApi.billSearch(id, body)
        commit('SET_TRANSACTION_BILL', res.data.content[0])
        return res.data.content[0]
      } catch (e) {
        console.log(e)
        // throw e
        return e
      }
    },
    async confirm ({ commit }, { id, data }) {
      commit('SET_REQUEST_STATUS', 'pending')
      try {
        const res = await transactionsApi.confirm(id, data)

        if (res.data.success) {
          commit('CONFIRM_TRANSACTIONS', res.data.success)
          this.dispatch('showAlert', { text: 'Операции успешно подтверждены.', type: 'success' })
        }

        if (res.data.errors && res.data.errors.length) {
          const v = res.data.errors.join(', ')
          this.dispatch('showAlert', { text: `Произошла ошибка при подтверждении операций ${v}.`, type: 'error', halfSuccess: false, timeout: -1 })
        }
      } catch (e) {
        console.log(e)
        // throw e
        const v = data.join(', ')
        this.dispatch('showAlert', { text: `Произошла ошибка при подтверждении операций ${v}.`, type: 'error', halfSuccess: false, timeout: -1 })
        return e
      } finally {
        commit('SET_REQUEST_STATUS', 'ok')
      }
    }
  }
}
