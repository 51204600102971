<template>
  <v-list
    class="nav-list overflow-y-auto"
    dense
  >
    <slot />

    <template>
      <nav-list-item
        v-for="club in clubs"
        :key="club._id"
        :id="`club-${club.id}`"
        :item="club"
        :active="club.id === +clubId"
        mode="child"
        activator
        @click="$emit('click', club)"
      />
    </template>
  </v-list>
</template>

<script>
import NavListItem from '@/components/nav/components/NavListItem'

export default {
  name: 'NavListClubs',
  components: {
    'nav-list-item': NavListItem
  },
  props: {
    clubs: {
      type: Array,
      required: true
    },
    clubsRoute: {
      type: Object,
      required: true
    }
  },
  computed: {
    clubId () {
      return this.$route.params.id
    }
  }
}
</script>

<style lang="scss">
</style>
