<template>
  <div class="reports-new__info">
    <app-tabs
      class="reports-new__info-tabs"
      mode="s"
      :value.sync="tab"
      :items="tabs"
    />
    <component
      class="reports-new__info-tab"
      :is="component"
      :data="data"
      :clubId="clubId"
    />
  </div>
</template>

<script>
import Tabs from '@/components/UI-Kit/Tabs'
import TemplateInfo from '@/components/Club/ClubReports/ReportsInfo/TemplateInfo'
import ParamsInfo from '@/components/Club/ClubReports/ReportsInfo/ParamsInfo'
import RecipientsInfo from '@/components/Club/ClubReports/ReportsInfo/RecipientsInfo'

export default {
  name: 'AutoReportsInfo',
  components: {
    'app-tabs': Tabs,
    'app-template': TemplateInfo,
    'app-parameters': ParamsInfo,
    'app-recipients': RecipientsInfo
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    clubId: {
      type: [Number, String],
      required: true
    }
  },
  data: () => ({
    tab: 0,
    tabs: [
      { val: 'template', title: 'Шаблон' },
      { val: 'parameters', title: 'Параметры' },
      { val: 'recipients', title: 'Получатели' }
    ]
  }),
  computed: {
    component () {
      return `app-${this.tabs[this.tab].val}`
    }
  }
}
</script>

<style lang="scss">
.reports-new__info {
  width: 100%;
  &-tab {
    padding-top: 20px;
    & .row {
      margin-bottom: 10px !important;
    }
    & .col {
      padding: 0 12px;
      font-size: $font-14;
      color: $icon;
      & ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }
    .template-info__value {
      text-align: left;
      color: $primary;
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .reports-new__info {
    &-tab {
      & .col {
        color: $primary;
      }
      .template-info__value {
        text-align: right;
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .reports-new__info {
    &-tab {
      & .col {
        font-size: $font-17;
      }
    }
  }
}
</style>
