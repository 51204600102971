<template>
  <v-list-group class="user-item">
    <template v-slot:activator>
      <div class="v-list-item__head d-flex">
        <v-list-item-content>
          <v-col class="col-auto align-self-start align-self-sm-center pa-0 ml-0 mb-0 mr-3 mr-lg-4 mr-xl-7 order-1 order-sm-1">
            <v-list-item-avatar
              :class="[
              'user-item__avatar ma-0',
              { 'bmsadmin bmsadmin--bottom': user.BMSAdmin }
            ]"
              :size="$vuetify.breakpoint.mdAndUp ? 60 : 40"
            >
              <v-img
                v-if="user.avatar"
                :src="user.avatar"
                :alt="user.name"
              />
              <picture
                v-else
                v-html="require('@/assets/img/icons/user-red.svg')"
              />
            </v-list-item-avatar>
          </v-col>
          <v-col class="col-9 col-sm-4 col-md-4 col-lg-4 order-2 order-sm-2 pa-0 mb-3 mb-sm-0">
            <v-list-item-title class="mb-1">
              <span class="user-item__name">
                {{ user.name || 'No name' }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle class="d-flex flex-column flex-sm-row flex-md-column flex-lg-row">
              <span class="user-item__login mr-sm-3 mb-1 mb-sm-0 mb-md-1 mb-lg-0">
                {{ user.login }}
              </span>
              <app-link
                class="user-item__mail"
                v-if="user.email && $vuetify.breakpoint.lgAndUp"
                :link="`mailto:${user.email}`"
                icon="mail"
              >
                {{ user.email }}
              </app-link>
            </v-list-item-subtitle>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.lgAndUp"
            class="col-3 col-xl-4 order-4 order-sm-3"
          >
            <div class="user-item__subtitle body-2 mb-1">
              Специализация
            </div>
            <span class="body-1">
              {{ user.comment || 'Не указано' }}
            </span>
          </v-col>
          <v-col class="col-auto order-5 order-sm-4 pa-0">
            <app-button
              v-if="isBMSAdmin"
              mode="outline"
              size="s"
              @click="showUserActionsInfo(user.login)"
            >
              Действия пользователя
            </app-button>
          </v-col>
          <v-col
            class="justify-end d-flex col-auto order-6 order-sm-5 pa-0 ml-auto"
          >
            <router-link
              v-if="isWritePermission()"
              :to="{ name: 'UsersEdit', params: { login: user.login }}"
            >
              <app-button-tooltip
                icon="edit"
                message="Редактировать"
                :size="[20, 20]"
                className="mr-7 mr-sm-4 mr-lg-3 mr-xl-7"
              />
            </router-link>
            <app-button-tooltip
              v-if="isWritePermission()"
              icon="trash"
              message="Удалить"
              :size="[16, 20]"
              @click="removeUser()"
            />
          </v-col>
          <div
            class="v-list-item__head-arrow col-auto d-flex align-self-start align-self-sm-center order-3 order-sm-6 pa-0 ml-auto ml-sm-4 ml-lg-3 ml-xl-7 mt-1 mt-sm-0"
            v-html="require(`@/assets/img/icons/arrow.svg`)"
          />
        </v-list-item-content>
      </div>
    </template>
    <div
      class="v-list-item__bottom row ma-0"
      ref="footer"
    >
      <v-col
        class="user-item__structure pa-0 col-12 col-lg-4"
      >
        <p class="user-item__subtitle mb-1">
          Доступ к программам
        </p>
        <div
          v-if="!user.structure.length"
          class="mb-0"
        >
          Все программы
        </div>
        <div v-else>
          <template v-for="item in user.structure">
            <template>
              <span :key="`${item.club.id}-title`">
                {{ item.club.title }}:
              </span>
              <span
                :key="`${item.club.id}-retailscount`"
                class="user-item__retails"
              >
                <span v-if="!item.retails.length">Все сети</span>
                <span v-else>{{ item.retails.length }} {{ declension(item.retails.length, 'retails') }}</span>
              </span>
              <span
                :key="`${item.club.id}-pointscount`"
                class="user-item__retails"
              >
                <span v-if="!getPoints(item.retails).length">Все точки</span>
                <span v-else>{{ getPoints(item.retails).length }} {{ declension(getPoints(item.retails).length, 'points') }}</span>
              </span>
            </template>
          </template>
        </div>
      </v-col>
      <v-col
        class="user-item__modules pt-4 pt-lg-0 pb-0 col-12 col-lg-6"
      >
        <p class="user-item__subtitle mb-1">
          Доступ к разделам
        </p>
        <div
          v-if="!user.modules.length"
          class="d-inline-flex flex-nowrap align-center mr-4"
        >
          <p class="mb-0 mr-1">
            Все разделы
          </p>
          <app-button-permission
            mode="static"
            :value="'WRITE'"
          />
        </div>
        <div v-else>
          <div
            v-for="module in userModules"
            :key="module.name"
            class="d-inline-flex flex-nowrap align-center mr-4"
          >
            <p class="mb-0 mr-1">
              {{ module.meta.title }}
            </p>
            <app-button-permission
              mode="static"
              :value="module.permission"
            />
          </div>
        </div>
      </v-col>
    </div>
  </v-list-group>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Button from '@/components/UI-Kit/buttons/Button'
import ButtonTooltip from '@/components/UI-Kit/buttons/ButtonTooltip'
import Link from '@/components/UI-Kit/links/Link'
import ButtonPermission from '@/components/UI-Kit/buttons/ButtonPermission'

export default {
  name: 'UserItem',
  components: {
    'app-button': Button,
    'app-button-tooltip': ButtonTooltip,
    'app-link': Link,
    'app-button-permission': ButtonPermission
  },
  props: {
    user: {
      type: Object,
      require: true
    }
  },
  computed: {
    ...mapGetters([
      'isBMSAdmin',
      'isWritePermission'
    ]),
    userModules () {
      return this.$store.getters.getUserModulesWithMeta(this.user.modules)
    }
  },
  methods: {
    ...mapActions([
      'showPopup'
    ]),
    ...mapActions('logs', {
      changeParams: 'changeParams',
      changePage: 'changePage',
      loadLogs: 'loadLogs'
    }),
    removeUser () {
      this.showPopup({
        name: 'PopupConfirmation',
        data: { action: 'removeUserAction', _id: this.user._id }
      })
    },
    // getPointTitle (clubId, pointId) {
    //   return this.$store.getters[`${clubId}/global/getPointTitle`](pointId) || ''
    // },
    getPoints (retails) {
      return retails.map(el => { return el.points }).flat()
    },
    declension (val, type) {
      let variants = ['точка', 'точки', 'точек']
      if (type === 'retails') {
        variants = ['сеть', 'сети', 'сетей']
      }
      let n = Math.abs(val) % 100
      let n1 = n % 10
      if (n > 10 && n < 20) { return variants[2] }
      if (n1 > 1 && n1 < 5) { return variants[1] }
      if (n1 === 1) { return variants[0] }
      return variants[2]
    },
    showUserActionsInfo (userLogin) {
      this.changeParams({ from: '', to: '', login: userLogin, club: '', module: '' })
      this.changePage(0)
      this.loadLogs()
      this.$router.push({ name: 'UsersActions', params: { previous: 'UsersMain' } })
    }
  }
}
</script>

<style lang="scss">
  .user-item {
    box-shadow: $shadow;
    padding: 15px 15px 0;
    cursor: pointer;
    transition: .2s linear;
    margin-bottom: 10px;
    .v-list-group__header {
      padding: 0;
      &__append-icon {
        display: none;
      }
    }
    .v-list-item__head,
    .v-list-item__bottom {
      width: 100%;
    }
    .v-list-item__head {
      &-arrow {
        transition: transform $primary-transition;
        svg {
          fill: $icon;
        }
      }
    }
    .v-list-item__bottom {
      padding: 15px 0;
      border-top: 1px solid $separator;
    }
    .v-list-item__content {
      padding: 0 0 15px;
      overflow: visible;
    }
    &__name {
      font-size: $font-20;
    }
    &__login {
      font-size: $font-14;
      color: $icon;
    }
    &__mail {
      font-size: $font-14;
    }
    &__avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      box-shadow: $shadow-card-logo;
      border-radius: $border-radius;
      svg {
        width: 100%;
        height: 100%;
        fill: $accent-disabled;
        display: flex;
      }
    }
    &__subtitle {
      font-size: $font-12;
      line-height: 1;
      color: $icon;
    }
    &__retails {
      color: $icon;
    }
    &__structure,
    &__modules {
      padding-left: 0;
      padding-right: 0;
      div {
        font-size: $font-14;
      }
    }
    &.v-list-group--active {
      .v-list-item__head {
        &-arrow {
          transform: rotate(180deg);
        }
      }
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    .user-item {
      .v-list-item__content {
        flex-wrap: nowrap;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    .user-item {
      &__name {
        font-size: $font-28;
      }
      &__login {
        font-size: $font-17;
      }
      &__mail {
        font-size: $font-17;
      }
      &__subtitle {
        font-size: $font-14;
      }
      &__structure,
      &__modules {
        div {
          font-size: $font-17;
        }
      }
    }
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    .user-item {
      padding: 15px 30px 0;
      &__modules {
        padding-left: 88px;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'xl-only')} {
    .user-item {
      &__modules {
        padding-left: 100px;
      }
    }
  }
</style>
