<template>
  <div>
    <div
      v-for="(retail, i) in getRetails()"
      :key="retail.id"
    >
      <v-row
        class="mt-0 mb-0"
        v-for="field in fields"
        :key="field.title"
      >
        <v-col class="col-12 col-sm-6">
          {{ field.title }}
        </v-col>

        <v-col
          class="col-12 col-sm-6 promotions-info__value"
          :class="[{ 'promotions-info__value--accent': field.value === 'retailNetworks' },
          { 'promotions-info__value--wrap': field.value === 'retailPoints' }]"
        >
          <template v-if="field.value === 'retailNetworks'">
            {{ retail.title }}
          </template>

          <template v-else-if="field.value === 'retailPoints'">
            {{ getPoints(retail.points, 0, visible) }}
          </template>

          <v-expand-transition>
            <span v-show="field.value === 'retailPoints' && expanded[retail.id]">
              {{ '\n' + getPoints(retail.points, visible) }}
            </span>
          </v-expand-transition>
        </v-col>
      </v-row>

      <v-expand-transition>
        <app-button
          v-if="retail.points.length > visible && !expanded[retail.id]"
          class="mb-3"
          mode="more"
          size="s"
          icon="arrow"
          @click="expand(retail.id)"
        >
          Показать еще
        </app-button>
      </v-expand-transition>

      <v-divider v-if="getRetails() && i < getRetails().length - 1" class="pt-2 pb-1" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/UI-Kit/buttons/Button'

export default {
  name: 'PromotionsInfoPoints',
  components: {
    'app-button': Button
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      fields: [
        { title: 'Сеть', value: 'retailNetworks' },
        { title: 'Точки', value: 'retailPoints' }
      ],
      expanded: {},
      visible: 10
    }
  },
  computed: {
    clubId () {
      return this.$route.params.id
    }
  },
  methods: {
    getRetails () {
      let points = []
      this.data.retailPoints.forEach(id => {
        let point = this.$store.getters[`${this.clubId}/global/getPointById`](id)
        if (point) points.push(point)
      })
      let ids = points.map(el => el.networkId)
      let retails = this.cutDublicates(ids).map(id => {
        return this.$store.getters[`${this.clubId}/global/getRetailById`](id)
      })
      retails.map(retail => {
        retail.points = points.filter(point => point.networkId === retail.id)
      })
      return retails
    },
    getPoints (points, start, end) {
      let v = points.slice(start, end)
      return v.map(el => el.title).join('\n')
    },
    cutDublicates (array) {
      let uniq = array.reduce((a, b) => {
        if (a.indexOf(b) < 0) a.push(b)
        return a
      }, [])
      return uniq
    },
    expand (id) {
      this.$set(this.expanded, id, !this.expanded[id])
    }
  }
}
</script>

<style lang="scss">

</style>
