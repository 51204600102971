<template>
  <v-list
    v-if="!singleProgramUser"
    :class="[
      `nav-list overflow-y-auto`
    ]"
    dense
  >
    <template>
      <nav-list-item
        v-for="route in routes"
        :key="route.name"
        :routeName="route.name"
        :item="route.meta"
        :link="route.path"
        :active="$route.name === route.name || ('children' in route && route.children.some(el => el.name === $route.name))"
        mode="parent"
        :drawer="drawer"
      />
    </template>

    <v-list-group
      v-model="opened"
      append-icon=""
      no-action
    >
      <template v-slot:activator >
        <nav-list-item
          :item="clubsRoute.meta"
          :active="$route.fullPath.includes(clubsRoute.path)"
          wrap
          mode="parent"
          :drawer="drawer"
        />
      </template>

      <slot />

      <!-- dropdown menu -->
      <template>
        <v-menu
          v-for="club in clubs"
          :key="club._id"
          content-class="nav-list__menu"
          transition="slide-x-transition"
          open-on-hover
          close-on-click
          close-on-content-click
          offset-x
          z-index="6"
          :open-delay="300"
          @input="$emit('click', club)"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <nav-list-item
                :id="`club-${club.id}`"
                :item="club"
                :active="club.id === +clubId"
                mode="child"
                activator
              />
            </div>
          </template>

          <v-list
            v-if="clubRoutes.length"
            class="nav-list__menu-list"
            @mouseover.native="setDrawer(false)"
            @mouseleave.native="setDrawer(true)"
          >
            <nav-list-item
              v-for="route in clubRoutes"
              :key="route.title"
              :item="route.meta"
              :link="`/clubs/${club.id}/${route.path}`"
              :active="$route.fullPath.includes(`/clubs/${club.id}/${route.path}`)"
              mode="child"
            />

            <!-- <nav-list-item
              v-if="isBMSAdmin"
              :item="{ title: 'Удалить', icon: 'trash'}"
              :popup="{
                name: 'PopupConfirmation',
                data: { action: 'removeClub', id: club.id }
              }"
              :active="false"
              mode="child"
            /> -->
          </v-list>
        </v-menu>
      </template>

    </v-list-group>
  </v-list>
  <div v-else>
    <nav-list-item
      v-for="route in singleProgramRoutes"
      :key="route.name"
      :item="route.meta"
      :link="route.path"
      :active="$route.name === route.name || ('children' in route && route.children.some(el => el.name === $route.name))"
      mode="parent"
      :drawer="drawer"
      single-program
    />
    <v-list class="nav-list overflow-y-auto pt-0">
      <template v-for="route in clubRoutes">
        <nav-list-item
          v-if="route.name !== 'ClubDashboard'"
          :key="route.title"
          :item="route.meta"
          :link="`/clubs/${clubs[0].id}/${route.path}`"
          :active="$route.fullPath.includes(`/clubs/${clubs[0].id}/${route.path}`)"
          mode="child"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NavListItem from '@/components/nav/components/NavListItem'

export default {
  name: 'NavList',
  props: {
    drawer: {
      type: Boolean,
      required: true
    },
    routes: {
      type: Array,
      required: true
    },
    clubs: {
      type: Array,
      required: true
    },
    clubsRoute: {
      type: Object,
      required: true
    },
    clubRoutes: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  components: {
    'nav-list-item': NavListItem
  },
  data () {
    return {
      opened: true
    }
  },
  computed: {
    ...mapGetters([
      'singleProgramUser'
    ]),
    clubId () {
      return this.$route.params.id
    },
    singleProgramRoutes () {
      if (this.routes.length > 1) {
        return [this.routes[0], this.routes[1]]
      } else return this.routes
    }
  },
  methods: {
    ...mapActions([
      'setDrawer'
    ])
  }
}
</script>

<style lang="scss">

</style>
