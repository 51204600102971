<template>
  <v-card class="popup popup--promotion">
    <v-card-title class="popup__title">
      Просмотр акции
      <app-button-tooltip
        v-if="isWritePermission()"
        icon="edit"
        message="Редактировать"
        :size="[20, 20]"
        @click="edit"
      />
    </v-card-title>

    <v-card-text class="popup__content">
      <app-promotions-info :data="data" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonTooltip from '@/components/UI-Kit/buttons/ButtonTooltip'
import PromotionsInfo from '@/components/Club/ClubPromotions/PromotionsInfo/Index'

export default {
  name: 'PopupPromotion',
  components: {
    'app-button-tooltip': ButtonTooltip,
    'app-promotions-info': PromotionsInfo
  },
  computed: {
    ...mapGetters([
      'popup',
      'isWritePermission'
    ]),
    data () {
      return this.popup.data
    },
    clubId () {
      return this.$route.params.id
    },
    storePath () {
      return `${this.clubId}/promotions`
    }
  },
  methods: {
    edit () {
      this.$router.push({ name: 'ClubPromotionsEdit', params: { promoId: this.data.id } })
    }
  }
}
</script>

<style lang="scss">
.popup--promotion {
  .popup__title {
    display: flex;
    justify-content: space-between;
  }
  .popup__content {
  }
  .popup__actions {
    justify-content: center;
  }
}
</style>
