<template>
  <div class="periodPiker">
    <template
      v-if="mode ==='buttons'"
    >
      <div
        v-for="(item, name) in items"
        :key="name"
        @click="setPeriod(name)"
        class="periodPiker__item"
        :class="{'periodPiker__item-active': name === activePeriod}"
      >
        {{ item.name }}
      </div>
    </template>
    <template
      v-if="mode ==='select'"
    >
      <app-select-single
        class="periodPiker__select"
        label="Выберите период"
        item-text="name"
        item-value="id"
        :value.sync="selectPeriod"
        :items="selectItems"
      />
    </template>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import SelectSingle from '@/components/UI-Kit/selects/SelectSingle'

export default {
  name: 'DashboardPeriodPiker',
  components: {
    'app-select-single': SelectSingle
  },
  props: {
    default: {
      type: Object,
      require: false
    },
    mode: {
      type: String,
      require: false,
      default: 'buttons' // buttons || select
    }
  },
  data: () => ({
    items: {
      day: {
        name: 'Сегодня',
        from: `${dayjs().format('YYYY-MM-DD')}`,
        to: `${dayjs().format('YYYY-MM-DD')}`
      },
      yesterday: {
        name: 'Вчера',
        from: `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`,
        to: `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`
      },
      month: {
        name: 'Месяц',
        from: `${dayjs().subtract(1, 'month').format('YYYY-MM-DD')}`,
        to: `${dayjs().format('YYYY-MM-DD')}`
      },
      quarter: {
        name: 'Квартал',
        from: `${dayjs().subtract(3, 'month').format('YYYY-MM-DD')}`,
        to: `${dayjs().format('YYYY-MM-DD')}`
      },
      year: {
        name: 'Год',
        from: `${dayjs().subtract(1, 'year').format('YYYY-MM-DD')}`,
        to: `${dayjs().format('YYYY-MM-DD')}`
      }
    },
    today: null,
    from: null,
    to: null
  }),
  computed: {
    activePeriod () {
      let active = ''
      for (let item in this.items) {
        if (this.items[item].from === this.default.from && this.items[item].to === this.default.to) {
          active = item
        }
      }
      return active
    },
    selectItems () {
      let selectItems = []
      for (let item in this.items) {
        selectItems.push({
          id: item,
          name: this.items[item].name
        })
      }
      return selectItems
    },
    selectPeriod: {
      get () {
        return this.activePeriod
      },
      set (period) {
        this.setPeriod(period)
      }
    }
  },
  methods: {
    setPeriod (period) {
      this.$emit('update', this.items[period])
    }
  }
}
</script>

<style scoped lang="scss">
  .periodPiker {
    display: flex;
    border: 1px solid #E0E0E0;
    border-radius: 30px;
    height: 50px;
    margin-right: 30px;
    font-size: 17px;
    line-height: 21px;
    transition: 0.3s;
    overflow: hidden;
    &:hover {
      box-shadow: 0 2px 16px rgb(0 0 0 / 14%);
    }
    &__item {
      height: 100%;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
      & + & {
        border-left: 1px solid #E0E0E0;
      }
      &-active {
        background: #ED5656;
        color: #FFFFFF;
      }
    }
    &__select {
      width: 100%;
      ::v-deep {
        .v-input__slot {
          box-shadow: none;
        }
      }
    }
  }
</style>
