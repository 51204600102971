<template>
  <v-menu
    ref="menu"
    content-class="calendar-menu calendar-menu--search"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dates"
    transition="scale-transition"
    offset-y
    :max-width="$vuetify.breakpoint.smAndUp ? '334px' : '300px'"
    min-width="290px"
    max-height="391px"
    nudge-top="6"
    :disabled="disabled"
    v-scroll="onScroll"
  >
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-text-field
          ref="input"
          class="input calendar-input calendar-input--search"
          v-model="datesFormatted"
          :label="inputLabel"
          rounded
          filled
          clearable
          readonly
          :disabled="disabled"
          :rules="rules"
          v-mask="mask"
          @click:clear="clickClear"
          :hide-details="!required"
          @keypress.enter="submit"
        >
          <template
            v-if="icon"
            v-slot:prepend-inner
          >
            <div
              class="calendar-input__icon d-flex"
              v-html="require(`@/assets/img/icons/calendar-search.svg`)"
            />
          </template>
          <template v-slot:append>
            <div
              class="calendar-input__icon d-flex"
              v-html="require(`@/assets/img/icons/calendar.svg`)"
            />
          </template>
        </v-text-field>
      </div>
    </template>
    <v-date-picker
      v-model="dates"
      :allowed-dates="allowedDates"
      multiple
      no-title
      scrollable
      range
      locale="ru"
      :first-day-of-week="1"
      :weekday-format="weekdayFormat"
      :header-date-format="titleDateFormat"
    >
      <app-button
        mode="link"
        @click="reset"
      >
        Очистить
      </app-button>
      <v-spacer/>
      <app-button
        mode="outline"
        size="s"
        @click="menu = false"
      >
        Отменить
      </app-button>
      <app-button
        size="s"
        @click="submit"
      >
        Применить
      </app-button>
    </v-date-picker>
  </v-menu>
</template>

<script>
/**
 * Компонент выбора интервала дат
 * @view
 * Инпут с выпадающим меню для выбора дат
 * @actions
 * allowedDates: Возвращает доступные для выбора даты (до сегодняшней даты включительно)
 * submit: Сохраняет в форму выбранные в меню даты
 * update: Возвращает объект с выбранными датами в формате для отправки на сервер
 * reset: Удаляет выбранные даты
 * weekdayFormat: Определяет внешний вид дней недели
 * titleDateFormat: Определяет внешний вид даты в шапке календаря
 * @props
 * default Array Начальное значение (массив дат в формате timestamp)
 * label String Заголовок
 * icon Boolean Добавляет иконку перед инпутом
 * required Boolean
 * disabled Boolean
 * clear Boolean Удалить выбранные даты
 * future Boolean Если разрешены только будущие даты включая сегодня
 */
import dayjs from 'dayjs'
import Button from '@/components/UI-Kit/buttons/Button'
import { mask } from 'vue-the-mask'

export default {
  name: 'SearchDatePicker',
  directives: { mask },
  components: {
    'app-button': Button
  },
  props: {
    default: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    label: {
      type: String,
      required: false,
      default: 'Дата или интервал дат'
    },
    unlockedDates: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: Boolean,
    required: Boolean,
    future: Boolean,
    disabled: Boolean,
    clear: Boolean
  },
  data () {
    return {
      mask: '##.##.#### - ##.##.####',
      menu: false,
      time: ['00:00', '23:59'],
      today: dayjs().format('YYYY-MM-DD'),
      dates: [],
      rules: [],
      rulesList: {
        required: value => !!value || 'Обязательно для заполнения.'
      },
      weekdays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      months: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
      ]
    }
  },
  computed: {
    inputLabel () {
      return this.required ? this.label + '*' : this.label
    },
    datesFormatted: {
      get () {
        if (this.dates && !this.dates.length) return ''
        let dates = this.dates.map(el => dayjs(el).format('DD.MM.YYYY'))
        return dates.length > 1 ? dates.join(' - ') : dates
      },
      set (val) {
        this.dates = this.dates
        // if (val.length === 10) {
        //   let dates = val.split(' - ')
        //   dates[1] = dates[0]
        //   this.dates = dates.map(el => dayjs(el).format('YYYY-DD-MM'))
        // } else if (val.length === 23) {
        //   let dates = val.split(' - ')
        //   if (dates[0] && dates[0].length === 10 && dates[1] && dates[1].length === 10) {
        //     this.dates = dates.map(el => dayjs(el).format('YYYY-DD-MM'))
        //   } else {
        //     this.dates = this.dates
        //   }
        // }
      }
    },
    datesServerFormatted () {
      if (this.dates.length === 2) {
        return {
          from: `${this.dates[0]}T${this.time[0]}:00Z`,
          to: `${this.dates[1]}T${this.time[1]}:59Z`
        }
      } else if (this.dates.length === 1) {
        return {
          from: `${this.dates[0]}T${this.time[0]}:00Z`,
          to: `${this.dates[0]}T${this.time[1]}:59Z`
        }
      } else {
        return {}
      }
    }
  },
  watch: {
    default (v) {
      this.setDefault()
    },
    menu () {
      if (!this.menu) this.$refs.input.validate()
    },
    clear () {
      if (this.clear) {
        this.reset()
        this.$emit('update:clear', false)
      }
    }
  },
  mounted () {
    if (this.required) this.rules.push(this.rulesList.required)
    if (this.default.length && this.default[0].length && this.default[1].length) this.setDefault()
    if (this.dates.length) this.update()
  },
  methods: {
    changeDates () {
      if (this.dates.length === 2) {
        if (dayjs(this.dates[0]).diff(dayjs(this.dates[1])) > 0) this.dates = this.dates.reverse()
      }
    },
    setDefault () {
      let from = this.default[0].split('T')[0]
      let to = this.default[1].split('T')[0]
      if (from.length && to.length) {
        this.dates = [from, to]
      } else if (from.length) {
        this.dates = [from]
      } else {
        this.dates = []
      }
      // this.update()
    },
    allowedDates (val) {
      if (this.unlockedDates) {
        return val
      } else {
        let dateToNum = (date) => parseInt(date.replace(/-/g, ''))
        return this.future ? dateToNum(val) >= dateToNum(this.today) : dateToNum(val) <= dateToNum(this.today)
      }
    },
    submit () {
      this.changeDates()
      this.$refs.menu.save(this.dates)
      this.update()
    },
    update () {
      this.$emit('update', this.datesServerFormatted)
    },
    reset () {
      this.dates = []
    },
    clickClear () {
      this.reset()
      this.$refs.input.blur()
      this.update()
    },
    weekdayFormat (date) {
      let i = dayjs(date).format('d')
      return this.weekdays[i]
    },
    titleDateFormat (date) {
      let m = dayjs(date).format('M')
      let y = dayjs(date).format('YYYY')
      return `${this.months[m - 1]} ${y}`
    },
    onScroll () {
      this.menu = false
    }
  }
}
</script>

<style lang="scss">

</style>
