import { newsApi } from '@/api/news'
import Vue from 'vue'
// import { minioURL } from '../../../../api/minio'

// function formatSort (sort) {
//   return sort.map(item => `${item.property},${item.direction}`).join('')
// }

const getDefaultState = () => {
  return {}
}

export default {
  namespaced: true,
  state: () => ({
    news: [],
    newsItem: {},
    inProcess: false,
    success: false,
    error: false,
    statusEdit: '',
    page: 0,
    totalElements: 0,
    tableLoad: false,
    timeoutTime: 1000,
    lastPage: false,
    imageBaseUrl: Vue.prototype.$minioURL
  }),
  mutations: {
    LOAD_NEWS (state, data) {
      if (!data.content.length) state.news = []
      else {
        state.news = data.content
        state.totalElements = data.content.length
      }
    },
    LOAD_PAGE (state, data) {
      state.page = data.page
      if (!data.page) state.news = data.content
      else state.news = [...state.news, ...data.content]
    },
    FIND_NEWS_ITEM (state, data) {
      state.newsItem = state.news.find(el => el.id === data.newsItemId)
    },
    LOAD_NEWS_ITEM (state, data) {
      if (!data) state.newsItem = {}
      else state.newsItem = data
    },
    ADD_NEWS (state, data) {
      state.news.unshift(data)
    },
    EDIT_NEWS (state, data) {
      let newsItem = state.news.find(el => el._id === data._id)
      if (newsItem) state.news.splice(state.news.indexOf(newsItem), 1, data)
    },
    REMOVE_NEWS (state, data) {
      data.forEach(el => {
        let find = state.news.find(find => find._id === el)
        state.news.splice(state.news.indexOf(find), 1)
      })
      state.totalElements -= data.length
    },
    SET_PROCESS (state, val) {
      state.inProcess = val
    },
    SET_SUCCESS (state, data) {
      state.success = data.success
    },
    SET_ERROR (state, data) {
      state.error = data.error
    },
    SET_TABLE_LOAD (state, data) {
      state.tableLoad = data
    },
    SET_STATUS_EDIT (state, status) {
      state.statusEdit = status
    },
    CLEAR (state) {
      Object.assign(state, getDefaultState())
    },
    SET_LAST_PAGE (state, data) {
      state.lastPage = data
    }
  },
  actions: {
    async loadNews ({ commit, dispatch }, data) {
      commit('SET_PROCESS', true)
      commit('SET_ERROR', { error: '' })
      try {
        const res = await newsApi.search(data.id, { page: data.pagination, search: data.search })
        if (!res.data.content.length && res.data.page) commit('SET_LAST_PAGE', true)
        else if (!res.data.content.length && !res.data.page) {
          commit('LOAD_NEWS', res.data)
          commit('SET_ERROR', { error: '404' })
        } else commit('LOAD_NEWS', res.data)
      } catch (e) {
        commit('SET_ERROR', { error: '500' })
        console.log(e)
        throw e
      } finally {
        commit('SET_PROCESS', false)
      }
    },
    timeout ({ state }) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, state.timeoutTime)
      })
    },
    async loadPage ({ commit }, data) {
      commit('SET_TABLE_LOAD', true)
      try {
        const res = await newsApi.search(data.id, { page: data.pagination, search: data.search })
        commit('SET_TABLE_LOAD', false)
        commit('LOAD_PAGE', res.data)
      } catch (e) {
        commit('SET_TABLE_LOAD', false)
      }
    },
    async loadEdit ({ commit, dispatch, state }, data) {
      commit('LOAD_NEWS_ITEM')
      commit('SET_STATUS_EDIT', '')
      if (state.news.length) {
        commit('FIND_NEWS_ITEM', data)
      } else {
        commit('SET_STATUS_EDIT', 'loading')
        let p1 = dispatch('loadNewsItem', data)
        let p2 = dispatch('timeout')
        Promise.all([p1, p2]).then(values => {
          if (Object.keys(values[0]).length !== 0) {
            commit('LOAD_NEWS_ITEM', values[0])
            commit('SET_STATUS_EDIT', '')
          } else commit('SET_STATUS_EDIT', '404')
        }, reason => commit('SET_STATUS_EDIT', '404'))
      }
    },
    async loadNewsItem ({ commit, dispatch, state }, data) {
      commit('LOAD_NEWS_ITEM')
      commit('SET_STATUS_EDIT', 'loading')
      try {
        let res = await newsApi.item(data.newsItemId)
        commit('LOAD_NEWS_ITEM', res.data)
        commit('SET_STATUS_EDIT', '')
      } catch (err) {
        commit('SET_STATUS_EDIT', '404')
        throw err
      }
    },
    async addNews ({ commit, dispatch, getters }, { data, image, thumbImage }) {
      commit('SET_PROCESS', true)
      let img, thumbImg
      try {
        if (!data.data.toDate) {
          data.data.toDate = Vue.prototype.$infinityDate
        }
        if (image) {
          img = await dispatch('addImage', { image: image, thumb: false })
        }
        if (thumbImage) {
          thumbImg = await dispatch('addImage', { image: thumbImage, thumb: true })
        }
        if (img) data.data.imageUrls = [getters.getImageBaseUrl + img.data]
        if (thumbImage) data.data.thumbImg = getters.getImageBaseUrl + thumbImg.data
        data.data.toDateTimestamp = Date.parse(data.data.toDate)
        data.data.fromDateTimestamp = Date.parse(data.data.fromDate)
        const res = await newsApi.add(data.data)
        dispatch('addSuccess', res)
      } catch (e) {
        dispatch('addError', { text: 'Произошла ошибка.' })
      }
    },
    addHalfSuccess ({ commit }, { r, value }) {
      commit('ADD_NEWS', r.data)
      this.dispatch('showAlert', { text: value, type: 'error', halfSuccess: true })
    },
    addSuccess ({ commit }, data) {
      commit('ADD_NEWS', data)
      this.dispatch('showAlert', { text: 'Новость создана.', type: 'success' })
      commit('SET_PROCESS', false)
    },
    addError ({ commit }, alert) {
      this.dispatch('showAlert', { text: alert.text, type: 'error' })
    },
    async editNews ({ commit, dispatch }, { editId, data, image, thumbImage }) {
      commit('SET_PROCESS', true)
      let img, thumbImg
      try {
        if (!data.data.retailNetworkId) delete data.data.retailNetworkId

        if (image !== undefined && typeof image !== 'string') {
          if (image === null && data.data.imageUrls.length) {
            await dispatch('removeImage', data.data.imageUrls[0].slice(5))
            data.data.imageUrls = []
          } else if (image && data.data.imageUrls.length) {
            await dispatch('removeImage', data.data.imageUrls[0].slice(5))
            img = await dispatch('addImage', { image, thumb: false })
          } else if (image && !data.data.imageUrls.length) {
            img = await dispatch('addImage', { image, thumb: false })
          }
        }

        if (thumbImage !== undefined && typeof thumbImage !== 'string') {
          if (thumbImage === null && data.data.thumbImg) {
            await dispatch('removeImage', data.data.thumbImg.slice(5))
            data.data.thumbImg = ''
          } else if (thumbImage && data.data.thumbImg) {
            await dispatch('removeImage', data.data.thumbImg.slice(5))
            thumbImg = await dispatch('addImage', { image: thumbImage, thumb: true })
          } else if (thumbImage && !data.data.thumbImg) {
            thumbImg = await dispatch('addImage', { image: thumbImage, thumb: true })
          }
        }
        if (!data.data.toDate) {
          data.data.toDate = Vue.prototype.$infinityDate
        }
        if (img) {
          data.data.imageUrls = [img.data]
          delete data.data.image
        }
        if (thumbImg) data.data.thumbImg = thumbImg.data
        data.data.toDateTimestamp = Date.parse(data.data.toDate)
        data.data.fromDateTimestamp = Date.parse(data.data.fromDate)
        const res = await newsApi.edit(data)
        dispatch('editSuccess', res)
        commit('SET_PROCESS', false)
      } catch (e) {
        throw e
      }
    },
    async removeImage ({ commmit }, editId) {
      try {
        await newsApi.removeImage(editId)
      } catch (e) {
        return 'Не удалось загрузить изображение.'
      }
    },
    async addImage ({ commmit }, { editId, image, thumb }) {
      let form = new FormData()
      form.append('image', image)
      form.append('newsId', editId)
      form.append('thumb', thumb)
      try {
        let res = await newsApi.addImage(form)
        return res
      } catch (e) {
        return 'Не удалось загрузить изображение.'
      }
    },
    editSuccess ({ commit }, data) {
      commit('EDIT_NEWS', data.data)
      this.dispatch('showAlert', { text: 'Изменения сохранены.', type: 'success' })
    },
    editError ({ commit }, data) {
      this.dispatch('showAlert', { text: data, type: 'error' })
    },
    async editPublished ({ commit }, data) {
      try {
        const res = await newsApi.edit(data)
        commit('EDIT_NEWS', res.data)
        this.dispatch('showAlert', { text: 'Изменения сохранены.', type: 'success' })
      } catch (e) {
        console.log('e', e)
      }
    },
    async removeNews ({ commit, state }, data) {
      commit('SET_TABLE_LOAD', true)
      if (!data.imageIds.length || !data.imageIds.every(i => i)) {
        data.imageIds = 'emptyArray'
      }
      try {
        let res = await newsApi.remove(data)
        commit('SET_TABLE_LOAD', false)
        commit('LOAD_NEWS', res.data)
        if (!res.data.content.length) commit('SET_ERROR', { error: '404' })
      } catch (e) {
        commit('SET_TABLE_LOAD', false)
      }
    },
    setSuccess ({ commit }, data) {
      commit('SET_SUCCESS', data)
    },
    setError ({ commit }, data) {
      commit('SET_ERROR', data)
    }
  },
  getters: {
    getNews: (state) => state.news,
    getPage: (state) => state.page,
    getTotalElements: (state) => state.totalElements,
    getNewsItem: (state) => state.newsItem,
    getInProcess: (state) => state.inProcess,
    getSuccess: (state) => state.success,
    getError: (state) => state.error,
    getTableLoad: (state) => state.tableLoad,
    getStatusEdit: (state) => state.statusEdit,
    getLastPage: state => state.lastPage,
    getImageBaseUrl: state => state.imageBaseUrl
  }
}
