import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import user from '@/store/modules/user'
import users from '@/store/modules/users'
import setUserOptions from '@/store/modules/setUserOptions'
import registerModules from '@/store/modules/registerModules'
import clubs from '@/store/modules/clubs'
import logs from '@/store/modules/logs'
import popups from '@/store/modules/popups'
import alerts from '@/store/modules/alerts'
import nav from '@/store/modules/nav'

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    production: false
  }
}

const state = getDefaultState()

const mutations = {
  production (state) {
    state.production = true
  },
  RESET_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  resetState ({ commit }) {
    commit('RESET_STATE')
  }
}

const getters = {}

export default new Vuex.Store({
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth,
    user,
    users,
    setUserOptions,
    registerModules,
    clubs,
    logs,
    popups,
    alerts,
    nav
  }
})
