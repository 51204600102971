<template>
  <div class="dashboard__progress">
    <span>{{ title }}</span>
    <div class="d-flex justify-space-between align-baseline">
      <div class="dashboard__progressBar">
        <div class="dashboard__progressDeterminate"
             v-bind:style="{width: percent + '%'}"
        />
      </div>
      <div class="dashboard__progressNum">
        <strong>{{ beforePercent.toLocaleString() }}</strong> {{ percentWithBrackets }}
      </div>
    </div>
    <span class="dashboard__subtitle">
      {{ subtitle }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'dashboardProgressBar',
  props: {
    title: {
      type: String,
      require: false,
      default: ''
    },
    subtitle: {
      type: String,
      require: false,
      default: ''
    },
    beforePercent: {
      type: Number,
      require: false
    },
    percent: {
      type: String,
      require: true,
      default: '0'
    }
  },
  computed: {
    percentWithBrackets () {
      if (this.percent) {
        return this.beforePercent !== undefined ? '(' + this.percent + '%)' : this.percent + '%'
      } else {
        return this.beforePercent !== undefined ? '(' + 0 + '%)' : 0 + '%'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard {
  &__progress {
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
    line-height: 17px;
  }
  &__progressNum {
    text-align: right;
    white-space: nowrap;
    flex-basis: 80px;
    padding-left: 6px;
    width: 100px;
  }
  &__progressBar {
    flex-basis: calc(100% - 100px);
    width: 100%;
    height: 10px;
    overflow: hidden;
    background: rgba(158, 158, 158, 0.2);
    border-radius: 10px;
  }
  &__progressDeterminate {
    background: #ED5656;
    border-radius: 10px;
    height: 100%;
    width: 20%;
  }
  &__subtitle {
    color: #ED5656;
  }
}
</style>
